@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  src: local('Open Sans Light'), local('OpenSans-Light'), url("../fonts/OpenSans-Light.ttf") format('truetype');
  unicode-range: U+0460-052F, U+20B4, U+2DE0-2DFF, U+A640-A69F;
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  src: local('Open Sans Light'), local('OpenSans-Light'), url("../fonts/OpenSans-Light.ttf") format('truetype');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  src: local('Open Sans Light'), local('OpenSans-Light'), url("../fonts/OpenSans-Light.ttf") format('truetype');
  unicode-range: U+1F00-1FFF;
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  src: local('Open Sans Light'), local('OpenSans-Light'), url("../fonts/OpenSans-Light.ttf") format('truetype');
  unicode-range: U+0370-03FF;
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  src: local('Open Sans Light'), local('OpenSans-Light'), url("../fonts/OpenSans-Light.ttf") format('truetype');
  unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  src: local('Open Sans Light'), local('OpenSans-Light'), url("../fonts/OpenSans-Light.ttf") format('truetype');
  unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  src: local('Open Sans Light'), local('OpenSans-Light'), url("../fonts/OpenSans-Light.ttf") format('truetype');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: local('Open Sans'), local('OpenSans'), url("../fonts/OpenSans-Regular.ttf") format('truetype');
  unicode-range: U+0460-052F, U+20B4, U+2DE0-2DFF, U+A640-A69F;
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: local('Open Sans'), local('OpenSans'), url("../fonts/OpenSans-Regular.ttf") format('truetype');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: local('Open Sans'), local('OpenSans'), url("../fonts/OpenSans-Regular.ttf") format('truetype');
  unicode-range: U+1F00-1FFF;
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: local('Open Sans'), local('OpenSans'), url("../fonts/OpenSans-Regular.ttf") format('truetype');
  unicode-range: U+0370-03FF;
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: local('Open Sans'), local('OpenSans'), url("../fonts/OpenSans-Regular.ttf") format('truetype');
  unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: local('Open Sans'), local('OpenSans'), url("../fonts/OpenSans-Regular.ttf") format('truetype');
  unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: local('Open Sans'), local('OpenSans'), url("../fonts/OpenSans-Regular.ttf") format('truetype');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: local('Open Sans Semibold'), local('OpenSans-Semibold'), url("../fonts/OpenSans-Semibold.ttf") format('truetype');
  unicode-range: U+0460-052F, U+20B4, U+2DE0-2DFF, U+A640-A69F;
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: local('Open Sans Semibold'), local('OpenSans-Semibold'), url("../fonts/OpenSans-Semibold.ttf") format('truetype');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: local('Open Sans Semibold'), local('OpenSans-Semibold'), url("../fonts/OpenSans-Semibold.ttf") format('truetype');
  unicode-range: U+1F00-1FFF;
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: local('Open Sans Semibold'), local('OpenSans-Semibold'), url("../fonts/OpenSans-Semibold.ttf") format('truetype');
  unicode-range: U+0370-03FF;
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: local('Open Sans Semibold'), local('OpenSans-Semibold'), url("../fonts/OpenSans-Semibold.ttf") format('truetype');
  unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: local('Open Sans Semibold'), local('OpenSans-Semibold'), url("../fonts/OpenSans-Semibold.ttf") format('truetype');
  unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: local('Open Sans Semibold'), local('OpenSans-Semibold'), url("../fonts/OpenSans-Semibold.ttf") format('truetype');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: local('Open Sans Bold'), local('OpenSans-Bold'), url("../fonts/OpenSans-Bold.ttf") format('truetype');
  unicode-range: U+0460-052F, U+20B4, U+2DE0-2DFF, U+A640-A69F;
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: local('Open Sans Bold'), local('OpenSans-Bold'), url("../fonts/OpenSans-Bold.ttf") format('truetype');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: local('Open Sans Bold'), local('OpenSans-Bold'), url("../fonts/OpenSans-Bold.ttf") format('truetype');
  unicode-range: U+1F00-1FFF;
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: local('Open Sans Bold'), local('OpenSans-Bold'), url("../fonts/OpenSans-Bold.ttf") format('truetype');
  unicode-range: U+0370-03FF;
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: local('Open Sans Bold'), local('OpenSans-Bold'), url("../fonts/OpenSans-Bold.ttf") format('truetype');
  unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: local('Open Sans Bold'), local('OpenSans-Bold'), url("../fonts/OpenSans-Bold.ttf") format('truetype');
  unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: local('Open Sans Bold'), local('OpenSans-Bold'), url("../fonts/OpenSans-Bold.ttf") format('truetype');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 800;
  src: local('Open Sans Extrabold'), local('OpenSans-Extrabold'), url("../fonts/OpenSans-Extrabold.ttf") format('truetype');
  unicode-range: U+0460-052F, U+20B4, U+2DE0-2DFF, U+A640-A69F;
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 800;
  src: local('Open Sans Extrabold'), local('OpenSans-Extrabold'), url("../fonts/OpenSans-Extrabold.ttf") format('truetype');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 800;
  src: local('Open Sans Extrabold'), local('OpenSans-Extrabold'), url("../fonts/OpenSans-Extrabold.ttf") format('truetype');
  unicode-range: U+1F00-1FFF;
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 800;
  src: local('Open Sans Extrabold'), local('OpenSans-Extrabold'), url("../fonts/OpenSans-Extrabold.ttf") format('truetype');
  unicode-range: U+0370-03FF;
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 800;
  src: local('Open Sans Extrabold'), local('OpenSans-Extrabold'), url("../fonts/OpenSans-Extrabold.ttf") format('truetype');
  unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 800;
  src: local('Open Sans Extrabold'), local('OpenSans-Extrabold'), url("../fonts/OpenSans-Extrabold.ttf") format('truetype');
  unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 800;
  src: local('Open Sans Extrabold'), local('OpenSans-Extrabold'), url("../fonts/OpenSans-Extrabold.ttf") format('truetype');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}

@font-face {
	font-family:"SolaimanLipi";
	src:url("fonts/SolaimanLipi.ttf") format("truetype");
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Siyam Rupali';
	src: url('fonts/SiyamRupali.eot?version=1.070');
	src: local('Siyam Rupali'), url('fonts/SiyamRupali.woff?version=1.070') format('woff'),       url('fonts/SiyamRupali.ttf?version=1.070') format('truetype');
	font-style: normal;
	font-weight: normal;
	unicode-range: U+0980-09FF;
}

@font-face {
	font-family: 'Kalpurush';
	src: url('fonts/Kalpurush.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
	unicode-range: U+0980-09FF;
}


@font-face {
	font-family: 'Noto Sans';
	src: url('fonts/NotoSansBengali-Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
	unicode-range: U+0980-09FF;
}



/* Global Rules
========================
*/

body {
	position: relative;
}

body, h1, h2, h3, h4, h5, h6, span, strong {
	font-family: 'Open Sans', sans-serif;
}

body.bn-version,
.bn-version h1,
.bn-version h2,
.bn-version h3,
.bn-version h4,
.bn-version h5,
.bn-version h6,
.bn-version span,
.bn-version strong,
.tooltip {
	font-family: 'SolaimanLipi', 'Kalpurush', 'Siyam Rupali', 'Open Sans', sans-serif;
}

.bn-version .glyphicon {
	font-family: 'Glyphicons Halflings';
}

a,
a:hover,
a:focus,
a:visited,
a:active {
	outline: 0;
}

h1 {
	color: #fff;
}

.h1, h1 {
	font-size: 34px;
	line-height: 40px;
}

.h2, h2 {
	font-size: 35px;
	font-weight: 700;
	color: #454545;
	margin-top: 0;
	margin-bottom: 40px;
}

.bn-version .h2, .bn-version h2 {
	font-size: 48px;
	line-height: 50px;
	margin-bottom: 20px;
}

.h3, h3 {
	font-size: 25px;
	margin-top: 0;
	margin-bottom: 0;
}

.bn-version .h3, .bn-version h3 {
	font-size: 30px;
}

.h4, h4 {
	font-size: 20px;
	color: #747474;
}

aside h4 {
	margin-top: 0;
	margin-bottom: 20px;
	font-size: 20px;
	line-height: 25px;
	font-weight: 600;
	color: #777777;
}

.bn-version aside h4 {
	font-size: 25px;
	line-height: 30px;
}

p {
	font-size: 13px;
	line-height: 20px;
	color: #6f6f6f;
	margin-bottom: 50px;
}

.bn-version p {
	font-size: 18px;
	line-height: 20px;
	margin-bottom: 20px;
}

p.total-user-review {
	margin-bottom: 0;
}

.bn-version .dir-address-wrap i{
	font-size: 25px;
	line-height: 30px;
}

hr {
	margin-top: 30px;
	margin-bottom: 30px;
	border-top: 1px solid #c6c6c7;
}

.white-font {
	color: #fff;
}

.btn {
	border-radius: 0;
	padding: 15px 30px;
	border-width: 2px;
	margin-bottom: 20px;
	-webkit-transition: all linear 0.3s;
	-moz-transition: all linear 0.3s;
	transition: all linear 0.3s;
}

.bn-version .btn {
	font-size: 22px;
	line-height: 30px;
	font-weight: normal;
	padding: 10px 45px;
}

.bn-version .meet-doc .btn {
	padding: 10px 15px;
}

.btn:hover, .btn:focus {
	color: #fff;
	background-color: #00baed;
	border-color: #00baed;
}

.btn-default {
	font-size: 17px;
	background-color: transparent;
	color: #fff;
	font-weight: 400;
	border-color: #fff;
}

.bn-version .btn-default {
	font-size: 20px;
}

.btn-grey {
	border-color: #7b7b7b;
	color: #7b7b7b;
	background-color: #fff;
	font-weight: 700;
}

.btn-light-grey {
	border-color: #ddd;
	color: #7b7b7b;
	background-color: #fff;
	font-weight: 700;
	border-width: 1px;
}

.btn-prescription {
	background-color: #27ae60;
	color: #fff;
}

.btn-grey-trans {
	border-color: #7b7b7b;
	color: #7b7b7b;
	font-weight: 700;
}

.btn-green {
	border-color: #7b7b7b;
	color: #7b7b7b;
	background-color: #fff;
	font-size: 25px;
	line-height: 30px;
	font-weight: 700;
	margin-bottom: 30px;
}

.btn-browse {
	color: #777777;
	font-weight: 400;
	font-size: 17px;
	line-height: 23px;
	text-align: left;
	border: 1px solid #e9e9e9;
	background-color: #f5f7f8;
	padding: 10px 20px;
	margin-bottom: 10px;
}

.btn-checked {
	display: block;
	text-align: center;
	color: #fff;
	width: 100%;
	padding: 10px;
	margin-bottom: 0;
	-webkit-transition: all linear 0.3s;
	-moz-transition: all linear 0.3s;
	-ms-transition: all linear 0.3s;
	transition: all linear 0.3s;
}

.btn-green:hover,
.btn-green:focus,
.btn-green:active {
	background-color: #009d00;
	border-color: #009d00;
	color: #fff;
}

.btn-green:hover {

}

.btn-center {
	display: table;
	margin-left: auto;
	margin-right: auto;
	margin-top: 40px;
}

.btn-right {
	display: table;
	margin-left: auto;
	margin-top: 30px;
}

.btn-avatar {
	font-size: 25px;
	line-height: 30px;
	font-weight: 700;
	height: 85px;
	width: 100%;
	display: block;
}

.bn-version .btn-avatar {
	padding: 10px 5px;
}

.btn-dismiss {
	margin-bottom: 0;
	height: 60px;
	border-color: #00baff;
	border-width: 1px;
}

.bn-version .btn-dismiss {
	padding: 0;
}


.food-list-btn {
	border: 0;
    background-color: transparent;
    font-size: 18px;
    padding: 0;
}

aside .btn-avatar {
	height: 55px;
	font-size: 18px;
	font-weight: 400;
	padding: 0;
}


.btn-full {
	width: 100%;
}

.btn-meal-plan {
	margin-bottom: 0;
	font-size: 14px;
	font-weight: 700;
	padding-top: 3px ;
	padding-bottom: 3px;
}

.bn-version .btn.btn-meal-plan {
	padding: 5px 10px;
	border-radius: 5px;
	font-size: 18px;
	line-height: 20px;
	background-color: #a6a6a6;
	border-color: #a6a6a6;
	color: #fff;
    text-overflow: initial;
    white-space: normal;
}

.bn-version .btn.btn-meal-plan:hover {
	border-color: #00baff;
	background-color: #00baff;
}

.black {
	color: #333;
}

.blue {
	font-weight: 400;
	color: #00baed;
}

.author-img {
	border-radius: 50%;
    border: 2px solid #fff;
    box-shadow: 0 0 1px #222;
}

.author-img-circle {
	background-image: url(../img/home/author.jpg);
	background-repeat: no-repeat;
	background-position: top center;
	width: 100px;
    height: 100px;
    border-radius: 50%;
    display: block;
    margin: auto;
}

.btn-meta {
	padding: 7px 15px;
	margin-bottom: 0;
}

.btn-success.active,
.btn-success:active,
.open>.dropdown-toggle.btn-success {
	border-color: #0aa70a;
	background-color: #0aa70a;
	box-shadow: none;
	outline: 0;
}

.btn-success:hover,
.btn-success:focus {
	border-color: #0aa70a;
	box-shadow: none;
}


.img-center {
	margin: auto;
}

.img-right {
	margin-left: auto;
}

.img-left {
	margin-right: auto;
}

.img-full {
	display: block;
	width: 100%;
}

.img-circle {
	border-radius: 50%;
	border: 1px solid #666666;
}

.btn-controll {
	width: 100px;
    padding: 15px 10px;
    margin: 5px;
}

.border-center {
	margin: auto;
}

.big-text {
    display: block;
    font-size: 40px;
    font-weight: 900;
    margin-top: 10px;
}

p.bottom-margin {
	margin-bottom: 50px;
}

label {
	position: relative;
}

.bn-version label {
	font-size: 18px;
	line-height: 20px;
}

a {
	color: #777777;
}

a:hover {
	color: #777777;
	text-decoration: none;
}

a:focus,
a:active,
a:visited {
	text-decoration: none;
}

hr {
	border-color: #cacaca;
	margin-top: 30px;
	margin-bottom: 30px;
}

ul {
	padding-left: 0;
	margin-bottom: 0;
}


table {
	width: 100%;
	max-width: 100%;
	margin-bottom: 20px;
}

.knowledge-desc-left .knowledge-desc-meta p {
	margin-bottom: 10px;
}

.knowledge-desc-meta p {
	margin-bottom: 10px !important;
}

.knowledge-desc-meta table {
	margin: 10px 0;
}

.knowledge-desc-meta table > tbody > tr,
.knowledge-desc-meta table > tbody > tr > td,
.knowledge-desc-meta table > thead > tr,
.knowledge-desc-meta table > thead > tr > td,
.knowledge-desc-meta table > tbody > tr > th,
.knowledge-desc-meta table > thead > tr > th {
	border: 1px solid #d3d3d3;
}

table > tbody > tr > td,
table > thead > tr > td,
table > tbody > tr > th,
table > thead > tr > th {
	padding: 10px 25px;
	color: #4c4c4c;
	font-size: 16px;
	line-height: 20px;
}



/*.form-group {
	position: relative;
}*/


::-webkit-scrollbar {
      width: 10px;
      height: 10px;
}
::-webkit-scrollbar-track {
      background-color: #cecece;
}

::-webkit-scrollbar-thumb {
      background-color: #00baff;
}

.pdf-body {
	position: relative;
}

p.desc-sub {
	max-width: 75%;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 60px;
}

.table-responsive {
	border-color: #00baff;
}

.symptom-save-btn {
	display: none;
}

.pagination>li>a, .pagination>li>span {
	color: #00baff;
}

.pagination>.active>a,
.pagination>.active>a:focus,
.pagination>.active>a:hover,
.pagination>.active>span,
.pagination>.active>span:focus,
.pagination>.active>span:hover {
	border-color: #00baff;
	background-color: #00baff;
}

.en-font,
span.en-font {
	font-family: 'OpenSans', sans-serif;
}

@media (max-width: 767px) {
	body {
		overflow-x: hidden;
	}
	.desc-sub {
		max-width: 100%;
	}

	p.desc-sub {
		width: 100%;
		max-width: 100%;
	}

	.btn,
	.bn-version .btn {
		/*width: 100%;*/
		margin-bottom: 10px;
	}

	.bn-version .meet-doc .btn {
		padding-left: 0;
		padding-right: 0;
	}

	.symptom-save-btn {
		display: block;
	}

	.bn-version .h2, .bn-version h2 {
		font-size: 30px;
		line-height: 33px;
	}
}

/* KickAssText Slider
========================
*/
.kickassTextSlider {
	position: relative;
	overflow: hidden;
	border: 1px solid #e3e3e3;
}

.kickassTextSlider .nav-tabs {
	border-bottom: 0;
}

.kickassTextSlider ul {
	position: relative;
	display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

.kickassTextSlider > ul > li > a {
	padding-right: 60px !important;
	border: 0 !important;
}

.kickass-slider-nav {
	position: absolute;
	top: 0;
	right: 10px;
}

.kickass-slider-nav button {
	border: 0;
	outline: 0;
	background-color: transparent;
	font-size: 30px;
	line-height: 65px;
	color: #00baff;
	background-color: transparent;
}

/* Header Rules
========================
*/
.site-header {
	position: relative;
	padding: 0;
	margin: 0;
	z-index: 2;
	box-shadow: 1px 1px 5px rgba(34,34,34, 0.3);
}

.site-header .top-nav {
	position: relative;
	background-color: #f6f6f6;
}

.site-header .bottom-nav {
	background-color: #fff;
	/*height: 70px;*/
	width: 100%;
}

.site-header .bottom-nav>.container {
	position: relative;
	transition: all linear 0.3s;
}
.site-header .bottom-nav .search-slide-in {
	position: absolute;
	visibility: hidden;
	opacity: 0;
	padding: 0 15px;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 10;
	height: 0;
	-webkit-transition: all linear 0.3s;
	-moz-transition: all linear 0.3s;
	-o-transition: all linear 0.3s;
	transition: all linear 0.3s;
}

.site-header .bottom-nav .search-slide-in.search-toggled {
	position: absolute;
	display: block;
	visibility: visible;
	opacity: 1;
	height: 70px;
}

.bottom-nav .search-slide-in>.col-md-9,
.bottom-nav .search-slide-in>.col-md-2,
.bottom-nav .search-slide-in>.col-md-1 {
	padding: 0;
}

.bottom-nav .search-slide-in .form-control {
	height: 60px;
	width: 100%;
	border-color: #00baff;
	background-color: #fff;
	color: #000;
}

.bottom-nav .search-slide-in .form-group {
	margin-bottom: 0;
}

.bottom-nav .search-slide-in .btn-blue {
	height: 60px;
	margin-bottom: 0;
	border-width: 1px;
	border-color: #00baff;
	border-left: 0;
}

.bottom-nav .search-slide-in .btn-blue:hover {
	background-color: #127EA7;
	border-color: #127EA7;
}

.site-header .site-navigation {
	position: relative;
	border-radius: 0;
	padding: 0;
	margin: 0;
}

.site-header .clearfix {
	margin-bottom: 0;
	background-color: transparent;
}

.navbar {
	margin-bottom: 0;
	min-height: 25px;
}

.top-nav-left{
	position: relative;
}

.top-nav-right {
	min-height: 25px;
}

.social-list-wrap {
	min-height: 25px;
}

.social-list-wrap .vertical-align {
	height: 30px;
	width: 100%;

	display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

.site-header .social-list {
    padding-top: 2px;
}



.list-inline {
	padding-left: 0;
    list-style: none;
    margin-left: -5px;
}

.social-list>li.first {
	padding-left: 0;
}

.social-list>li>a {
	color: #656565;
	font-weight: 700;
	font-size: 17px;
	line-height: 30px;
	/*padding-top: 10px;*/
	/*padding-bottom: 10px;*/
}

/*.social-list>li>a>i {
	position: relative;
	top: 2px;
}*/


.site-title {
	font-size: 32px;
    font-weight: 400;
    margin: auto 0;
}


.site-title a {
    color: #3598db;
    background: url(../img/home/logo-beta.png)no-repeat center center;
    width: 98px;
    height: auto;
	background-size: contain;
    display: block;
    text-indent: -9999px;
}


.bg-img {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
	z-index: -1;
}

.navbar-nav>li>a {
	font-weight: 600;
	color: #656565;
	position: relative;
	-webkit-transition: all linear 0.3s;
	-moz-transition: all linear 0.3s;
	-ms-transition: all linear 0.3s;
	-o-transition: all linear 0.3s;
	transition: all linear 0.3s;
}

li.top-nav-search {
	padding: 0 15px;
	display: inline-flex !important;
	height: 68px;
}


li.top-nav-search>form {
	display: flex;
}

li.search-trigger a {
	font-size: 18px;

}

.site-header .nav>li.search-trigger a {
	padding-right: 0;
}


.nav>li.has-sub-menu>.sub-menu>li a:hover {
	text-decoration: none;
}

.nav>li {
	position: inherit;
	display: block;
}

.site-header .nav>li>a,
.site-footer .nav>li>a {
	padding: 5px 15px;
}

.bn-version .site-header .nav>li>a,
.bn-version .site-footer .nav>li>a {
	font-size: 17px;
	font-weight: normal;
}

.bn-version .nav-tabs>li>a{
	font-size: 18px;
	line-height: 22px;
}

.nav>li>a:focus,
.nav>li>a:hover,
.nav>li.active>a,
.nav>li.active>a:focus,
.nav>li.active>a:hover {
	background-color: transparent;
	color: #00baff;
}

input.nav-search {
	height: 33px;
	margin: auto;
	max-width: 175px;
	font-size: 12px;
	background: url(../img/home/search-icon.png) no-repeat scroll 7px 7px;
    background-position: 90%;
}


.navbar-toggle {
	background-color: #026a86;
	border: 1px solid #026a86;
	border-radius: 0;
}

.navbar-toggle .icon-bar {
	background-color: #fff;
}


.sub-menu {
	padding-left: 0;
	margin-bottom: 0;
}

.sub-menu>li {
	position: relative;
	list-style: none;
	/*height: 69px;*/
}

.sub-menu>li>a {
	position: relative;
	display: inline-block;
	padding: 17px 15px;
	margin: auto;
	font-size: 14px;
    line-height: 26px;
    color: #656565;
    -webkit-transition: all linear 0.3s;
    -moz-transition: all linear 0.3s;
    -ms-transition: all linear 0.3s;
    -o-transition: all linear 0.3s;
    transition: all linear 0.3s;
}

.bn-version .sub-menu>li>a {
	font-size: 18px;
}

.sub-menu>li:last-child>a {
	margin-right: -15px;
}

.sub-menu li a:hover,
.sub-menu>li.active>a {
	text-decoration: none;
	color: #00baff;
}

.nav .open>a, .nav .open>a:focus, .nav .open>a:hover {
	background-color: transparent;
	border: 0;
	text-decoration: none;
}

.navbar-nav>li>.dropdown-menu {
	padding: 0;
}

ul.dropdown-menu>li {
	display: block;
}

ul.dropdown-menu>li>a {
	display: block;
	padding: 10px 20px;
}

.bn-version ul.dropdown-menu>li>a {
	font-size: 18px;
	line-height: 20px;
}

.dropdown-menu>li>a:focus,
.dropdown-menu>li>a:hover {
	background-color: transparent;
}

.dropdown-menu>li>a:hover:before {
	border: 0;
}

.has-sub-menu .sub-menu {
    position: absolute;
    left: auto;
    right: 0;
    min-width: 220px;
    background: #fff;
    top: 106%;
    margin: 0;
    padding: 0;
    z-index: 1;

    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

.logo-wrap {
	height: 60px;
	width: 100%;

	display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

ul.dropdown-menu {
	padding: 0;
	margin: 0;
	border: 0;
	border-radius: 0;
}

.author-notification-list {
	padding-left: 0;
	margin-bottom: 0;
	min-height: 30px;

	display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;

	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	-ms-grid-row-align: center;
	align-items: center;
}

.author-notification-list li {
	list-style: none;
	position: relative;
	padding: 0 11.5px;
}

.author-notification-list>.search-trigger {
	padding-left: 0;
}

.author-notification-list>li:first-child {
	padding-left: 0;
}

.author-notification-list>li:last-child {
	padding-right: 0;
}

.blocked-nav {
	display: none;
	position: absolute;
    top: 100%;
    margin-top: 10px;
    left: 0;
    width: 100%;
    background-color: #fff;
    z-index: 9999;
    transition: all linear 0.3s;
}

.more-toggled {
	display: block;
}

.blocked-nav-list {
	padding-left: 0;
	margin-bottom: 0;
	display: table;
	margin: auto;
	border-collapse: collapse;
	position: relative;
}

.blocked-nav-list::after {
	content: " ";
	position: absolute;
	top: -10px;
	right: 15px;
	width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #fff;
}

.blocked-nav-list .nav-block-inner {
	display: table-row;
	border-bottom: 1px solid #ededed;
}

.blocked-nav-list .nav-block-inner:last-child {
	border-bottom: 0;
}

.blocked-nav-list li {
	display: table-cell;
}

.blocked-nav-list .nav-block-inner li {
	padding: 30px 35px;
	border-right: 1px solid #ededed;
	min-width: 16.666%;
	-moz-transition: all linear 0.3s;
	-webkit-transition: all linear 0.3s;
	-o-transition: all linear 0.3s;
	transition: all linear 0.3s;
}

.blocked-nav-list .nav-block-inner li:hover,
.blocked-nav-list .nav-block-inner li:focus,
.blocked-nav-list .nav-block-inner li.active {
	background-color: #00baff;
	border-color: #00baff;
}

.blocked-nav-list .nav-block-inner li:hover a,
.blocked-nav-list .nav-block-inner li:focus a,
.blocked-nav-list .nav-block-inner li.active a {
	color: #fff;
	display: block;
}

.blocked-nav-list .nav-block-inner li a {
	color: #acacac;
	font-weight: 600;
}

.blocked-nav-list .nav-block-inner li:last-child {
	border-right: 0;
}

.more-close {
    color: #d5d5d5;
    cursor: pointer;
    position: absolute;
    top: 50%;
    right: -36px;
    margin-top: -18px;
}


/*.dropdown .dropdown-menu {
	display: block;
}*/


.home-fixed-nav-wrap {
	display: none;
}

.home-fixed-nav {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	background-color: #00baff;
	display: flex;
	justify-content: center;
	z-index: 110;
	box-shadow: 1px 1px 5px rgba(34,34,34, 0.3);
}

.home-fixed-nav li {
	position: static !important;
	list-style: none;
	padding-left: 15px;
	padding-right: 15px;
	-webkit-transition: all ease 0.3s;
	-moz-transition: all ease 0.3s;
	-ms-transition: all ease 0.3s;
	transition: all ease 0.3s;
}


.home-fixed-nav li a {
	position: relative;
	font-size: 20px;
	padding-top: 15px;
	padding-bottom: 15px;
	color: #fff;
	display: block;
	-webkit-transition: all ease 0.3s;
	-moz-transition: all ease 0.3s;
	-ms-transition: all ease 0.3s;
	transition: all ease 0.3s;
}

.home-fixed-nav li a:hover:after,
.home-fixed-nav li.active a:after {
	content: " ";
    position: absolute;
    bottom: 0;
    width: 10px;
    height: 10px;
    background-color: #00baff;
    text-align: center;
    left: 50%;
    margin-left: -5px;
    margin-bottom: -5px;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.home-fixed-nav li:hover a {
	display: block;
	color: #00baff;
}

.home-fixed-nav li:focus a {
	background-color: #fff;
	color: #00baff;
}

.home-fixed-nav li a:focus {
	color: #fff;
}

.home-fixed-nav li:hover,
.home-fixed-nav li.active {
	background-color: #fff;
}


@media (max-width: 991px) {
	.social-list-wrap {
		display: table;
		position: relative;
		z-index: 10;
	}

	/*.logo-wrap {
		position: absolute;
		width: 98px;
		height: 45px;
		top: -130px;
	}*/

	li.top-nav-search {
		padding-right: 0;
	}
}



@media (min-width: 768px) {
	/*.logged-user-nav .top-nav-right {
		display: table-cell;
		width: 65%;
		vertical-align: top;
	}

	.logged-user-nav .author-notification-wrap {
		display: table;
		margin-left: auto;
	}*/
	.site-header .top-nav {
		border-bottom: 1px solid #e6e6e6;
	}

	.top-nav-right {
		margin-right: 40px;
	}

	.site-header .site-navigation {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: end;
		-webkit-justify-content: flex-end;
		    -ms-flex-pack: end;
		        justify-content: flex-end;
	}

	.social-nav .navbar-nav>li>a {
		padding-top: 5px;
		padding-bottom: 5px;
		padding-left: 10px;
		padding-right: 10px;
	}

	/*.top-nav-right .navbar-nav>li>a {
		padding-top: 24px;
		padding-bottom: 24px;
	}*/

	#main-menu .navbar-nav>li:not(.search-trigger)>a:hover::after,
	#main-menu .navbar-nav>li:not(.search-trigger).active>a::after {
		content: "";
		position: absolute;
		bottom: 0;
		left: 50%;
		display: inline-block;
	    width: 0;
	    height: 0;
	    margin-left: -4px;
	    vertical-align: middle;
	    border-top: 4px solid rgb(0,186,255);
	    border-right: 4px solid transparent;
	    border-left: 4px solid transparent;
	    /*border-bottom: transparent;*/
	}

	.sub-menu>li>a::after,
	.sub-menu>li.active>a::after {
		content: "";
		position: absolute;
		bottom: 16px;
		left: 50%;
		display: inline-block;
	    width: 0;
	    height: 0;
	    margin-left: -4px;
	    vertical-align: middle;
	    border-top: 4px dashed transparent;
	    border-top: 4px solid transparent;
	    border-right: 4px solid transparent;
	    border-left: 4px solid transparent;
	}

	.sub-menu>li>a:hover::after,
	.sub-menu>li.active>a::after {
	    border-top-color: rgb(0,186,255);
	    border-top-color: rgb(0,186,255);
	}

	.nav>li.has-sub-menu>a::before {
		content: " ";
		position: absolute;
		bottom: -3px;
		left: 50%;
		margin-left: -5px;
		width: 0;
		height: 0;
		border-left: 5px solid transparent;
	    border-right: 5px solid transparent;
	    border-bottom: 5px solid #fff;
	    z-index: 2;
	}

	.nav>li.has-sub-menu>a::after,
	#main-menu .navbar-nav>li.has-sub-menu:not(.search-trigger)>a:hover::after,
	#main-menu .navbar-nav>li.has-sub-menu:not(.search-trigger).active>a::after {
		content: " ";
		position: absolute;
		bottom: -3px;
		left: 50%;
		margin-left: -6px;
		width: 0;
		height: 0;
		border-top: transparent;
		border-left: 6px solid transparent;
	    border-right: 6px solid transparent;
	    border-bottom: 6px solid #e5e5e5;
	    z-index: 1;
	}

	/*#main-menu .navbar-nav>li.has-sub-menu:not(.search-trigger)>a:hover::after,
	#main-menu .navbar-nav>li.has-sub-menu:not(.search-trigger).active>a::after {
		border-top: 4px solid rgb(0,186,255);
		border-right: 4px solid transparent;
	    border-left: 4px solid transparent;
	}*/

	/*.dropdown:hover .dropdown-menu {
		display: block;
	}*/
}

/*@media (min-width: 768px) {
	.navbar-collapse.collapse {
		display: table;
		margin: auto;
	}
}*/

@media(max-width: 991px) {
	.has-sub-menu .sub-menu {
		left: auto;
		right: 0;
		width: auto;
		text-align: right;
		margin-right: 0;
	}

	.btn-right,
	.btn {
		display: table;
		margin: auto;
	}

	aside {
		margin-top: 50px;
	}

}

@media (max-width: 767px) {
	.bn-version .btn {
		font-size: 18px;
		line-height: 20px;
	}

	.navbar {
		min-height: 80px;
	}

	.social-nav-wrap ul {
		display: table;
		margin: auto;
	}
	.social-nav-wrap ul li {
		display: inline-block;
	}

	.has-sub-menu .sub-menu {
		width: 100%;
		position: relative;
		margin-bottom: 15px;
		background-color: #e2e2e2;
		right: 0;

		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.site-title {
		margin-right: auto;
		margin-left: 15px;
	}

	.site-header .site-navigation {
		padding: 0;
	}

	.site-header .navbar-nav {
		margin: 0;
	}

	.sub-menu li {
		display: block;
		text-align: left;
	}

	.nav>li.has-sub-menu {
		background-color: #026a86;
	}

	.nav>li.has-sub-menu>a {
		color: #fff;
	}

	.sub-menu li a {
		display: block;
		padding-left: 20px;
		padding-right: 10px;
		padding-top: 10px;
		padding-bottom: 10px;
	}

	.sub-menu li:not(:last-child) a {
		border-bottom: 1px dotted;
	}

	/*.sub-menu li a:last-child {
		border-bottom: 0;
	}*/

	.navbar-nav .open .dropdown-menu .dropdown-header,
	.navbar-nav .open .dropdown-menu>li>a {
		padding-left: 35px;
	}

	input.nav-search {
		margin-top: 0;
	}

	.site-header .bottom-nav {
		position: absolute;
		top: 100%;
		left: 0;
		width: 100%;
		background-color: #fff;
	}

	.site-header .bottom-nav .col-md-12 {
		background-color: transparent;
	}

	.site-header .logo-wrap {
		display: block;
		margin-left: 0;
		margin-right: 0;
		position: static;
		left: 0;
		height: auto;
		padding: 10px 0;
	}

	.site-header .social-list {
		position: relative;
		min-width: 200px;
		margin-top: 0;
		margin-bottom: 0;
		z-index: 1;
	}

	.social-list-wrap .vertical-align {
		height: 45px;
	}

	.dropdown-col {
		background-color: #f6f6f6;
		margin-left: -15px;
		margin-right: -15px;
		-webkit-box-shadow: 0 6px 12px rgba(0,0,0,.175);
   		box-shadow: 0 6px 12px rgba(0,0,0,.175);
	}

	.navbar {
		min-height: 1px;
	}

	/*.top-nav-right {
		min-height: 1px;
		width: 100%;
		position: absolute;
	    right: 0;
	    top: 50%;
	    margin-top: -20px;
	    background-color: #e2e2e2;
	}*/

	.navbar-collapse,
	.navbar-collapse.in {
		-webkit-box-shadow: 0 6px 12px rgba(0,0,0,.175);
    	box-shadow: 0 6px 12px rgba(0,0,0,.175);
    	background-color: #fff;
	}

	.sub-menu>li {
		height: auto;
	}

	.author-notification-list li {
		padding: 0 10.5px;
		position: relative;
		top: 7px;
	}

	.author-notification-list>li:first-child {
		padding-left: 0;
	}

	.bn-version .btn {
		padding: 10px 25px;
	}

	.site-title {
		margin-left: 0;
	}

	/*.author-notification-list {
		position: relative;
		top: 10px;
	}*/

	.site-header .site-navigation {
		min-height: 45px;
		padding: 0 15px;
	}

	.site-header .bottom-nav .search-slide-in {
		padding: 0 15px;
	}

	/*.navbar-toggle {
		margin-right: 0;
	}*/

	.bottom-nav .col-md-12 > .col-md-3,
	.bottom-nav .col-md-12 > .col-md-9,
	.top-nav .col-md-12 > .col-md-3 {
		padding: 0;
	}

	.navbar-toggle {
		margin-right: 0;
	}

	.author-notification-wrap {
		position: absolute;
	    right: 15px;
	    top: 101%;
	    z-index: 1;
	    min-height: 55px;
	}

	.social-list-wrap {
		min-width: 100px;
	}

	.author-notification-list {
		padding: 10px 0;
	}

	.bottom-nav .search-slide-in .form-control,
	.bottom-nav .search-slide-in .btn-blue,
	.bn-version .btn.btn-dismiss {
		height: 60px;
	}

}


/* About Us Page Rules
=============================
*/

.abt-heading {
	text-align: center;
	color: #fff;
	margin-bottom: 45px;
}

p.abt-sub {
	font-size: 35px;
	line-height: 38px;
	color: #fff;
	text-align: center;
	margin-bottom: 50px;
}

.abt-quote {
	padding: 100px 0;
}

.abt-blockquote {
	position: relative;
	margin-top: 45px;
}

.abt-blockquote::before {
    content: " ";
    position: absolute;
    top: -30px;
    left: 0;
    width: 36px;
    height: 25px;
    background-image: url(../img/about/bn-ql.png);
    background-position: top left;
    background-repeat: no-repeat;
}

.abt-blockquote::after {
    content: " ";
    position: absolute;
    bottom: -30px;
    right: 50px;
    width: 36px;
    height: 25px;
    background-image: url(../img/about/bn-qr.png);
    background-position: bottom right;
    background-repeat: no-repeat;
}

.abt-blockquote p {
	color: #929292;
	font-size: 25px;
	line-height: 30px;
	padding: 0 25px;
	margin-bottom: 0;
}

.abt-blockquote p .blue {
	font-size: 28px;
}

.abt-cause {
	position: relative;
	padding: 100px 0;
	background-color: #f8f8f8;
}

.abt-cause h2 {
	color: #656565;
	font-size: 35px;
	line-height: 40px;
	text-align: right;
	margin-bottom: 0;
}

.abt-cause .heading-border-wrap {
	display: table;
	margin-left: auto;
}

.abt-cause p {
	color: #fff;
	font-size: 18px;
	line-height: 22px;
}

.abt-cause-left p {
	text-align: right;
	color: #929292;
	margin-bottom: 0;
}

.abt-cause .heading-border {
	width: 60px;
}

.blue-block {
	padding: 30px;
	/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#00baed+0,8068ac+100 */
	background: rgb(0,186,237); /* Old browsers */
	background: -moz-linear-gradient(left, rgba(0,186,237,1) 0%, rgba(128,104,172,1) 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(left, rgba(0,186,237,1) 0%,rgba(128,104,172,1) 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to right, rgba(0,186,237,1) 0%,rgba(128,104,172,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00baed', endColorstr='#8068ac',GradientType=1 ); /* IE6-9 */
}

.abt-cause .blue-block {
	margin-left: auto;
}

.blue-block p {
	font-size: 25px;
	line-height: 28px;
}

.blue-block p:last-child {
	margin-bottom: 0;
}

.abt-cause .heading-border-wrap,
.abt-feature .heading-border-wrap {
	padding-top: 15px;
	padding-bottom: 20px;
}

.abt-feature {
	padding: 85px 0;
}

.abt-feature h3,
.abt-feature p {
	color: #a9d8f3;
}

.abt-feature p {
	text-align: justify;
}

.abt-feature-block .abt-feature-single {
	padding: 45px 40px;
}

.abt-feature-block .abt-feature-single p:last-child {
	margin-bottom: 0;
}

.abt-feature-block .abt-feature-single.blue {
	background-color: #455157;
}

.abt-feature-block .abt-feature-single.grey {
	background-color: #2281b8;
}


.abt-feature .heading-border {
	width: 60px;
}

.heading-border-wrap.white .heading-border {
	border-bottom-color: #fff;
}

.abt-trust,
.abt-value {
	position: relative;
	padding-top: 120px;
	padding-bottom: 105px;
}

.abt-trust h3,
.abt-trust p {
	color: #fff;
	text-align: center;
}

.abt-trust p {
	max-width: 600px;
	margin: auto;
	line-height: 25px;
}

.abt-trust .heading-border-wrap {
	padding-top: 15px;
	padding-bottom: 20px;
}

.heading-border-wrap.center .heading-border {
	width: 60px;
	margin: auto;
}

.abt-trust .heading-border {
	border-bottom-color: #fff;
}


.abt-does {
	position: relative;
	padding: 80px 0;
}

.abt-does h3 {
	color: #6d6d6d;
}

.abt-does p {
	text-align: justify;
	color: #929292;
}

.abt-does .heading-border-wrap,
.abt-value .heading-border-wrap {
	padding-top: 15px;
	padding-bottom: 20px;
}

.abt-does .heading-border {
	width: 60px;
}

.abt-does-single {
	padding: 25px;
	border: 1px solid #dbdbdb;
	margin-bottom: 20px;
	background-color: #fff;
}

.abt-does-single:last-child {
	margin-bottom: 0;
}

.abt-does-single p:last-child {
	margin-bottom: 0;
}

.abt-value h3 {
	text-align: center;
	color: #676767;
}

.abt-value p {
	color: #787878;
	max-width: 600px;
	margin: auto;
	line-height: 25px;
	text-align: center;
}

.offwhite {
	background-color: #f5f5f5;
}

/* About Destiny Section Rules */

.abt-destiny {
	padding: 100px 0 60px 0;
	background-color: #ebf0f4;
}

h2.destiny-title {
	position: relative;
	background-color: #00baed;
	max-width: 450px;
	color: #fff;
	font-size: 30px;
	line-height: 35px;
	text-align: center;
	padding: 50px 20px 20px 20px;
	margin: 0 auto 30px auto;
}

h2.destiny-title:after {
	content: '';
	position: absolute;
	top: 0;
	left: 50%;
	background-image: url('../img/about/destiny.png');
	background-repeat: no-repeat;
	background-size: 95px;
	height: 95px;
	width: 95px;
	margin-left: -47.5px;
	margin-top: -47.5px;
}

p.destiny-desc {
	max-width: 500px;
	color: #929292;
	text-align: center;
	margin: auto;
}

/* About Servie Section Rules */

.abt-service {
	position: relative;
	padding: 100px 0;
}

.abt-service h2 {
	font-size: 35px;
	line-height: 40px;
	color: #656565;
}

.abt-service h2 span {
	color: #929292;
}

.abt-service .heading-border-wrap {
	display: table;
	margin: 0 auto 65px auto;
}

.abt-check-list li {
	position: relative;
	list-style-type: none;
	padding-left: 65px;
	padding-right: 50px;
}

.abt-check-list li p {
	color: #929292;
	font-size: 16px;
	line-height: 23px;
	text-align: justify;
}

.abt-check-list li:before {
	content: '';
	position: absolute;
	top: 4px;
	left: 0;
	background-image: url('../img/about/check.png');
	background-repeat: no-repeat;
	background-size: 33px;
	height: 33px;
	width: 33px;
}

/* About Popular Section Rules */
.abt-popular {
	position: relative;
	padding: 100px 0;
	background-color: #f6f6f6;
}

.abt-popular h2 {
	font-size: 35px;
	line-height: 40px;
	text-align: right
}

.abt-popular h2 span {
	display: block;
}

.abt-popular h2 span.first {
	color: #929292;
	font-weight: bold;
}

.abt-popular h2 span.second {
	color: #656565;
}

.abt-popular h2 span.third {
	color: #929292;
	font-weight: normal;
}

.abt-popular p {
	color: #929292;
	text-align: right;
}

.abt-popular .heading-border-wrap {
	display: table;
	margin-left: auto;
	margin-bottom: 20px;
}

/* About Workplace Section Rules */

.abt-workplace {
	position: relative;
	padding: 100px 0;
}

.abt-workplace h2 {
	font-size: 35px;
	line-height: 40px;
	margin-bottom: 10px;
}

.abt-workplace h2 span {
	display: block;
}

.abt-workplace h2 span.first {
	color: #656565;
}

.abt-workplace h2 span.first {
	color: #929292;
}

.abt-workplace .heading-border-wrap {
	margin-bottom: 20px;
}

/* About Help Section Rules */

.abt-help {
	position: relative;
	padding: 100px 0;
}

.abt-help h2 {
	text-align: right;
	font-size: 35px;
	line-height: 40px;
	margin-top: 50px;
}

.abt-help h2 span {
	display: block;
}

.abt-help h2 span.first {
	color: #656565;
}

.abt-help h2 span.second {
	color: #929292;
}

.abt-help .heading-border-wrap {
	display: table;
	margin-left: auto;
	margin-bottom: 20px;
}

.abt-help p {
	color: #929292;
	text-align: right;
}

/* About Confident Section Rules */

.abt-confident {
	position: relative;
	padding-top: 100px;
	background-color: #f9f9f9;
}

.abt-confident h2 {
	font-size: 35px;
	line-height: 40px
}

.abt-confident h2 span {
	display: block;
}

.abt-confident h2 span.first {
	color: #656565;
}

.abt-confident h2 span.second {
	color: #929292;
}

.abt-confident .heading-border-wrap {
	margin-bottom: 50px;
}

.img-confident-wrap {
	position: relative;
	-moz-transform: translate(2d);
	-ms-transform: translate(2d);
	-webkittransform: translate(2d);
	transform: translate(2d);
}

p.img-confident-text {
	position: absolute;
	font-size: 20px;
	line-height: 25px;
	top: 50%;
	left: 50%;
	padding: 30px;
	max-width: 500px;
	-moz-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	color: #fff;

	/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#8068ac+0,00baed+100 */
	background: rgba(128,104,172, .6); /* Old browsers */
	background: -moz-linear-gradient(-45deg, rgba(128,104,172,.6) 0%, rgba(0,186,237,.6) 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(-45deg, rgba(128,104,172,.6) 0%,rgba(0,186,237,.6) 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(135deg, rgba(128,104,172,.6) 0%,rgba(0,186,237,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#8068ac', endColorstr='#00baed',GradientType=.6 ); /* IE6-9 fallback on horizontal gradient */
}

@media ( max-width: 1200px ) {
	.abt-help h2 {
		margin-top: 15px;
	}
}

/* About Values Section Rules */

.abt-values {
	position: relative;
	padding: 100px 0;
}

.abt-values h2 {
	font-size: 35px;
	line-height: 40px;
	margin-top: 50px;
}

.abt-values h2 span {
	display: block;
}

.abt-values h2 span.first {
	color: #656565;
}

.abt-values h2 span.second {
	color: #929292;
}

.abt-values .heading-border-wrap {
	margin-bottom: 30px;
}

.abt-values p {
	color: #929292;
}

@media ( max-width: 1200px ) {
	.abt-values h2 {
		margin-top: 15px;
	}
}


/* About Enjoys Section Rules */
.abt-enjoys {
	position: relative;
	padding: 100px 0;
	background-color: #f9f9f9;
}

.abt-enjoys h2 {
	font-size: 35px;
	line-height: 40px;
	text-align: right;
	margin-top: 50px;
}

.abt-enjoys h2 span {
	display: block;
}

.abt-enjoys h2 span.first {
	color: #656565;
}

.abt-enjoys h2 span.second {
	color: #929292;
}

.abt-enjoys p {
	color: #929292;
	text-align: right;
}

.abt-enjoys .heading-border-wrap {
	display: table;
	margin: 0 0 30px auto;
}

@media ( max-width: 1200px ) {
	.abt-enjoys h2 {
		margin-top: 15px;
	}
}

/* About Challenge Page Rules */
.abt-challenge {
	position: relative;
	padding: 100px 0;
}

.challenge-inner-wrap {
	position: relative;
	box-shadow: 0 0 15px #ddd;
	padding: 50px 30px 30px 30px;
}

.challenge-inner-wrap:before {
	content: '';
	position: absolute;
	top: 0;
	left: 50%;
	background-image: url('../img/about/challen-icon.png');
	background-repeat: no-repeat;
	background-size: 94px;
	height: 94px;
	width: 94px;
	margin-left: -47px;
	margin-top: -47px;
}

.abt-challenge h2 {
	font-size: 35px;
	line-height: 40px;
}

.abt-challenge h2 span {
	display: block;
}

.abt-challenge h2 span.first {
	color: #656565;
}

.abt-challenge h2 span.second {
	color: #929292;
}

.abt-challenge .heading-border-wrap {
	margin-bottom: 30px;
}

/* About Work Style Section Rules */
.abt-work-style {
	position: relative;
	padding: 100px 0;
}

.work-style-wrap {
	position: relative;
}

.work-style-wrap:after {
	content: '';
	position: absolute;
	top: 0;
	left: 50%;
	background-image: url('../img/about/work-style-icon.png');
	background-repeat: no-repeat;
	background-size: 94px;
	height: 94px;
	width: 94px;
	margin-left: -47px;
	margin-top: -47px;
}

.abt-work-style .img-block {
	padding-left: 0;
}

.abt-work-style .content-block {
	padding-right: 0;
}

.work-style-inner {
	background-color: #fff;
	padding: 30px;
	min-height: 251px;
}

.abt-work-style h2 {
	font-size: 35px;
	line-height: 40px;
	color: #656565;
	text-align: right;
}

.abt-work-style h2 span {
	color: #929292;
}

.abt-work-style .heading-border-wrap {
	display: table;
	margin-left: auto;
	margin-bottom: 30px;
}

.abt-work-style p {
	color: #929292;
	text-align: right;
}

@media ( max-width: 1200px ) {
	.abt-work-style h2 {
		margin-bottom: 5px;
	}

	.abt-work-style .heading-border-wrap {
		margin-bottom: 15px;
	}

	.work-style-inner {
		padding: 20px;
		min-height: 206px;
	}
}

/* About Hard Work Section Rules */

.abt-hard-work {
	position: relative;
	padding: 200px 0 100px 0;
}

.hard-work-inner {
	position: relative;
	padding: 50px 100px;

	/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#8068ac+0,00baed+100 */
	background: rgba(128,104,172, .6); /* Old browsers */
	background: -moz-linear-gradient(-45deg, rgba(128,104,172,.6) 0%, rgba(0,186,237,.6) 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(-45deg, rgba(128,104,172,.6) 0%,rgba(0,186,237,.6) 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(135deg, rgba(128,104,172,.6) 0%,rgba(0,186,237,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#8068ac', endColorstr='#00baed',GradientType=.6 ); /* IE6-9 fallback on horizontal gradient */
}

.hard-work-inner:after {
	content: '';
	position: absolute;
	top: 0;
	left: 50%;
	background-image: url('../img/about/hard-work-icon.png');
	background-repeat: no-repeat;
	background-size: 94px;
	height: 94px;
	width: 94px;
	margin-left: -47px;
	margin-top: -47px;
}

.hard-work-inner h2 {
	font-size: 35px;
	line-height: 40px;
	color: #fff;
}

.hard-work-inner .heading-border-wrap {
	margin-bottom: 30px;
}

.hard-work-inner .heading-border {
	border-bottom-color: #fff;
}

.hard-work-inner p {
	text-align: center;
	color: #fff;
	max-width: 460px;
	margin: auto;
}

/* About Creation Page Rules */
.abt-creation {
	position: relative;
	padding: 100px 0;
	background-color: #f9f9f9;
}

.creation-inner {
	max-width: 500px;
	padding: 30px;
	background-color: #fff;
	box-shadow: 0 0 15px #ddd;
	margin: auto;
}

.creation-inner .img-center {
	margin-bottom: 20px;
}

.creation-inner h2 {
	font-size: 35px;
	line-height: 40px;
}

.creation-inner h2 span {
	display: block;
}

.creation-inner h2 span.first {
	color: #929292;
}

.creation-inner h2 span.second {
	color: #00baff;
}

.creation-inner .heading-border-wrap {
	margin-bottom: 30px;
}

/* About Good Health Section Rules */
.abt-good-health {
	position: relative;
	padding: 100px 0;
}

.abt-good-health h2 {
	font-size: 35px;
	line-height: 40px;
	margin-top: 40px;
}

.abt-good-health h2 span {
	display: block;
}

.abt-good-health h2 span.first {
	color: #656565;
}

.abt-good-health h2 span.second {
	color: #929292;
}

.abt-good-health .heading-border-wrap {
	margin-bottom: 30px;
}

/* About Priority Section Rules */

.abt-priority {
	position: relative;
	background-color: #f9f9f9;
	padding: 100px 0;
}

.abt-priority h2 {
	font-size: 35px;
	line-height: 40px;
	color: #00baff;
}

.abt-priority .heading-border-wrap {
	margin-bottom: 30px;
}

.abt-priority p {
	color: #929292;
	max-width: 650px;
	margin: auto ;
}

.abt-priority .img-center {
	margin-top: 30px;
}

/* About Idea Section Rules */

.abt-idea {
	position: relative;
	padding: 100px 0;
	background-color: #f9f9f9;
}

.abt-idea .bg-img {
	z-index: 0;
}

.abt-idea h2 {
	font-size: 35px;
	line-height: 40px;
}

.abt-idea h2 span {
	display: block;
}

.abt-idea h2 span.first {
	color: #656565;
}

.abt-idea h2 span.second {
	color: #00baff;
}

.abt-idea .heading-border-wrap {
	margin-bottom: 30px;
}

.abt-idea p {
	color: #929292;
	max-width: 410px;
	text-align: justify;
}

@media ( max-width: 1200px ) {
	.abt-good-health h2 {
		margin-top: 0px;
	}
}

/* About Brave Section Rules */
.abt-brave {
	position: relative;
	padding: 100px 0;
}

.abt-brave h2 {
	text-align: right;
	font-size: 35px;
	line-height: 40px;
	margin-top: 15px;
}

.abt-brave h2 span {
	display: block;
}

.abt-brave h2 span.first {
	color: #656565;
}

.abt-brave h2 span.second {
	color: #929292;
}

.abt-brave .heading-border-wrap {
	display: table;
	margin-left: auto;
	margin-bottom: 20px;
}

.abt-brave p {
	color: #929292;
	text-align: right;
}

@media ( max-width: 1200px ) {
	.abt-brave h2 {
		margin-top: 0;
	}
}

/* About All section Rules */

.abt-all {
	position: relative;
	padding: 100px 0;
	background-color: #f9f9f9;
}

.abt-all h2 {
	font-size: 35px;
	line-height: 40px;
	margin-top: 50px;
}

.abt-all h2 span {
	display: block;
}

.abt-all h2 span.first {
	color: #656565;
}

.abt-all h2 span.second {
	color: #929292;
}

.abt-all .heading-border-wrap {
	margin-bottom: 30px;
}

.abt-all p {
	color: #929292;
}

@media ( max-width: 1200px ) {
	.abt-all h2 {
		margin-top: 20px;
	}
}

@media ( max-width: 992px ) {
	.abt-quote img,
	.abt-service img,
	.abt-popular img,
	.abt-workplace img,
	.abt-help img,
	.abt-values img,
	.abt-enjoys img,
	.abt-good-health img,
	.abt-brave img {
		margin: 0 auto 20px auto;
	}

	.abt-cause h2,
	.abt-popular h2,
	.abt-workplace h2,
	.abt-help h2,
	.abt-values h2,
	.abt-enjoys h2,
	.abt-work-style h2,
	.abt-good-health h2,
	.abt-brave h2 {
		text-align: center;
	}

	.abt-cause-left p,
	.abt-popular p,
	.abt-help p,
	.abt-values p,
	.abt-enjoys p,
	.abt-work-style p,
	.abt-good-health p,
	.abt-brave p {
		text-align: center;
		margin-bottom: 20px;
	}

	.abt-cause .heading-border-wrap,
	.abt-popular .heading-border-wrap,
	.abt-workplace .heading-border-wrap,
	.abt-help .heading-border-wrap,
	.abt-values .heading-border-wrap,
	.abt-enjoys .heading-border-wrap,
	.abt-work-style .heading-border-wrap,
	.abt-good-health .heading-border-wrap,
	.abt-brave .heading-border-wrap {
		display: table;
		margin: 0 auto 30px auto;
	}

	p.img-confident-text {
		font-size: 16px;
		line-height: 20px;
		padding: 15px;
		max-width: 100%;
		width: 100%;
	}

	.abt-work-style .img-block img {
		width: 100%;
	}

	.abt-work-style .img-block {
		padding-left: 15px;
	}

	.abt-work-style .content-block {
		padding-right: 15px;
	}
}

@media ( max-width: 767px ) {
	.hard-work-inner {
		padding: 50px 15px 15px 15px;
	}
}


@media (min-width: 768px) {
	.abt-feature-block {
		display: -webkit-box;
	    display: -webkit-flex;
	    display: -ms-flexbox;
	    display: flex;
	    -webkit-flex-wrap: wrap;
	    -ms-flex-wrap: wrap;
	        flex-wrap: wrap;
	}

	.abt-feature-block .abt-feature-single {
		-webkit-box-flex: 1;
		-webkit-flex: 1 1 50%;
		    -ms-flex: 1 1 50%;
		        flex: 1 1 50%;
	}

	.abt-does-block {
		display: -webkit-box;
	    display: -webkit-flex;
	    display: -ms-flexbox;
	    display: flex;
	    -webkit-justify-content: space-around;
	    -ms-flex-pack: distribute;
	        justify-content: space-around;
		    -webkit-flex-wrap: wrap;
		    -ms-flex-wrap: wrap;
		        flex-wrap: wrap;
	}

	.abt-does-block .abt-does-single {
		/*width: 365px;
    	margin: 7px 5px;*/
    	box-shadow: 1px 1px 1px #dbdbdb;
    	margin: 1em;
		-webkit-box-flex: 1;
		-webkit-flex: 1 1 calc(365px - 1em);
		    -ms-flex: 1 1 calc(365px - 1em);
		        flex: 1 1 calc(365px - 1em);
	}
}



/* Home content Rules
==============================
*/

.home-content {
	position: relative;
	color: #fff;
	padding: 135px 0;
}

.bn-version .home-content {
	padding: 100px 0;
}


.home-content-inner {
	position: relative;
}

.home-content-inner .home-desc {
	position: relative;
	display: table;
	margin: 20px auto;
}


.arrow span.right::after {
	content: " ";
	position: absolute;
	border-bottom: 2px solid #fff;
	height: 0;
	right: 35px;
	top: 9px;
	z-index: 1;
	overflow: hidden;
	width: 150px;
}

/*.bn-version .arrow span.right::after {
	top: 15px;
}*/

.arrow span.right::before {
	content: " ";
	position: absolute;
	top: 0;
	right: 0;
	height: 20px;
	width: 20px;
	border: 2px solid #fff;
	-moz-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	-webkit-transform: rotate(45deg);
	transform: rotate(45deg);
}

/*.bn-version .arrow span.right::before {
	top: 5px;
}*/

.arrow span.left::after {
	content: " ";
	position: absolute;
	border-bottom: 2px solid #fff;
	height: 0;
	left: 35px;
	top: 9px;
	z-index: 1;
	overflow: hidden;
	width: 150px;
}

/*.bn-version .arrow span.left::after {
	top: 15px;
}*/

.arrow span.left::before {
	content: " ";
	position: absolute;
	top: 0;
	left: 0;
	height: 20px;
	width: 20px;
	border: 2px solid #fff;
	-moz-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	-webkit-transform: rotate(45deg);
	transform: rotate(45deg);
}

/*.bn-version .arrow span.left::before {
	top: 5px;
}*/

.home-content-inner p {
	position: relative;
	display: table;
	margin: auto;
	max-width: 480px;
}

.bn-version .home-content-inner p {
	font-size: 23px;
	line-height: 29px;
}


.home-content h3 {
	font-size: 40px;
	line-height: 45px;
	font-weight: 400;
	display: table;
	margin: auto;
	background-color: #037ca9;
	padding: 30px 20px;
}

/*.bn-version .home-content h3 {
	font-weight: bold;
}*/

.home-content h2 {
	font-size: 51px;
	line-height: 55px;
	font-weight: 900;
	margin-top: 40px;
	margin-bottom: 30px
}

.bn-version .home-content h2 {
	font-size: 50px;
	line-height: 55px;
	font-weight: normal;
}

/*.home-content h2*/

.center-bg-text {
	display: table;
	margin: auto;
	background-color: #026a86;
	padding: 5px 10px;
}

.features {
	position: relative;
	min-height: 450px;
	margin-bottom: 100px;

	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;

	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;

	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
}

.features > .container:after {
	content: url('../img/home/new/green_man.png');
	position: absolute;
	bottom: 0;
	left: auto;
	margin-bottom: -52.5px;
}


.slick-slide .btn-blue,
.slick-slider {
	margin-bottom: 0;
}

.slick-slide:focus,
.slick-slide:hover {
	outline: 0;
}

.service-nav {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
}

/*.service-nav:after {
	content: url('../img/home/new/green_man.png');
	position: absolute;
	bottom: 0;
	left: 0;
	margin-bottom: -52.5px;
}*/

.slick-slide h1 {
	font-size: 50px;
	line-height: 55px;
	margin-top: 0;
	color: #007AA4;
}

.slick-slide h3 {
	color: #656565;
	font-size: 25px;
	line-height: 30px;
}

.service-nav-inner h4 {
	font-weight: bold;
	margin-top: 0;
	margin-bottom: 20px;
}

.service-nav-inner ul {
	position: relative;
	background-color: #fff;
	border-radius: 50px;
	display: table;
	margin: auto;
	padding: 0 30px;
	margin-bottom: -52.5px;
	box-shadow: 0px 3px 20px #ddd;
}

.service-nav-inner ul:after {
    content: " ";
    position: absolute;
    top: -7px;
    left: 50%;
    margin-left: -6px;
    width: 0;
    height: 0;
    border-top: transparent;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 6px solid #e5e5e5;
    z-index: 1;
}

.service-nav-inner ul:before {
    content: " ";
    position: absolute;
    top: -6px;
    left: 50%;
    margin-left: -7px;
    width: 0;
    height: 0;
    border-top: transparent;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid #fff;
    z-index: 2;
}

.service-nav-inner ul > li {
	position: relative;
	color: #8e8e8e;
	font-size: 16px;
	line-height: 20px;
	list-style: none;
	display: inline-block;
	padding: 60px 20px 20px;
	cursor: pointer;
	min-height: 90px;
	background-color: transparent;

	background-repeat: no-repeat;
	background-position: 50% 25%;
}

.service-nav-inner ul > li.symptom {
	background-image: url("../img/home/new/icons/sym_off.png");
}

.service-nav-inner ul > li.symptom.active {
	background-image: url("../img/home/new/icons/sym_on.png");
}

.service-nav-inner ul > li.kdb {
	background-image: url("../img/home/new/icons/kdb_off.png");
}

.service-nav-inner ul > li.kdb.active {
	background-image: url("../img/home/new/icons/kdb_on.png");
}

.service-nav-inner ul > li.hospital {
	background-image: url("../img/home/new/icons/hospital_off.png");
}

.service-nav-inner ul > li.hospital.active {
	background-image: url("../img/home/new/icons/hospital_on.png");
}

.service-nav-inner ul > li.doctor {
	background-image: url("../img/home/new/icons/doctor_off.png");
}

.service-nav-inner ul > li.doctor.active {
	background-image: url("../img/home/new/icons/doctor_on.png");
}

.service-nav-inner ul > li.food {
	background-image: url("../img/home/new/icons/food_off.png");
}

.service-nav-inner ul > li.food.active {
	background-image: url("../img/home/new/icons/food_on.png");
}

.service-nav-inner ul > li.ask-doc {
	background-image: url("../img/home/new/icons/ask_off.png");
}

.service-nav-inner ul > li.ask-doc.active {
	background-image: url("../img/home/new/icons/ask_on.png");
}


.service-nav-inner ul > li.active {
	color: #333;
}

.health-media {
	position: relative;
	padding: 100px 0;
}

.health-media h2 {
	font-weight: normal;
}

.media-wrap {
	margin-bottom: 50px;
}

.media-health {
	display: block;
	margin-top: 0;
	box-shadow: 1px 0px 20px 1px #e5e5e5;
}

.media-health:first-child {
	position: relative;
	margin-bottom: 25px;
}

.media-health .media-body {
	background-color: #fff;
	vertical-align: middle;
}

.media-health .media-heading {
	padding: 30px 20px;
	color: #929292;
	font-weight: bold;
}

.media-health .media-left {
	padding-right: 0;
}

.media-health .media-left .media-object {
	max-width: 181px;
}

.media-health-meta {
	padding: 10px 20px;

	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;

	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.media-health-meta > li {
	list-style: none;
    font-size: 18px;
    color: #929292;
    line-height: 23px;
}

.media-thumb {
	display: block;
	box-shadow: 1px 0px 20px 1px #e5e5e5;
}

.media-thumb > .img-wrap {
	position: relative;
}

.media-thumb .img-title {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	background-color: rgba(0, 0, 0, 0.3);
	color: #fff;
	margin-bottom: 0;
	padding: 15px;
}

.media-thumb .media-health-meta {
	padding: 15px;
}

.thumb-row {
	margin-bottom: 40px;
}

p.small-margin {
	margin-bottom: 10px;
}

.thumb-meta-row {
	margin-bottom: 15px;
}

.thumb-meta-row p.small-margin + p {
	margin-bottom: 0;
}

.thumb-meta-row p {
	color: #929292;
}

.projects {
	position: relative;
	background-image: url('../img/home/new/project.png');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	padding: 130px 0;
}

.projects ul {
	display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;

	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
}

.projects ul > li {
	list-style: none;
	padding: 0 35px;
}

.projects ul > li .bottom-line {
	height: 1px;
	width: 25px;
	background-color: #fff;
	margin: 10px auto 15px auto;
}

.projects ul > li .img-wrap {
	min-height: 75px;

	display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

.projects ul > li .project-num,
.projects ul > li .project-text {
	color: #fff;
	display: block;
	text-align: center;
}

.projects ul > li .project-num {
	font-size: 45px;
	line-height: 50px;
	margin-bottom: 10px;
}

.projects ul > li .project-text {
	font-size: 22px;
	line-height: 25px;
}

.feedbacks {
	background-color: #F6F6F7;
	position: relative;
	padding: 100px 0;
	background-image: url('../img/home/new/map.png');
	background-repeat: no-repeat;
	background-size: contain;
	background-position: center;
}

.feedback-container {
	margin-bottom: 50px;

	display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;

	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.patient-feedback {
	max-width: calc(50% - 50px);
	margin-right: 50px;
}

.doctor-feedback {
	max-width: calc(50% - 50px);
	margin-left: 50px;
}

.patient-feedback h2,
.doctor-feedback h2 {
	font-weight: normal;
	margin-bottom: 50px;
}

.feedback {
	position: relative;
	background-color: #fff;
	padding: 30px;
	margin-bottom: 15px;
	margin-right: 23px;
	/*box-shadow: 1px 0px 20px 1px #e5e5e5;*/

	display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

.feedback:after {
	content: url('../img/home/new/quote.png');
	background-repeat: no-repeat;
	position: absolute;
	top: 50%;
	margin-top: -23px;
	right: 0;
	margin-right: -23px;
	z-index: 100;
}

.feedback:last-child {
	margin-bottom: 0;
}

.feedback > .img-wrap {
	min-width: 100px;
	margin-right: 20px;

	-webkit-align-self: center;
	-ms-flex-item-align: center;
	align-self: center;
}


.feedback > .img-wrap img {
	border-radius: 50%;
	border: 5px solid #fff;
	box-shadow: 1px 0px 20px 1px #e5e5e5;
}

.feedback > .feedback-content {
	-webkit-align-self: center;
	-ms-flex-item-align: center;
	align-self: center;

}

.feedback p {
	color: #929292;
	margin-bottom: 10px;
}

.feedback h4 {
	font-weight: bold;
	margin-bottom: 0;
}

.feed-left-arrow.slick-arrow,
.feed-right-arrow.slick-arrow {
	position: absolute;
	bottom: -50px;
	left: 0;
	font-size: 30px;
	line-height: 35px;
	margin-bottom: 0;
	color: #CFD9DB;
	cursor: pointer;

	background-color: #fff;
	border-radius: 50%;
	border: 1px solid #CFD9DB;
	z-index: 1;
	padding: 0 12px;
}

p.news-left-arrow,
p.news-right-arrow {
	font-size: 30px;
	line-height: 35px;
	color: #ddd;
	cursor: pointer;
}

p.news-left-arrow:hover,
p.news-right-arrow:hover {
	color: #777;
}

.newspaper-title {
	padding-right: 15px;
}

.news-left-arrow.slick-arrow {
	position: absolute;
	left: -30px;
	top: 50%;
	margin-top: -15px;
	z-index: 1;
}

.news-right-arrow.slick-arrow {
	position: absolute;
	right: -30px;
	top: 50%;
	margin-top: -15px;
	z-index: 1;
}

.newspaper-title > li > a:hover {
	color: #00baff;
}

.feed-right-arrow.slick-arrow {
	margin-left: 50px;
}

.latest-story {
	padding-top: 50px;
	padding-bottom: 80px;
}

.banner-rx {
	display: table;
	border: 1px solid #ddd;
	padding: 25px;
	background-color: #f6f6f6;
	width: 100%;
}

.banner-rx .banner-left,
.banner-rx .banner-body {
	display: table-cell;
}

.banner-rx .banner-left {
	vertical-align: top;
	width: 251px;
	padding-right: 25px;
}

.banner-rx .banner-body {
	vertical-align: middle;
}

.banner-rx .banner-body h4 {
	color: #000;
	font-size: 25px;
    line-height: 30px;
    font-weight: bold;
    margin-bottom: 25px;
}

.banner-rx .banner-body p {
	margin-bottom: 0;
}

.latest-news,
.next-step {
	border: 1px solid #ddd;
}

.latest-news > h3,
.next-step > h3 {
	padding-top: 25px;
	padding-left: 25px;
}

.latest-list,
.step-list {
	padding: 25px;
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

	-ms-flex-flow: row wrap;
	flex-flow: row wrap;
}

.latest-list > li,
.step-list > li {
	color: #929292;
	list-style: none;
	width: 50%;
	padding: 15px;
	border-bottom: 1px solid #ddd;
	border-right: 1px solid #ddd;

	/*display: -webkit-box;
    display: -ms-flexbox;*/
    display: table;
}

.latest-list .img-wrap,
.latest-list .news-content {
	display: table-cell;
	vertical-align: top;
}

.latest-list .news-content {
	max-width: 230px;
}

.step-list > li {
	position: relative;
	font-size: 20px;
	padding: 14px;
	padding-left: 55px;
}

.step-list > li:before {
	content: '';
	position: absolute;
	top: 0;
	left: 15px;
	width: 25px;
	height: 25px;
	border-radius: 50%;
	top: 50%;
	margin-top: -25px;
}

.step-list > li:nth-child(1),
.step-list > li:nth-child(3) {
	padding-left: 40px;
}

.step-list > li:nth-child(1):before {
	background-color: #ddd;
	left: 0;
}

.step-list > li:nth-child(2):before {
	background-color: #1676E9;
}

.step-list > li:nth-child(3):before {
	background-color: #A2DD5F;
	left: 0;
}

.step-list > li:nth-child(4):before {
	background-color: #55BF3A;
}

.latest-list > li .img-wrap {
	padding-right: 10px;
}

.latest-list > li .img-wrap > img {
	max-width: 65px;
}

.latest-list > li h4 {
	font-size: 18px;
	line-height: 20px;
	margin-top: 0;
	margin-bottom: 9px;
}

.latest-list > li .date {
	color: #929292;
	font-size: 16px;
	line-height: 20px;
	margin-bottom: 0;
}

.latest-list > li:nth-child(even) {
	border-right: 0;
	padding-right: 0;
}

.step-list > li:nth-child(even) {
	border-right: 0;
}

.latest-list > li:nth-child(odd) {
	padding-left: 0;
}

.latest-list > li:nth-child(-n+2) {
	padding-top: 0;
}

.latest-list > li:nth-child(n+3) {
	border-bottom: 0;
	padding-bottom: 0;
}

.step-list > li:nth-child(n+3) {
	border-bottom: 0;
}

.playstore-link,
.appstore-link {
	display: inline-block;
	max-width: 160px;
}

.playstore-link img,
.appstore-link img {
	background-color: #000;
	border-radius: 10px;
	border: 2px solid #fff;
}

.appstore-link {
	margin-left: 20px;
}

.featured-on-media {
	padding: 100px 0;
	border-top: 1px solid #ddd;
	border-bottom: 1px solid #ddd;
	background-color: #f4f4f4;
}

.featured-on-media h2 {
	font-weight: normal;
	margin-bottom: 10px;
}

.newspaper-title > li {
	display: none;
	list-style: none;
	font-size: 25px;
	line-height: 30px;
	color: #929292;
}

.newspaper-title > li.active {
	display: block;
}

.newspapers .img-wrap {
	margin: 0 5px;
}

.newspapers .img-wrap img {
	border: 2px solid #ddd;
}

.newspapers .slick-center img {
	border-color: #00baff;
}

@media (min-width: 992px) {
	.home-content-inner .home-desc {
		width: 865px;
	}
}

@media (max-width: 991px) {
	.home-content-inner .home-desc {
		width: 100%;
	}
	.arrow span.right::after,
	.arrow span.left::after {
		width: 65px;
	}
	.features > .container:after {
		display: none;
	}
}


@media (max-width: 767px) {
	.home-content {
		padding: 100px 0;
	}

	.arrow {
		display: none;
	}

	.bn-version .home-content h2 {
		font-size: 30px;
		line-height: 35px;
	}


	.arrow span.right::after,
	.arrow span.left::after {
		width: 0;
	}

	.home-content-inner p {
		padding: 0 20px;
	}

	.features {
		display: block;
		margin-top: 60px;
	}

	.service-slider {
		margin-top: 90px;
	}

	.service-nav-dots .nav-text {
		display: none;
	}

	.service-nav-inner ul > li {
		min-height: auto;
		padding: 30px 20px;
		min-height: auto;
		background-position: 50%;
	}

	.projects ul {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.projects ul > li:not(:last-child) {
		margin-bottom: 40px;
	}

	.feedback-container {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.patient-feedback,
	.doctor-feedback {
		max-width: 100%;
	}

	.patient-feedback {
		margin-bottom: 100px;
		margin-right: 0;
	}

	.doctor-feedback {
		margin-left: 0;
	}

	.latest-news {
		margin-bottom: 20px;
	}

}

/* Service Section Rules
=============================
*/

.service {
	position: relative;
	padding: 110px 0;
}

.top-diamond::after {
	content: " ";
	position: absolute;
	background-image: url(../img/home/shape.png);
	background-repeat: no-repeat;
	width: 38px;
	height: 82px;
	top: 0;
	left: 50%;
	margin-left: -19px;
}

.service h2 {
	margin-top: 0;
	margin-bottom: 35px;
}

.service p {
	color: #6f6f6f;
	margin-bottom: 55px;
}

.service h4 {
	color: #747474;
	font-size: 20px;
	font-weight: 700;
}

.bn-version .service h4 {
	font-size: 25px;
	line-height: 35px;
}

.service a {
	font-size: 18px;
	font-weight: 500;
	color: #7b7b7b;
}

.bn-version .service a {
	font-size: 22px;
	line-height: 28px;
}

.service a:hover {
	text-decoration: none;
}

.service-inner {
	padding: 0 20px;

	-webkit-transition: all ease 0.3s;
	transition: all ease 0.3s;
}

.service-inner .btn-center {
	padding-top: 7px;
	padding-bottom: 7px;
}

.service-inner p {
	margin-bottom: 25px;
}

.service-inner p > img {
	max-width: 100px;
}

.svg-icon {
	width: 100px;
	height: 100px;
	margin: auto;
	background-color: #cacaca;

	-webkit-transition: all ease 0.3s;
	transition: all ease 0.3s;
}

.service-inner:hover .svg-icon {
	background-color: #00baff;
}

.svg-icon > object {
	display: block;
	width: 50px;
	height: 100px;
	margin: auto;
	background-color: transparent;
	pointer-events: none;
}

.svg-arrow {
	min-width: 50px;
}

object.control-arrow {
	min-width: 50px;
}


.bn-version .service-inner p {
	font-size: 18px;
	line-height: 25px;
}

.service-inner .heading-border-wrap {
	position: relative;
	margin-top: 15px;
	margin-bottom: 15px;
}

.heading-border {
	position: relative;
    border-bottom: 5px solid #00baff;
    border-left: 2.5px solid transparent;
    border-right: 2.5px solid transparent;
    height: 0;
    width: 40px;
}

.bn-version .heading-border {
	border-bottom-width: 4px;
}

footer .heading-border {
	border-bottom-width: 3px;
}

.heading-border-bottom {
	border-bottom: 1px solid #cacaca;
	margin-top: -1px;
}


.diamond {
	position: absolute;
	right: -20%;
	width: 0;
	height: 0;
	border: 10px solid transparent;
	border-bottom-color: #cacaca;
	top: -15px;
}
.diamond::after {
	content: '';
	position: absolute;
	left: -10px;
	top: 10px;
	width: 0;
	height: 0;
	border: 10px solid transparent;
	border-top-color: #cacaca;
}

.service-inner .btn-center {
	margin-top: 30px;
	margin-bottom: 30px;
}

@media (max-width: 768px) {
	.diamond {
		display: none;
	}
}


/* Symptom checker Section Rules
=======================================
*/
section.symptom-checker {
		padding: 110px 0;
		background-color: #f0f0f0;
}

.symptom-checker {
	position: relative;
	padding: 20px 0;
	background-color: #fff;
	min-height: 505px;

	display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;

	-webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;

	-webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
}

.symptom-checker h2 {
	margin-top: 0;
	margin-bottom: 35px;
}

.bn-version .symptom-checker h2 {
	margin-bottom: 20px;
}

.symptom-checker p {
	margin-bottom: 50px;
}

.bn-version .symptom-checker p {
	font-size: 18px;
	line-height: 25px;
}

.symptom-checker-inner-left {
	margin-left: 10%;
}

.symptom-checker-inner-right p {
	margin-bottom: 25px;
}
.symptom-checker-inner-right h3 {
	font-size: 25px;
	line-height: 30px;
	margin-top: 0;
	color: #787878;
	margin-bottom: 0;
	font-weight: 600;
}

.bn-version .symptom-checker-inner-right h3 {
	font-size: 30px;
	line-height: 38px;
	font-weight: normal;
}

.symptom-checker-inner-right h3 span {
	display: block;
	color: #5a5a5a;
	font-size: 40px;
	font-weight: 900;
	margin-top: 20px;
}

.bn-version .symptom-checker-inner-right h3 span {
	font-size: 45px;
	color: #6f6f6f;
}

.symptom-checker-inner-right .heading-border-wrap {
	margin-top: 20px;
	margin-bottom: 20px;
}

.symptom-checker-inner-right .btn-grey {
	margin-top: 40px;
	background-color: transparent;
}

.symptom-checker-inner-right .btn-grey:hover {
	background-color: #00baff;
}

.checker-meta {
	background-color: #e4e4e4;
	padding: 15px 0;
}

.checker-meta .clearfix, .checker-meta p {
	margin-bottom: 0;
}

.checker-meta-left {
    width: 30%;
    float: left;
    margin-right: 5%;
}

.checker-meta-left img {
	margin-top: 3px;
}

.checker-meta-right {
	width: 65%;
    float: left;
}

.checker-list,
.knowledge-desc-meta ul,
.faq-content ul {
	padding-left: 0;
	margin-top: 15px;
}

.knowledge-desc-meta ul,
.faq-content ul {
	padding-left: 20px;
}

.checker-list li,
.knowledge-desc-meta ul li,
.faq-content ul li {
	position: relative;
	list-style: none;
	font-size: 13px;
	line-height: 20px;
	padding-top: 10px;
	padding-bottom: 10px;
	padding-left: 35px;
	padding-right: 10px;
}

.bn-version .checker-list li,
.bn-version .knowledge-desc-meta ul li,
.bn-version .faq-content ul li {
	font-size: 18px;
	line-height: 25px;
}

.checker-list-inline li {
	display: inline-block;
	margin-left: 10px;
}

.checker-list li:before {
	content: " ";
	position: absolute;
	top: 13px;
	left: 0;
	width: 20px;
	height: 20px;
	background: url(../img/home/green-check.png);
	background-repeat: no-repeat;
	background-position: top left;
}

.knowledge-desc-meta ul li:before,
.knowledge-base-content .checker-list li:before,
.faq-content ul li:before {
	content: " ";
	position: absolute;
	top: 0;
	margin-top: 15px;
	left: 0;
	width: 20px;
	height: 20px;
	background: url(../img/home/green-check.png);
	background-repeat: no-repeat;
	background-position: top left;
}


.dir-single-features {
	display: table-row;
}

.dir-single-features h4 {
	display: table-cell;
	vertical-align: middle;
	padding-right: 15px;
	padding-bottom: 0;
}

.dir-single-features .checker-list-inline {
	display: table-cell;
	vertical-align: middle;
}

.tab-content .tab-pane .dir-single-features p.time {
	margin-bottom: 0;
}

.checker-total-list li {
	list-style: none;
	display: table-row;
	position: relative;
}


.checker-total-list li h4 {
	display: table-cell;
	vertical-align: middle;
	padding-top: 25px;
	padding-bottom: 25px;
	padding-right: 50px;
}

.checker-total-list li span {
	display: table-cell;
	vertical-align: middle;
	padding-top: 25px;
	padding-bottom: 25px;
	padding-left: 35px;
	position: relative;
}

.checker-total-list li span:before {
	content: " ";
	position: absolute;
	top: 50%;
	margin-top: -10px;
	left: 0;
	width: 20px;
	height: 20px;
	background: url(../img/home/green-check.png);
	background-repeat: no-repeat;
	background-position: top left;
}

.multiple-checker {
	display: table;
	width: 100%;
}

.prescription-links {
	display: table;
	width: 100%;
}

.prescription-links li {
	list-style: none;
	display: table-cell;
	width: 20%;
}

.prescription-links li > p {
	margin-bottom: 0;
	color: #fff;
	line-height: 16px;
}

.prescription-links li > p:last-child {
	margin-bottom: 10px;
}

.prescription-links li.left {
	background-color: #02B7F0;
}

.prescription-links li.middle {
	background-color: #0284CF;
}

.prescription-links li.right {
	background-color: #024C7D;
}

.prescription-links .svg-icon {
	background-color: transparent;
}

.multiple-checker .checker-list {
	display: table-cell;
	width: 33.33%;
	padding-right: 20px;
}

@media (max-width: 767px) {
	.symptom-checker .img-right {
		margin: auto;
	}

	.checker-meta .clearfix {
		margin-bottom: 10px;
	}

	.symptom-checker-inner-left {
		margin-left: 0;
		margin-bottom: 25px;
	}

	.bn-version .prescription-links-wrap {
		margin-left: 0;
		margin-right: 0;
	}

	.checker-total-list li h4 {
		padding-right: 30px;
	}

	.bn-version .symptom-checker-inner-right h3 {
		font-size: 23px;
		line-height: 25px;
	}

	.bn-version .symptom-checker-inner-right h3 span {
		margin-top: 5px;
		font-size: 25px;
		line-height: 27px;
	}

	.bn-version .knowledge-base h3 .big-text {
		font-size: 23px;
		line-height: 25px;
	}

}

/* Diagnosis Page Rules
===================================
*/
.diagnosis {
	margin: 30px 0;
}

.diagnosis-overlay {
	display: none;
	position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0,0.6);
    width: 100%;
    height: 100%;
    z-index: 100;
}

.diagnosis h3 {
	color: #777;
}

.diagnosis .btn {
	margin-bottom: 0;
	font-size: 15px;
	line-height: 20px;
	padding: 5px 10px;
	width: 100%;

	display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;

	-webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;

	-webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
}

.diagnosis .btn-grey {
	background-color: #777;
	color: #fff;
}

.diagnosis .btn-grey:hover,
.diagnosis .btn-grey:focus {
	background-color: #00baff;
}

.diagnosis .heading-border-wrap {
	margin-top: 10px;
	margin-bottom: 10px;
}

.diagnosis hr {
	margin-top: 10px;
	margin-bottom: 10px;
}

.possible-cure-list {
	max-height: 470px;
	overflow-y: auto;
	border-bottom: 1px solid #ccc;
}

.possible-cure-list .right-block {
	border-bottom: 1px solid #ccc;
	margin-right: 5px;

	display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;

	-webkit-flex-flow: column;
        -ms-flex-flow: column;
            flex-flow: column;
}

.possible-cure-list .right-block > .btn {
	min-height: 50px;
	min-width: 55px;
	pointer-events: none;
}

.possible-cure-list > li {
	list-style: none;
	margin-bottom: 10px;
	border-bottom: 1px solid #ccc;
}

.procedure-list > li {
	list-style: none;
}

.possible-cure-list .procedure-list > li > a {
	padding-left: 25px;
}

.possible-cure-list > li:last-child {
	border-bottom: 0;
}

.possible-cure-list li a {
	display: block;
	position: relative;
	padding-top: 5px;
	padding-bottom: 5px;
	padding-left: 0;
	padding-right: 10px;
	font-size: 16px;
	line-height: 18px;
}

.possible-cure-list li a.drugs {
	background-position: 5% center;
    background-repeat: no-repeat;
	background-image: url("../img/diagnosis/drugs.png");
}

.possible-cure-list li a.drugs:hover,
.possible-cure-list li a.drugs:focus {
	background-image: url("../img/diagnosis/drugs-deep.png");
}

.possible-cure-list li a.test {
	background-position: 5% center;
    background-repeat: no-repeat;
	background-image: url("../img/diagnosis/test.png");
}

.possible-cure-list li a.test:hover,
.possible-cure-list li a.test:focus {
	background-image: url("../img/diagnosis/test-deep.png");
}

.possible-cure-list li a.faq {
	background-position: 5% center;
    background-repeat: no-repeat;
	background-image: url("../img/diagnosis/faq.png");
}

.possible-cure-list li a.faq:hover,
.possible-cure-list li a.faq:focus {
	background-image: url("../img/diagnosis/faq-deep.png");
}

.possible-cure-list li a.images {
	background-position: 5% center;
    background-repeat: no-repeat;
	background-image: url("../img/diagnosis/img.png");
}

.possible-cure-list li a.images:hover,
.possible-cure-list li a.images:focus {
	background-image: url("../img/diagnosis/img-deep.png");
}

.possible-cure-list li a.videos {
	background-position: 5% center;
    background-repeat: no-repeat;
	background-image: url("../img/diagnosis/video.png");
}

.possible-cure-list li a.videos:hover,
.possible-cure-list li a.videos:focus {
	background-image: url("../img/diagnosis/video-deep.png");
}

.possible-cure-list li a.articles {
	background-position: 5% center;
    background-repeat: no-repeat;
	background-image: url("../img/diagnosis/article.png");
}

.possible-cure-list li a.articles:hover,
.possible-cure-list li a.articles:focus {
	background-image: url("../img/diagnosis/article-deep.png");
}

.possible-cure-list li a:hover,
.possible-cure-list li a:focus {
	color: #6e6e6e;
}

.recomended-doc-heading {
	position: relative;
}

.arrow-container {
	position: absolute;
	top: 0;
	right: 0;
}

.arrow-container a:hover {
	color: #00baff;
}

.arrow-container a.disabled {
	opacity: 0.3;
	pointer-events: none;
}

.recomended-doctors-list {
	border-bottom: 1px solid #ccc;
	min-height: 470px;

	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;

	-webkit-flex-flow: row wrap;
        -ms-flex-flow: row wrap;
            flex-flow: row wrap;

	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
			justify-content: space-between;
}

.recomended-doctors-list > li {
	list-style: none;
	width: 50%;
	max-width: 270px;
	margin-bottom: 15px;

	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;

	-webkit-box-pack: justify;
	-webkit-justify-content: space-around;
		-ms-flex-pack: justify;
			justify-content: space-around;
}

.recomended-doctors-list .left-block {
	margin-right: 10px;
	width: 40%;
}

.left-block-title {
	position: relative;
}

.possible-cure-list .left-block > a {
	display: block;
	padding-left: 0;
}


.possible-cure-list .left-block a > h4 {
	font-weight: bold;
	margin-top: 0;
	margin-bottom: 10px;
}

.possible-cure-list .left-block a:hover h4,
.possible-cure-list .left-block a:focus h4 {
	color: #00baff;
}
.recomended-doctors-list .right-block {
	width: 60%;
}

.recomended-doctors-list .right-block h4 {
	margin-top: 0;
	margin-bottom: 0;
	white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.recomended-doctors-list .right-block p {
	margin-bottom: 5px;
	white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.recomended-doctors-list .right-block p.degree {
	font-size: 16px;
	line-height: 18px;
}

.recomended-doctors-list .clearfix {
	margin-bottom: 15px;
}

.mfp-white-bg {
	padding: 20px;
	background-color: #fff;
}

.mfp-white-bg > header > h2 {
	color: #6e6e6e;
}

.mfp-white-bg > header > h3 {
	color: #777;
	font-weight: bold;
}

.mfp-white-bg ul li {
	color: #777;
}

.mfp-white-bg .body {
	padding: 20px 0;
}

.img-content {
	margin-bottom: 20px;
}

.haza-modal-container .video-content {
	position: relative;
}

.haza-modal-container .video-content::after {
	content: " ";
	background-image: url(../img/symptom/play-btn.png);
	background-repeat: no-repeat;
	background-position: top left;
	width: 31px;
	height: 31px;
	position: absolute;
	top: 50%;
	left: 50%;
	margin-left: -15.5px;
	margin-top: -31.5px;
	cursor: pointer;
}

.img-content img,
.video-content img {
	width: 100%;
}

.haza-modal-container .tab-inner {
	margin-bottom: 20px;
}

.haza-modal-container .video-content h3 {
	padding-top: 5px;
	font-size: 16px;
	line-height: 20px;
}

.haza-modal-container .video-content .spacer {
	font-size: 12px;
	line-height: 16px;
}

.haza-modal-container .video-content .video-meta {
	position: absolute;
    bottom: 25px;
    left: 0px;
    margin-bottom: 0;
    color: #fff;
    line-height: 20px;
}

.tab-inner h3 {
	font-size: 20px;
    line-height: 25px;
    color: #6f6f6f;
}

article.blog-single > a > h3 {
	font-size: 25px;
	line-height: 27px;
	font-weight: bold;
	margin-bottom: 10px;

	-webkit-transition: all ease 0.3s;
	transition: all ease 0.3s;
}

article.blog-single > a > h3:hover,
article.blog-single > a > h3:focus  {
	color: #00baff;
}

.blog-single {
	margin-bottom: 20px;
}

.blog-single p {
	margin-bottom: 10px;
}

.blog-single .btn-more {
	font-size: 18px;
	line-height: 20px;
	border-color: #777;
	padding: 10px;
	margin-bottom: 0;
}

.blog-single .btn-more:hover,
.blog-single .btn-more:focus {
	border-color: #00baff;
}

.qa-list > li {
	list-style: none;
}

p.qa-question {
	font-size: 18px;
	line-height: 20px;
	padding: 5px 10px;
	background-color: #777;
	color: #fff;
	margin-bottom: 0;
}

p.qa-answer {
	padding: 10px;
	background-color: #e2e2e2;
}

/*.tooltip.top,
.tooltip.bottom,
.tooltip.left,
.tooltip.right {
	background-color: #fff;
}*/

.tooltip-inner {
	background-color: #000;
	color: #fff;
	box-shadow: 1px 1px 1px #ccc;
	font-size: 18px;
	line-height: 20px;
	border-radius: 0;
}

.tooltip.top .tooltip-arrow {
	border-top-color: #ccc;
}
.tooltip.bottom .tooltip-arrow {
	border-bottom-color: #fff;
}
.tooltip.left .tooltip-arrow {
	border-left-color: #fff;
}
.tooltip.right .tooltip-arrow {
	border-right-color: #fff;
}


.image-popup {

	display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;

	-webkit-flex-flow: row wrap;
        -ms-flex-flow: row wrap;
            flex-flow: row wrap;
}

.gallery-item {
	width: 50%;
}

.gallery-item > img {
	padding: 5px;
}

.title-toggler {
	display: none;
	cursor: pointer;
	position: absolute;
	font-size: 18px;
	line-height: 40px;
	top: 0;
	right: 5px;
	height: 100%;
	background-color: #f0f0f0;
	color: #777;
	z-index: 1;
}

.title-toggler > i {
	padding: 12px;
}

.procedure-show .procedure-list {
	display: block;
}


/* Haza Modal Rules
================================
*/

.haza-modal-container {
	position: absolute;
	opacity: 0;
	/*max-height: 400px;*/
	/*overflow-y: auto;*/
	background-color: #fff;
	z-index: 110;
}

.haza-modal-container-inner {
	background-color: #fff;
	min-width: 400px;
	max-width: 400px;
	min-height: 400px;
	padding: 10px;
	position: relative;
	z-index: 1;

	-webkit-box-shadow: 0px 0px 15px rgba(0,0,0,.175);
	box-shadow: 0px 0px 15px rgba(0,0,0,.175);
}

.haza-modal-container header {
	margin: 0 10px;
}

.haza-modal-container footer {
	margin: 0 10px;
	padding: 10px 0;
}

.haza-modal-container footer p {
	margin-bottom: 10px;
}

.haza-modal-container header h3 {
	font-weight: bold;
	color: #777;
}

.haza-modal-container .body {
	margin-top: 20px;
	padding: 10px;
	max-height: 400px;
	overflow-y: auto;
}

.haza-modal-container .body p {
	margin-bottom: 10px;
}

.haza-modal-container .body .checker-list,
.haza-modal-container .body ul:not(.qa-list) {
	padding-left: 15px;
}

.haza-modal-container .body ul:not(.qa-list) li {
	position: relative;
    list-style: none;
    font-size: 16px;
    line-height: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 35px;
    padding-right: 10px;
}

.haza-modal-container .body ul:not(.qa-list) li:before {
	content: " ";
    position: absolute;
    top: 10px;
    left: 0;
    width: 20px;
    height: 20px;
    background: url(../img/home/green-check.png);
    background-repeat: no-repeat;
    background-position: top left;
}


.haza-modal-container .checker-list {
	margin-top: 0;
}

.haza-modal-container .close-btn {
	top: 0px;
	right: 5px;
	font-size: 20px;
	line-height: 30px;
}

.no-scroll {
	pointer-events: none;
}

.left-arrow {
	position: absolute;
	box-sizing: border-box;
	left: -10px;
	width: 0;
	height: 0;

	-webkit-box-shadow: 0px 0px 15px rgba(0,0,0,.175);
	box-shadow: 0px 0px 15px rgba(0,0,0,.175);
}

.left-arrow:before {
	content: " ";
	position: absolute;
	left: 10px;
	top: 3px;
	width: 0;
	height: 0;
	z-index: -1;

	border: 5px solid black;
	border-color: transparent #fff #fff #fff;
	transform-origin: 0 0;
  	transform: rotate(45deg);

	-webkit-box-shadow: -3px 3px 10px 0px rgba(0,0,0,.10);
	box-shadow: -3px 3px 10px 0px rgba(0,0,0,.10);
}

.haza-modal-container .blog-single .btn-more {
	margin-right: auto;
	margin-left: 0;
}


@media ( min-width: 768px ) {
	.possible-cure-list li,
	.procedure-list {
		padding-bottom: 5px;

		display: -webkit-box;
	    display: -webkit-flex;
	    display: -ms-flexbox;
	    display: flex;

		-webkit-box-align: center;
	    -webkit-align-items: center;
	        -ms-flex-align: center;
	            align-items: center;

		-webkit-box-pack: justify;
	    -webkit-justify-content: space-between;
	        -ms-flex-pack: justify;
	            justify-content: space-between;
	}

}

@media ( max-width: 991px ) {
	.left-arrow {
		left: 32px;
	}

	.left-arrow:before {
		top: 0;
		transform: rotate(135deg);
	}
}

@media ( max-width: 767px ) {
	.diagnosis h3 {
		margin-top: 20px;
		margin-right: 50px;
	}

	.procedure-list {
		display: none;
		background-color: #f0f0f0;
	    margin-right: 5px;
	    padding: 0 10px;
	}

	.procedure-list > li {
		display: block;
		width: 100%;
		padding: 10px 0;
	}

	.possible-cure-list .left-block a {
		display: block;
		padding-top: 0;
		padding-bottom: 0;
		padding-left: 0;
		padding-right: 45px;
	}

	.possible-cure-list .left-block a > h4 {
		background-color: #777;
		margin: 0;
		color: #fff;
		padding: 10px;
	}

	.possible-cure-list > li {
		border-bottom: 0;
	}

	.possible-cure-list .procedure-list > li > a {
		padding-left: 25px;
		background-position: 0% center;

		display: -webkit-box;
	    display: -webkit-flex;
	    display: -ms-flexbox;
	    display: flex;

	}

	.possible-cure-list > li:last-child {
		margin-bottom: 20px;
	}

	.haza-modal-container {
		width: 100%;
		min-width: auto;
		background-color: transparent;
	}

	.haza-modal-container-inner {
		background-color: #fff;
		max-width: auto;
		min-width: auto;
		margin: 0 15px;

		-webkit-box-shadow: 0px 0px 15px rgba(0,0,0,.175);
		box-shadow: 0px 0px 15px rgba(0,0,0,.175);
	}

	.haza-modal-container .body {
		max-height: 300px;
	}

	.left-arrow {
		left: 42.5px;
	}

	.possible-cure-list .left-block a:hover h4,
	.possible-cure-list .left-block a:focus h4 {
		color: #fff;
	}

	.title-toggler {
		display: block;
	}

	.possible-cure-list .right-block > .btn {
		min-height: 35px;
	}

}

@media ( max-width: 600px ) {
	.recomended-doctors-list > li {
		width: 100%;
	}
}

/* Knowledge-base Section Rules
===================================
*/

.knowledge-base-content .col-md-9 > section {
	margin-bottom: 25px;
}

.knowledge-base-content .col-md-9 > section:last-child {
	margin-bottom: 0;
}

.knowledge-base {
	position: relative;
	padding: 110px 0;
	color: #fff;
}

.bn-version .knowledge-base h2 .blue {
	font-weight: bold;
}

.knowledge-base-meta {
	margin: 65px 0;
	position: relative;
}

.knowledge-base-meta div.clearfix {
	margin-bottom: 12px;
}

.top-arrow-circle:before {
	content: " ";
	position: absolute;
	top: -42%;
	right: 15px;
	width: 40px;
	height: 17px;
	background-image: url(../img/home/knowledge-base/arrow_top.png);
	background-position: top center;
	background-repeat: no-repeat;
}

.bottom-arrow-circle::after {
	content: " ";
	position: absolute;
	bottom: -42%;
	right: 15px;
	width: 40px;
	height: 17px;
	background-image: url(../img/home/knowledge-base/arrow_down.png);
	background-position: top center;
	background-repeat: no-repeat;
}

.knowledge-base-meta p {
	margin-bottom: 0;
	margin-top: 10px;
	margin-left: -15px;
}

.base-img {
	/*position: relative;*/
	margin-top: 70px;
    /*margin-left: -40px;*/
}

.knowledge-base .big-text {
	color: #8be0ff;
	font-size: 48px;
	margin-top: 10px;
}

.bn-version .knowledge-base .big-text {
	font-size: 50px;
}

.knowledge-base h4 {
	margin: 15px 0;
	font-size: 21px;
}

.bn-version .knowledge-base h4 {
	font-size: 38px;
}

.search-symptom-links {
	display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

.search-symptom-links li {
	list-style: none;
	padding: 10px 20px;
	max-width: 130px;
	min-width: 130px;
}

.search-symptom-links li p {
	color: #fff;
	margin: 0;
}

.search-symptom-links li p span {
	display: block;
}

.search-symptom-links li.left {
	background-color: #00baff;
}

.search-symptom-links li.middle {
	background-color: #024C7D;
}

.search-symptom-links li.right {
	background-color: #67A31C;
}

.search-symptom-links .svg-icon {
	width: 25px;
	height: auto;
	margin-left: auto;
	margin-right: auto;
	margin-top: 10px;
	margin-bottom: 25px;
	background-color: transparent;
}

.search-symptom-links .svg-icon object {
	width: 25px;
	height: auto;
}

@media (max-width: 991px) {
	.search-symptom-links li {
		display: table-cell;
	}

	.bn-version .knowledge-base h3,
	.bn-version .knowledge-base h4 {
		text-align: center;
	}

}

@media (max-width: 767px ) {
	.base-img {
		margin-left: 0;
		margin-top: 20px;
	}

	.search-symptom-links {
		margin: auto;
	}

	.bn-version .knowledge-base h4 {
		font-size: 26px;
		line-height: 28px;
		margin-top: 5px;
	}

	.knowledge-base-meta {
		margin: 30px 0;
	}

	.search-symptom-links {
		margin: auto;

		-webkit-box-pack: center;
	    -webkit-justify-content: center;
	        -ms-flex-pack: center;
	            justify-content: center;
	}

	.search-symptom-links li {
		min-width: 100px;
		max-width: 100px;
	}

}

/* Knowledge-base Page Rules
==============================
*/

.header-share-wrap h4 {
	text-align: right;
	margin-top: 0;
	font-size: 20px;
	line-height: 27px;
	font-weight: 600;
}

.bn-version .header-share-wrap h4 {
	font-weight: normal;
}

.header-social-list {
	padding-left: 0;
	margin-bottom: 0;
	display: table;
	margin-left: auto;
}

.header-social-list li {
	list-style: none;
	display: inline-block;
	margin: 0 3px;

}

.header-social-list li:first-child {
	margin-left: 0;
}

.header-social-list li:last-child {
	margin-right: 0;
}

.header-social-list li a {
	color: #fff;
}

.header-social-list li a:hover {
	text-decoration: none;
}

.header-secondary-nav {
	position: relative;
	background-color: #fff;
	border: 1px solid #e2e2e2;
	/*box-shadow: 1px 0px 5px #222;*/
	z-index: 1;
}

.header-secondary-nav.fixed {
	display: block;
	position: fixed;
	top: 4%;
}

.user-logged-in .header-secondary-nav.fixed {
	top: 86px;
	margin-left: 110px;
}

/*.secondary-nav-list {
	padding-left: 0;
	margin-bottom: 0;
	margin: auto;
}
*/


.secondary-nav-list li {
	list-style: none;
	-webkit-transition: all linear 0.3s;
	-moz-transition: all linear 0.3s;
	-ms-transition: all linear 0.3s;
	transition: all linear 0.3s;
}

.secondary-nav-list li:hover,
.secondary-nav-list li.active {
	background-color: #00baff;
	color: #fff;
}

.secondary-nav-list li:first-child {
	padding-left: 0;
}

.secondary-nav-list li:last-child {
	padding-right: 0;
}

.secondary-nav-list li a {
	color: #979797;
	font-size: 15px;
	line-height: 15px;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 15px;
    padding-right: 45px;
    border-bottom: 1px solid #d3d3d3;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
        -ms-flex-pack: end;
            justify-content: flex-end;
}


.secondary-nav-single-icon {
	width: 20%;
}

.secondary-nav-single {
	-webkit-align-self: center;
    -ms-flex-item-align: center;
        align-self: center;
}

.bn-version .secondary-nav-single {
	font-size: 20px;
    line-height: 25px;
}

.secondary-nav-list li a:hover,
.secondary-nav-list li.active a,
.secondary-nav-list li.active a:focus,
.secondary-nav-list li.active a:hover {
	text-decoration: none;
	color: #fff;
}

.secondary-nav-list li a:hover .secondary-nav-single,
.secondary-nav-list li.active a .secondary-nav-single {
	border-color: #00baff;
	color: #fff;
}


.icon-bg {
	background-position: 96% center;
	background-repeat: no-repeat;
}

.secondary-nav-list li .sym_1 {
	background-image: url("../img/knowledge_base/nav-icon-small/sym_1_gray.png");
}

.secondary-nav-list li:hover .sym_1,
.secondary-nav-list li:focus .sym_1,
.secondary-nav-list li.active .sym_1 {
	color: #fff;
	background-image: url("../img/knowledge_base/nav-icon-small/sym_1_white.png");
}

.secondary-nav-list li .sym_2 {
	background-image: url("../img/knowledge_base/nav-icon-small/sym_2_gray.png");
}

.secondary-nav-list li:hover .sym_2,
.secondary-nav-list li:focus .sym_2,
.secondary-nav-list li.active .sym_2 {
	color: #fff;
	background-image: url("../img/knowledge_base/nav-icon-small/sym_2_white.png");
}

.secondary-nav-list li .sym_3 {
	background-image: url("../img/knowledge_base/nav-icon-small/sym_3_gray.png");
}

.secondary-nav-list li:hover .sym_3,
.secondary-nav-list li:focus .sym_3,
.secondary-nav-list li.active .sym_3 {
	color: #fff;
	background-image: url("../img/knowledge_base/nav-icon-small/sym_3_white.png");
}

.secondary-nav-list li .sym_4 {
	background-image: url("../img/knowledge_base/nav-icon-small/sym_4_gray.png");
}

.secondary-nav-list li:hover .sym_4,
.secondary-nav-list li:focus .sym_4,
.secondary-nav-list li.active .sym_4 {
	color: #fff;
	background-image: url("../img/knowledge_base/nav-icon-small/sym_4_white.png");
}

.secondary-nav-list li .sym_5 {
	background-image: url("../img/knowledge_base/nav-icon-small/sym_5_gray.png");
}

.secondary-nav-list li:hover .sym_5,
.secondary-nav-list li:focus .sym_5,
.secondary-nav-list li.active .sym_5 {
	color: #fff;
	background-image: url("../img/knowledge_base/nav-icon-small/sym_5_white.png");
}

.secondary-nav-list li .sym_6 {
	background-image: url("../img/knowledge_base/nav-icon-small/sym_6_gray.png");
}

.secondary-nav-list li:hover .sym_6,
.secondary-nav-list li:focus .sym_6,
.secondary-nav-list li.active .sym_6 {
	color: #fff;
	background-image: url("../img/knowledge_base/nav-icon-small/sym_6_white.png");
}

.secondary-nav-list li .sym_7 {
	background-image: url("../img/knowledge_base/nav-icon-small/sym_7_gray.png");
}

.secondary-nav-list li:hover .sym_7,
.secondary-nav-list li:focus .sym_7,
.secondary-nav-list li.active .sym_7 {
	color: #fff;
	background-image: url("../img/knowledge_base/nav-icon-small/sym_7_white.png");
}

.secondary-nav-list li .sym_8 {
	background-image: url("../img/knowledge_base/nav-icon-small/sym_8_gray.png");
}

.secondary-nav-list li:hover .sym_8,
.secondary-nav-list li:focus .sym_8,
.secondary-nav-list li.active .sym_8 {
	color: #fff;
	background-image: url("../img/knowledge_base/nav-icon-small/sym_8_white.png");
}

.secondary-nav-list li .sym_9 {
	background-image: url("../img/knowledge_base/nav-icon-small/sym_9_gray.png");
}

.secondary-nav-list li:hover .sym_9,
.secondary-nav-list li:focus .sym_9,
.secondary-nav-list li.active .sym_9 {
	color: #fff;
	background-image: url("../img/knowledge_base/nav-icon-small/sym_9_white.png");
}

.secondary-nav-list li .sym_10 {
	background-image: url("../img/knowledge_base/nav-icon-small/sym_10_gray.png");
}

.secondary-nav-list li:hover .sym_10,
.secondary-nav-list li:focus .sym_10,
.secondary-nav-list li.active .sym_10 {
	color: #fff;
	background-image: url("../img/knowledge_base/nav-icon-small/sym_10_white.png");
}

.secondary-nav-list li .sym_11 {
	background-image: url("../img/knowledge_base/nav-icon-small/sym_11_gray.png");
}

.secondary-nav-list li:hover .sym_11,
.secondary-nav-list li:focus .sym_11,
.secondary-nav-list li.active .sym_11 {
	color: #fff;
	background-image: url("../img/knowledge_base/nav-icon-small/sym_11_white.png");
}

.secondary-nav-list li .doctor-icon {
	background-image: url("../img/diagnosis/video.png");
}
.secondary-nav-list li:hover .doctor-icon,
.secondary-nav-list li:focus .doctor-icon,
.secondary-nav-list li.active .doctor-icon {
	color: #fff;
	background-image: url("../img/diagnosis/video-deep.png");
}

/* Knowledge-desc Section Rules
================================
*/

.knowledge-desc {
	position: relative;
	padding: 30px;
	background-color: #f8f9fa;
	border: 1px solid #dfdfdf;
	margin-bottom: 30px;
}

.knowledge-base .desc-sub {
	padding: 0 15px;
}

.knowledge-desc .row>.col-md-9 {
	padding-right: 50px;
}

.user-logged-in .knowledge-desc {
	padding: 25px;
	background-color: #fff;
}

.user-logged-in .knowledge-desc .row > .col-md-8 {
	padding: 25px;
	border: 1px solid #ccc;
}

.user-logged-in .knowledge-desc .row > .col-md-4 {
	margin-top: -25px;
	padding-left: 25px;
}

.user-logged-in .knowledge-desc .knowledge-desc-right {
	padding: 25px;
	margin-right: -40px;
	background-color: #eaeaea;
}

.knowledge-base-head h2 {
	font-size: 30px;
	line-height: 35px;
	font-weight: 600;
	margin-top: 0;
	margin-bottom: 15px;
	color: #797979;
}

.bn-version .knowledge-base-head h2 {
	font-size: 40px;
	line-height: 45px;
}

.knowledge-base-head .heading-border-wrap {
	margin-bottom: 30px;
}

p.desc-details i {
	margin-right: 15px;
}

p.desc-details {
	margin-bottom: 30px;
	font-size: 15px;
	line-height: 20px;
	color: #616161;
	font-weight: 400;
}

.bn-version p.desc-details {
	font-size: 18px;
	line-height: 22px;
	margin-bottom: 20px;
}

.knowledge-desc-left h3 {
	font-weight: 300;
	font-size: 25px;
	line-height: 35px;
	color: #3d3d3d;
}

.knowledge-desc-left p {
	font-size: 13px;
	line-height: 22px;
	margin-bottom: 20px;
	text-align: justify;
}

.bn-version .knowledge-desc-left p {
	font-size: 18px;
}


.knowledge-desc-left p.desc-details {
	margin-bottom: 30px;
    font-size: 15px;
    line-height: 20px;
    color: #616161;
    font-weight: 400;
}

.knowledge-desc-right {
	margin-bottom: 25px;
}

.knowledge-desc-right h4 {
	font-size: 18px;
	line-height: 23px;
color: #777777;
	font-weight: 600;
}

.bn-version .knowledge-desc-right h4 {
	font-size: 25px;
	line-height: 30px;
}

.knowledge-desc-right .col-md-6:first-child {
	padding-right: 65px;
}

.knowledge-desc-right .col-md-6:last-child {
	padding-left: 65px;
}

.knowledge-desc-right .heading-border-wrap {
	margin: 30px 0;
}

@media (min-width: 992px) {

	.knowledge-base .desc-sub {
		width: 50%;
	}
}

@media (max-width: 991px) {
	.user-content.knowledge-base-content .container .row > .col-md-3 {
		display: none;
	}

	.knowledge-desc-right .col-md-6:first-child {
		padding-right: 0;
	}

	.knowledge-desc-right .col-md-6:last-child {
		padding-left: 0;
	}
}

/* Symptom-stat Section Rules
===============================
*/

.symptom-stat {
	padding: 30px;
	border: 1px solid #dfdfdf;
	margin-bottom: 30px;
}

.user-logged-in .symptom-stat {
	padding: 25px;
	margin: 0 25px;
	border: 1px solid #ccc;
	background-color: #fff;
}

.symptom-stat table>tbody>tr>td {
	text-align: left;
	font-size: 15px;
	line-height: 20px;
	padding: 10px 25px;
	font-weight: 400;
	color: #616161;
	border: 1px solid #dfdfdf;
	vertical-align: middle;
	max-width: 200px;
}

.bn-version .symptom-stat table>tbody>tr>td {
	font-size: 18px;
	line-height: 25px;
	max-width: 200px;
}

.symptom-stat table>tbody>tr:nth-child(odd) {
	background-color: #f7f7f7;
}


table>tbody>tr>td a,
table>tbody>tr>th a,
table>tfoot>tr>td a,
table>tfoot>tr>th a,
table>thead>tr>td a,
table>thead>tr>th a {
	color: #616161;
	border-bottom: 1px solid transparent;
	-moz-transition: all ease 0.3s;
	-webkit-transition: all ease 0.3s;
	-ms-transition: all ease 0.3s;
	transition: all ease 0.3s;
}

table>tbody>tr>td a:hover,
table>tbody>tr>th a:hover,
table>tfoot>tr>td a:hover,
table>tfoot>tr>th a:hover,
table>thead>tr>td a:hover,
table>thead>tr>th a:hover {
	color: #00baff;
	border-bottom: 1px solid #00baff;
}

.bn-version .table>tbody>tr>td,
.bn-version .table>tbody>tr>th,
.bn-version .table>tfoot>tr>td,
.bn-version .table>tfoot>tr>th,
.bn-version .table>thead>tr>td,
.bn-version .table>thead>tr>th {
	font-size: 18px;
	line-height: 22px;
	vertical-align: middle;
	max-width: 200px;
}

.bn-version .sans-serif .table>thead>tr>td {
	font-size: 16px;
	line-height: 20px;
	font-weight: 600;
}

.bn-version .sans-serif .table>tbody>tr>td {
	font-size: 16px;
	line-height: 20px;
	font-weight: 300;
    color: #222;
}

/* Symptom-cause section rules
===================================
*/
.symptom-cause {
	position: relative;
	padding: 30px;
	margin-bottom: 30px;
	border: 1px solid #dfdfdf;
}

.user-logged-in .symptom-cause {
	z-index: 0;
	padding: 25px 50px;
}

.symptom-cause table>tbody>tr {
	border: 1px solid #dfdfdf;
}

.symptom-cause table>tbody>tr>td {
	text-align: left;
	font-size: 15px;
	line-height: 20px;
	color: #616161;
	padding: 10px 25px;
	border: 1px solid #dfdfdf;
	max-width: 200px;
}

.symptom-cause table>tbody>tr>td>a {
	border-bottom: 1px solid transparent;
	-moz-transition: all ease 0.3s;
	-webkit-transition: all ease 0.3s;
	-ms-transition: all ease 0.3s;
	transition: all ease 0.3s;
}

.symptom-cause table>tbody>tr>td>a:hover {
	color: #00baff;
	/*text-decoration: underline;*/
	border-bottom: 1px solid #00baff
}

.bn-version .symptom-cause table>tbody>tr>td {
	font-size: 18px;
	line-height: 22px;
	max-width: 200px;
}

.table-striped.table>tbody>tr>td,
.table-striped.table>tbody>tr>th,
.table-striped.table>tfoot>tr>td,
.table-striped.table>tfoot>tr>th,
.table-striped.table>thead>tr>td,
.table-striped.table>thead>tr>th {
	color: #616161;
	max-width: 200px;
}

.table-striped>tbody>tr:nth-of-type(odd) {
	background-color: #f7f7f7;
}


/* Symptom-treatment Section Rules
====================================
*/

.symptom-treatment {
	position: relative;
	padding: 30px;
	border: 1px solid #dfdfdf;
	margin-bottom: 30px;
}

.user-logged-in .symptom-treatment {
	padding: 25px;
	border: 1px solid #ccc;
	margin: 25px;
	background-color: #fff;
}

.user-logged-in .symptom-treatment .img-inline {
	width: 30%;
}

.table-treatment {
	margin-top: 30px;
}

.tab-pane p {
	margin-bottom: 20px;
}

.table-treatment.table>tbody>tr>td,
.table-treatment.table>tbody>tr>th,
.table-treatment.table>tfoot>tr>td,
.table-treatment.table>tfoot>tr>th,
.table-treatment.table>thead>tr>td,
.table-treatment.table>thead>tr>th {
	padding: 10px 20px;
	text-align: left;
	vertical-align: middle;
	font-size: 15px;
	max-width: 200px;
}

.table-treatment.table>tbody>tr>td:nth-child(odd),
.table-treatment.table>tfoot>tr>td:nth-child(odd),
.table-treatment.table>thead>tr>td:nth-child(odd) {
	text-align: left;
	color: #00baff;
}

.table-treatment.table>tbody>tr>td:nth-child(odd) i,
.table-treatment.table>tfoot>tr>td:nth-child(odd) i,
.table-treatment.table>thead>tr>td:nth-child(odd) i {
	border: 1px solid #00baff;
	border-radius: 50%;
	padding: 5px;
}


/*.symptom-treatment-tab .nav-tabs>li {
	float: none;
	border: 0;
}

.symptom-treatment-tab .nav-tabs {
	border: 0;
}*/

/*.symptom-treatment-tab .nav-tabs>li:last-child {
	border-bottom: 0;
}*/

.symptom-treatment-tab .symptom-list-tab.nav-tabs {
	border-bottom: 0;
	display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
}

.symptom-treatment-tab .symptom-list-tab.nav-tabs>li {
	position: relative;
	display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 33.33%;
	/*border-bottom: 1px solid #e1e2e2;*/
	-moz-transition: all linear 0.3s;
	-webkit-transition: all linear 0.3s;
	-ms-transition: all linear 0.3s;
	transition: all linear 0.3s;
}

/*.symptom-treatment-tab .nav-tabs>li:after {
	content: " ";
	position: absolute;
	top: 50%;
	right: 0;
	width: 0;
	height: 0;
	border-top: 15px solid transparent;
	border-right: 10px solid transparent;
	border-bottom: 15px solid transparent;
	margin-top: -15px;
}*/

/*.symptom-treatment-tab .nav-tabs>li:hover:after,
.symptom-treatment-tab .nav-tabs>li.active:after {
	content: " ";
	position: absolute;
	top: 50%;
	right: -20px;
	width: 0;
	height: 0;
	border-top: 15px solid transparent;
	border-left: 10px solid #00baff;
	border-bottom: 15px solid transparent;
	margin-top: -15px;
}*/


.symptom-treatment-tab .nav-tabs>li>a,
.symptom-treatment-tab .nav-tabs .item li {
	font-size: 18px;
	line-height: 25px;
	background-color: #fff;
	color: #4c4c4c;
	padding-top: 20px;
	padding-bottom: 20px;
	padding-left: 25px;
	padding-right: 35px;
	border: 1px solid #ededed;
	border-radius: 0;
	margin-right: 0;
	-webkit-transition: all linear 0.3s;
	-moz-transition: all linear 0.3s;
	-o-transition: all linear 0.3s;
	transition: all linear 0.3s;
}

.bn-version .symptom-treatment-tab .nav-tabs>li>a {
	font-size: 20px;
	line-height: 25px;
	max-width: 100%;
}

.symptom-treatment-tab .nav>li>a:focus,
.symptom-treatment-tab .nav>li>a:hover,
.symptom-treatment-tab .nav>li.active>a,
.symptom-treatment-tab .nav>li.active>a:focus,
.symptom-treatment-tab .nav>li.active>a:hover {
	border: 1px solid #00baff;
	color: #fff;
	background-color: #00baff;
	border-radius: 0;
	margin-right: 0;
}


.symptom-faq .symptom-treatment-tab .nav>li>a:focus,
.symptom-faq .symptom-treatment-tab .nav>li>a:hover,
.symptom-faq .symptom-treatment-tab .nav>li.active>a,
.symptom-faq .symptom-treatment-tab .nav>li.active>a:focus,
.symptom-faq .symptom-treatment-tab .nav>li.active>a:hover {
	background-color: #fff;
	color: #6f6f6f;
	border: 0;
}


.symptom-treatment-tab .tab-content {
	padding-top: 30px;
	padding-bottom: 30px;
}

@media ( max-width: 590px) {
	.symptom-treatment-tab .symptom-list-tab.nav-tabs>li {
		width: 100%;
	}

	.bn-version .symptom-treatment-tab .nav-tabs>li>a {
		padding: 10px;
	    width: 100%;
		font-size: 20px;
		line-height: 22px;

		display: -webkit-box;
	    display: -webkit-flex;
	    display: -ms-flexbox;
	    display: flex;
	}
	.bn-version .symptom-treatment table>tbody>tr>td {
		min-width: none !important;
	}
}

/* symptom-risk section rules
=================================
*/

.symptom-risk {
	position: relative;
	padding: 25px;
	margin-bottom: 25px;
	border: 1px solid #dfdfdf;
}

.user-logged-in .symptom-risk {
	padding: 25px;
	border: 1px solid #ccc;
	margin: 25px;
	background-color: #fff;
}

.table-risk.table-striped>tbody>tr {
	background-color: #fff;
	border: 1px solid #ededed;
}

.table-risk.table-striped>tbody>tr th{
	background-color: #f5f7f8;
}


.table-risk.table-striped>tbody>tr td{
	background-color: #fff;
	border: 1px solid #ededed;
}


.table-risk.table>tbody>tr>td:first-child,
.table-risk.table>tfoot>tr>td:first-child,
.table-risk.table>thead>tr>td:first-child {
	text-align: left;
	padding-left: 20px;
	text-align: center;
	color: #616161;
	font-size: 15px;
	line-height: 20px;
}

.table-risk.table>tbody>tr>td,
.table-risk.table>tfoot>tr>td,
.table-risk.table>thead>tr>td {
	padding: 10px 25px;
	text-align: left;
	color: #616161;
	font-size: 15px;
	line-height: 20px;
	vertical-align: middle;
	max-width: 200px;
}


@media ( max-width: 767px ) {
	.table-risk.table>tbody>tr>td,
	.table-risk.table>tfoot>tr>td,
	.table-risk.table>thead>tr>td {
		max-width: none;
	}
}

/* Symptom-faq section Rules
=================================
*/

.symptom-faq {
	position: relative;
	padding: 25px;
	border: 1px solid #dfdfdf;
	margin-bottom: 25px;
}

.user-logged-in .symptom-faq {
	padding: 25px;
	border: 1px solid #ccc;
	margin: 25px;
	background-color: #fff;
}

.faq-content {
	border-radius: 0;
	background-color: #fff;
	min-height: 272px;
}

/* Doctors List Section Rules
================================
*/

.doctors-list,
.video-list {
	position: relative;
	padding: 25px;
	border: 1px solid #dfdfdf;
	margin-bottom: 25px;
}

.doctors-list .bottom-block p,
.doctors-list .bottom-block a h4 {
	display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.video-list .clearfix,
.doctors-list .clearfix {
	margin: 25px 0;
}
/* symptom-tips section Rules
===============================
*/

.symptom-tips {
	position: relative;
	padding: 25px;
	margin-bottom: 25px;
	border: 1px solid #dfdfdf;
}

.user-logged-in .symptom-tips {
	z-index: 0;
	padding: 25px;
}

/*.symptom-tips h4,
.symptom-tips p,
.symptom-tips .symptom-treatment-tab .nav>li.active>a,
.symptom-tips .symptom-treatment-tab .nav-tabs>li>a,
.symptom-tips .table-treatment.table>tbody>tr>td,
.symptom-tips .table-treatment.table>tbody>tr>th,
.symptom-tips .table-treatment.table>tfoot>tr>td,
.symptom-tips .table-treatment.table>tfoot>tr>th,
.symptom-tips .table-treatment.table>thead>tr>td,
.symptom-tips .table-treatment.table>thead>tr>th,
.symptom-tips .table-treatment.table>tbody>tr>th i,
.symptom-tips .table-treatment.table>tfoot>tr>th i,
.symptom-tips .table-treatment.table>thead>tr>th i {
	color: #4c4c4c;
	border-color: #ededed;
	background-color: transparent;
}*/

.symptom-tips .symptom-treatment-tab .nav>li.active>a:focus,
.symptom-tips .symptom-treatment-tab .nav>li.active>a:hover,
.symptom-tips .symptom-treatment-tab .nav>li>a:focus,
.symptom-tips .symptom-treatment-tab .nav>li>a:hover {
	background-color: #00baff;
	border-color: #00baff;
	border-bottom-color: #00baff;
}

.symptom-treatment-tab .nav .item li.active:focus,
.symptom-treatment-tab .nav .item li.active:hover,
.symptom-treatment-tab .nav .item li:focus,
.symptom-treatment-tab .nav .item li:hover {
	background-color: #00baff;
	border-color: #00baff;
	border-bottom-color: #00baff;
}

.symptom-treatment-tab .nav .item li.active:focus a,
.symptom-treatment-tab .nav .item li.active:hover a,
.symptom-treatment-tab .nav .item li:focus a,
.symptom-treatment-tab .nav .item li:hover a  {
	color: #fff;
}

/*.symptom-tips .symptom-treatment-tab .nav-tabs>li:hover:after,
.symptom-tips .symptom-treatment-tab .nav-tabs>li.active:after {
	border-right-color: #4d5b77;
}*/




/* Stories Section Rules
================================
*/

.stories {
	position: relative;
	background-color: #fff;
	padding: 110px 0;
}

.sub {
	margin-bottom: 50px;
}

.bn-version .sub {
	margin-bottom: 60px;
}

.stories-right-inner {
	padding: 0 35px;
}

.stories-left-outer {
	padding: 0;
}

.stories-left-inner img {
	margin-bottom: 88px;
}

.bn-version .stories-left-inner img {
	margin-bottom: 52px;
}

.stories-left-inner .btn-right {
	margin-top: 61px;
	margin-bottom: 0;
}

.stories-right-inner h3 {
	font-size: 40px;
	line-height: 45px;
	font-weight: 700;
	color: #0093c9;
	margin-bottom: 25px;
}

.bn-version .stories-right-inner h3 {
	font-size: 34px;
}

.stories-right-inner .subtitle {
	font-size: 20px;
	line-height: 25px;
	color: #5d5d5d;
	font-weight: 700;
	margin-bottom: 15px;
}

.bn-version .stories-right-inner .subtitle {
	font-size: 28px;
	font-weight: normal;
}

.stories-right-inner h3 span {
	display: block;
	color: #505050;
}

.bn-version .stories-right-inner h3 span {
	font-size: 32px;
	font-weight: normal;
}

.stories-right-inner h4 {
	color: #5e5e5e;
	font-weight: bold;
	margin-top: 25px;
	margin-bottom: 25px;
}

.stories-right-inner .heading-border-wrap {
	margin-top: 0;
	margin-bottom: 20px;
}

.stories-right-inner p {
	color: #858585;
	margin-bottom: 30px;
}

.bn-version .stories-right-inner p {
	font-size: 18px;
	line-height: 20px;
	color: #6f6f6f;
}

.meta-info-wrapper {
	display: table;
}

.meta-info {
	width: 100%;
}

.meta-info-btn {
	width: 100%;
	margin-top: 10px;
}

.meta-info-left {
	width: 30%;
	float: left;
}

.meta-info-right {
	width: 70%;
	float: left;
	padding-left: 15px;
	padding-top: 10px;
}

.meta-info-btn-left, .meta-info-btn-right {
	width: 50%;
	float: left;
}

.meta-info h4{
	margin-top: 0;
	margin-bottom: 0;
}

.meta-info p {
	margin-bottom: 0;
}

.meta-info-wrapper {
	display: table;
	margin-right: auto;
}

.social-list {
	padding-left: 0;
	margin-bottom: 0;
}

.social-list li {
	list-style-type: none;
	display: inline-block;
    padding: 0 5px;
    color: #8c8c8c;
}

.social-list li:first-child {
	padding-left: 0;
}

@media (max-width: 991px) {
	.stories-left-inner img {
		margin-left: auto;
		margin-right: auto;
	}

	.stories-right-inner {
		display: table;
		margin: auto;
	}

	.stories-left-inner .btn-right {
		margin-bottom: 30px;
	}

	.bn-version .stories-left-inner img {
		margin-top: 25px;
	}

	.stories-left-inner .btn-right {
		margin-bottom: 0;
	}
}

@media (max-width: 767px) {
	.stories-left-inner, .stories-right-inner {
		padding: 0;
	}

	.bn-version .stories-right-inner .subtitle {
		font-size: 25px;
		line-height: 27px;
	}

	.bn-version .stories-right-inner h3 {
		font-size: 25px;
		line-height: 27px;
	}
	.bn-version .stories-right-inner h3 span {
		font-size: 23px;
		line-height: 25px;
	}

	.social-list li {
		padding: 10px 5px;
	}
}


/* Blog Section Rules
==============================
*/

.blog {
	position: relative;
	padding: 110px 0;
	color: #fff;
}

.blog p {
	position: relative;
	color: #a4a4a4;
}

.blog h3 {
	color: #a2a2a2;
}

.blog .sub {
	margin-bottom: 60px;
}


.blog .btn-right {
	margin-top: 55px;
	margin-bottom: 0;
}

.blog-entries {
	padding-left: 25px;
}


.blog-entries .big-text {
	margin-top: 10px;
}

.bn-version .blog-entries .big-text {
	font-size: 41px;
	font-weight: normal;
}

.blog-entries h3 {
	margin-bottom: 20px;
}

.blog-entries-inner {
	margin-bottom: 7px;
}

.blog-entries-inner p.svg-icon {
	width: 55px;
	height: 55px;
}

.blog-entries-inner p.dir {
	background-color: #00baff;
}

.blog-entries-inner p.dir-profile {
	background-color: #006CFF;
}

.blog-entries-inner p.case {
	background-color: #8E01FF;
}

.blog-entries-inner .svg-icon > object {
	width: 35px;
	height: 55px;
}
.blog-right-desc {
	margin-left: -20px;
}

.blog-right-desc p {
	margin-bottom: 0;
}

.blog-entries .heading-border-wrap{
	margin-bottom: 30px;
}

.blog-entries-inner h5 {
	margin-top: 0;
	margin-bottom: 10px;
	font-size: 17px;
	line-height: 25px;
	font-weight: 600;
	color: #d2d2d2;
}

.bn-version .blog-entries-inner h5 {
	font-size: 23px;
	line-height: 55px;
	margin-bottom: 0;
	font-weight: normal;
}

.latest-blog {
	position: relative;
	width: 100%;
	height: 360px;
}

.latest-blog-wrap {
	position: absolute;
	top: 0;
	right: 0;
}

.latest-blog-wrap.below {
	left: 0;
}

.latest-blog-wrap.below .latest-blog-single {
	transform: scale(0.8);
}

.latest-blog-single {
	position: relative;
}

p.blog-date {
	position: absolute;
}

p.blog-comments {
	margin-bottom: 0;
	font-size: 13px;
	line-height: 20px;
	margin-top: -35px;
	margin-left: 30px;
	color: #585858;
}

p.blog-comments i {
	font-size: 20px;
	margin-right: 15px;
}



.blog-date {
	position: absolute;
	top: 0;
	left: 30px;
    width: 75px;
    height: 105px;
    background-color: #393937;
    font-weight: bold;
    color: #fff;
    text-align: center;
    border-bottom-left-radius: 35px;
    border-bottom-right-radius: 35px;
}

.blog-date p {
	display: table-cell;
	height: 105px;
	width: 75px;
	vertical-align: middle;
	text-align: center;
	margin-bottom: 0;
	color: #fff;
}

.date-day {
	display: block;
	font-size: 25px;
	line-height: 30px;
	font-weight: 700;
}

.date-month {
	display: block;
	font-size: 17px;
	line-height: 20px;
}

@media ( max-width: 991px ) {
	.blog-entries {
		margin-bottom: 30px;
	}
}

@media ( max-width: 767px ) {
	.ia-container figure {
		left: 50px;
	}

	.blog-entries {
		padding-left: 0;
	}

	.bn-version .blog-entries-inner h5 {
		font-size: 20px;
		line-height: 22px;
		padding-left: 35px;
		margin-top: 0;
	}

	.blog-entries h3 {
		font-size: 23px;
		line-height: 25px;
	}

	.bn-version .blog-entries .big-text {
		font-size: 25px;
		line-height: 27px;
		margin-top: 5px;
	}

}

@media (max-width: 991px) {
	.latest-blog {
		height: auto;
	}

	.latest-blog-wrap {
		position: static;
		display: table;
		margin: auto;
		margin-bottom: 30px;
	}

	.latest-blog-wrap.below .latest-blog-single {
		transform: scale(1);
	}

	.blog .btn-right {
		margin-right: auto;
		margin-bottom: 0;
	}
}


/* Food And Nutrition Section Rules
=====================================
*/

.food-nutrition {
	padding: 110px 0;
	position: relative;
}


.food-nutrition .btn-center {
	margin-top: 20px;
	margin-bottom: 0;
}

.food-nutrition-content {
	display: flex;
	justify-content: space-around;
	padding-top: 20px;
}

.food-nutrition-content-left {
	display: flex;
	flex-direction: column;
    justify-content: space-between;
    /*margin-right: -45px;*/
    /*width: 30.5%;*/
}

.food-nutrition-content-left-title {
	width: 320px;
    text-align: right;
    font-size: 15px;
    line-height: 22px;
    margin-bottom: 20px;
}

.bn-version .food-nutrition-content-left-title,
.bn-version .food-nutrition-content-right-title {
	font-size: 20px;
	color: #6c6c6c;
}

.food-nutrition-content-right-title {
	width: 320px;
    font-size: 15px;
    line-height: 22px;
    margin-bottom: 20px;
}

.food-nutrition-content-middle {
	align-self: flex-end;
}


.food-nutrition-content-right {
	width: 355px;
}

.form-table {
	background-color: #fff;
	padding: 10px 20px;
	border: 1px solid #e4e4e4;
}

.food-nutrition-content-right .btn-full {
	margin-bottom: 0;
}

.food-form-heading {
	color: #fff;
	font-size: 17px;
	font-weight: 700;
	padding: 18px 0;
	background-color: #9b9b9b;
}

.bn-version .food-form-heading {
	font-size: 23px;
	line-height: 25px;
	font-weight: normal;
}

.food-inner-row {
	display: flex;
}

.food-inner-row:last-child {
	margin-bottom: 0;
}

.food-inner-left {
	align-self: center;
}

.food-inner-right {
	padding: 0;
}

/*.food-inner-left,
.food-inner-right {
	display: table-cell;
	vertical-align: middle;
	float: none;
}*/


.food-nutrition-content p {
	margin-bottom: 0;
}

.food-nutrition-content h5 {
	margin-top: 0;
	font-size: 17px;
	color: #777777;
	line-height: 20px;
	font-weight: 700;
}

.bn-version .food-nutrition-content h5 {
	margin-bottom: 0;
	font-size: 23px;
}

.food-nutrition-content-left h5,
.food-nutrition-content-left p {
	text-align: right;
}

@media (max-width: 991px) {
	.food-nutrition-content-left-title,
	.food-nutrition-content-right-title {
		width: auto;
	}

	.food-inner-row {
		justify-content: flex-end;
		margin-right: 0;
	}

	.food-nutrition-content-left,
	.food-nutrition-content-right {
		width: 35%;
	}

	.food-nutrition-content-middle {
		width: 30%;
	}

}


@media (max-width: 767px) {
	.food-nutrition-content {
		display: block;
	}

	.food-nutrition-content-left,
	.food-nutrition-content-right {
		width: auto;
	}

	.food-nutrition-content-middle {
		width: auto;
	}


	.food-nutrition-content-left-title {
		width: auto;
		text-align: left;
	}

	.food-inner-right {
		display: table;
		margin-left: auto;
	}

	.food-inner-row {
		margin-bottom: 20px;
		margin-left: 0;
		margin-right: 0;
	}

	.food-nutrition-content-middle {
		margin: 20px 0;
	}

	.food-nutrition-content-right,
	.food-nutrition-content-right-title {
		width: auto;
	}
}


/* Health Section Rules
================================
*/

.health {
	padding: 110px 0;
	position: relative;
}

.health-left {
	position: relative;
	border-right: 3px dotted #b8b8b8;
	padding: 0 40px;
	margin-right: -17px;
}

.health-left::after {
	content: " ";
	position: absolute;
	top: 50%;
	right: 0;
	margin-top: -7.5px;
	margin-right: -8.5px;
	width: 15px;
	height: 15px;
	background-color: #00baff;
	-webkit-transform: rotate(45deg);
	-moz-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	-o-transform: rotate(45deg);
	transform: rotate(45deg);
}

.health-right {
	padding: 0 40px;
}

.health h3 {
	margin-bottom: 0;
	color: #a7a7a7;
	font-weight: 600;
	margin-bottom: 30px;
}

.bn-version .health h3 {
	font-size: 38px;
	line-height: 40px;
	font-weight: normal;
}

.bn-version .health h3 {
	margin-bottom: 20px;
}

.health .big-text {
	color: #6a6a6a;
	margin-top: 15px;
}

.bn-version .health .big-text {
	margin-top: 0;
	font-size: 55px;
	line-height: 60px;
}

.health .heading-border-wrap {
	margin-bottom: 30px;
}

.health p {
	margin-bottom: 40px;
}

.health h2 {
	margin-top: 0;
}

.health .video-meta {
	margin-top: 0;
	margin-bottom: 40px;
}

.bn-version .health .video-meta {
	font-size: 18px;
	line-height: 20px;
}

.health .btn {
	margin-bottom: 0;
}

.spacer {
	padding: 0 7.5px;
}

.spacer i {
	padding-right: 5px;
}

.swiper-slide {
	position: relative;
}


.swiper-slide::after {
    content: " ";
    background-image: url(../img/home/play-btn.png);
    background-repeat: no-repeat;
    background-position: top left;
    width: 69px;
    height: 69px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -34.5px;
    margin-top: -34.5px;
    cursor: pointer;
}

.swiper-container {
    width: 100%;
    height: auto;
}


.swiper-container .img-center {
	opacity: 0;
	width: 100%;
	height: auto;
}

.swiper-slide-prev .img-center {
	opacity: .5;
}

.swiper-slide-next .img-center {
	opacity: .5;
}

.swiper-slide-active .img-center {
	opacity: 1;
}

.swiper-container-horizontal>.swiper-pagination {
	position: static;
	margin-top: 40px;
	left: auto;
	bottom: auto;
}

.swiper-pagination-bullet {
	width: 15px;
	height: 15px;
	background-color: transparent;
	opacity: 1;
	border: 2px solid #00baff
}

.swiper-pagination-bullet-active {
	background-color: #00baff;
}


@media (max-width: 991px) {
	.health-left {
		border-right: 0;
		padding: 0;
		margin-bottom: 35px;
	}
}

@media (max-width: 767px) {

	.health-right {
		padding: 0;
		margin-bottom: 30px;
	}

	.health-left {
		margin-right: 0;
	}

	.health-left::after {
		background-color: transparent;
	}

	.spacer {
		display: block;
		padding: 0;
	}

	.bn-version .health h3 {
		font-size: 23px;
		line-height: 25px;
	}

	.bn-version .health .big-text {
		font-size: 26px;
		line-height: 28px;
		margin-top: 5px;
	}
}


/* Video Section Rules
===============================
*/

.video {
	position: relative;
}

.arrow-down {
	position: absolute;
	top: 0;
	left: 50%;
	margin-left: -20px;
	width: 0;
	height: 0;
	border-left: 20px solid transparent;
	border-right: 20px solid transparent;
	border-top: 20px solid #fff;
	z-index: 1;
}

.video-inner::after {
	content: " ";
	background-image: url(../img/home/svg/video.svg);
	background-repeat: no-repeat;
	background-position: top left;
	width: 69px;
	height: 69px;
	position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -34.5px;
    margin-top: -34.5px;
    cursor: pointer;
}

@media ( max-width: 400px ) {
	.video-inner::after {
		width: 35px;
		height: 35px;
		margin-left: -17.5px;
	    margin-top: -17.5px;
	}
}

/* Testimonial Section Rules
================================
*/

.testimonial {
	padding: 90px 0;
	position: relative;
}


.testimonial h3 {
	font-size: 30px;
	margin-top: 10px;
	font-weight: 700;
	color: #6a6a6a;
}

.bn-version .testimonial h3 {
    font-size: 30px;
    line-height: 35px;
}

.bn-version .testimonial h3 .blue {
	font-size: 60px;
}

.testimonial .big-text {
	margin-top: 0;
	font-size: 67px;
	color: #505050;
}
.title-wrap {
	position: relative;
	margin-top: 50px;
}

.bn-version .title-wrap {
	margin-top: 90px;
}

.border-circle-right {
	content: " ";
	position: absolute;
	top: 50%;
	right: 0;
	margin-top: -2px;
	width: 50%;
	height:2px;
	border-bottom: 1px solid #cfcfcf;
	z-index: 1;
}

.bn-version .border-circle-right {
	width: 35%;
}

.border-circle-right::after {
	content: " ";
	position: absolute;
	top: 0;
	right: 0;
	margin-top: -6px;
	width: 15px;
	height: 15px;
	background-color: #cfcfcf;
	border-radius: 50%;
	z-index: 2;
}

.testimonial-desc {
	margin-top: 60px;
}

.bn-version .testimonial-desc {
	font-size: 18px;
	line-height: 20px;
}

.testimonial-desc::before {
	content: " ";
	position: absolute;
	top: 20px;
	left: 15px;
	width: 55px;
	height: 36px;
	background-image: url(../img/home/say/comma.png);
	background-position: top left;
	background-repeat: no-repeat;
}

.testimonial-desc::after {
	content: " ";
	position: absolute;
	bottom: 40px;
	right: 20px;
	width: 55px;
	height: 36px;
	background-image: url(../img/home/say/comma_2.png);
	background-position: bottom right;
	background-repeat: no-repeat;
}

.bn-version .testimonial-desc::after {
	bottom: 20px;
}

.owl-testimonial {
	position: relative;
}

@media (max-width: 991px) {
	.border-circle-right {
		display: none;
	}

	.title-wrap {
		text-align: center;
	}
}

@media (max-width: 767px) {
	.owl-testimonial img {
		margin-left: auto;
		margin-right: auto;
	}

}

/* Ask A Doctor Page Rules
================================
*/

.img-inline {
	display: inline;
    padding: 0 10px;
    position: relative;
    top: -1px;
}

.img-inline:first-child {
	padding-left: 0;
}

.img-inline:last-child {
	padding-right: 0;
}

.img-inline.img-thumb {
	top: -4px;
	padding-right: 0;
	padding-left: 5px;
}

.doc-content {
	margin-bottom: 50px;
}

.doc-content .col-md-8 {
	padding-right: 40px;
}

.doc-content .col-md-4 {
	padding-left: 40px;
}

.doc-content-single {
	border: 1px solid #e4e4e4;
	padding: 25px;
	margin-bottom: 25px;
}

.doc-content-header .col-md-6 {
	padding: 0;
}

.doc-content-head-left h4 {
	margin-top: 0;
	color: #585858;
	font-size: 20px;
	line-height: 27px;
	font-weight: 700;
	margin-bottom: 20px;
}

.doc-content-head-left .heading-border-wrap {
	margin-bottom: 20px;
}

.doc-content-head-left .heading-border {
	width: 60px;
}

.doc-content-head-right {
	display: table;
	margin-left: auto;
}

.doc-content-head-right ul>li>ul.dropdown-menu {
	left: auto;
	right: 0;
}

.doc-content-head-right ul>li>ul.dropdown-menu li {
	width: 100%;
	padding: 0;
}

.doc-content-head-right .btn,
li.dropdown .btn {
	margin-bottom: 0;
	padding: 15px;
	background-color: #eee;
	border-color: #eee;
}

li.dropdown .btn.dropdown-toggle {
	padding: 15px 5px;
}

.doc-content-head-right ul li:first-child {
	padding-left: 0;
}

.doc-content-head-right ul li:last-child {
	padding-right: 0;
}

.doc-content-head-right .btn:hover, .doc-content-head-right .open .btn {
	background-color: #00baff;
	border-color: #00baff;
}

.doc-content-head-right ul {
	padding-left: 0;
	margin-bottom: 0;
}

.doc-content-head-right ul li.answer-text {
	font-size: 13px;
	line-height: 20px;
	color: #9e9e9e;
	margin-right: 5px;
}

.doc-content-head-right ul li {
	padding: 0 3px;
	font-size: 13px;
	line-height: 20px;
	list-style-type: none;
	display: inline-block;
}

.doc-content-para p {
	text-align: justify;
	margin-bottom: 20px;
}

.doc-content-footer .col-md-8,
.doc-content-footer .col-md-4 {
	padding: 0;
}

.doc-content-footer-left ul {
	padding-left: 0;
	margin-bottom: 0;
}

.doc-content-footer-left ul li {
	list-style: none;
	display: inline-block;
	padding: 0 5px;
}

.doc-content-footer-left ul li:first-child {
	padding-left: 0;
}

.doc-content-footer-left ul li:last-child {
	padding-right: 0;
}

.doc-content-footer-left .btn-default {
	background-color: #00baff;
	border-color: #00baff;
	margin-bottom: 0;
	padding: 15px;
	font-size: 16px;
	line-height: 20px;
	font-weight: 700;
}

.doc-content-footer-right .img-share {
	margin-right: 5px;
}

.doc-content-footer-right .img-inline {
	padding: 0;
}

.doc-content-footer-right ul {
	padding-left: 0;
	margin-bottom: 0;
	display: table;
	margin-left: auto;
}

.doc-content-footer-right ul li {
	display: table-cell;
	vertical-align: middle;
	height: 54px;
}

.doc-content-footer-right ul li.barrier {
	border-right: 1px solid #a5a5a5;
}


.doc-content-footer-right ul li a {
	font-size: 15px;
	line-height: 20px;
	color: #a5a5a5;
	padding: 0 10px;
	border-left: 1px solid #a5a5a5;
}

.doc-content-footer-right ul li:first-child a {
	border-left: 0;
	padding-left: 0;
}

.doc-content-footer-right ul li:last-child a {
	padding-right: 0;
}

.doc-content-footer-right ul li a:hover {
	text-decoration: none;
}

.btn-wrap {
	display: table;
	width: 100%;
	margin-bottom: 25px;
}

.widget {
	margin-bottom: 70px;
}

.widget:first-child {
	margin-bottom: 45px;
}

.widget:last-child {
	margin-bottom: 20px;
}

aside .btn-wrap .btn-avatar {
	height: 100px;
}

aside .btn-wrap .btn {
	display: table-cell;
	vertical-align: middle;
}

aside .btn-wrap .btn-blue {
	height: 55px;
}

.btn-blue {
	background-color: #00baff;
	text-align: center;
	font-size: 18px;
	line-height: 20px;
	font-weight: 700;
	color: #fff;
}

.bn-version .btn-newsletter {
	background-color: #a5a5a5;
	color: #fff;
	font-weight: bold;
	padding: 5px;
	font-size: 13px;
	min-height: 50px;
	max-height: 50px;
	margin-top: 0;
	margin-bottom: 0 !important;
}

.bn-version .btn-newsletter:hover {
	background-color: #00baff;
}

.form-newsletter {
	position: relative;
	max-height: 50px;
	width: 100%;
}

.form-newsletter .btn-newsletter {
	color: #00baff;
	position: absolute;
	font-size: 25px;
	line-height: 30px;
	top: 0;
	right: 10px;
	background-color: transparent;
	z-index: 1;
	outline: 0;
}

.form-newsletter .btn-newsletter:hover,
.form-newsletter .btn-newsletter:focus {
	background-color: transparent;
	border-color: transparent;
	outline: 0;
}

.input-group-addon.btn-file,
.input-group-addon.fileinput-exists {
	border: 0;
}

.doc-profile-tab .tab-pane p.upload-file-desc,
.dir-profile-tab .tab-pane p.upload-file-desc {
	margin-bottom: 20px;
	color: #00baff;
}

.btn-blue:hover {
	background-color: #0B7BA5;
	border-color: #0B7BA5;
}


.widget h4 {
	font-size: 18px;
	line-height: 25px;
	font-weight: 700;
	margin-bottom: 20px;
	margin-top: 0;
}


.widget .heading-border-wrap {
	margin-bottom: 25px;
}

.widget .heading-border {
	width: 60px;
}

.popular-ques-single {
	display: table;
	width: 100%;
	padding: 20px 0;
	border-bottom: 1px solid #cacaca;
}

.popular-ques-single:first-child {
	padding-top: 0;
}

.popular-ques-single:last-child {
	border-bottom: 0;
	padding-bottom: 0;
}

.popular-ques-left {
	display: table-cell;
	vertical-align: top;
}

.popular-ques-right {
	display: table-cell;
	vertical-align: top;
	padding-left: 20px;
}

.popular-ques-right>p {
	font-size: 14px;
	line-height: 20px;
	margin-bottom: 15px;
	font-weight: 600;
}

.popular-ques-right>p a {
	color: #616161;
}


.popular-ques-right>p a:hover {
	text-decoration: none;
}

.popular-ques-right-inner {
	display: table;
	width: 100%;
}

.popular-ques-right-inner-left,
.popular-ques-right-inner-right {
	display: table-cell;
	vertical-align: middle;
}

.popular-ques-right-inner-right p {
	margin-bottom: 0;
	color: #505050;
}

.widget .latest-post .heading-border-wrap {
	margin-bottom: 0;
}

ul.latest-post-list {
	padding-left: 0;
	margin-bottom: 0;
}

ul.latest-post-list li {
	list-style: none;
	padding: 22px 0;
	border-bottom: 1px solid #cacaca;
}

ul.latest-post-list li:last-child {
	border-bottom: 0;
	padding-bottom: 0;
}

ul.latest-post-list li a {
	font-size: 14px;
	line-height: 20px;
	text-decoration: none;
	color: #616161;
	font-weight: 600;
}

.tags-inner {
	display: table;
	width: 100%;
	border-collapse: separate;
	border-spacing: 10px 5px;
}

.tags-inner p {
	margin-bottom: 0;
}

.tags-inner-wrap {
	margin-right: -10px;
	margin-left: -10px;
}

p.tags-inner-left, p.tags-inner-right {
	display: table-cell;
	text-align: center;
	background-color: #eeeeee;
	padding: 10px 0;
}

p.tags-inner-left a,
p.tags-inner-right a {
	color: #616161;
	font-size: 13px;
	line-height: 22px;
}

p.tags-inner-left a:hover,
p.tags-inner-right a:hover {
	text-decoration: none;
}


/* Ask A Doctor Single page Rules
====================================
*/
.ask-a-doc-single .secondary-nav-list li a {
	width: 110px;
}

.ask-a-doc-single .doc-content-single-page .col-md-4 {
	padding-left: 40px;
}
.ask-a-doc-single .doc-content-single-page .col-md-8 {
	padding-right: 40px;
}

.doc-content-single-page {
	padding: 75px 0;
}

.user-logged-in .doc-content-single-page {
	padding: 0;
}

.user-logged-in .doc-content-single-page > .container-fluid > .row > .col-md-9 {
	padding: 25px;
}

.doc-answers {
	margin-bottom: 70px;
}

.user-logged-in .doc-answers {
	padding: 25px;
	border: 1px solid #e3e3e3;
	margin-bottom: 25px;
}


.user-logged-in .doc-content-single-page aside.col-md-3 {
	background-color: #eaeaea;
	padding: 25px;
}

.doc-answers:last-child {
	margin-bottom: 0;
}

.doc-answers h3 {
	line-height: 30px;
	font-weight: 700;
	color: #585858;
	margin-bottom: 20px;
}

.doc-answers .heading-border-wrap {
	margin-bottom: 25px;
}

.doc-answers .heading-border {
	width: 60px;
}

.doc-meta {
	display: table;
	width: 100%;
	margin-bottom: 30px;
}

.doc-meta-left {
	display: table-cell;
	vertical-align: top;
}

.doc-meta-right {
	display: table-cell;
	vertical-align: bottom;
}

.doc-meta-left-inner-wrap {
	display: table;
	width: 100%;
}

.doc-meta-left-inner-left {
	display: table-cell;
	width: 140px;
}


.doc-meta-left-inner-right {
	display: table-cell;
	vertical-align: top;
}

.doc-meta-left-inner-right h4 {
	font-size: 20px;
	line-height: 25px;
	margin-top: 0;
	margin-bottom: 0;
	font-weight: 600;
}

.doc-meta-left-inner-right p.blue {
	font-size: 15px;
	line-height: 23px;
	margin-bottom: 10px;
}

.doc-meta-left-inner-right p {
	margin-bottom: 5px;
	font-size: 15px;
	line-height: 23px;
}

.doc-meta-left-inner-right ul.rating {
	padding-left: 0;
	margin-bottom: 0;
}

.doc-meta-right>ul {
	display: table;
	margin-left: auto;
	width: 100%;
	margin-bottom: 0;
}

.doc-meta-right>ul>li {
	display: table-cell;
	list-style: none;
	text-align: right;
}

.doc-meta-right>ul>li>ul.dropdown-menu {
	right: 0;
	left: auto;
}

.doc-meta-right>ul>li>a.btn-grey {
	margin-bottom: 0;
}

li.dropdown>a {
	color: #9e9e9e;
}

li.dropdown>a>i {
	font-size: 25px;
    line-height: 30px;
    font-weight: 600;
    position: relative;
    margin-left: 10px;
    top: 4px;
}

li.dropdown>a:hover,
li.dropdown>a:active,
li.dropdown>a:focus {
	text-decoration: none;
}


.grey-heading-border-wrap .heading-border {
	border-bottom-color: #7e7e7e;
}

h4.brief {
	margin-top: 0;
	color: #a4a4a4;
	font-weight: 600;
	margin-bottom: 40px;
}

h4.brief span {
	font-size: 15px;
	line-height: 25px;
	color: #777777;
	font-weight: 700;
	margin-left: 20px;
}

p.brief-sub {
	color: #6f6f6f;
	line-height: 20px;
	margin-bottom: 45px;
}

.doc-answers .featured-img {
	width: 100%;
	margin-bottom: 40px;
}

.suggest-btn-wrap .btn-grey {
	font-size: 20px;
	line-height: 20px;
	font-weight: 400;
	padding: 20px 30px;
	margin-bottom: 0;
	margin-right: 10px;
}

.suggest-btn-wrap .btn-blue {
	font-size: 20px;
	line-height: 20px;
	font-weight: 400;
	margin-bottom: 0;
}

.suggest-btn-wrap .btn-blue .img-inline {
	margin-right: 10px;
    padding-left: 0;
}

.related-ques {
	display: table;
	width: 100%;
}

.related-ques-single {
	padding: 20px 0;
	border-bottom: 1px solid #c5c6c6;
}

.related-ques-single:first-child {
	padding-top: 0;
}

.related-ques-single:last-child {
	border-bottom: 0;
	padding-bottom: 0;
}

.related-ques p{
	color: #777777;
	font-weight: 700;
	margin-bottom: 10px;
}



.related-ques-inner {
	display: table;
	width: 100%;
}

.related-ques-inner-left,
.related-ques-inner-right {
	display: table-cell;
	vertical-align: middle;
}

.related-ques-inner-left {
	width: 35px;
}

.related-ques-inner-right>p {
	margin-bottom: 0;
}


.related-topics-single {
	display: table;
	width: 100%;
	margin-bottom: 20px;
}

.related-topics-single:first-child {
	padding-top: 10px;
}

.related-topics-single:last-child {
	margin-bottom: 0;
}

.related-topics-left,
.related-topics-right {
	display: table-cell;
	vertical-align: top;
}

.related-topics-right {
	padding-left: 15px;
}

.related-topics-right h5 {
	font-size: 14px;
	line-height: 20px;
	color: #616161;
	margin-top: 0;
	margin-bottom: 20px;
}

.related-topics-right ul {
	padding-left: 0;
	margin-bottom: 0;
}

.related-topics-right ul li {
	list-style: none;
	color: #7c7c7c;
}

.related-topics-right ul li img {
	padding-left: 0;
}

.rx-quote-list {
	padding: 25px 0;
	background-color: #fff;
	border: 1px solid #ddd;
}

.rx-quote-head {
	padding: 0 25px;
	border-bottom: 1px solid #ddd;
}

.rx-disease-head {
	background-color: #fff;
	padding: 25px;
	border-top: 1px solid #ddd;
	border-left: 1px solid #ddd;
	border-right: 1px solid #ddd;
}

.rx-disease-head .heading-border-wrap {
	padding: 10px 0;
}

.rx-quote-head h3 {
	margin-bottom: 10px;
}

.rx-quote-head .heading-border-wrap {
	padding: 5px 0;
	margin-bottom: 15px;
}

.rx-quote {
	display: block;
	padding: 25px;
	border-bottom: 1px solid #ddd;

	-webkit-transition: all ease 0.3s;
	transition: all ease 0.3s;
}

.rx-quote:hover,
.rx-quote:focus {
	background-color: #f5f5f5;
}

.rx-quote:last-child {
	border-bottom: 0;
}

.rx-quote blockquote {
	position: relative;
	padding: 25px 0;
	border: 0;
	color: #656565;
}

.rx-quote blockquote:before {
	content: '\f10d';
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 15px;
    line-height: 25px;
    position: absolute;
    top: 0;
    left: 0;
    color: #ccc;
}

.rx-quote blockquote:after {
	content: '\f10e';
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 15px;
    line-height: 25px;
    position: absolute;
    bottom: 0;
    right: 0;
    color: #ccc;
}

.complainer {
	display: table;
	margin-left: auto;
	color: #222;
}

/* Hospital Directory Page Rules
=================================
*/

.dropdown .panel-group {
	margin-bottom: 0;
}

.dropdown .panel-group .panel {
	border: 0;
	margin-top: 0;
}

.dropdown .panel-heading {
	background-color: #f4f4f4;
	border-bottom: 1px solid #cccccc;
	padding: 15px;
}

.dropdown .panel-title a {
	display: block;
}

.dropdown .panel-title a::after {
	padding: 0;
	line-height: 15px;
}

.dropdown .area-filter-list {
	padding: 0 15px;
}

.dropdown .radio {
	padding-left: 0;
	padding-right: 0;
}

.dropdown input[type=radio]::after {
    content: " ";
    background-color: #fff;
    display: inline-block;
    visibility: visible;
    width: 25px;
    height: 25px;
    border: 1px solid #00baff;
    border-radius: 50%;
}

.dropdown input[type=radio]:checked::after {
	position: absolute;
    font-family: "FontAwesome";
    content: "\f00c";
    color: #00baff;
    font-size: 14px;
    width: 25px;
    height: 25px;
    padding: 4px;
    top: 0;
    left: 0;
}

.dropdown .radio span {
	margin-left: 15px;
    font-size: 13px;
    line-height: 20px;
}

.bn-version .dropdown .radio span {
	font-size: 18px;
	line-height: 22px;
}

.dropdown .ui-corner-all,
.dropdown .ui-corner-bottom,
.dropdown .ui-corner-left,
.dropdown .ui-corner-bl {
	margin-bottom: 20px;
}

.dir-profile-tab .tab-content>.tab-pane .doc-info p {
	margin-bottom: 0;
}


.dir-profile-tab .tab-content>.tab-pane .doc-info p.blue {
	color: #00baff;
}

@media (max-width: 767px) {
	.dir-profile-tab > .col-md-12,
	.doc-profile-tab > .col-md-12 {
		padding: 0;
	}
}


/* Footer Rules
======================
*/

.site-footer {
	background-color: #353535;
	padding: 60px 0;
	color: #c1c1c1;
	position: relative;
	overflow: hidden;
}

.site-footer .bg-img {
	z-index: 0;
	top: -5%;
}

.footer-border-right {
	width: 100%;
	border-bottom: 1px solid #626262;
	margin-top: -1px;
}

.footer-logo {
	background-color: #353535;
	position: relative;
}

img.footer-logo {
	max-width: 124px;
}

.footer-border {
	width: 100%;
	border-bottom: 1px solid #626262;
	position: relative;
}

.footer-content {
	padding: 50px 0;
	position: relative;
}

.footer-content h3 {
	margin-bottom: 20px;
	font-size: 20px;
	line-height: 30px;
}

.bn-version .footer-content h3 {
	font-size: 26px;
	line-height: 28px;
}

.footer-content p {
	color: #c1c1c1;
	margin-bottom: 15px;
}

.bn-version .footer-content p {
	font-size: 18px;
	line-height: 20px;
	margin-bottom: 5px;
}

.bn-version .footer-content p.footer-about {
	margin-bottom: 15px;
}

.footer-blog-list {
	display: table;
	padding-left: 10px;
}

.footer-blog-desc-wrap {
	max-width: 180px;
}

.footer-blog-list .col-md-4,
.footer-blog-list .col-sm-4,
.footer-blog-list .col-xs-4,
.footer-blog-list .col-md-8,
.footer-blog-list .col-sm-8,
.footer-blog-list .col-xs-8 {
	padding-left: 5px;
	padding-right: 5px;
}

.footer-content .clearfix {
	margin-bottom: 20px;
}

.footer-content .heading-border-wrap {
	margin-bottom: 20px;
}

.footer-first-block {
	margin-left: 30px;
}

.footer-middle-block {
	margin-left: -30px;
}

.footer-last-block {
	display: table;
	margin-left: auto;
	margin-right: -15px;
}

.footer-about{
	margin-top: 25px;
}

.footer-list {
	padding-left: 0;
}

.footer-list li {
	position: relative;
	list-style: none;
	font-size: 14px;
	line-height: 25px;
	padding: 5px 0;
}

.footer-list li a {
	color: #c1c1c1;
	padding: 0 20px;
}

.bn-version .footer-list li a {
	font-size: 18px;
}

.footer-list li a:before {
	content: " ";
	position: absolute;
	left: 0;
	top: 0;
	margin-top: 12px;
	width: 0;
	height: 0;
	border-top: 5px solid transparent;
	border-bottom: 5px solid transparent;
	border-left: 5px solid #c1c1c1;
}


.footer-list li:hover a:before, .footer-list li a.active:before  {
	border-left: 5px solid rgb(0,186,255);
}

.footer-list li:hover a,
.footer-list li a.active {
	color: #fff;
}

.footer-list li a:hover {
	text-decoration: none;
}

.promotion {
	margin-top: 50px;
}

.promotion h3 {
	font-weight: 100;
	margin-bottom: 25px;
}

.footer-social-list {
	padding-left: 0;
}

.footer-social-list li {
	list-style: none;
	display: inline-block;
	border: 1px solid #c1c1c1;
	margin-right: 10px;
	width: 46px;
	height: 47px;
	border-radius: 50%;
	-webkit-transition: all linear 0.3s;
	-ms-transition: all linear 0.3s;
	-moz-transition: all linear 0.3s;
	transition: all linear 0.3s;
}

.footer-social-list li a {
	color: #c1c1c1;
    display: table-cell;
    text-align: center;
    vertical-align: middle;
    width: 46px;
    height: 47px;
    -webkit-transition: all linear 0.3s;
	-ms-transition: all linear 0.3s;
	-moz-transition: all linear 0.3s;
	transition: all linear 0.3s;
	border-radius: 0;
}

.footer-social-list li.facebook:hover {
	background-color: #3b5999;
	border-color: #3b5999;
}

.footer-social-list li.twitter:hover {
	background-color: #55acee;
	border-color: #55acee;
}

.footer-social-list li.dribbble:hover {
	background-color: #ea4c89;
	border-color: #ea4c89;
}

.footer-social-list li.google-plus:hover {
	background-color: #DC4E41;
	border-color: #DC4E41;
}

.footer-social-list li:hover a {
	color: #fff;
}


.form-control {
	position: relative;
	height: 50px;
	border-radius: 0;
	background-color: transparent;
	padding: 10px 25px 10px 10px;
	color: #222;
	border: 1px solid transparent;
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	box-shadow: none;
	transition: all linear 0.3s;
}

.modal-form input.form-control {
    height: 40px;
}

.bn-version .form-control {
	font-size: 16px;
	line-height: 20px;
}

.form-control:focus {
	border: 1px solid #00baff;
	-webkit-box-shadow: none;
	-webkit-box-shadow: none;
	-webkit-box-shadow: none;
}

/*.email-input {
	background: url(../img/home/tick-mark.png) no-repeat scroll 7px 7px;
	background-position: 90%;
	padding-right: 55px;
}*/

.site-footer .email-input {
	padding: 10px 50px 10px 25px;
	border-radius: 25px;
	border-color: #a5a5a5;
    color: #c1c1c1;
}

.site-footer .email-input:focus {
	border-color: #00baff;
}

.footer-nav {
	background-color: #1f1f1f;
	padding: 30px 0;
	color: #c1c1c1;
}

.copyright {
	margin-top: 20px;
	margin-bottom: 0;
}

.bn-version .copyright {
	font-size: 18px;
	line-height: 20px;
}

.footer-nav .navbar-nav>li>a {
	color: #c1c1c1;
}

.bn-version .footer-nav .navbar-nav>li>a {
	font-size: 18px;
	line-height: 20px;
	font-weight: normal;
}

.footer-nav-list.nav>li>a:hover,
.footer-nav-list.nav>li>a:focus {
	color: #00baff;
}

.footer-nav-inner {
	position: relative;
}

.up-arrow,
.up-arrow:focus {
	display: none;
	opacity: 0;
	outline: 0;
	position: fixed;
	right: 15px;
	bottom: 15px;
	padding: 0;
	border-radius: 2px;
	background-color: #a5a5a5;
	padding: 10px 12.5px;
	font-size: 20px;
	line-height: 22px;
	color: #fff;

	-webkit-transition: all ease 0.3s;
	transition: all ease 0.3s;
}

.up-arrow:hover {
	background-color: #00baff;
	color: #fff;
}

@media (max-width: 991px) {
	.email-input {
		background-position: 97%;
	}

	.footer-first-block {
		margin-left: 0;
		padding-left: 0;
	}

	.footer-middle-block {
		margin-left: 0;
	}

	.footer-last-block {
		margin-left: 0;
	}
}

@media (max-width: 767px) {
	.footer-first-block {
		margin-bottom: 20px;
	}

	.footer-blog-list {
		padding-left: 0;
	}

	.footer-middle-block {
		margin-bottom: 20px;
	}

	.footer-last-block {
		display: block;
		margin-bottom: 20px;
	}

	.promotion h3 {
		margin-top: 25px;
	}
}


/* Symptom Page Rules
============================
*/

.wrapper {
	padding-left: 270px;
	transition: all linear 0.3s;
	margin-top: 86px;
}

body.toggled .wrapper {
	padding-left: 110px;
}

.hidden-block,
.hidden-form-block {
	position: relative;
	display: none;
	/*-moz-transition: all ease 0.3s;
	-webkit-transition: all ease 0.3s;
	-ms-transition: all ease 0.3s;
	transition: all ease 0.3s;*/
}

.symptom .col-md-12 .man {
	margin: 0 220px;
}

.human-block {
	/*-moz-transition: all ease 0.3s;
	-webkit-transition: all ease 0.3s;
	-ms-transition: all ease 0.3s;
	transition: all ease 0.3s;*/
}

.symptom-close {
	position: absolute;
    top: 12px;
    right: 35px;
}

.symptom-form-close {
    position: absolute;
    top: 12px;
    right: 19px;
}

.symptom-list li.search {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
}

.btn-fixed-bottom {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	margin-bottom: 0;
	height: 57px;
}

.symptom-list-table {
	border-collapse: collapse;
	border-top: 1px solid #d7d7d7;
	border-bottom: 1px solid #d7d7d7;
	margin-bottom: 0;
}

.symptom-list-table>tbody>tr {
	border-bottom: 1px solid #d7d7d7;
}

.symptom-list-table>tbody>tr>td {
	text-align: left;
	position: relative;
	color: #616161;
	cursor: pointer;
	padding: 0;
	max-width: 200px;
}

.symptom-list-table>tbody>tr>td>span {
	position: relative;
	border: 0;
	display: block;
	padding-top: 15px;
	padding-bottom: 15px;
	padding-left: 30px;
	padding-right: 40px;
	border-radius: 0;
	border-left: 5px solid transparent;
	transition: all linear 0.3s;
}


.symptom-list-table>tbody>tr>td span::before {
	content: '\f105';
	position: absolute;
	top: 0;
	right: 25px;
	display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: 25px;
    line-height: 51px;
    font-weight: 700;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #c0c0c0;
    -webkit-transition: all linear 0.3s;
    -moz-transition: all linear 0.3s;
    -ms-transition: all linear 0.3s;
    transition: all linear 0.3s;
}

.symptom-list-table>tbody>tr>td>span:hover {
	font-weight: 700;
	border-left: 5px solid #00baff;
	background-color: #efefef;
}

.symptom-list-table>tbody>tr>td>span:hover:before {
	color: #616161;
}

/*.symptom-list-body {
	max-height: 195px;
	overflow-x: auto;
}*/

.symptom-wrap,
.symptom-form {
	height: 305px;
	position: relative;
}

.symptom-wrap {
	border: 1px solid #e2e2e2;
}

header.title-area {
	background-color: #ccc;
	padding: 10px;
}

.symptom-form-body,
.symptom-list-body {
	max-height: 250px;
	overflow-y: auto;
}

.content-area {
	max-height: 250px;
}

.symptom-wrap footer.search {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	background-color: #fff;
}

.bn-version .symptom .btn {
	margin-bottom: 0;
}

.mapTooltip {
	background-color: #fff;
	padding: 10px 25px;
	border: 1px solid #d3d7da;
	color: #616161;
	/*box-shadow: 0 6px 12px rgba(0, 0, 0, .125);*/
}


@media (max-width: 991px) {
	.symptom .col-md-12 .man {
		margin: auto;
	}

	.human-block,
	.hidden-block,
	.hidden-form-block {
		margin-bottom: 20px;
	}


}

@media (max-width: 767px) {

	.hidden-block,
	.hidden-form-block {
		position: absolute;
		top: 30px;
		width: 100%;
		max-width: 100%;
		background-color: #fff;
	}

	.hidden-block {
		z-index: 9;
	}
	.hidden-form-block {
		z-index: 10;
	}

	.symptom-list-body {
		max-height: 255px;
	}

	.man,
	.symptom-wrap,
	.symptom-form {
		height: 535px;
	}

	.bn-version .symptom .btn {
		margin-bottom: 0;
	}

	.symptom-list-table>tbody>tr>td>span {
		padding-left: 40px;
	}

	.symptom-list-table>tbody>tr>td>span.ticked::after {
		content: "\f00c";
		position: absolute;
		top: 15px;
		left: 11px;
		color: #00baff;
		display: inline-block;
	    font: normal normal normal 14px/1 FontAwesome;
	    font-size: inherit;
	    text-rendering: auto;
	    -webkit-font-smoothing: antialiased;
	    -moz-osx-font-smoothing: grayscale;

	}

	.symptom-list-table>tbody>tr>td span::before {
		content: '';
		position: absolute;
		top: 15px;
		left: 10px;
		width: 20px;
		height: 20px;
		border: 1px solid #c0c0c0;
	    -webkit-transition: all linear 0.3s;
	    -moz-transition: all linear 0.3s;
	    -ms-transition: all linear 0.3s;
	    transition: all linear 0.3s;
	}

	.symptom-form-body,
	.symptom-list-body {
		max-height: 250px;
		overflow-y: auto;
	}
}



/* Sidebar Rules
=========================
*/

.sidebar-wrapper {
	z-index: 1000;
    position: fixed;
	left: 270px;
	width: 270px;
	margin-left: -270px;
    height: 100%;
    background: #2a2d33;
    overflow-y: scroll;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
    overflow-x: hidden;
    overflow-y: hidden;
}

.sidebar-wrapper .author-below-text {
	opacity: 1;
	-moz-transition: all linear 0.8s;
	-webkit-transition: all linear 0.8s;
	-ms-transition: all linear 0.8s;
	transition: all linear 0.8s;
}

body.toggled .sidebar-wrapper {
	left: 110px;
    width: 110px;
    margin-left: -110px;
}

body.toggled .side-nav-text,
body.toggled .author-below-text {
	opacity: 0;
}

body.toggled .side-nav-img {
	width: 110px;
}



.author-info {
	padding: 25px 0;
	background-color: #34373d;
}

.author-info h3 {
	font-size: 16px;
	line-height: 22px;
	color: #fff;
	margin-top: 10px;
}

.author-img-wrap {
	position: relative;
	width: 100%;
	height: auto;
	margin-bottom: 15px;
}

.author-img-border {
	width: 65px;
	height: 65px;
	background-color: transparent;
	border-radius: 50%;
	position: absolute;
	top: 0;
	left: 50%;
	margin-left: -32.5px;
	border: 3px solid rgb(0,186,255);
    border-top-color: transparent;
    z-index: 1;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

.author-info .author-img {
	width: 65px;
	height: 65px;
    border: 5px solid #515151;
    position: relative;
}

.notification {
	background-color: #515151;
	padding: 10px 0;
}


.notification-list {
	padding-left: 0;
	margin-bottom: 0;
	direction: table;
	margin: auto;
}


.notification-list li {
	display: inline-block;
	padding: 0 20px;
	position: relative;
}

.notification-list li a {
	color: #fff;
}

.notification-num {
	position: absolute;
    top: -120%;
    right: -65%;
    background-color: rgb(0,186,255);
    width: 20px;
    height: 20px;
    text-align: center;
    border-radius: 50%;
    font-size: 12px;
    line-height: 21px;
    font-weight: 300;
    color: #fff;
}

.logged-user-nav .notification-num {
	top: 0;
	right: 0;
	margin-top: -5px;
}


.side-nav-list {
	padding-left: 0;
	color: #8a8a8a;
}

.side-nav-list>li {
	display: block;
	width: 100%;
	margin: 0;
	padding: 0;
	padding: 10px 0;
}

.side-nav-list>li>a {
	position: relative;
	list-style: none;
	padding: 10px 0;
	display: block;
	width: 100%;
	min-width: 270px;
}

.side-nav-list>li.has-dropdown>a::after {
	content: " ";
	background-image: url(../img/symptom/dropdown.png);
	background-repeat: no-repeat;
	background-size: center center;
	position: absolute;
	width: 15px;
	height: 15px;
	top: 50%;
	right: 30px;
	margin-top: -7.5px;
}

.side-nav-list>li:hover, .side-nav-list>li.active,.side-nav-list>li.open {
	background-color: #1f2125;
}

.side-nav-list>li>a:hover:before,
.side-nav-list>li.active>a:before  {
	content: " ";
	position: absolute;
	top: 50%;
	left: 10px;
	margin-top: -2.5px;
	width: 5px;
	height: 5px;
	border-radius: 100%;
	background-color: #00baed;
}


.side-nav-list>li>a {
	color: #9fa4af;
	width: 100%;
	-moz-transition: all linear 0.3s;
	-webkit-transition: all linear 0.3s;
	-ms-transition: all linear 0.3s;
	transition: all linear 0.3s;
}

.side-nav-list>li>a:hover,
.side-nav-list>li.active>a,
.side-nav-list>li.open>a {
	text-decoration: none;
	color: #fff;
}

.side-nav-list>li>a>i {
	margin-right: 10px;
}

.side-nav-img {
	width: 70px;
	display: table-cell;
	vertical-align: middle;
	text-align: center;
	-webkit-transition: all linear 0.3s;
	-moz-transition: all linear 0.3s;
	-o-transition: all linear 0.3s;
	transition: all linear 0.3s;
}

.side-nav-text {
	font-size: 15px;
	font-weight: 500;
	display: table-cell;
	vertical-align: middle;
}



.side-nav-list .open .dropdown-menu {
	opacity: 1;
	position: static;
	float: none;
	width: auto;
	margin-top: 0;
	background-color: transparent;
	border: 0;
	-webkit-box-shadow: none;
	box-shadow: none;
	height: auto;
}

.side-nav-list .open .dropdown-menu>li>a {
  color: #9fa4af;
}

.side-nav-list .open .dropdown-menu>li>a:hover {
	color: #fff;
}


/* Content Nav Rules
==========================
*/

.headerpanel-fixed {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 9999;
	background-color: #fff;
	box-shadow: 1px 0px 5px #222;
}

.headerpanel {
	display: table;
	width: 100%;
}

.logo-panel {
	display: table-cell;
    width: 270px;
    height: 86px;
    background-color: #34373d;
    vertical-align: middle;
    text-align: center;
    -moz-transition: all linear 0.3s;
    -webkit-transition: all linear 0.3s;
    -ms-transition: all linear 0.3s;
    transition: all linear 0.3s;
}

.mobile-logo-panel {
	display: none;
}

body.toggled .logo-panel {
	width: 110px;
}

.logo-panel h2 {
	display: table;
    vertical-align: middle;
    margin-bottom: 0;
    text-align: center;
    width: 100%;
}

.headerbar {
	display: table-cell;
	height: 86px;
}

.headbar-wrap {
	display: table;
	width: 100%;
}

.headerpanel-left, .headerpanel-right {
	display: table-cell;
	height: 86px;
	vertical-align: middle;
}

.headerpanel-left {
	width: 40%;
}

.headerpanel-right {
	width: 60%;
}


/* headerpanel-left rules */

.headerpanel-left-inner {
	display: table;
	width: 100%;
}

.headerbar .toggle-btn-wrap {
	display: table-cell;
	height: 86px;
	vertical-align: middle;
}

.headerbar .toggle-btn-wrap a {
	display: table-cell;
	vertical-align: middle;
	padding: 0 15px;
	color: #cdcdcd;
}

.headerbar .searchpanel {
	display: table-cell;
	width: 100%;
	vertical-align: middle;
}

.headerbar .mobile-searchpanel {
	display: none;
	width: 100%;
	vertical-align: middle;
}

.headerbar .mobile-searchpanel .btn-default {
	margin-bottom: 0;
	padding: 8px 15px;
	background-color: #cdcdcd;
	border: 0;
}

.headerbar .mobile-searchpanel .btn-default:hover {
	border: 0;
}

.headerbar .searchpanel .search-inline {
	display: table-cell;
}

.headerbar .searchpanel .search-down-arrow .btn-default {
	background-color: #eaeaea;
	width: 75px;
}

.headerbar .searchpanel .search-down-arrow .btn-default i {
	color: #7f7f7f;
}

.headerbar .searchpanel .btn-default {
	padding: 0;
	height: 40px;
	width: 48px;
	background-color: #cdcdcd;
	margin-bottom: 0;
	border: 0;
}

.headerbar .searchpanel .form-control {
	background-color: #eaeaea;
	border: 0;
	height: 40px;
	box-shadow: none;
	color: #7f7f7f;
}

/*header-panel-left rules end */

/*header-panel-right rules */

.headerpanel-right .author-list {
	display: table;
	margin-left: auto;
	padding-left: 0;
	margin-bottom: 0;
}

.headerpanel-right .author-list>li {
	list-style: none;
	display: table-cell;
	height: 86px;
	vertical-align: middle;
}

.headerpanel-right .author-list li.author-notification {
	width: 60px;
	text-align: center;
}

.headerpanel-right .author-list li>a {
	position: relative;
}

.headerpanel-right .author-profile {
	padding-left: 40px;
}

.headerpanel-right .author-list>li.author-profile-inner {
	display: table;
}

.logged-user-nav .nav>li.author-profile {
	padding-left: 25px;
}

.author-profile-inner > .dropdown-toggle {
	display: block;
}

.author-profile-inner-left,
.author-profile-inner-right{
	display: table-cell;
	vertical-align: middle;
}

.author-profile-inner-left {
	max-width: 100px;
}

.author-profile-inner-left .user-name {
	display: block;
	white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.logged-user-nav .author-profile-inner-left {
	padding-right: 10px;
}

.logged-user-nav .author-profile-inner-left,
.logged-user-nav .author-profile-inner-right {
	height: 30px;
}

.logged-user-nav .author-profile-inner-right a {
	position: relative;
	top: -5px;
	padding-left: 0;
}

.author-profile-inner-left img {
	width: 50px;
	padding: 0;
}

.logged-user-nav .author-profile-inner-left img {
	width: 30px;
	border: 2px solid #fff;
}

.author-profile-inner-right a {
	color: #666666;
	font-size: 15px;
	color: #666666;
	padding-left: 15px;
}

.author-profile-inner-right a:hover,
.author-profile-inner-right a:focus,
.author-profile-inner-right a:active {
	text-decoration: none;
}


.author-profile-inner-right a i {
	position: relative;
	top: 5px;
	padding: 0 20px;
}

.logged-user-nav .author-profile-inner-right a i {
	padding: 0 10px;
}

.author-profile-inner-right>.dropdown-menu {
	right: 0;
	left: auto;
	top: 101%;
}

.logged-user-nav .author-profile-inner-right>.dropdown-menu {
	padding: 10px;
}


/*header-panel-right rules end */


.notification-link {
	color: #cdcdcd;
}

.notification-link:hover {
	color: #cdcdcd;
	text-decoration: none;
}

.notification-link i {
	font-size: 22px;
	line-height: 15px;
}

.logged-user-nav .notification-link i {
	font-size: 18px;
	line-height: 22px;
	color: #777777;
}

.author-nav-img {
    padding: 10px;
    display: block;
    border-radius: 50%;
}

/*input.form-control.nav-search.typehead-search-suggest.tt-hint {
	top: 50% !important;
	margin-top: -16px;
}
*/

/*============ Typehead Plugin Css =================*/
input.nav-search.typehead-search-suggest {
	background: none;
}


.twitter-typeahead {
	display: block !important;
	position: relative !important;
}

.tt-menu {
	width: 100%;
	max-height: 250px;
	overflow-y: auto;
	border-left: 1px solid #00baff;
	border-right: 1px solid #00baff;
	border-bottom: 1px solid #00baff;
	background-color: #fff;
    -webkit-box-shadow: 0 6px 12px rgba(0,0,0,.175);
    box-shadow: 0 6px 12px rgba(0,0,0,.175);
	/*border-left: 1px solid #00baff;
	border-right: 1px solid #00baff;
	border-bottom: 1px solid #00baff;*/
}

.form-control.tt-input.typehead-search-suggest {
	color: #000;
}

.tt-suggestion.tt-selectable {
	padding: 20px 25px;
	cursor: pointer;
	-moz-transition: all linear 0.3s;
	-webkit-transition: all linear 0.3s;
	-ms-transition: all linear 0.3s;
	transition: all linear 0.3s;
}

.tt-suggestion.tt-selectable:hover {
	background-color: #00baff;
	color: #fff;
}

.tt-suggestion.tt-selectable:nth-child(odd){
	background-color: rgba(148, 226, 255, 0.15);
}

.tt-suggestion.tt-selectable:nth-child(odd):hover {
	background-color: #00baff;
}

.tt-suggestion.tt-selectable:last-child {
	border-bottom: 0;
}

.tt-suggestion.tt-selectable.tt-cursor {
	background-color: #00baff;
	color: #fff;
}



/*============ Easy Autocomplet Plugin Css ============ */

.easy-autocomplete input {
	border-radius: 0;
	box-shadow: none;
}

.easy-autocomplete-container ul {
	border-color: #00baff;
}

.easy-autocomplete-container ul li, .easy-autocomplete-container ul .eac-category {
	padding: 20px 12px;
	border-color: #00baff;
	transition: all ease 0.3s;
}

.easy-autocomplete-container ul li:hover,
.easy-autocomplete-container ul li:focus,
.easy-autocomplete-container ul .eac-category {
	background-color: #00baff;
	color: #fff;
}

.easy-autocomplete-container ul li.selected{
	background-color: #00baff;
	color: #fff;
}


@media (max-width: 991px) {

	.headerpanel-left {
		width: 10%;
	}

	.headerpanel-right {
		width: 90%;
	}

	.headerbar .searchpanel {
		display: none;
	}

	.headerbar .mobile-searchpanel {
		display: table-cell;
	}

	.headerpanel-right .author-profile {
		padding-left: 0;
	}
}

@media(max-width: 767px) {
	.logo-panel {
		display: none;
	}

	.mobile-logo-panel {
		display: table;
		width: 100%;
	}

	.mobile-logo-panel h2 {
		display: table-cell;
		vertical-align: middle;
		height: 86px;
	}

	.sidebar-wrapper {
		left: 0;
		width: 100%;
		height: auto;
		margin-left: 0;
		overflow: auto;
		-webkit-transition: initial;
		-moz-transition: initial;
		-ms-transition: initial;
		transition: initial;
	}

	body.toggled .wrapper {
		padding-left: 0;
	}
}

@media(max-width: 500px) {
	.headerbar .toggle-btn-wrap a {
		padding: 0 10px;
	}

	.fa-2x {
		font-size: 1.5em;
	}

	.headerbar .mobile-searchpanel .btn-default {
		padding: 5px 10px;
	}

	.headerpanel-right .author-list li.author-notification {
		width: 40px;
	}

	.headerpanel-right .author-profile {
		padding-left: 5px;
	}

	.author-profile-inner-left img {
		width: 30px;
	}

	.author-profile-inner-right a {
		padding-left: 5px;
	}

	.author-profile-inner-right a i {
		top: 3px;
		padding: 0 5px;
	}
}




/* Page Content sidebar Rules
===============================
*/

aside.content-sidebar {
	padding: 0;
}


@media (max-width: 767px) {
	.sidebar-wrapper {
		display: none;
	}

	.wrapper, .page-content {
		padding-left: 0;
	}
}


/* solutions section Rules
==============================
*/

.solutions {
	padding: 30px 25px;
	margin-left: 5px;
}

.resource {
	background-color: #f0f0f0;
}


.solutions .blue, .resource .blue {
	font-weight: bold;
}

.solutions h2 {
	position: relative;
	font-weight: bold;
	margin-top: 0;
	font-size: 28px;
	font-weight: 400;
	line-height: 30px;
	color: #616161;
	margin-bottom: 20px;
}

.solutions .heading-border-wrap {
	margin-bottom: 20px;
}

.solutions hr {
	margin-top: 5px;
	margin-bottom: 20px;
}

.solutions h2 span, .resource h2 span  {
	display: block;
}

.solution-inner h3 {
	font-size: 18px;
	line-height: 23px;
	color: #616161;
	margin-bottom: 15px;
}


.solution-inner .heading-border-wrap {
	margin-bottom: 20px;
}

.heading-border-down {
	position: relative;
    border-top: 5px solid #616161;
    border-left: 2.5px solid transparent;
    border-right: 2.5px solid transparent;
    height: 0;
    width: 100%;
}


.solutions ul {
	padding-left: 0;
}

.solutions ul li {
	list-style: none;
	display: inline-block;
    font-size: 13px;
    line-height: 25px;
    margin-bottom: 10px;
    width: 100%;
    color: #8d8c8c;
}

.solutions ul li img {
	display: inline-block;
	margin-right: 25px;
}


.proposed-disease {
	margin-bottom: 40px;
}

.doctor-profile {
	margin-top: 20px;
	margin-bottom: 55px;
	padding: 0 15px;
}

.doctor-profile img {
	width: 100%;
}

.doctor-profile h3 {
	margin-bottom: 5px;
}

.doctor-profile h3 a {
	font-weight: 400;
	margin-top: 0;
	font-size: 18px;
	color: #616161;
}

.doctor-profile h3 a:hover {
	text-decoration: none;
}

.doctor-profile .blue {
	font-size: 13px;
	font-weight: 700;
	margin-bottom: 10px;
}

.doctor-profile h5 {
	font-size: 16px;
    line-height: 22px;
    padding-bottom: 5px;
    border-bottom: 1px solid #ccc;
    margin-bottom: 0;
}

.doctor-profile-inner {
	display: table;
	margin-bottom: 30px;
}

.doctor-profile-inner .col-md-4,
.doctor-profile-inner .col-sm-4,
.doctor-profile-inner .col-xs-4 {
	padding: 0;
}

.doctor-profile-inner ul.rating li {
	font-size: 12px;
}

.doctor-profile-inner-left,
.doctor-profile-inner-right {
	display: table-cell;
	vertical-align: top;
}

.doctor-profile-inner-right {
	padding-left: 20px;
}

.doctor-profile-inner:last-child {
	margin-bottom: 0;
}

ul.rating li {
	color: #9a9a9a;
	display: inline;
	border-bottom: 0;
	padding: 0;
	font-size: 16px;
}

ul.rating li.selected {
	color: #d09d03;
}

p.address {
	margin-bottom: 0;
}

.rating {
  unicode-bidi: bidi-override;
  /*direction: rtl;*/
  font-size: 16px;
  text-align: left;
}

.rating span.star {
  font-family: FontAwesome;
  font-weight: normal;
  font-style: normal;
  display: inline-block;
  transition: all linear 0.3s;
}

/*.rating span.star:hover {
  cursor: pointer;
}*/

.rating span.star:before {
  content: "\f005";
  padding-right: 2px;
  color: #9a9a9a;
}
/*.rating span.star:hover:before,*/
/*.rating span.star:hover ~ span.star:before,*/
.rating span.selected:before {
  content: "\f005";
  color: #d09d03;
}


/* Solutions-non Section Rules
================================
*/

.solutions.non-user {
	padding: 45px 0;
	position: relative;
	margin-left: 0;
}

/* Symptom details Section Rules
=====================================
*/

.symptom-details {
	position: relative;
	padding-top: 25px;
	padding-bottom: 25px;
	margin-left: -15px;
	margin-right: -15px;
	z-index: 0;
}

.symptom-details h2 {
	font-weight: 400;
}

.details-inner-content {
	position: relative;
	width: 100%;
}

.details-inner-content img {
	margin-bottom: 30px;
}

.details-kid .details-inner-content img {
	margin-top: 43px;
}

.details-inner-content .btn-default {
	width: 100%;
	margin-bottom: 0;
}

.details-inner-content .form-group {
	display: table;
	width: 100%;
	margin-bottom: 0;
	margin-left: -3px;
}

.details-form-right .form-group {
	margin-bottom: 0;
}

.details-form-right .form-group:first-child {
	margin-bottom: 10px;
}

.details-form-left .btn {
	display: table-cell;
	vertical-align: middle;
	height: 40px;
	padding: 0;
	width: 100%;
	border: 1px solid #fff;
	font-size: 13px;
}

.human-group .col-md-4 {
	padding: 0 5px;
}

.details-form {
	display: block;
	height: auto;
	margin-top: 55px;
	padding-right: 20px;
}

.details-form-select {
	position: relative;
	display: table;
	margin: auto;
	z-index: 1;
}

.details-select {
	background-color: #f8f8f8;
	border-color: #f8f8f8;
	color: #616161;
	text-align: left;
}

.details-form .btn-default {
	width: 100%;
	margin-bottom: 0;
	font-size: 13px;
}



.details-form-right .btn-default {
	font-size: 16px;
	line-height: 20px;
}

.details-form-right .btn-default, .details-form .btn-default:hover {
	margin-bottom: 0;
	height: 50px;
	padding: 10px;
	background-color: #00baed;
	color: #fff;
	border-color: #00baed;
}

.details-inner-content input[type="radio"] {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	cursor: pointer;
	border: 0;
	padding: 0;
	opacity: 0;
	z-index: 9;
	text-indent: 0.01px;
    text-overflow: '';
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}

/*input[type="radio"]:checked + label.btn-checked,
input[type="radio"]:hover + label.btn-checked {
	width: 100%;
    text-align: center;
    background-color: #009d00;
    margin-bottom: 0;
    padding: 10px;
}*/

/*lable.btn-*/


select.details-select {
	-webkit-appearance: none;
	-moz-appearance: none;
	-o-appearance: none;
	text-indent: 0.01px;
    text-overflow: '';
}

.form-down-angle {
	position: relative;
	float: right;
	margin-top: -40px;
	margin-right: 15px;
	pointer-events: none;
	background-color: transparent;
	padding-right: 5px;
	color: #b8b8b8;
	font-size: 25px;
	line-height: 30px;
}


/* New details-form rules */

.details-form-wrap {
	display: table;
	width: 100%;
}


.details-form-left,
.details-form-right {
	display: table-cell;
	vertical-align: bottom;
	width: 100%;
}


.details-form-right-inner {
	display: table;
	width: 220px;
	margin-left: auto;
}

.details-form-inner-wrap {
	display: block;
	width: 100%;
}


.details-inner {
	display: inline-flex;
	width: 140px;
	padding-top: 30px;
	box-sizing: border-box;
}


/*.details-inner:hover,
.details-inner:active,
.details-inner:focus {
	border: 1px solid #fff;
}*/

.details-inner:hover .btn-default {
	border-color: transparent;
	background: transparent;
}

@media(max-width: 767px) {
	.solutions {
		padding-left: 0;
		padding-right: 0;
	}

	.details-form-left, .details-form-right {
		display: table;
	}

	.details-form-inner-wrap {
		margin: auto;
		margin-bottom: 30px;
	}


	.details-form-right-inner {
		width: 100%;
	}
}

/* Symptom-details-non section Rules
=====================================
*/

.symptom-details-non {
	position: relative;
	padding: 40px 0;
}

.symptom-details-non .details-inner {
	width: 190px;
	margin: 0 10px;
	background-color: #e2e2e2;
	transition: all ease 0.3s;
}

.symptom-details-non .details-inner:hover,
.symptom-details-non .human-checked {
	background-color: #00baff;
}

.symptom-details-non .details-inner:hover .btn-checked,
.symptom-details-non .details-inner.human-checked .btn-checked {
	color: #fff;
}

.symptom-details-non .details-inner.details-male:hover img,
.symptom-details-non .human-checked.details-male img {
	content: url("../img/symptom/male.png");
}

.symptom-details-non .details-inner.details-female:hover img,
.symptom-details-non .human-checked.details-female img {
	content: url("../img/symptom/female.png");
}

.symptom-details-non .details-inner.details-kid:hover img,
.symptom-details-non .human-checked.details-kid img {
	content: url("../img/symptom/kid.png");
}

.symptom-details-non .details-form-inner-wrap {
	border-spacing: 0;
}

.symptom-details-non .details-form-inner-wrap .btn-checked {
	color: #898989;
}

.symptom-details-non h2 {
	color: #6c6c6c;
	font-size: 28px;
	line-height: 30px;
	font-weight: 400;
}

.symptom-details-non .details-form-inner-wrap {
	display: flex;
	margin: auto;
	margin-bottom: 50px;
}

.symptom-details-non .details-form-left,
.symptom-details-non .details-form-right {
	display: table;
	margin: auto;
}

.symptom-details-non .details-form-left {
	margin-bottom: 105px;
}

.symptom-details-non .details-form-left .btn {
	height: 55px;
	border-color: #ccc;
	width:
}

.symptom-details-non .btn-center {
	width: 190px;
	margin-top: 25px;
}

.symptom-details-non .details-form {
	margin-top: 0;
	padding-right: 0;
}

.symptom-details-non .form-control {
	display: block;
	min-width: 225px;
	height: 50px;
	border: 1px solid #cccccc;
}

.symptom-details-non .form-group {
	position: relative;
	padding: 0 10px;
	display: inline-block;
	margin: auto;
}

.symptom-details-non .form-group .btn-default {
	position: relative;
	top: -2px;
	margin-bottom: 0;
	height: 50px;
	min-width: 225px;
	border: 1px solid #cccccc;
	padding: 0;
	color: #616161;
	font-size: 14px;
	line-height: 20px;
}

.bn-version .symptom-details-non .form-group .btn-default {
	font-size: 18px;
	line-height: 20px;
}

.bn-version .symptom-details-non .form-group a.btn-default {
	padding: 15px;
}

.symptom-details-non .form-group .btn-default:hover {
	border-color: #00baff;
	color: #fff;
}

.details-form-select .form-group {
	display: inline-block;
}

.symptom-details-non .details-form-right {
	display: table;
	margin: auto;
}

.symptom-details-non .details-form-right .form-group {
	display: table-cell;
}

.symptom-details-non .details-form-right-inner {
	display: block;
	margin: auto;
	width: auto;
}

/*.details-male {
	background-color: #02b7f0;
}

.details-female {
	background-color: #024c7d;
}

.details-kid {
	background-color: #7bbc2a;
}*/

@media ( max-width: 992px ) {
	.details-form-select .form-group {
		display: block;
		margin-bottom: 20px;
	}
}

@media (max-width: 767px) {
	.symptom-details-non .details-form-right .form-group {
		display: table;
	}
}

@media (max-width: 460px) {
	.symptom-details-non .details-form-inner-wrap {
		display: block;
	}

	.symptom-details-non .details-inner,
	.details-inner {
		display: block;
		margin-bottom: 20px;
	}
}

/* Symptom Section Rules
===================================
*/

.symptom {
	position: relative;
	padding-top: 30px;
	padding-bottom: 30px;
	background-color: #fff;
	z-index: 1;
}

.symptom > .container > h2 {
	margin-top: 0;
	margin-bottom: 20px;
}

.hidden {
	display: none;
}

.man {
	background-color: #f0f0f0;
	padding: 20px;
	padding-bottom: 0;
	border: 1px solid #ccc;
}


.controller {
	margin-top: 30px;
}

.btn-controll i {
	margin-right: 5px;
}

.btn-controll-wrap {
	display: table;
	margin: auto;
}

.symptom-wrap header,
.symptom-form header {
	border-bottom: 1px solid #d7d7d7;
}

.symptom-wrap h3, .symptom-form h3 {
	position: relative;
	background-color: #e6e6e6;
	margin: 0;
    padding: 20px;
}

.symptom-list {
	padding-left: 0;
	margin-bottom: 0;
}

.symptom-list li {
	position: relative;
	list-style: none;
	border-bottom: 1px solid #e6e6e6;
}

.symptom-list li a {
	display: block;
	padding: 15px 20px;
	border-left: 8px solid #fff;
	color: #616161;
	-moz-transition: all linear 0.3s;
	-webkit-transition: all linear 0.3s;
	-ms-transition: all linear 0.3s;
	-o-transition: all linear 0.3s;
	transition: all linear 0.3s;
}

.symptom-list li a:before {
	content: '\f105';
	position: absolute;
	top: 0;
	right: 25px;
	display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: 25px;
    line-height: 50px;
    font-weight: 700;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #c0c0c0;
}

.symptom-list li:hover a, .symptom-list li.active a {
	border-left: 8px solid #00baff;
	background-color: #f0f0f0;
	color: #606060;
	border-right: 0;
	font-weight: bold;
	text-decoration: none;
}

.symptom-list li:hover a:before, .symptom-list li.active a:before {
	color: #606060;
}

.search-input {
	border-top: 1px solid #bcbcbc;
	border-bottom: 1px solid #bcbcbc;
	border-left: 1px solid #bcbcbc;
	height: 57px;
    padding-right: 70px;
    -moz-transition: all linear 0.3s;
    -webkit-transition: all linear 0.3s;
    -ms-transition: all linear 0.3s;
    transition: all linear 0.3s;
}

ul div.clearfix {
	margin-bottom: 0;
}


.submit-input {
	position: absolute;
    top: 0;
    right: 0;
    width: 20%;
    height: 57px;
}

input.submit-input[type="submit"] {
    font-family: FontAwesome;
    background-color: rgb(0,186,255);
    border: 1px solid rgb(0,186,255);
    color: #fff;
}

.symptom-form {
	border: 1px solid #ccc;
}

.symptom-form .checkbox, .symptom-form .radio {
	padding: 15px 20px;
	margin: 0;
	border-bottom: 1px solid #e6e6e6;
}

.checkbox-list {
	padding-left: 0;
	margin-bottom: 0;
}

.symptom-form .checkbox-list li {
	cursor: pointer;
}

.symptom-form .checkbox-list li label {
	position: relative;
}

.symptom-form .checkbox-list-table>tbody>tr>td .checkbox:after {
	content: " ";
	height: 0;
	position: absolute;
	cursor: pointer;
	-moz-transition: all ease 0.3s;
	-webkit-transition: all ease 0.3s;
	-ms-transition: all ease 0.3s;
	transition: all ease 0.3s;
}

.symptom-form .checkbox-list-table>tbody>tr>td:hover .checkbox:after {
	content: "\f00d";
	display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
	top: -6px;
	left: 0;
	font-size: 20px;
	line-height: 30px;
	width: 25px;
	height: 29px;
	background-color: #fff;
	text-align: center;
	color: red;
	-moz-transition: all ease 0.3s;
	-webkit-transition: all ease 0.3s;
	-ms-transition: all ease 0.3s;
	transition: all ease 0.3s;
}

.symptom-form .checkbox-list .checkbox input[type=checkbox] {
	pointer-events: none;
}

.symptom-form .checkbox-list .checkbox .checkbox-text,
.symptom-form .checkbox-list li.checkbox label {
	pointer-events: none;
}

.checkbox input[type=checkbox],
.checkbox-inline input[type=checkbox],
.radio input[type=radio],
.radio-inline input[type=radio] {
	position: absolute;
	margin-left: 0;
	opacity: 0;
}

input[type=checkbox], input[type=radio] {
	height: 25px;
	width: 25px;
	outline: 0;
	opacity: 0;
	z-index: 10;
	cursor: pointer;
}

input[type=radio] + span.radio-btn {
	position: absolute;
	top: 0;
	left: 0;
	margin: 0;
	width: 20px;
	height: 20px;
	border: 1px solid #00baff;
	border-radius: 50%;
	cursor: pointer;
}

input[type=radio]:checked + span.radio-btn::after {
	content: " ";
	position: absolute;
	top: 50%;
	left: 50%;
	background-color: #00baff;
	width: 14px;
	height: 14px;
	margin-top: -7px;
	margin-left: -7px;
	border-radius: 50%;
}

input[type="checkbox"] + span.checkbox-btn {
	position: absolute;
	top: 0;
	left: 0;
	margin: 0;
	margin-top: -2px;
	width: 25px;
	height: 25px;
	border: 1px solid #00baff;
	cursor: pointer;
}

input[type="checkbox"]:checked + span.checkbox-btn::after {
	content: "\f00c";
	display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
	position: absolute;
	color: #00baff;
	font-size: 16px;
	line-height: 25px;
	left: 50%;
	margin-left: -8px;
}


.checkbox-text {
	margin-left: 15px;
}

.done-input {
	height: 57px;
	background-color: rgb(0, 186,255);
	border-color: rgb(0, 186,255);
	color: #fff;
	font-weight: bold;
	font-size: 20px;
}

input[type=checkbox] {
	position: relative;
	cursor: pointer;
	font-size: 18px;
	text-indent: 0.01px;
	text-overflow: '';
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	/*width: 100%;*/
}


input[type=checkbox]::after {
    content: " ";
    background-color: #fff;
    display: inline-block;
    visibility: visible;
    width: 25px;
    height: 25px;
    border: 1px solid #ccc;
    border-radius: 3px;
}

input[type=checkbox]:-moz-placeholder::after {
	content: " ";
    background-color: #fff;
    display: inline-block;
    visibility: visible;
    width: 25px;
    height: 25px;
    border: 1px solid #ccc;
    border-radius: 3px;
}

input[type=checkbox]:checked::after {
	position: absolute;
	font-family: "FontAwesome";
    content: "\f00c";
    background-color: #2d9f01;
    color: #fff;
    font-size: 14px;
    width: 25px;
    height: 25px;
    padding: 4px;
    top: 0;
    left: 0;
}


.bg-checked {
	background-color: #f0f0f0;
}

.human-inline-btn {
	margin-left: -5px;
	margin-right: -5px;
}

.human-btn-list {
	position: absolute;
	top: 0;
	left: 0;
	height: 415px;

	display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;

	-webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column;

	-webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
}

.human-btn-list li {
	list-style: none;
	margin: 2.5px 0;
}

.human-btn-list li a.btn-grey {
	margin-bottom: 0;
	width: 80px;
	height: 70px;
	line-height: 10px;
	padding: 15px 0;
	background-color: #868686;
	color: #fff;
}

.bn-version .human-btn-list li a.btn-grey {
	padding: 5px 0;
}

.human-btn-list li a.btn-grey:hover {
	background-color: #00baff;
}

.human-btn-list li a.btn-grey span.human-btn-inner {
	display: flex;
	justify-content: center;
}


.symptom-wrap .search .col-md-10,
.symptom-wrap .search .col-md-2 {
	padding: 0;
}

.symptom-wrap .search .btn-blue {
	height: 57px;
	margin-bottom: 0;
}

/*
.human-btn-list li a.btn-grey:focus {
	background-color: #fff;
	color: #7b7b7b;
}*/

.checkbox-list-table > tbody>tr {
	border-top: 1px solid #d7d7d7;
	border-bottom: 1px solid #d7d7d7;
}

.checkbox-list-table > tbody>tr>td {
	text-align: left;
	padding: 15px;
	background-color: #fff;
	/*border-bottom: 1px solid #d7d7d7;*/
}

.checkbox-list-table > tbody > tr > td .checkbox input[type=checkbox] {
	margin-left: 0;
}

.checkbox-list-table > tbody>tr>td .checkbox {
	border: 0;
	padding: 0;
}

.checkbox-list-table > tbody>tr>td .checkbox label {
	display: block;
}

.checkbox-list-table > tbody>tr>td span {
	border: 0;
	border-radius: 0;
}

.symptom-icon-wrap {
	display: none;
    position: absolute;
    top: 50%;
    left: 15px;
    height: 50px;
    width: 45px;
    background-color: #00baff;
    margin-top: -25px;
    cursor: pointer;
}

.symptom-icon-wrap > img {
	position: relative;
    top: 50%;
    margin-top: -33.5px;
}


@media (max-width: 767px) {
	.symptom-icon-wrap {
		display: block;
	}

	.symptom-wrap h3,
	.symptom-form h3 {
		padding-left: 65px;
		padding-right: 40px;
	}

	.symptom-wrap .search .btn-blue{
		width: 100%;
	}

	/*.human-btn-list {
		height: 535px;
	}
	.human-btn-list {
		width: 100%;
		bottom: 0;
		height: auto;
		top: auto;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		    -ms-flex-direction: row;
		        flex-direction: row;
        -webkit-box-pack: center;
		-webkit-justify-content: center;
		    -ms-flex-pack: center;
		        justify-content: center;
	}

	.human-btn-list li {
		-webkit-transition: all linear 0.3s;
    -moz-transition: all linear 0.3s;
    transition: all linear 0.3s;
		-webkit-box-flex: 1;
		-webkit-flex: 1 1 calc(25% - 2.5px);
		    -ms-flex: 1 1 calc(25% - 2.5px);
		        flex: 1 1 calc(25% - 2.5px);
		        margin: 0 2.5px;
		        background-color: #868686;
    			color: #fff;
	}

	.human-btn-list li:hover {
		background-color: #00baff;
	}

	.bn-version .human-btn-list li a.btn-grey:hover {
		background-color: transparent;
	}

	.bn-version .human-btn-list li a.btn-grey {
		padding: 10px;
		border: 0;
		background-color: transparent;
	}

	.human-btn-list li a.btn-grey {
		padding: 0;
		display: block;
		width: auto;
		height: auto;
	}*/
}



/* Select-symptom page rules
====================================
*/

.symptom-select {
	position: relative;
	padding: 45px 0;
}

.symptom-select h2 {
	font-size: 28px;
	line-height: 30px;
	font-weight: 700;
	color: #6c6c6c;
}

.symptom-select-inner {
	display: table;
	padding: 25px 100px;
	padding-bottom: 0;
	border: 1px solid #e9e9e9;
	margin: auto;
	background-color: #f0f0f0;
}

.symptom-select-inner img {
	margin-bottom: 45px;
}

.symptom-select input[type="radio"],
.man input[type="radio"] {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	cursor: pointer;
	border: 0;
	padding: 0;
	opacity: 0;
	z-index: 100;
	text-indent: 0.01px;
    text-overflow: '';
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}

input[type="radio"]:checked + label.btn-grey,
input[type="radio"]:hover + label.btn-grey {
	color: #fff;
	background-color: #00baed;
	border-color: #00baed;
}


label.btn-grey {
	display: inline-block;
	width: 100%;
	height: 50px;
	padding: 15px;
	margin-bottom: 0;
	text-align: center;
}


.man label.btn-grey {
	height: auto;
	padding: 10px 5px;
}

label.btn-grey i {
	margin-right: 5px;
}

.symptom-select-inner .inline-form,
.man .inline-form {
	display: table;
	width: 100%;
	border-spacing: 10px 0;
}

.man .inline-form {
	margin-top: 30px;
	border-spacing: 5px 0;
}

.symptom-select-inner .form-group,
.man .form-group {
	position: relative;
	display: table-cell;
	margin: 0 10px;
}

#mapster_wrap_0,
#mapster_wrap_1 {
	margin-left: auto;
}

.col-md-12 #mapster_wrap_0,
.col-md-12 #mapster_wrap_1 {
	margin: auto;
}

.manBody-inner-single.back,
.manBody-inner-single.skin,
.manBody-inner-single.blood,
.manBody-inner-single.bones {
	display: none;
}


@media (max-width: 767px) {
	/*.col-md-12 #mapster_wrap_0,
	.col-md-12 #mapster_wrap_1 {
		margin-left: auto;
		margin-right: initial;
	}*/

	.col-md-12 #mapster_wrap_0,
	.col-md-12 #mapster_wrap_1,
	#mapster_wrap_0,
	#mapster_wrap_1 {
		margin: auto;
	}

}

/* Resource Section Rules
====================================
*/

.resource {
	padding: 40px 0;
}

.resource .container-fluid {
	padding: 0;
}

.resource h2 {
	font-size: 28px;
	line-height: 30px;
	color: #666666;
	margin-bottom: 25px;
	font-weight: 400;
}

.resource-inner {
	border: 1px solid #e3e3e3;
}


.resource-inner .nav-tabs {
	border-bottom: 0;
	width: 100%;
}

.resource-inner .nav>li {
	width: 25%;
}

.resource-inner .nav>li>a {
	padding: 30px 5px;
	color: #616161;
	background-color: #f0f0f0;
	margin: 0;
	border: 0;
	border-radius: 0;
	transition: all linear 0.3s ;
	text-align: center;
}

.resource-inner .nav>li>a:focus,
.resource-inner .nav>li>a:hover,
.resource-inner .nav>li.active>a,
.resource-inner .nav>li.active>a:focus,
.resource-inner .nav>li.active>a:hover {
	border: 0;
	color: #8d8c8c;
	background-color: #fff;
}

.resource-inner .nav-tabs>li>a:hover {
	border: 0;
}

.resource-inner .tab-inner {
	margin-bottom: 45px;
}

.resource-inner .tab-inner h3 {
	font-size: 20px;
	line-height: 25px;
	color: #6f6f6f;
}

.resource-inner .tab-content {
	padding: 35px 25px;
	background-color: #fff;
}

.resource-inner .video-content {
	width: 100%;
	height: 100%;
	position: relative;
	margin-bottom: 15px;
}

.resource-inner .video-content img {
	width: 100%;
}

.resource-inner .video-content::after {
	content: " ";
	background-image: url(../img/symptom/play-btn.png);
	background-repeat: no-repeat;
	background-position: top left;
	width: 31px;
	height: 31px;
	position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -15.5px;
    margin-top: -15.5px;
    cursor: pointer;
}

.resource-inner p.video-meta {
	position: absolute;
	bottom: 5px;
	left: 5px;
	margin-bottom: 0;
	color: #fff;
	line-height: 20px;
}

.symptom-footer-bottom {
	background-color: #00baed;
	color: #fff;
}

.symptom-footer-bottom p {
	margin-bottom: 0;
	color: #fff;
	padding: 30px 10px;
}

/* Resource non-user section Rules
=====================================
*/

.resource.non-user {
	padding: 45px 0;
}

.resource.non-user .resource-inner .video-content img {
	width: 100%;
}

/* Symptom Details Form Page Rules
=====================================
*/

/* Age Selection Page */

.symptom-details-form {
	margin: 30px 0;
}

.symptom-details-form .symptom-checker {
	padding-left: 50px;
	padding-right: 50px;
}


.symptom-checker p.first-text, .symptom-checker p.last-text {
	background-color: #eee;
	font-weight: bold;
	margin-bottom: 0;
	line-height: 47px;
	text-align: center;
}

.symptom-checker .select2-container::after {
	content: "\f107";
    position: absolute;
    top: 0;
    right: 0;
    color: #ccc;
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 25px;
    font-weight: bold;
    right: 30px;
    line-height: 48px;
	pointer-events: none;
}

.symptom-checker .select2-container--open.select2-container::after {
    content: "\f106";
	color: #00baff;
}

.symptom-checker .select2-container--default .select2-selection--single .select2-selection__rendered {
	line-height: 46px;
}

/* Gender Selection Page */
.details-form-inner-wrap {

	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;

	-webkit-box-pack: center;
	-webkit-justify-content: center;
		-ms-flex-pack: center;
			justify-content: center;
}

.details-inner-single {
	width: 50%;
	min-width: 190px;
	max-width: 190px;
	height: 275px;
	margin: 5px;
	/*background-color: #e2e2e2;*/
}

.details-inner-single input[type="radio"] {
	display: none;
	width: 100%;
	height: 275px;
}

.details-inner-single label {
	background-color: #e2e2e2;
	color: #777;
	text-align: center;
	width: 100%;
	height: 275px;
	cursor: pointer;

	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;

	-webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column;

	-webkit-box-pack: end;
    -webkit-justify-content: flex-end;
        -ms-flex-pack: end;
            justify-content: flex-end;

	-webkit-transition: all ease 0.3s;
	transition: all ease 0.3s;
}

.details-inner-single.male label {
	background-image: url("../img/symptom/male-grey.png");
	background-position: 50% 30%;
	background-repeat: no-repeat;
}

.details-inner-single.female label {
	background-image: url("../img/symptom/female-grey.png");
	background-position: 50% 30%;
	background-repeat: no-repeat;
}

.details-inner-single label span {
	padding-bottom: 15px;
	color: #777;
}

.details-inner-single input[type="radio"]:checked + label,
.details-inner-single label:hover,
.details-inner-single label:focus {
	background-color: #00baff;
}

.details-inner-single.male input[type="radio"]:checked + label,
.details-inner-single.male label:hover,
.details-inner-single.male label:focus {
	background-image: url("../img/symptom/male.png");
}

.details-inner-single.female input[type="radio"]:checked + label,
.details-inner-single.female label:hover,
.details-inner-single.female label:focus {
	background-image: url("../img/symptom/female.png");
}

.details-inner-single label:hover span,
.details-inner-single label:focus span,
.details-inner-single input[type="radio"]:checked + label span {
	color: #fff;
}

/* Body Part Selection Page Rules */
h2.modal-title {
	font-weight: bold;
	margin-bottom: 0;
}

.modal-body h3 {
	margin-bottom: 15px;
}

/* Symptom Checker Page Rules */
.symptom-details-human-form {
	margin: 20px 0;
}

.symptom-checker-block {
	padding: 20px 0;
	width : 100%;
	display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;

	-webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;

	-webkit-transition: all ease 0.3s;
	transition: all ease 0.3s;
}

.symptom-checker-single {
	display: block;
	position: relative;
	min-width: 33.33%;
	max-width: 33.33%;
	margin: 0 5px;

	-webkit-box-flex: 1;
    -webkit-flex: 1;
        -ms-flex: 1;
            flex: 1;

	-webkit-transition: all ease 0.3s;
	transition: all ease 0.3s;
}

.symptom-checker-single .close-btn {
	display: none;
}

.human-img-block-inner {
	position: relative;
	background-color: #e6e6e6;
	border: 1px solid #EEEEEE;
	padding: 20.5px 0;
	margin: auto;
}


.only-block .human-img-block-inner {
	min-width: 370px;
	max-width: 500px;
}

.only-block .body-img-mapper {
	margin-right: auto;
}

.only-block #mapster_wrap_0,
.only-block #mapster_wrap_1,
.only-block #mapster_wrap_2,
.only-block #mapster_wrap_3,
.only-block #mapster_wrap_4 {
	margin: auto;
}

.symptom-checker-single header {
	position: relative;
	background-color: #e6e6e6;
	padding: 20px;
	border-bottom: 2px solid #D7D7D7;
}

.close-btn {
	position: absolute;
	top: 10px;
	right: 10px;
	color: #777;
	cursor: pointer;
}

.symptom-checker-single header h3 {
	font-size: 25px;
	line-height: 27px;
	font-weight: bold;
	color: #777;
}

.list-body {
	border-left: 1px solid #d7d7d7;
	border-right: 1px solid #d7d7d7;
	border-bottom: 1px solid #d7d7d7;
	max-height: 300px;
	min-height: 300px;
	overflow-y: auto;
}

.symptom-lists li {
	list-style: none;
	position: relative;
    border: 0;
    display: block;
	white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 30px;
    padding-right: 40px;
    border-radius: 0;
	cursor: pointer;
    border-left: 5px solid transparent;
    -webkit-transition: all ease 0.1s;
    transition: all ease 0.1s;
}


.symptom-lists li:after {
	content: '\f105';
	position: absolute;
	top: 0;
	right: 25px;
	display: inline-block;
	font: normal normal normal 14px/1 FontAwesome;
	font-size: 25px;
	line-height: 51px;
	font-weight: 700;
	text-rendering: auto;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	color: #c0c0c0;
	-webkit-transition: all linear 0.3s;
	-moz-transition: all linear 0.3s;
	-ms-transition: all linear 0.3s;
	transition: all linear 0.3s;
}

.symptom-lists li:hover,
.symptom-lists li:focus {
	font-weight: 700;
    border-left: 5px solid #00baff;
    background-color: #efefef;
}

.symptom-lists li:hover:after,
.symptom-lists li:focus:after {
	color: #616161;
}

.selected-symptom-list li {
	position: relative;
	padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 55px;
    padding-right: 30px;
	cursor: pointer;
	border-bottom: 1px solid #d7d7d7;
}

/*.selected-symptom-list li:last-child {
	border-bottom: 0;
}*/

.selected-symptom-list li:before {
	content: "\f00c";
	display: inline-block;
	font: normal normal normal 14px/1 FontAwesome;
	font-size: inherit;
	text-rendering: auto;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: center;
	line-height: 25px;
	color: #00baff;
	position: absolute;
	top: 10.5px;
	left: 20px;
	width: 25px;
	height: 25px;
	border: 1px solid #00baff;
}

.selected-symptom-list li:hover:before {
	content: "\f00d";
	color: #d9534f;
	border-color: #d9534f;
}

.list-footer .form-control {
	border-top: 0;
	border-color: #d7d7d7;
}

.list-footer .btn {
	margin-bottom: 0;
	padding-top: 7.5px;
	padding-bottom: 7.5px;
}

#questions-modal .modal-header {
	border-bottom: 0;
}

#questions-modal .modal-header h3 {
	max-width: 330px;
	margin-top: 20px;
	margin-left: auto;
	margin-right: auto;
}

#questions-modal .modal-footer {
	border-top: 0;
	margin-bottom: 20px;
}

.modal-header h3 {
	color: #777;
	font-weight: bold;
}

#questions-modal .modal-header,
#questions-modal .modal-footer {
	text-align: center;
}

.question {

	display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;

    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
}

.modal-content .btn {
	font-size: 16px;
	line-height: 20px;
}

.modal-content .btn-grey {
	background-color: #ccc;
	border-color: #ccc;
	color: #fff;
	font-weight: bold;
}

.modal-content .btn-grey:hover,
.modal-content .btn-grey:focus {
	background-color: #009d00;
	border-color: #009d00;
}

.modal-content .btn-danger {
	border-color: #d9534f;
}

.modal-content .btn-danger:hover,
.modal-content .btn-danger:focus {
	background-color: #ac2925;
	border-color: #ac2925;
}

.question .btn {
	padding: 10px 15px;
}

.symptom-home-btn {
	display: none;
	position: absolute;
    top: 50%;
    left: 5px;
    width: 20px;
    background-color: #00baff;
    margin-top: -17px;
	cursor: pointer;
}

.symptom-home-btn > img {
	padding: 5px;
}

@media ( max-width: 991px ) {
	.modal-content .question .btn {
		margin: 0;
	}

	.symptom-details-human-form > .container {
		padding: 0;
	}

	.symptom-checker-single .close-btn {
		display: block;
	}

	.only-block .human-img-block-inner {
		display: -webkit-box;
	    display: -webkit-flex;
	    display: -ms-flexbox;
		-webkit-justify-content: space-around;
        -ms-flex-pack: distribute;
            justify-content: space-around;
	}

	.human-inline-btn {
		width: 25%;

		display: -webkit-box;
	    display: -webkit-flex;
	    display: -ms-flexbox;
	    display: flex;
	}

	.symptom-checker-block {
		position: relative;
	}

	.symptom-checker-single {
		min-width: auto;
		max-width: 100%;
		width: 100%;
	}

	.symptom-details-form .symptom-checker {
		padding: 0;
		margin-left: -15px;
		margin-right: -15px;
	}

	.symptom-checker-single.symptom-list-block,
	.symptom-checker-single.symptom-form-block {
		position: absolute;
		top: 20px;
		left: 0;
		z-index: 1;
		background-color: #fff;
		width: 100%;
		margin: 0;
		padding: 0 5px;
	}

	.close-btn {
		top: 5px;
		right: 5px;
	}

	.symptom-checker-single header h3 {
		margin-left: 15px;
	}

	.symptom-home-btn {
		display: block;
	}

	.human-btn-list {
		background-color: #e6e6e6;
		width: 100%;
		height: auto;
		top: 100%;

		-webkit-box-pack: center;
	    -webkit-justify-content: center;
	        -ms-flex-pack: center;
	            justify-content: center;

		-webkit-box-orient: horizontal;
	    -webkit-box-direction: normal;
	    -webkit-flex-direction: row;
	        -ms-flex-direction: row;
	            flex-direction: row;
	}

	.human-btn-list li {
		margin: 0;
	}

	.symptom-details-human-form {
		margin-bottom: 60px;
	}

	.symptom-details-form {
		margin-bottom: 100px;
	}

	.human-btn-list li a.btn-grey {
		display: flex;
		flex-direction: column;
		justify-content: center;
		width: 75px;
		font-size: 16px;
		line-height: 18px;
	}

	.human-img-block-inner {
		background-color: #fff;
		border: 0;
	}

	#mapster_wrap_0, #mapster_wrap_1 {
		margin: auto;
	}
}

@media (min-width: 768px) {
	.select-age {
		max-width: 500px;
		min-width: 500px;
		margin: auto;

		display: -webkit-box;
	    display: -webkit-flex;
	    display: -ms-flexbox;
	    display: flex;
	}
	.symptom-checker p.first-text, .symptom-checker p.last-text {
		width: 50%;
	}

}

@media (max-width: 767px) {
	.symptom-checker h2 {
		font-size: 30px;
		line-height: 33px;
	}

	.symptom-checker-single header h3 {
		font-size: 20px;
		line-height: 22px;
	}

	.details-inner-single {
		min-width: 140px;
	}
}

/* Knowledge Base Page Rules
====================================================================
*/

/* General Rules Knowledge Base page
======================================
*/

.user-logged-in .page-content {
	position: relative;
	background-color: #f7f7f7;
}

.user-content {
	padding: 25px 0;
}

.user-content .heading-border-wrap,
.diet-plan .heading-border-wrap,
.diet-plan-details .heading-border-wrap,
.single-food-details .heading-border-wrap {
	margin: 20px 0;
}

.canvas-wrap {
	max-width: 250px;
	/*padding: 25px;*/
}

.user-content .heading-border {
	width: 60px;
}

.bold-white-text {
	font-weight: 700;
	color: #fff;
}

.light-text {
	font-weight: 100;
}

.breadcrumb {
	background-color: transparent;
	border-radius: 0;
}

.breadcrumb li {
	font-size: 16px;
}

.breadcrumb li a:hover {
	text-decoration: underline;
}
/*
.breadcrumb li a,
.breadcrumb>.active {
	color: #fff;
}*/

.category .dropdown-menu>.active>a,
.category .dropdown-menu>.active>a:focus,
.category .dropdown-menu>.active>a:hover {
	background-color: transparent;
	color: #00baed;
}

.quality-quote {
	position: relative;
    margin-bottom: 25px;
}

.quality-quote-media {
	position: relative;
	background-color: #fff;
    border: 1px solid #ddd;
    padding: 25px;
}

.quality-quote-media .media-heading {
	margin-bottom: 0;
}

.quality-quote-media .img-wrap {
	min-width: 50px;
}

.quality-quote-media .media-left {
	padding-right: 25px;
}

@media ( max-width: 767px ) {
	.canvas-wrap {
		margin: auto;
	}
}


/* Category section rules
===========================
*/

.category {
	position: relative;
	padding-top: 50px;
	padding-bottom: 50px;
}

.category .search-form-right .col-md-10,
.category .search-form-right .col-xs-10,
.category .search-form-right .col-xs-2,
.category .search-form-right .col-md-2 {
	padding: 0;
}

.category .form-control {
	height: 60px;
	border: 2px solid #fff;
	color: #fff;
}

.category .form-control.tt-input.typehead-search-suggest {
	color: #fff;
}

.category ::-webkit-input-placeholder {
   color: #fff;
}

.category :-moz-placeholder { /* Firefox 18- */
   color: #fff;
}

.category ::-moz-placeholder {  /* Firefox 19+ */
   color: #fff;
}

.category :-ms-input-placeholder {
   color: #fff;
}


.category.has-doc-list {
	padding-top: 30px;
	padding-bottom: 0;
}

.category.has-category-block {
	padding-bottom: 0;
}

.user-logged-in .category {
	padding-top: 0;
	padding-bottom: 0;
}

.user-logged-in .category h1 {
	margin-top: 25px;
	margin-bottom: 35px;
}



.category.single-category {
	padding-top: 25px;
	padding-bottom: 50px;
}


.has-cateogry-nav .category {
	margin-bottom: 115px;
}

.category .cat-heading {
	margin-top: 0;
	color: #fff;
	font-size: 35px;
	line-height: 45px;
}

.category .breadcrumb {
	padding-left: 0;
	display: table;
	margin-bottom: 0;
	color: #777;
}

.category .breadcrumb.breadcrumb-left {
	margin-right: auto;
	margin-left: 0;
}


.single-category .category-header {
	margin-bottom: 0;
}

.category-header .search-form {
	min-height: auto;
	width: 750px;
	margin: auto;
}

.category-header h1 {
	margin-top: 0;
	margin-bottom: 0;
}

.category-header p {
	margin-bottom: 0;
}

.category-nav>ul {
	padding-left: 0;
	margin-bottom: 0;
	display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
}


.category-nav>ul>li {
	list-style-type: none;
}

.category-nav>ul>li>a {
	font-size: 18px;
	line-height: 20px;
	color: #868686;
	text-align: center;

	width: 190px;
	height: 105px;
	padding-bottom: 15px;
	margin-right: 5px;
	background-color: #e2e2e2;
	-moz-transition: all linear 0.3s;
	-webkit-transition: all linear 0.3s;
	-ms-transition: all linear 0.3s;
	transition: all linear 0.3s;

	display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
        -ms-flex-pack: end;
            justify-content: flex-end;
}
/* Nutrient */
.category-nav>ul>li.active>a.nutrient {
	background-image: url('../img/food/icons/nutr-blue.png');
}

.category-nav>ul>li>a.nutrient {
	background-image: url('../img/food/icons/nutr-grey.png');
}

.category-nav>ul>li:not(.active)>a.nutrient:hover {
	background-image: url('../img/food/icons/nutr-white.png');
}

/* Browse */
.category-nav>ul>li.active>a.browse {
	background-image: url('../img/food/icons/browse-blue.png');
}

.category-nav>ul>li>a.browse {
	background-image: url('../img/food/icons/browse-grey.png');
}

.category-nav>ul>li:not(.active)>a.browse:hover {
	background-image: url('../img/food/icons/browse-white.png');
}

/* List */
.category-nav>ul>li.active>a.list {
	background-image: url('../img/food/icons/list-blue.png');
}

.category-nav>ul>li>a.list {
	background-image: url('../img/food/icons/list-grey.png');
}

.category-nav>ul>li:not(.active)>a.list:hover {
	background-image: url('../img/food/icons/list-white.png');
}

/* Details */
.category-nav>ul>li.active>a.details {
	background-image: url('../img/food/icons/details-blue.png');
}

.category-nav>ul>li>a.details {
	background-image: url('../img/food/icons/details-grey.png');
}

.category-nav>ul>li:not(.active)>a.details:hover {
	background-image: url('../img/food/icons/details-white.png');
}

/* Plan */
.category-nav>ul>li.active>a.plan {
	background-image: url('../img/food/icons/plan-blue.png');
}

.category-nav>ul>li>a.plan {
	background-image: url('../img/food/icons/plan-grey.png');
}

.category-nav>ul>li:not(.active)>a.plan:hover {
	background-image: url('../img/food/icons/plan-white.png');
}

/* Symptom */
.category-nav>ul>li.active>a.symptom {
	background-image: url('../img/knowledge_base/icons/symptom-blue.png');
}

.category-nav>ul>li>a.symptom {
	background-image: url('../img/knowledge_base/icons/symptom-grey.png');
}

.category-nav>ul>li:not(.active)>a.symptom:hover {
	background-image: url('../img/knowledge_base/icons/symptom-white.png');
}

/* Disease */
.category-nav>ul>li.active>a.disease {
	background-image: url('../img/knowledge_base/icons/disease-blue.png');
}

.category-nav>ul>li>a.disease {
	background-image: url('../img/knowledge_base/icons/disease-grey.png');
}

.category-nav>ul>li:not(.active)>a.disease:hover {
	background-image: url('../img/knowledge_base/icons/disease-white.png');
}

/* Drugs */
.category-nav>ul>li.active>a.drugs {
	background-image: url('../img/knowledge_base/icons/drugs-blue.png');
}

.category-nav>ul>li>a.drugs {
	background-image: url('../img/knowledge_base/icons/drugs-grey.png');
}

.category-nav>ul>li:not(.active)>a.drugs:hover {
	background-image: url('../img/knowledge_base/icons/drugs-white.png');
}

/* Procedure */
.category-nav>ul>li.active>a.procedure {
	background-image: url('../img/knowledge_base/icons/procedure-blue.png');
}

.category-nav>ul>li>a.procedure {
	background-image: url('../img/knowledge_base/icons/procedure-grey.png');
}

.category-nav>ul>li:not(.active)>a.procedure:hover {
	background-image: url('../img/knowledge_base/icons/procedure-white.png');
}

.category-nav>ul>li:last-child a {
	margin-right: 0;
}

.category-nav>ul>li a:hover {
	background-color: #00baff;
	color: #fff;
}

.category-nav>ul>li.active {
	position: relative;
	z-index: 1;
}

.category-nav>ul>li:hover a {
	color: #fff;
}


.category-nav>ul>li.active a,
.category-nav>ul>li.active a:hover,
.category-nav>ul>li.active a:focus {
	background-color: #fff;
	color: #00baed;
	-moz-transition: all linear 0.3s;
	-webkit-transition: all linear 0.3s;
	-ms-transition: all linear 0.3s;
	transition: all linear 0.3s;
}


.bn-version .category-nav>ul>li>a {
	font-size: 20px;
	line-height: 20px;
}

.category-nav>ul>li>a:hover,
.category-nav>ul>li>a:focus {
	text-decoration: none;
}

.category-header-form .col-md-11,
.category-header-form .col-md-1 {
	padding: 0;
}

.category-header-form .form-group {
	margin-bottom: 0;
}

.category-header-form .form-control {
	height: 40px;
	background-color: #fff;
	font-size: 13px;
	line-height: 20px;
	border-color: #fff;
}

.category-header-form .form-search-icon {
	height: 40px;
	width: 20%;
	background-color: #00baff;
	font-size: 15px;
	padding-right: 15px;
}

.subcat-nav {
	background-color: #fff;
	width: 100%;
	height: 115px;
	border-bottom: 1px solid #d7d7d7;
}


.category-search-page .category {
	padding-bottom: 0;
}

.category-search-page .category-header {
	margin-bottom: 100px;
}

.search-form .form-control.search-height,
.form-search-icon.search-height {
	height: 85px;
}


.open>.category-dropdown {
	display: flex;
	height: 115px;
	width: 100%;
	border: 0;
	box-shadow: none;
	border-radius: 0;
}

ul.category-dropdown>li {
	display: inline-flex;
	border-bottom: 4px solid #fff;
	margin: 0 12.5px;
}

ul.category-dropdown>li:hover,
ul.category-dropdown>li.active {
	border-bottom: 4px solid #00baed;
}

ul.category-dropdown>li>a {
	margin: auto;
	font-size: 15px;
	text-align: center;
}

ul.category-dropdown>li>a>i {
	display: block;
	margin-bottom: 10px;
}

.category-btn-trigger {
	display: none;
	margin: auto;
	background-color: #026a86;
	border: 1px solid #026a86;
	color: #fff;
	font-size: 30px;
	line-height: 33px;
	padding: 10px 20px;
	cursor: pointer;
	outline: 0
	;
}

@media ( max-width:991px ) {
	.category-nav>ul>li>a {
		width: 150px;
	}
}

@media ( max-width:767px ) {
	.category-header h1 {
		margin-bottom: 10px;
	}

	.header-share-wrap h4{
		text-align: left;
		margin-bottom: 0;
	}

	.header-social-list {
		display: block;
	}

	.category-nav>ul>li>a {
		width: 100px;
		height: 100px;

		background-position: 50% 20%;
	}
}

@media ( max-width:540px ) {
	.category-btn-trigger {
		display: block;
	}

	.category-nav-container {
		display: none;
	}

	.category-nav>ul {
		-webkit-box-orient: vertical;
	    -webkit-box-direction: normal;
	    -webkit-flex-direction: column;
	        -ms-flex-direction: column;
	            flex-direction: column;
	}

	.category-nav>ul>li>a,
	.category-nav>ul>li.active>a {
		padding-left: 0;
		padding-right: 0;
		padding-top: 5px;
		padding-bottom: 0;
		background-position: 50% 50%;
		background-size: 20px;
	}

	.category .category-nav>ul>li>a {
		width: 100%;
		min-height: 70px;
		font-size: 13px;
		line-height: 15px;
		font-weight: bold;
		margin: 0;
		padding-bottom: 10px;

		-webkit-box-pack: end;
	    -webkit-justify-content: flex-end;
	        -ms-flex-pack: end;
	            justify-content: flex-end;
	}

	.category .category-nav>ul>li:last-child>a {
		margin-right: 0;
	}

}

/* Knowledge Base Banner
==========================
*/

.category.knowledge-banner {
	padding-bottom: 0;
}


.knowledge-banner-share,
.knowledge-share-list {
	display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
}

.knowledge-banner-wrap {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	/*padding: 5px 15px;*/
}

.knowledge-banner-share {
	padding: 5px 15px;
}

.knowledge-banner-share h4 {
	padding-right: 15px;
	color: #fff;
}

.knowledge-share-list li {
	list-style: none;
}

.knowledge-share-list li a {
	display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    min-width: 37px;
	padding: 10px 12.5px;
	color: #fff;
	margin-left: 5px;
}

.knowledge-share-list li a.facebook {
	background-color: #3b5999;
}

.knowledge-share-list li a.twitter {
	background-color: #55acee;
}

.knowledge-share-list li a.google-plus {
	background-color: #DC4E41;
}

.knowledge-share-list li a.linkedin {
	background-color: #0077B5;
}


.knowledge-banner-wrap h1 {
	margin: 0;
	color: #fff;
	background-color: rgba(0,0,0, 0.35);
	padding: 5px 15px;
}

.knowledge-banner-wrap .btn-default {
	margin-bottom: 0;
	border-width: 1px;
	padding: 5px 30px;
}

.knowledge-image-wrap {
	position: relative;
}

@media ( min-width: 768px) {
	.knowledge-banner-wrap {
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 15px;

		display: -webkit-box;
	    display: -webkit-flex;
	    display: -ms-flexbox;
	    display: flex;

	    -webkit-box-pack: justify;
	    -webkit-justify-content: space-between;
	        -ms-flex-pack: justify;
	            justify-content: space-between;
	    -webkit-box-align: center;
	    -webkit-align-items: center;
	        -ms-flex-align: center;
	            align-items: center;
	}
}

@media ( max-width: 767px ) {
	.knowledge-banner-wrap {
		position: static !important;
	}

	.knowledge-banner-wrap h1 {
		font-size: 22px;
		line-height: 25px;
		text-align: center;
		margin-bottom: 10px;
	}

	.knowledge-banner-wrap h4 {
		color: #666666;
	}

	.knowledge-banner-share {
		margin-bottom: 10px;
		-webkit-box-pack: center;
	    -webkit-justify-content: center;
	        -ms-flex-pack: center;
	            justify-content: center;
	}

	.bn-version .symptom-cause table>tbody>tr>td,
	.bn-version .symptom-stat table>tbody>tr>td {
		max-width: none !important;
	}
}


/* Medical section Rules
==========================
*/

.medical {
	padding: 110px 0;
	position: relative;
	background-color: #f8f9fa;
}

.medical h3 {
	margin-bottom: 30px;
	font-size: 20px;
	line-height: 25px;
	color: #797979;
	font-weight: 400;
}

.medical p {
	margin-bottom: 0;
	margin-top: 25px;
}

.medical-about img {
	width: 100%;
}

.medical .heading-border-wrap {
	margin-bottom: 30px;
}

.medical-img-gallery img {
	float: left;
	width: 33.333%;
}

.medical-video-gallery a.video-link {
	float: left;
	width: 33.333%;
	position: relative;
}

.medical-video-gallery a.video-link::after {
	content: " ";
    background-image: url(../img/knowledge_base/cam-icon.png);
    background-repeat: no-repeat;
    background-position: top left;
    width: 38px;
    height: 24px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -19px;
    margin-top: -12px;
    cursor: pointer;
}

.medical-video-gallery img {
	width: 100%;
}


/* Symptom Notice Section Rules
=================================
*/

.symptom-notice {
	padding: 110px 0;
}

.symptom-notice h3 {
	font-size: 25px;
	color: #797979;
	font-weight: 700;
	line-height: 30px;
	margin-bottom: 30px;
}

.symptom-notice .heading-border {
	width: 75px;
	margin-bottom: 30px;
}

.slides-img-inner .col-md-4 {
	padding: 0;
}

.notice-slider .slides h4 {
	margin-top: 0;
	font-size: 20px;
	line-height: 25px;
	margin-bottom: 25px;
	color: #797979;
}

.notice-slider .slides p {
	margin-bottom: 0;
	margin-top: 35px;
}

.notice-slider-nav .slides,
.notice-slider-nav .slides > li,
.notice-slider-nav .flex-control-nav,
.notice-slider-nav .flex-direction-nav {
	float: none !important;
}

.notice-slider-nav .slides > li {
	font-size: 18px;
	line-height: 25px;
	padding: 25px 20px;
	border-top: 1px solid #e8e8e8;
	border-bottom: 1px solid #e8e8e8;
	cursor: pointer;
}

.notice-slider-nav .slides > li:hover {
	background-color: #e8e8e8;
}

.notice-slider-nav .slides > li.flex-active-slide {
	background-color: #e8e8e8;
	position: relative;
}

.notice-slider-nav .slides > li.flex-active-slide::after {
	content: " ";
	position: absolute;
	top: 50%;
	right: 0;
	width: 0;
	height: 0;
	margin-top: -15px;
	border-top: 15px solid transparent;
	border-bottom: 15px solid transparent;
	border-right:10px solid #fff;
}

/* Knowledge-search Page Rules
===========================================
*/

.search-form {
	position: relative;
	min-height: 100px;
}

.non-user-search-form {
	padding: 50px 0;
}

.single-search-cat {
	position: relative;
}

.sidebar-form .col-md-9,
.sidebar-form .col-md-3 {
	padding: 0;
}

.single-search-cat.search-form .sidebar-form .form-control {
	border: 1px solid #cfcfcf;
}

.user-logged-in .single-search-cat {
	padding: 25px 0;
	box-shadow: 1px 0 5px #222;
}

.search-form form {
	margin-top: -33.5px;
	margin-bottom: 50px;
	height: auto;
}

.search-form form.search-width {
	width: 750px;
	margin-left: auto;
	margin-right: auto;
}

aside .single-search-cat.search-form form {
	width: 100%;
	margin-bottom: 40px;
}

.single-search-cat.search-form form {
	margin: 0;
}

.search-form form .col-md-11,
.search-form form .col-xs-10,
.search-form form .col-md-1,
.search-form form .col-xs-2,
.search-form form .col-md-7,
.search-form form .col-md-3,
.search-form form .col-md-2 {
	padding: 0;
}

.search-form .form-group {
	margin-bottom: 0;
}

.search-form .form-control {
	height: 67px;
	font-size: 15px;
	line-height: 20px;
	color: #000 ;
	background-color: #e9e9e9;
}

.bn-version .search-form .form-control {
	font-size: 18px;
	line-height: 22px;
}

aside .search-form .form-control {
	height: 60px;
}

.search-form.single-search-cat .form-control {
	font-size: 13px;
	line-height: 20px;
	color: #7f7f7f;
}

.single-search-cat.search-form .form-control {
	background-color: #fff;
}

.user-logged-in .single-search-cat .search-select-wrap {
	height: 67px;
}

.user-logged-in .single-search-cat .btn-avatar {
	height: 67px;
}

.user-logged-in .single-search-cat .alphabet-list {
	margin-bottom: 0;
}

.search-select-wrap {
	display: inline-flex;
	height: 85px;
	width: 100%;
	text-align: right;
	background-color: #fff;
}

.search-form .search-select {
	margin: auto;
	border-left: 1px solid #ccc;
	font-size: 20px;
	line-height: 25px;
	color: #797979;
	width: 100%;
	padding: 0 10px;
}

.single-search-cat.search-form .search-select {
	font-size: 13px;
	line-height: 20px;
	color: #7f7f7f;
	text-align: center;
}

.search-form .search-select:hover {
	text-decoration: none;
}

.search-form .search-form-left .col-md-8,
.search-form .search-form-left .col-md-7,
.search-form .search-form-left .col-md-3,
.search-form .search-form-left .col-md-2 {
	padding: 0;
}

.search-form .input-btn-wrap,
.search-form .input-btn-wrap .btn-blue {
	display: table;
	width: 100%;
	height: 67px;
	margin-bottom: 0;
}

.bn-version .search-form .input-btn-wrap a.btn-blue i {
	padding: 10px;
}

aside .search-form .input-btn-wrap {
	height: 60px;
}

.caption-right .input-btn-wrap {
	position: relative;
}

.form-search-icon {
	position: relative;
	display: table-cell;
	vertical-align: middle;
	width: 100%;
	height: 67px;
	color: #fff;
	text-align: center;
	pointer-events: none;
	font-size: 22px;
	line-height: 25px;
	background-color: #9e9e9e;
}

aside .form-search-icon {
	height: 60px;
}

.search-form input.submit-input[type="submit"] {
	width: 100%;
	background-color: #9e9e9e;
	border-color: #9e9e9e;
}

.single-search-cat.search-form input.submit-input[type="submit"] {
	background-color: #cfcfcf;
	border-color: #cfcfcf;
}

.single-search-cat .form-search-icon {
	background-color: #cfcfcf;
	border-color: #cfcfcf;
}

.submit-btn-wrap {
	display: flex;
	width: 100%;
	height: 85px;
	background-color: #00baed;
}

.submit-btn {
	margin: auto;
	font-weight: 700;
	color: #fff;
	font-size: 25px;
	line-height: 30px;
}


.alphabet-list {
	padding-left: 0;
	margin-bottom: 0;
	margin: 20px 0;
}

.alphabet-list li  {
	display: inline-block;
	height: 38px;
	border: 1px solid #b3b3b3;
	margin-right: 2px;
	-moz-transition: all linear 0.3s;
	-webkit-transition: all linear 0.3s;
	-ms-transition: all linear 0.3s;
	transition: all linear 0.3s;
}

.user-up .alphabet-list li {
	display: inline-block;
	height: 38px;
	width: 14.685%;
	border: 1px solid #b3b3b3;
	margin-right: 2px;
	-moz-transition: all linear 0.3s;
	-webkit-transition: all linear 0.3s;
	-ms-transition: all linear 0.3s;
	transition: all linear 0.3s;
}

.alphabet-list li:hover {
	background-color: #b3b3b3;
}

.alphabet-list li a {
	height: 36px;
	width: 36px;
	text-align: center;
	display: table-cell;
	vertical-align: middle;
	color: #b3b3b3;
	-moz-transition: all linear 0.3s;
	-webkit-transition: all linear 0.3s;
	-ms-transition: all linear 0.3s;
	transition: all linear 0.3s;
}

.bn-version .alphabet-list li a {
	font-size: 20px;
	line-height: 25px;
}

.bn-version .alphabet-list .clearfix {
	margin-bottom: 20px;
}

.user-up .alphabet-list li a {
	width: 14.685%;
	height: 38px
}

.alphabet-list li a:hover {
	text-decoration: none;
	color: #fff;
	border: 0;
	border-radius: 0;
	padding: 0;
	margin: 0;
}

/*.symptom-thumbnail {
	padding: 105px 0;
}*/

.non-user-symptom-thumbnail {
	padding: 50px 0;
}

.thumbnail {
	border: 0;
	border-radius: 0;
	padding-top: 25px;
	padding-bottom: 25px;
}

.category-thumbnail {
	padding-right: 50px;
}

.category-thumbnail .thumbnail {
	padding: 0;
	margin-bottom: 30px;
	position: relative;
	overflow: hidden;
	display: table;
	margin-left: auto;
	margin-right: auto;
}

.thumbnail:hover {
	background-color: #f3f4f5;
}

.thumbnail-caption {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: #00baff;
}

.category-thumbnail .thumbnail:hover .thumbnail-caption {
	opacity: 1;
	height: 100%;
}

.thumbnail-caption {
	opacity: 0;
	height: 0;
	-moz-transition: all ease 0.3s;
	-webkit-transition: all ease 0.3s;
	-ms-transition: all ease 0.3s;
	transition: all ease 0.3s;
}

.thumbnail-caption .caption-vertical {
	position: relative;
	top: 50%;
	padding: 0 15px;
}

.caption-vertical img {
	margin-bottom: 10px;
}

.caption-vertical h4 {
	font-size: 14px;
	line-height: 20px;
	color: #fff;
	font-weight: 600;
	margin-top: 0;
	margin-bottom: 0;
}

.bn-version .caption-vertical h4 {
	font-size: 20px;
	font-weight: normal;
}

.thumbnail .caption-vertical p {
	color: #fff;
	font-weight: 400;
	margin-bottom: 0;
}


.thumbnail>img {
	margin-bottom: 20px;
	border-radius: 50%;
	border: 5px solid #9a9a9a;
	-moz-transition: all linear 0.3s;
	-webkit-transition: all linear 0.3s;
	-ms-transition: all linear 0.3s;
	transition: all linear 0.3s;
}

.category-thumbnail .thumbnail>img {
	border-radius: 0;
}

.thumbnail:hover img {
	border-color: #00baff;
}
.thumbnail h3 {
	font-size: 20px;
	line-height: 25px;
	color: #777;
	margin-bottom: 15px;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}

.thumbnail p {
	color: #959595;
	font-size: 13px;
	line-height: 20px;
	margin-bottom: 20px;
}

.thumbnail .btn-grey {
	background-color: #7e7e7e;
	border-color: #7e7e7e;
	color: #fff;
	margin-top: 0;
	margin-bottom: 0;
}

.thumbnail .caption {
	padding-top: 0;
	padding-bottom: 0;
	overflow: hidden;
}

.category-thumbnail .caption {
	display: table;
	width: 100%;
	padding: 0;
}

.category-thumbnail .caption h4 {
	font-size: 14px;
	line-height: 20px;
	font-weight: 700;
	color: #494949;
	margin-top: 0;
	margin-bottom: 0;
	white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.bn-version .category-thumbnail .caption h4 {
	font-size: 20px;
	line-height: 25px;
}

.bn-version .thumbnail p {
	font-size: 16px;
	line-height: 20px;
}

.category-thumbnail .caption p {
	margin-bottom: 0;
	white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.category-thumbnail .caption .caption-right,
.category-thumbnail .caption .caption-left {
	display: table-cell;
	vertical-align: middle;
}

.category-thumbnail .caption .caption-right {
	width: 20%;
}

.category-thumbnail .caption .caption-left {
	width: 80%;
	max-width: 135px;
	overflow: hidden;
	padding-right: 10px;
}

.category-thumbnail .caption .caption-right .submit-input {
	padding: 0;
	width: 100%;
	height: 30px;
	background-color: #9e9e9e;
	border-color: #9e9e9e;
}

.category-thumbnail .caption .caption-right .form-search-icon {
	height: 30px;
	width: 37px;
}

.thumbnail .caption .heading-border-wrap {
	margin-bottom: 15px;
}

.thumbnail .btn-grey:hover {
	background-color: #00baed;
	text-decoration: none;
	border-color: #00baed;
}

.rx-pagination {
	margin-top: 40px;
}

.rx-pagination-center {
	display: table;
	margin: auto;
}

.rx-pagination-list, .rx-nav-list {
	display: inline-block;
	padding-left: 5px;
	margin-bottom: 0;
}

.rx-pagination-list li, .rx-nav-list li {
	list-style: none;
	display: inline-block;
	height: 40px;
	width: 40px;
	margin-right: 2px;
}

.rx-pagination-list li a, .rx-nav-list li a {
	display: table-cell;
	text-align: center;
	height: 40px;
	width: 40px;
	vertical-align: middle;
	font-size: 15px;
	line-height: 20px;
	color: #9e9e9e;
	border: 2px solid #00baff;
	text-decoration: none;
	-moz-transition: all linear 0.3s;
	-webkit-transition: all linear 0.3s;
	-ms-transition: all linear 0.3s;
	transition: all linear 0.3s;
}

.bn-version .rx-pagination-list li a, .rx-nav-list li a {
	font-size: 18px;
	line-height: 22px;
}

.rx-nav-list li a {
	border-color: #505050;
	font-size: 20px;
	line-height: 25px;
}
.rx-pagination-list li a:hover,
.rx-nav-list li a:hover,
.rx-pagination-list li.current a,
.rx-pagination-list li.disabled a {
	text-decoration: none;
	border-color: #505050;
}


@media (max-width: 991px) {
	.category-thumbnail {
		padding-right: 0;
	}
}

@media (max-width: 767px) {
	.search-form .form-group {
		margin-bottom: 20px;
	}
}


/* category-content section Rules
=======================================
*/

.category-content {
	padding-top: 50px;
	margin-bottom: 70px;
}

.category-content h2 {
	color: #4f4f4f;
	font-weight: 600;
}

.user-up .category-content {
	padding-top: 50px;
	padding-bottom: 20px;
	margin-bottom: 0;
}

.user-logged-in .category-content h2 {
	font-size: 28px;
	line-height: 30px;
	margin-top: 25px;
	margin-bottom: 65px;
	font-weight: 400;
	color: #666666;
}


.category-content-wrap {
	display: table;
	margin: auto;
}

.category-content-single {
	position: relative;
	display: table;
    margin: auto;
    width: 230px;
    height: 230px;
    margin-bottom: 30px;
}

.category-content-symptom {
	background-color: #02b7f0;
}

.category-content-disease {
	background-color: #024c7d;
}

.category-content-drugs {
	background-color: #7bbc2a;
}

.category-content-medical {
	background-color: #0b937b;
}

p.category-single-content-meta {
	margin-bottom: 0;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	text-align: center;
	font-size: 18px;
	line-height: 22px;
	color: #fff;
	padding: 50px 0;
	transition: all linear 0.3s;
}

.bn-version p.category-single-content-meta {
	font-size: 25px;
	line-height: 30px;
}

.category-content-symptom p.category-single-content-meta:hover {
	background-color: #0b7ba5;
}

.category-content-disease p.category-single-content-meta:hover {
	background-color: #0c344f;
}

.category-content-drugs p.category-single-content-meta:hover {
	background-color: #57940a;
}

.category-content-medical p.category-single-content-meta:hover {
	background-color: #076f5d;
}

@media (min-width: 768px) {
	.category-page .search-form .container,
	.search-form.search-form-top .container {
		width: 760px;
	}

	.category-page .category-content .container {
		width: 760px;
		padding: 0;
	}
}

@media (max-width: 767px) {
	p.category-single-content-meta {
		padding: 50px 0;
	}
}


/* Your Question Section Rules
==================================
*/

.your-ques {
	width: 430px;
	margin: auto;
	position: relative;
	text-align: center;
}

h2.ques-title {
	color: #666666;
	font-size: 28px;
	line-height: 30px;
	font-weight: 400;
	margin-top: 25px;
	margin-bottom: 55px;
}

.your-ques h4 {
	color: #777777;
	margin-bottom: 35px;
	font-weight: 600;
}

.add-user-list {
	display: block;
	margin: auto;
	padding-left: 0;
	margin-bottom: 0;
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
    margin-bottom: 80px;
}

.add-user-list tr>td {
	vertical-align: top;
	padding-left: 5px;
	padding-right: 5px;
	padding-bottom: 40px;
}

.add-user-list tr>td:first-child {
	padding-left: 0;
}

.add-user-list tr>td:last-child {
	padding-right: 0;
}

.add-user-list tr>td>a>img {
	margin: auto;
	width: 57px;
}

.add-user-list tr>td>a>p {
	margin-bottom: 0;
	width: 100px;
}

p.limit {
	text-align: right;
	margin-bottom: 0;
}

.ajax-search-title {
	margin-top: 0;
	margin-bottom: 0;
	padding: 25px 0;
	background-color: #e0e0e0;
	color: #616161;
	font-weight: 600;
}

.ajax-search-list {
	padding-left: 0;
	margin-bottom: 0;
}

.ajax-search-list li {
	list-style: none;
	padding: 25px 10px;
	border-bottom: 1px solid #eaeaea;
}

.ajax-search-list li.active,
.ajax-search-list li:hover {
	background-color: #f5f5f5;
}

.ajax-search-list li:last-child {
	border-bottom: 0;
}

.ajax-page-content {
	padding: 0 130px;
}

.ajax-page-content .your-ques,
.ajax-page-content .question-form {
	width: 100%;
}


/* question-form section Rules
=================================
*/

.question-form {
	width: 485px;
	display: table;
	margin: auto;
	margin-bottom: 125px;
}

.question-form .form-group:nth-child(2) {
	margin-bottom: 0;
}

.question-form .form-group:nth-child(4) {
	margin-bottom: 25px;
}

.question-form h4 {
	font-size: 20px;
	line-height: 27px;
	font-weight: 600;
	color: #777777;
	margin-top: 0;
	margin-bottom: 20px;
}

.inline-btn-wrap {
	display: table;
	margin-left: auto;
}

.inline-btn-wrap .btn:first-child {
	margin-right: 20px;
}

textarea.form-control {
	padding-left: 10px;
	padding-right: 10px;
}

/* Add Symptom Section Rules
===============================
*/

.add-symptom {
	padding-bottom: 70px;
}

.add-symptom-title {
	font-size: 28px;
	line-height: 30px;
	margin-top: 20px;
	margin-bottom: 25px;
	color: #666666;
}

.asked-for {
	border: 2px dashed #777777;
	padding-top: 35px;
	padding-bottom: 55px;
}

.asked-for h4:first-child {
	color: #777777;
	font-weight: 700;
	margin-top: 0;
	margin-bottom: 45px;
}

.asked-for .author-img-wrap {
	width: 82px;
	margin: auto;
}

.asked-for .author-img-wrap img {
	border-radius: 50%;
}

.asked-for .author-name {
	margin-bottom: 35px;
}

.asked-for .author-img-wrap i {
	position: absolute;
    top: -15px;
    right: -10px;
    font-size: 40px;
    background-color: #fff;
    border-radius: 50%;
    border: 2px solid #fff;
    color: #008000;
}

.asked-for p {
	margin-bottom: 0;
	color: #777;
}

.asked-for .your-question-text {
	position: relative;
	background-color: #00baff;
	color: #fff;
	font-size: 20px;
	line-height: 27px;
	padding: 25px 0;
	margin-bottom: 50px;
}

.asked-for .your-question-text::after {
	content: " ";
	position: absolute;
	bottom: -10px;
	left: 50%;
	margin-left: -15px;
	width: 0;
	height: 0;
	border-left: 15px solid transparent;
	border-right: 15px solid transparent;
	border-top: 10px solid #00baff;
}

.add-symptom > h4 {
	background-color: #e6e6e6;
	margin: 0;
	padding-top: 25px;
	padding-bottom: 25px;
	text-align: center;
	font-size: 18px;
	line-height: 27px;
	font-weight: 700;
	border-top: 1px solid #d6d6d6;
	border-left: 1px solid #d6d6d6;
	border-right: 1px solid #d6d6d6;
}


table.add-symptom-table, table.selected-symptom-table {
	width: 100%;
	border: 1px solid #d6d6d6;
	margin-bottom: 10px;
}

table.add-symptom-table tr, table.selected-symptom-table tr {
	border-bottom: 1px solid #d6d6d6;
}


table.add-symptom-table td,
table.add-symptom-table th,
table.selected-symptom-table td,
table.selected-symptom-table th {
	padding: 25px 5px;
}

table.add-symptom-table td:first-child {
	padding-left: 25px;
}

table.add-symptom-table td i,
table.selected-symptom-table td i {
	font-size: 25px;
	color: #a1a1a1;
}

table.add-symptom-table td.checked,
table.add-symptom-table td.cancelled,
table.selected-symptom-table td.checked,
table.selected-symptom-table td.checked {
	text-align: center;
}

table.add-symptom-table td.active i,
table.selected-symptom-table td.active i  {
	color: #008800;
}

table.add-symptom-table td.cancelled i,
table.selected-symptom-table td.cancelled i {
	color: #ff0000;
}

.add-symptom .form-control.search-inline {
	border: 2px solid #bcbcbc;
	height: 55px;
	border-right: 0;
}

.add-symptom .btn-grey {
	display: table;
	height: 55px;
	background-color: #fff;
	margin-bottom: 0;
	border-color: #bcbcbc;
	font-size: 20px;
}

.add-symptom .btn-grey:hover,
.add-symptom .btn-grey:active,
.add-symptom .btn-grey:focus {
	background-color: #00baff;
	border-color: #00baff;
}


.selected-symptom > h4 {
	background-color: #e6e6e6;
	margin: 0;
	padding-top: 25px;
	padding-bottom: 25px;
	text-align: center;
	font-size: 18px;
	line-height: 27px;
	font-weight: 700;
	border-top: 1px solid #d6d6d6;
	border-left: 1px solid #d6d6d6;
	border-right: 1px solid #d6d6d6;
}

/*.modal {
	z-index: 9999;
}

.modal-content {
	border-radius: 0;
}

.modal-header {
	background-color: #e9e9e9;
	display: table;
	width: 100%;
	padding: 25px 40px;
}

.modal-header .btn {
	margin-bottom: 0;
}

.modal-header h2 {
	font-size: 23px;
	line-height: 27px;
	display: table-cell;
	margin-bottom: 0;
	vertical-align: middle;
	color: #777777;
}

.modal-header .btn-group,
.modal-header .btn-group-vertical {
	display: table-cell;
	float: right;
}

.modal-body {
	padding: 0;
}

.modal-table {
	width: 280px;
	margin: auto;
}

.modal-table tr {
	border-bottom: 1px solid #cfcfcf;
}

.modal-table tr:last-child {
	border-bottom: 0;
}

.modal-table td.med-name {
	font-size: 15px;
	line-height: 20px;
	font-weight: 700;
	padding: 30px 0;
	color: #0aa70a;
}

.modal-table td.cancelled i {
	font-size: 25px;
	line-height: 30px;
	color: #ff0000;
}

.modal-body .input-group {
	width: 280px;
	margin: auto;
}

.modal-body .input-group .btn {
	margin-bottom: 0;
	height: 50px;
	background-color: #fff;
	border-width: 1px;
}

.modal-body .input-group .btn:hover {
	background-color: #00baff;
}

.modal-body .modal-btn {
	display: table;
	width: 280px;
	margin-top: 35px;
	margin-bottom: 55px;
	font-size: 20px;
}

.modal-header .btn-success.active,
.modal-header .btn-success.active.focus,
.modal-header .btn-success.active:focus,
.modal-header .btn-success.active:hover,
.modal-header .btn-success:active.focus,
.modal-header .btn-success:active:focus,
.modal-header .btn-success:active:hover,
.modal-header .open>.dropdown-toggle.btn-success.focus,
.modal-header .open>.dropdown-toggle.btn-success:focus,
.modal-header .open>.dropdown-toggle.btn-success:hover,
.modal-header .btn-success:hover,
.modal-header .btn-success:focus {
	background-color: #0aa70a;
	border-color: #0aa70a;
}

.modal-header .btn-success {
	background-color: #c2c2c2;
	border-color: #c2c2c2;
	font-size: 20px;
	line-height: 20px;
	font-weight: 700;
}*/

.modal-content {
	border-radius: 0;
}

.modal-content .btn {
	padding-top: 10px;
	padding-bottom: 10px;
	margin-bottom: 0;
}

.btn-success a {
	color: #fff;
	outline: 0;
}

.btn-success.active a:hover,
.btn-success.active a:focus {
	outline: 0;
	text-decoration: none;
}

.modal-no-body {
	padding: 105px 125px;
}

.modal-no-body h3 {
	font-size: 23px;
	line-height: 27px;
	color: #777777;
	font-weight: 700;
	margin-bottom: 60px;
}

.modal-no-body .btn-group {
	display: table;
	margin: auto;
	margin-bottom: 60px;
}

.modal-no-body .modal-btn {
	margin-top: 0;
	margin-bottom: 0;
}

.modal-no-body .btn-danger.active,
.modal-no-body .btn-danger.active.focus,
.modal-no-body .btn-danger.active:focus,
.modal-no-body .btn-danger.active:hover,
.modal-no-body .btn-danger:active.focus,
.modal-no-body .btn-danger:active:focus,
.modal-no-body .btn-danger:active:hover,
.modal-no-body .open>.dropdown-toggle.btn-danger.focus,
.modal-no-body .open>.dropdown-toggle.btn-danger:focus,
.modal-no-body .open>.dropdown-toggle.btn-danger:hover,
.modal-no-body .btn-danger:hover,
.modal-no-body .btn-danger:focus {
	background-color: #ff0000;
	border-color: #ff0000;
}

.modal-no-body .btn-danger {
	background-color: #c2c2c2;
	border-color: #c2c2c2;
	font-size: 20px;
	line-height: 20px;
	font-weight: 700;
	margin-bottom: 0;
}

.btn-danger a {
	color: #fff;
	outline: 0;
}

.btn-danger.active a:hover,
.btn-danger.active a:focus {
	outline: 0;
	text-decoration: none;
}


/* Doctor Directory Page Rules
==================================
*/


.meet-doc {
	padding: 75px 0;
}

.meet-doc > .container > h2 {
	font-size: 30px;
	color: #8a8a8a;
}

.meet-doc .search-form .input-btn-wrap,
.meet-doc .search-form .input-btn-wrap .btn-blue {
	display: block;
}

.meet-doc .search-location-block.hidden,
.meet-doc .search-name-block.hidden {
	display: none;
}

.meet-doc .search-name-block .col-md-8,
.meet-doc .search-name-block .col-sm-8,
.meet-doc .search-name-block .col-md-2,
.meet-doc .search-name-block .col-sm-2 {
	padding: 0;
}

.user-logged-in .meet-doc {
	padding-top: 0;
	padding-bottom: 0;
}

.meet-doc-header {
	position: relative;
	padding: 30px;
	z-index: 0;
}

.meet-doc-header .container-fluid>h2 {
	font-size: 30px;
	line-height: 40px;
	color: #fff;
	font-weight: 400;
	margin-bottom: 30px;
}


.user-logged-in .doctors-wrap {
	margin-bottom: 0;
}

.doctors-wrap h2 {
	font-size: 30px;
	line-height: 40px;
	color: #8a8a8a;
	font-weight: 600;
	margin-bottom: 30px;
}

.bn-version .doctors-wrap h2 {
	font-size: 40px;
	line-height: 45px;
	font-weight: normal;
}

.doctors-wrap h2.white-font {
	color: #fff;
}

.doc-list {
	padding-left: 0;
	display: block;
	position: relative;
	text-align: center;
	margin: auto;
	margin-bottom: -5px;
}

.doc-list li {
	list-style: none;
	display: inline-block;
	padding: 0 7.5px;
}

.doc-list li img {
	border-top: 2px solid #fff;
	border-left: 2px solid #fff;
	border-right: 2px solid #fff;
	-moz-transition: all linear 0.3s;
	-webkit-transition: all linear 0.3s;
	-o-transition: all linear 0.3s;
	transition: all linear 0.3s;
	max-width: 90px;
	max-height: 90px;
}

.doc-list li img:hover {
	border-color: #00baff;
}

/*.doc-search {
	padding: 0 145px;
}*/

.user-logged-in .doc-search {
	padding: 35px 145px;
}

.user-logged-in .doc-search .search-form {
	padding: 0;
	border: 0;
}

.doc-search.single-doc-search {
	margin-top: -33.5px;
	padding: 0;
	width: 850px;
	margin-left: auto;
	margin-right: auto;
}

.doc-search .search-form {
	min-height: auto;
	margin: auto;
}

.doc-search .search-form-left,
.doc-search .search-form-right,
.doc-search .search-form-right-end.col-md-2,
.doc-search .search-form-right-end.col-sm-2 {
	padding-left: 7.5px;
	padding-right: 7.5px;
}

.doc-search .search-form-right-end .btn-solid-grey {
	margin-bottom: 0;
	height: 67px;
	background-color: #a4a4a4;
	color: #fff;
	font-size: 13px;
	width: 100%;
}

.doc-search .search-form-right-end .btn-solid-grey:hover,
.doc-search .search-form-right-end .btn-solid-grey:focus {
	background-color: #00baff;
	outline: 0;
}

.doc-search .search-form-right .col-md-10,
.doc-search .search-form-right .col-xs-10,
.doc-search .search-form-right .col-md-2,
.doc-search .search-form-right .col-xs-2,
.search-form-right-end.col-md-2,
.search-form-right-end.col-sm-2 {
	padding: 0;
}

.doc-search .form-search-icon,
.doc-search .search-form input.submit-input[type="submit"] {
	background-color: #00baff;
	border-color: #00baff;

}

.location-search {
    /*background: url(../img/doc-directory/search-icon.png) no-repeat scroll 7px 7px;*/
    /*background-position: 7%;*/
    /*padding-left: 55px;*/
}

.location-search + .select2-container {
	position: relative;
}

.location-search + .select2-container::after {
	content: "\f041";
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: absolute;
    top: 0;
    left: 20px;
    font-size: 25px;
    line-height: 67px;
    color: #999;
}

.location-search + .select2-container.select2-container--open::after {
	color: #00baff;
}

.location-search + .select2-container .select2-selection--single .select2-selection__rendered {
	padding-left: 40px;
}

.location-search + i {
	color: #9e9e9e;
	position: absolute;
    top: 50%;
    left: 25px;
    margin-top: -14px;
    -moz-transition: all linear 0.3s;
    -webkit-transition: all linear 0.3s;
    -ms-transition: all linear 0.3s;
    transition: all linear 0.3s;
}

.location-search:focus + i {
	color: #00baff;
}

.location-search:focus {
	background-image: none;
}

.specialities {
	padding: 70px 0;
	background-color: #f3f3f3;
}

.specialities h3 {
	display: table-cell;
	height: 110px;
	vertical-align: middle;
	color: #8a8a8a;
	font-weight: 700;
	font-size: 28px;
	line-height: 30px;
}

.specialities h3 span.light-text {
	font-weight: 400;
	display: block;
	font-size: 25px;
}

.bn-version .specialities h3 span.light-text {
	font-size: 47px;
	line-height: 55px;
}

.specialities .border-circle-right {
	width: 35%;
}

.speciality-list {
	display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-justify-content: space-around;
	        justify-content: space-around;
    		  -ms-flex-pack: distribute;
	-webkit-flex-wrap: wrap;
	    -ms-flex-wrap: wrap;
	        flex-wrap: wrap;
	padding-left: 0;
	margin-bottom: 0;
}

.speciality-list li {
	position: relative;
	list-style: none;
	display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
	-webkit-justify-content: center;
	    -ms-flex-pack: center;
	        justify-content: center;
	-webkit-align-self: center;
	    -ms-flex-item-align: center;
	        align-self: center;
	background-color: #dedede;
	color: #8a8a8a;
	border: 1px solid #8a8a8a;
	width: 130px;
	height: 110px;
	vertical-align: bottom;
	margin-bottom: 10px;
	text-align: center;
	overflow: hidden;
	-moz-transition: all linear 0.3s;
	-webkit-transition: all linear 0.3s;
	-o-transition: all linear 0.3s;
	transition: all linear 0.3s;
}

.speciality-list li .bg-img {
	z-index: 0;
	-moz-transition: all linear 0.3s;
	-webkit-transition: all linear 0.3s;
	-o-transition: all linear 0.3s;
	transition: all linear 0.3s;
}

.speciality-list li a {
	display: block;
	position: relative;
	z-index: 1;
	color: #fff;
	font-weight: 600;
    background-color: rgba(0,0,0,0.65);
    width: 100%;
	-webkit-align-self: end;
    -ms-flex-item-align: flex-end;
        align-self: flex-end;
        -webkit-transition: all ease 0.3s;
        -moz-transition: all ease 0.3s;
        -ms-transition: all ease 0.3s;
        transition: all ease 0.3s;
}

h3.speciality-cat-title {
	height: 260px;
}


.speciality-cat,
.speciality-cat-hospital {
	display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-flow: row wrap;
        -ms-flex-flow: row wrap;
            flex-flow: row wrap;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
}

.speciality-cat li {
	list-style: none;
	text-align: center;
	-webkit-box-flex: 0;
    -webkit-flex: 0 1 calc(19% - 5px);
        -ms-flex: 0 1 calc(19% - 5px);
            flex: 0 1 calc(19% - 5px);
    margin: 5px;

}

.speciality-cat-hospital li {
	list-style: none;
	text-align: center;
	-webkit-box-flex: 0;
    -webkit-flex: 0 1 calc(24% - 5px);
        -ms-flex: 0 1 calc(24% - 5px);
            flex: 0 1 calc(24% - 5px);
    margin: 5px;
}

.speciality-cat li a,
.speciality-cat-hospital li a {
	height: 120px;
	font-size: 14px;
	border: 1px solid #dddddd;
	display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
        -ms-flex-pack: end;
            justify-content: flex-end;
	background-color: #fff;
	-moz-transition: all ease 0.3s;
	-webkit-transition: all ease 0.3s;
	-ms-transition: all ease 0.3s;
	transition: all ease 0.3s;
}

.speciality-cat li a > .img-center,
.speciality-cat-hospital li a > .img-center {
	margin-top: 0;
	margin-bottom: 5px;
}

.speciality-cat li a:hover,
.speciality-cat-hospital li a:hover {
	background-color: #00baff;
	border-color: #00baff;
	color: #fff;
}

.speciality-cat li a span,
.speciality-cat-hospital li a span {
	padding-bottom: 15px;
}

.hover-bg {
	background-position: center center;
	background-repeat: no-repeat;
}

.speciality-cat li a.med {
	background-image: url("../img/hospital-directory/med-icon.png");
}

.speciality-cat li a.med:hover {
	background-image: url("../img/hospital-directory/med-icon-white.png");
}

.speciality-cat li a.dent {
	background-image: url("../img/hospital-directory/dent-icon.png");
}

.speciality-cat li a.dent:hover {
	background-image: url("../img/hospital-directory/dent-icon-white.png");
}

.speciality-cat li a.surg {
	background-image: url("../img/hospital-directory/surg-icon.png");
}

.speciality-cat li a.surg:hover {
	background-image: url("../img/hospital-directory/surg-icon-white.png");
}

.speciality-cat li a.gast {
	background-image: url("../img/hospital-directory/gast-icon.png");
}

.speciality-cat li a.gast:hover {
	background-image: url("../img/hospital-directory/gast-icon-white.png");
}

.speciality-cat li a.neu {
	background-image: url("../img/hospital-directory/neo-icon.png");
}

.speciality-cat li a.neu:hover {
	background-image: url("../img/hospital-directory/neo-icon-white.png");
}

.speciality-cat li a.nep {
	background-image: url("../img/hospital-directory/nep-icon.png");
}

.speciality-cat li a.nep:hover {
	background-image: url("../img/hospital-directory/nep-icon-white.png");
}

.speciality-cat li a.card {
	background-image: url("../img/hospital-directory/card-icon.png");
}

.speciality-cat li a.card:hover {
	background-image: url("../img/hospital-directory/card-icon-white.png");
}

.speciality-cat li a.oph {
	background-image: url("../img/hospital-directory/oph-icon.png");
}

.speciality-cat li a.oph:hover {
	background-image: url("../img/hospital-directory/oph-icon-white.png");
}


.speciality-cat li a.oto {
	background-image: url("../img/hospital-directory/oto-icon.png");
}

.speciality-cat li a.oto:hover {
	background-image: url("../img/hospital-directory/oto-icon-white.png");
}

.speciality-cat li a.ort {
	background-image: url("../img/hospital-directory/ort-icon.png");
}

.speciality-cat li a.ort:hover {
	background-image: url("../img/hospital-directory/ort-icon-white.png");
}

.speciality-cat-hospital li a.hos {
	background-image: url("../img/hospital-directory/hos-icon.png");
}

.speciality-cat-hospital li a:hover {
	background-image: url("../img/hospital-directory/hos-icon-white.png");
}

.speciality-cat-hospital li a.clinic {
	background-image: url("../img/hospital-directory/clinic-icon.png");
}

.speciality-cat-hospital li a.clinic:hover {
	background-image: url("../img/hospital-directory/clinic-icon-white.png");
}

.speciality-cat-hospital li a.con {
	background-image: url("../img/hospital-directory/con-icon.png");
}

.speciality-cat-hospital li a.con:hover {
	background-image: url("../img/hospital-directory/con-icon-white.png");
}

.speciality-cat-hospital li a.dia {
	background-image: url("../img/hospital-directory/dia-icon.png");
}

.speciality-cat-hospital li a.dia:hover {
	background-image: url("../img/hospital-directory/dia-icon-white.png");
}

.speciality-cat-hospital li a.upa {
	background-image: url("../img/hospital-directory/upa-icon.png");
}

.speciality-cat-hospital li a.upa:hover {
	background-image: url("../img/hospital-directory/upa-icon-white.png");
}

.speciality-cat-hospital li a.far {
	background-image: url("../img/hospital-directory/far-icon.png");
}

.speciality-cat-hospital li a.far:hover {
	background-image: url("../img/hospital-directory/far-icon-white.png");
}

.speciality-cat-hospital li a.oth {
	background-image: url("../img/hospital-directory/oth-icon.png");
}

.speciality-cat-hospital li a.oth:hover {
	background-image: url("../img/hospital-directory/oth-icon-white.png");
}

.speciality-cat-hospital li a.den {
	background-image: url("../img/hospital-directory/den-icon.png");
}

.speciality-cat-hospital li a.den:hover {
	background-image: url("../img/hospital-directory/den-icon-white.png");
}


.bn-version .speciality-list li a {
	font-size: 18px;
	line-height: 18px;
	padding: 5px 0;
	font-weight: normal;
	display: block;
	min-height: 50px;
}

.speciality-list li::after {
	content: " ";
	position: absolute;
	top: 0;
	left: 0;
	width: 130px;
	height: 110px;
	background-color: transparent;
	transition: all linear 0.3s;
}

.speciality-list li:hover {
	background-color: #00baff;
	border-color: #00baff;
}

.speciality-list li:hover a {
	background-color: transparent;
}

.speciality-list li:hover::after {
	background-color: #00baff;
	opacity: 0.8;
}

.speciality-list li:hover a {
	color: #fff;
}

.speciality-list li:hover .bg-img {
	-webkit-transform: scale(1.2);
	-moz-transform: scale(1.2);
	-o-transform: scale(1.2);
	transform: scale(1.2);
}

.speciality-list li img {
	display: block;
	margin-bottom: 10px;
}

@media (max-width: 991px ) {
	.specialities h3 {
		font-size: 28px;
		line-height: 32px;
		display: block;
		height: auto;
		margin-bottom: 20px;
	}

	.specialities h3 span.light-text,
	.bn-version .specialities h3 span.light-text {
		font-size: 28px;
		line-height: 32px;
		display: inline-block;
	}
}

@media (max-width: 767px) {
	.doc-search {
		padding: 0;
	}

	.category .form-control,
	.category .select2-container {
		margin-bottom: 20px;
	}

	.category .col-md-4:last-child .select2-container,
	.category .col-md-3:last-child .select2-container {
		margin-bottom: 0;
	}

	.speciality-cat-hospital li,
	.speciality-cat li {
		list-style: none;
		text-align: center;
		-webkit-box-flex: 0;
	    -webkit-flex: 0 1 calc(49% - 5px);
	        -ms-flex: 0 1 calc(49% - 5px);
	            flex: 0 1 calc(49% - 5px);
	    margin: 5px;

	}
	/*.speciality-list li {
		display: table;
		margin: auto;
		margin-bottom: 10px;
	}*/
}

@media (max-width: 420px) {
	.doc-list {
		display: table;
		margin-bottom: 0;
		margin-left: auto;
		margin-right: auto;
	}

	.doc-list li {
		display: table-cell;
		vertical-align: bottom;
		padding: 0 2px;
	}

	.speciality-cat li {
		list-style: none;
		text-align: center;
		-webkit-box-flex: 0;
	    -webkit-flex: 0 1 calc(100% - 5px);
	        -ms-flex: 0 1 calc(100% - 5px);
	            flex: 0 1 calc(100% - 5px);
	    margin: 5px;

	}
}



/* Doc Single Search Page Rules
==================================
*/

.doc-single-search-wrap {
	padding: 80px 0;
}

.doc-single-wrap-left h4 {
	font-weight: 700;
	margin-top: 0;
	margin-bottom: 20px;
}

.doc-single-wrap-left .heading-border-wrap {
	margin-bottom: 30px;
}

.appointment-filter {
	margin-bottom: 80px;
}

.checkbox input,
.checkbox input:hover,
.checkbox input:active,
.checkbox input:focus {
	outline: 0;
}


.appointment-filter-inner .checkbox {
	padding-left: 0;
	padding-right: 0;
	border-bottom: 0;
}

.appointment-filter-inner .checkbox span {
	margin-left: 20px;
}

.appointment-filter-inner input[type=checkbox]::after {
	border-radius: 0;
	background-color: #e9e9e9;
}

.appointment-filter input[type=checkbox]:checked::after {
	background-color: #2d9f01;
}

.location-filter {
	margin-bottom: 80px;
}

.filter-list {
	padding-left: 0;
	margin-bottom: 0;
}

.filter-list>li {
	list-style: none;
	position: relative;
}

.filter-list>li>a {
	position: relative;
	display: table-cell;
	height: 50px;
	vertical-align: middle;
	font-size: 13px;
	line-height: 20px ;
	color: #505050;
	padding-left: 40px;
}

.filter-list>li>a::before {
    content: "\f106";
	position: absolute;
	font-family: "FontAwesome";
	left: 0;
	top: 50%;
	margin-top: -12.5px;
	color: #00baff;
	border: 1px solid #00baff;
	border-radius: 50%;
	font-size: 18px;
	width: 25px;
	height: 25px;
	text-align: center;
	opacity: 1;
}

.filter-list>li.open>a::before {
	content: "\f107";
	padding: 2px;
}

.filter-list>li>.dropdown-menu {
	opacity: 1;
    position: static;
    float: none;
    width: auto;
    margin-top: 0;
    background-color: transparent;
    border: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    height: auto;
    margin-left: 40px;
}

.filter-list>li>.dropdown-menu>li .checkbox {
	padding-left: 0;
	padding-right: 0;
	border-bottom: 0;
}

.filter-list>li>.dropdown-menu>li .checkbox label span {
	margin-left: 15px;
	font-size: 13px;
	line-height: 20px;
}


.filter-list>li>.dropdown-menu  input[type=checkbox]::after {
	border-radius: 0;
	background-color: #e9e9e9;
}

.filter-list>li>.dropdown-menu input[type=checkbox]:checked::after {
	background-color: #2d9f01;
}

.availability-filter {
	margin-bottom: 80px;
}

.availability-filter .radio {
	padding: 0;
	border-bottom: 0;
}

.availability-filter .radio span {
	margin-left: 15px;
	font-size: 13px;
	line-height: 20px;
}


.availability-filter input[type=radio]::after {
	content: " ";
    background-color: #fff;
    display: inline-block;
    visibility: visible;
    width: 25px;
    height: 25px;
    border: 1px solid #00baff;
    border-radius: 50%;
}

.availability-filter input[type=radio]:checked::after {
    position: absolute;
    font-family: "FontAwesome";
    content: "\f00c";
    color: #00baff;
    font-size: 14px;
    width: 25px;
    height: 25px;
    padding: 4px;
    top: 0;
    left: 0;
}


.day-list {
	padding-left: 0;
	margin-bottom: 0;
	display: table;
	width: 100%;
	border-collapse: collapse;
	margin: 20px 0;
}

.day-list li.btn {
	display: table-cell;
	border: 1px solid #ccc;
	width: 14.28%;
	text-align: center;
	height: 38px;
	vertical-align: middle;
	position: relative;
	padding: 0;
	float: none;
	margin-bottom: 0;
}

.day-list li.btn.active,
.day-list li.btn:active {
	-webkit-box-shadow: none;
     box-shadow: none;
     background-color: #00baff;
     color: #fff;
     border-color: #00baff;
}

.day-list li input[type="checkbox"] {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    border: 0;
    padding: 0;
    opacity: 0;
    z-index: 100;
    text-indent: 0.01px;
    text-overflow: '';
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.ui-slider-horizontal {
	height: 5px;
}

.ui-widget-content {
	background: #cacaca;
	border: 0;
}

.ui-corner-all,
.ui-corner-bottom,
.ui-corner-left,
.ui-corner-bl {
	border-radius: 0;
}

.ui-slider-horizontal .ui-slider-handle {
	top: -.4em;
}

.ui-state-default,
.ui-widget-content .ui-state-default,
.ui-widget-header .ui-state-default {
	border-radius: 50%;
	background-color: #fff;
	border-width: 3px;
	background-image: none;
	outline: 0;
	-moz-transition: background-color linear 0.3s;
	-webkit-transition: background-color linear 0.3s;
	-o-transition: background-color linear 0.3s;
	transition: background-color linear 0.3s;
}

.ui-state-hover,
.ui-widget-content .ui-state-hover,
.ui-widget-header .ui-state-hover,
.ui-state-focus,
.ui-widget-content .ui-state-focus,
.ui-widget-header .ui-state-focus {
	background-color: #00baff;
	border-color: #dfdfdf;
	cursor: pointer;
	background-image: none;
	outline: 0;
}

.user-logged-in .appointment-filter,
.user-logged-in .location-filter,
.user-logged-in .availability-filter,
.user-logged-in .consultation-filter {
	background-color: #fff;
	border: 1px solid #dfdfdf;
	padding: 25px;
	margin-bottom: 25px;
}

.ui-widget-header {
	background-color: #00baff;
	background-image: none;
}

#time-val,
#price-amount {
	margin-bottom: 30px;
}

.doc-single-wrap-right-inner .contact-list {
	margin-top: 20px;
}

.doc-single-wrap-right-inner .contact-list li {
	padding: 5px 10px;
}

.doc-single-wrap-right-inner .contact-list li:last-child {
	margin-right: 0;
}

.doc-single-wrap-right-inner h2 {
	font-size: 24px;
	line-height: 30px;
	font-weight: 600;
	color: #505050;
	margin-bottom: 5px;
}

.doc-single-wrap-right-inner p {
	margin-bottom: 5px;
}

.doc-single-wrap-right-inner .rating {
	padding-left: 0;
	margin-bottom: 20px;
}

.doc-single-wrap-right-inner ul.rating li {
	font-size: 20px;
}

p.designation {
	color: #838383;
	font-size: 15px;
	line-height: 24px;

}

.service-list {
	padding-left: 0;
	margin-bottom: 0;
}

.service-list li {
	list-style: none;
	display: inline-block;
	padding-right: 5px;
}

.dir-address-wrap .service-list li {
	padding-right: 0;
	margin: 5px;
}

.service-list li:last-child {
	padding-right: 5px;
}

p.location-addr {
	color: #505050;
	margin-bottom: 10px;
}

.location-addr i {
	position: relative;
	top: 3px;
	margin-right: 10px;
	color: #c4c4c4;
}

.doc-single-wrap-right-inner p.blue {
	margin-bottom: 15px;
}

.blue a {
	color: #00baff;
}

.blue a i {
	position: relative;
	top: 3px;
	margin-right: 8px;
	color: #c4c4c4;
}

.doc-single-wrap-right-inner {
	margin-bottom: 25px;
}


.doc-single-wrap-right-inner-left {
	padding: 25px;
	border-left: 1px solid #e1e5e6;
	border-top: 1px solid #e1e5e6;
	border-bottom: 1px solid #e1e5e6;
	background-color: #fff;
}

.doc-single-wrap-right-inner-left img {
	width: 100%;
}

.doc-single-wrap-right-inner-left .col-md-5 {
	padding-left: 0;
	padding-right: 20px;
}

.doc-single-wrap-right-inner-left .col-md-7 {
	padding-left: 5px;
	padding-right: 0;
}

.doc-single-wrap-right-inner-right {
	background-color: #f5f5f5;
	padding: 25px 30px;
}

.user-logged-in .doc-single-wrap-right-inner-right {
	background-color: #e9e9e9;
}

.doc-single-wrap-right-inner-right p {
	margin-bottom: 25px;
}

.doc-single-wrap-right-inner-right p span.time {
	display: block;
	margin-left: 35px;
	margin-top: 10px;
}

.doc-single-wrap-right-inner-right i {
	width: 30px;
	position: relative;
	top: 4px;
	color: #c4c4c4;
	text-align: center;
}

#reviews .form-control {
	border-color: #e6e6e6;
	padding-left: 20px;
	padding-top: 10px;
	color: #222;
}

#reviews .form-control:focus {
	border-color: #00baff;
	color: #222;
}

#reviews .rating {
	margin: 10px 0;
}

.comment-single {
	margin-bottom: 30px;
}

.commentor-img {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-bottom: 20px;
}

.commentor-img > img {
	width: 50px;
	height: 50px;
	margin-right: 20px;
}

#reviews .commentor-name > .rating {
	margin: 0;
}

.tab-pane .comment-list .comment-desc > p {
	margin-bottom: 10px;
	font-size: 18px;
	line-height: 22px;
}

p.comment-meta {
	color: #d3d3d3;
}


/*form .stars,*/
div.stars {
  background: url("../img/stars.png") repeat-x 0 0;
  width: 150px;
  margin-top: 10px;
  margin-bottom: 10px;
}

form .stars input[type="radio"] {
  position: absolute;
  opacity: 0;
  filter: alpha(opacity=0);
}
form .stars input[type="radio"].star-5:checked ~ span {
  width: 100%;
}
form .stars input[type="radio"].star-4:checked ~ span {
  width: 80%;
}
form .stars input[type="radio"].star-3:checked ~ span {
  width: 60%;
}
form .stars input[type="radio"].star-2:checked ~ span {
  width: 40%;
}
form .stars input[type="radio"].star-1:checked ~ span {
  width: 20%;
}

form .stars input[type="radio"].star-5:checked ~ span,
.stars div.star-5.selected ~ span {
  width: 100%;
}
form .stars input[type="radio"].star-4:checked ~ span,
.stars div.star-4.selected ~ span {
  width: 80%;
}
form .stars input[type="radio"].star-3:checked ~ span,
.stars div.star-3.selected ~ span {
  width: 60%;
}
form .stars input[type="radio"].star-2:checked ~ span,
.stars div.star-2.selected ~ span {
  width: 40%;
}
form .stars input[type="radio"].star-1:checked ~ span,
.stars div.star-1.selected ~ span {
  width: 20%;
}

form .stars label,
.stars div {
  display: block;
  width: 30px;
  height: 30px;
  margin: 0!important;
  padding: 0!important;
  text-indent: -999em;
  float: left;
  position: relative;
  z-index: 10;
  background: transparent!important;
  cursor: pointer;
}
form .stars label:hover ~ span,
.stars div:hover ~ span {
  background-position: 0 -30px;
}
form .stars label.star-5:hover ~ span,
.stars div.star-5:hover ~ span {
  width: 100% !important;
}
form .stars label.star-4:hover ~ span,
.stars div.star-4:hover ~ span {
  width: 80% !important;
}
form .stars label.star-3:hover ~ span,
.stars div.star-3:hover ~ span {
  width: 60% !important;
}
form .stars label.star-2:hover ~ span,
.stars div.star-2:hover ~ span {
  width: 40% !important;
}
form .stars label.star-1:hover ~ span,
.stars div.star-1:hover ~ span {
  width: 20% !important;
}
form .stars span,
.stars span {
  display: block;
  width: 0;
  position: relative;
  top: 0;
  left: 0;
  height: 30px;
  background: url("../img/stars.png") repeat-x 0 -60px;
  -webkit-transition: -webkit-width 0.5s;
  -moz-transition: -moz-width 0.5s;
  -ms-transition: -ms-width 0.5s;
  -o-transition: -o-width 0.5s;
  transition: width 0.5s;
}

@media (max-width: 767px) {
	.service-list {
		display: -webkit-box;
	    display: -webkit-flex;
	    display: -ms-flexbox;
	    display: flex;
	-webkit-flex-flow: row wrap;
	    -ms-flex-flow: row wrap;
	        flex-flow: row wrap;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	    -ms-flex-pack: center;
	        justify-content: center;
	}
}



/* Doc Single Profile page Rules
====================================
*/

.doc-profile-wrap,
.dir-profile-wrap {
	margin-bottom: 70px;
	position: relative;
}

.doc-single-profile,
.dir-single-profile {
	padding: 70px 0;
	background-color: #fff;
}

.user-logged-in .doc-single-profile,
.user-logged-in .dir-single-profile {
	padding-bottom: 0;
}

.doc-single-profile .row > .col-md-9,
.dir-single-profile .row > .col-md-9 {
	padding-left: 0;
}

.doc-single-profile .container-fluid > h3,
.dir-single-profile .container-fluid > h3 {
	font-size: 28px;
	line-height: 30px;
	color: #787878;
	margin-top: -40px;
    margin-bottom: 10px;
}

.user-logged-in .doc-single-profile .row > .col-md-3,
.user-logged-in .dir-single-profile .row > .col-md-3 {
	margin-top: -70px;
	background-color: #eaeaea;
	padding-left: 25px;
	padding-right: 25px;
}

.user-logged-in .doc-profile-side,
.user-logged-in .dir-profile-side {
	padding-top: 70px;
}

.doc-profile-header h3,
.dir-profile-header h3 {
	line-height: 30px;
	font-weight: 700;
	color: #616161;
	position: relative;
	margin-bottom: 10px;
}

.user-ip {
	position: absolute;
	top: 50%;
	right: 0;
	background-color: #e3e3e3;
	font-size: 16px;
	line-height: 20px;
	padding: 10px 30px;
	border-radius: 20px;
	color: #7f7f7f;
	font-weight: 400;
	z-index: 1;
}

.user-ip:before {
	content: " ";
	position: absolute;
	top: 50%;
	margin-top: -5px;
	left: -25px;
	width: 0;
	height: 0;
	border-top: 25px solid #e3e3e3;
	border-left: 55px solid transparent;
	z-index: -2;
}

.doc-profile-header p.blue,
.dir-profile-header p.blue {
	font-size: 18px;
	line-height: 20px;
	margin-bottom: 0;
}

.doc-profile-wrap .heading-border-wrap,
.dir-profile-wrap .heading-border-wrap {
	margin: 15px 0;
}


.doc-profile-content,
.dir-profile-content {
	display: table;
	width: 100%;
}

.doc-profile-share-content {
	display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-top: 15px;
	border-top: 1px solid #cacaca;
}

.doc-profile-share-content > h4 {
	min-width: 90px;
	color: #464646;
	font-weight: bold;
	-webkit-align-self: center;
        -ms-flex-item-align: center;
            align-self: center;

}

.doc-profile-share-content > .share-icons {
	width: 100%;
	padding-left: 25px;
}

.share-icons {
	display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-flow: row wrap;
        -ms-flex-flow: row wrap;
            flex-flow: row wrap;
}

.share-icons li {
	list-style: none;
	margin-right: 5px;
	margin-top: 5px;
	margin-bottom: 5px;
	-webkit-align-self: center;
        -ms-flex-item-align: center;
            align-self: center;
}

.share-icons li a {
	display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
	color: #fff;
	text-align: center;
	flex: 1 1 calc(25% - 5px);
	margin: 0 5px;
	padding: 10px;
	min-width: 38px;
}

.share-icons li:last-child {
	margin-right: 0;
}

.share-icons li i {
	color: #fff;
	font-size: 20px;
}

.share-icons li.facebook,
.share-icons li.fb {
	background-color: #3b5999;
}

.share-icons li.twitter {
	background-color: #55acee;
}

.share-icons li.linkedin {
	background-color: #0077B5;
}

.share-icons li.google-plus {
	background-color: #DC4E41;
}

.doc-profile-content .doc-profile-content-single,
.dir-profile-content .dir-profile-content-single {
	display: table-row;
}

.doc-profile-content .doc-profile-content-single .doc-key,
.doc-profile-content .doc-profile-content-single .doc-value,
.dir-profile-content .dir-profile-content-single .dir-key,
.dir-profile-content .dir-profile-content-single .dir-value {
	display: table-cell;
	padding-top: 12.5px;
	padding-bottom: 12.5px;
}

.doc-profile-content .doc-profile-content-single .doc-key,
.dir-profile-content .dir-profile-content-single .dir-key {
	font-size: 18px;
	line-height: 22px;
	color: #464646;
	font-weight: 600;
	text-align: left;
}

.bn-version .doc-profile-content .doc-profile-content-single .doc-key,
.bn-version .dir-profile-content .dir-profile-content-single .dir-key {
	font-size: 20px;
	line-height: 25px;
}

.doc-profile-content .doc-profile-content-single .doc-value,
.dir-profile-content .dir-profile-content-single .dir-value {
	font-size: 14px;
	line-height: 20px;
	color: #7c7c7c;
	padding-left: 25px;
}

.bn-version .doc-profile-content .doc-profile-content-single .doc-value,
.bn-version .dir-profile-content .dir-profile-content-single .dir-value {
	font-size: 16px;
	line-height: 20px;
}

.doc-profile-footer,
.dir-profile-footer {
	display: table;
	width: 100%;
}

.doc-profile-footer-left,
.dir-profile-footer-left {
	display: table-cell;
	vertical-align: middle;
	cursor: pointer;
}

.doc-profile-footer-left ul.rating li,
.dir-profile-footer-left ul.rating li {
	font-size: 30px;
	padding-right: 10px;
}

.doc-profile-footer-left .rating,
.dir-profile-footer-left .rating {
	font-size: 30px;
	padding-left: 0;
	margin-bottom: 0;
}

.doc-profile-footer-left .rating span.star:before,
.dir-profile-footer-left .rating span.star:before {
	padding-right: 10px;
}

.doc-profile-footer-right,
.dir-profile-footer-right {
	display: table-cell;
	vertical-align: middle;
}


.doc-profile-footer-right .btn-right,
.dir-profile-footer-right .btn-right {
	margin-top: 0;
	margin-bottom: 0;
	font-size: 18px;
	line-height: 20px;
}


.doc-profile-side .visit-fee,
.dir-profile-side .visit-fee,
.dir-profile-side .visit-hour {
	background-color: #e0e0e0;
	padding: 15px 25px;
	margin-bottom: 30px;
	font-size: 15px;
	line-height: 20px;
}

.bn-version .doc-profile-side .visit-fee,
.bn-version .dir-profile-side .visit-fee,
.bn-version .dir-profile-side .visit-hour {
	font-size: 18px;
	line-height: 22px;
}

.doc-profile-side .visit-hour {
	background-color: #e0e0e0;
}

.doc-profile-side .visit-hour h3 {
	padding-top: 15px;
}
.doc-profile-side .visit-hour h3,
.doc-profile-side .visit-hour .heading-border-wrap {
	padding-left: 25px;
	padding-right: 25px;
}

.visit-hour .panel-default>.panel-heading {
	border: 0;
	background-color: #e0e0e0;
	padding: 0;
}

.visit-hour .panel-default>.panel-heading a {
	display: block;
	position: relative;
	color: #00baff;
	font-size: 18px;
	line-height: 23px;
	font-weight: 700;
	margin-left: 25px;
	margin-right: 25px;
	padding-top: 15px;
	padding-bottom: 15px;
	border-bottom: 1px solid transparent;
	transition: all linear 0.3s;
}

.visit-hour .panel-default>.panel-heading a:after {
	content: "\f056";
	position: absolute;
	top: 50%;
	margin-top: -11px;
	right: 0;
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: 18px;
    line-height: 22px;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.visit-hour .panel-default>.panel-heading a.collapsed:after {
	content: "\f055";
}

.visit-hour .panel-default>.panel-heading a.collapsed {
	border-bottom: 1px solid #b4b4b4;
	color: #464646;
}

.visit-hour .panel-default:last-child>.panel-heading a {
	border-bottom: 0;
}

.visit-hour .panel-default>.panel-heading+.panel-collapse>.panel-body {
	border-top: 0;
	padding: 25px;
	background-color: #eeefef;
}

.visit-hour .panel-group .panel+.panel {
	border: 0;
	margin-top: 0;
}

.visit-hour .panel-group .panel {
	border: 0;
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	box-shadow: none;
}

.user-logged-in .doc-profile-side .visit-fee,
.user-logged-in .doc-profile-side .visit-hour,
.user-logged-in .dir-profile-side .visit-fee,
.user-logged-in .dir-profile-side .visit-hour {
	background-color: #fff;
}

.doc-visiting-card {
	margin-bottom: 30px;
}

.doc-visiting-card .img-full {
	border: 2px solid #7b7b7b;
}

.doc-visiting-card .btn-grey {
	margin-top: 20px;
	margin-bottom: 0;
}

.doc-visiting-card i {
	margin-left: 20px;
}

.doc-profile-side .visit-fee span,
.dir-profile-side .visit-fee span {
	margin-left: 15px;
}

.doc-profile-side .visit-hour,
.dir-profile-side .visit-hour {
	margin-bottom: 30px;
}

.doc-profile-side h3,
.dir-profile-side h3 {
	font-size: 28px;
	line-height: 35px;
	margin-top: 0;
	margin-bottom: 20px;
	color: #616161;
	font-weight: 400;
}

.visit-hour .heading-border-wrap {
	margin-bottom: 30px;
}

.doc-profile-side .visit-hour .heading-border-wrap {
	margin-bottom: 10px;
}

.doc-contact-side,
.dir-contact-side {
	margin-bottom: 30px;
}

.doc-schedule-list,
.dir-schedule-list {
	padding-left: 0;
	margin-bottom: 0;
	display: table;
	width: 100%;
	border-collapse: collapse;
	/*margin-bottom: 40px;*/
}

.doc-schedule-list li,
.dir-schedule-list li {
	list-style: none;
	border-bottom: 1px solid #b4b4b4;

	display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;

	-webkit-box-pack: justify;
    -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
            justify-content: space-between;

}

.doc-schedule-list li h4,
.dir-schedule-list li h4 {
	display: table-cell;
	font-size: 18px;
	line-height: 23px;
	color: #464646;
	font-weight: 600;
	padding: 20px 0;
	margin: 0;
}

.doc-schedule-list li p,
.dir-schedule-list li p{
	display: table-cell;
	text-align: right;
	color: #7c7c7c;
	padding: 20px 0;
	margin: 0;

	overflow-wrap: break-word;
	word-wrap: break-word;

	-ms-word-break: break-all;
	word-break: break-all;
	word-break: break-word;

	-ms-hyphens: auto;
	-moz-hyphens: auto;
	-webkit-hyphens: auto;
	hyphens: auto;
}

.doc-schedule-list li:first-child h4,
.doc-schedule-list li:first-child p,
.dir-schedule-list li:first-child h4,
.dir-schedule-list li:first-child p {
	padding-top: 0;
}

.doc-schedule-list li:last-child h4,
.doc-schedule-list li:last-child p,
.dir-schedule-list li:last-child h4,
.dir-schedule-list li:last-child p {
	padding-bottom: 0;
}

.doc-schedule-list li:last-child,
.dir-schedule-list li:last-child {
	border-bottom: 0;
}

.doc-contact-side .heading-border-wrap,
.dir-contact-side .heading-border-wrap {
	margin-bottom: 35px;
}

.contact-desc {
	margin-bottom: 20px;
}

.contact-desc.email,
.contact-desc.website {
	font-family: "Open Sans", sans-serif;
	font-size: 16px;
	line-height: 20px;
}

.contact-desc.email i,
.contact-desc.website i {
	top: 0;
}

.contact-desc i {
	position: relative;
	top: 5px;
	color: #a8a8a8;
	width: 45px;
}

.bn-version .contact-desc i {
	font-size: 20px;
	line-height: 25px;
}

.side-social-list-wrap {
	margin-left: -10px;
	margin-right: -10px;
}

.side-social-list {
	padding-left: 0;
	margin-bottom: 0;
	display: table;
	width: 100%;
	border-spacing: 10px 0;
	padding-top: 20px;
}

.side-social-list li {
	list-style: none;
	display: table-cell;
	width: 25%;
	text-align: center;
	height: 55px;
	vertical-align: middle;
}

.side-social-list li a {
	color: #fff;
	display: block;
}

.bn-version .side-social-list li a {
	font-size: 20px;
	line-height: 25px;
}

.side-social-list li.fb {
	background-color: #2980b9;
}

.side-social-list li.twitter {
	background-color: #41d9f2;
}

.side-social-list li.dribble {
	background-color: #e62387;
}

.side-social-list li.pinterest {
	background-color: #e13719;
}

.award-side,
.membership-side{
	margin-bottom: 30px;
}

.doc-profile-tab > .col-md-12,
.dir-profile-tab > .col-md-12 {
	padding: 0;
}

.doc-profile-tab .nav-tabs,
.dir-profile-tab .nav-tabs {
	border-top: 1px solid #dcdcdc;
	border-bottom: 0;
}

.doc-profile-tab .nav-tabs>li,
.dir-profile-tab .nav-tabs>li {
	padding: 0 17.5px;
}

.doc-profile-tab .nav-tabs>li>a,
.dir-profile-tab .nav-tabs>li>a {
	font-size: 25px;
	line-height: 30px;
	color: #666666;
	border-radius: 0;
	border: 0;
	padding: 25px 0;
	border-bottom: 5px solid transparent;
    border-left: 2.5px solid transparent;
    border-right: 2.5px solid transparent;
    -webkit-transition: all linear 0.3s;
    -moz-transition: all linear 0.3s;
    -o-transition: all linear 0.3s;
    transition: all linear 0.3s;
}

.doc-profile-tab .nav>li>a:focus,
.doc-profile-tab .nav>li>a:hover,
.doc-profile-tab .nav>li.active>a,
.doc-profile-tab .nav>li.active>a:focus,
.doc-profile-tab .nav>li.active>a:hover,
.dir-profile-tab .nav>li>a:focus,
.dir-profile-tab .nav>li>a:hover,
.dir-profile-tab .nav>li.active>a,
.dir-profile-tab .nav>li.active>a:focus,
.dir-profile-tab .nav>li.active>a:hover {
	color: #666666;
	border: 0;
	border-bottom: 5px solid #00baff;
    border-left: 2.5px solid transparent;
    border-right: 2.5px solid transparent;
}

.doc-profile-tab .tab-content>.tab-pane,
.dir-profile-tab .tab-content>.tab-pane {
	padding: 30px 25px;
	border: 1px solid #e9e9e9;
	background-color: #fff;
}

.doc-profile-tab .tab-pane p,
.dir-profile-tab .tab-pane p {
	font-size: 14px;
	line-height: 24px;
}

.bn-version .doc-profile-tab .tab-pane p,
.bn-version .dir-profile-tab .tab-pane p {
	font-size: 18px;
	line-height: 22px;
}

.doc-profile-tab .tab-content>.tab-pane .tab-doc-loation,
.dir-profile-tab .tab-content>.tab-pane .tab-doc-loation {
	display: table;
	width: 100%;
}

.doc-profile-tab .tab-content>.tab-pane .tab-doc-loation span,
.dir-profile-tab .tab-content>.tab-pane .tab-doc-loation span {
	display: table-cell;
	vertical-align: middle;
}

.doc-profile-tab .tab-content>.tab-pane .tab-doc-loation span:first-child {
	width: 5%;
}

.doc-profile-tab .tab-content>.tab-pane .tab-doc-loation span:last-child {
	width: 95%;
}

.doc-profile-tab .tab-content>.tab-pane .image-list,
.dir-profile-tab .tab-content>.tab-pane .image-list {
	padding-left: 0;
	margin-bottom: 0;
}

.doc-profile-tab .tab-content>.tab-pane .image-list li,
.dir-profile-tab .tab-content>.tab-pane .image-list li {
	list-style-type: none;
	display: inline-block;
	margin-right: 10px;
}

.doc-profile-tab .tab-content>.tab-pane .image-list li img,
.dir-profile-tab .tab-content>.tab-pane .image-list li img {
	margin: auto;
	margin-bottom: 20px;
}

.doc-profile-tab .tab-content>.tab-pane .image-list li p,
.dir-profile-tab .tab-content>.tab-pane .image-list li p {
	margin-bottom: 0;
	text-align: center;
}

.doc-profile-tab .tab-content>.tab-pane .image-list.right-big-space li,
.dir-profile-tab .tab-content>.tab-pane .image-list.right-big-space li {
	margin-right: 20px;
}


.doc-profile-tab .tab-content>.tab-pane .image-list li:last-child,
.doc-profile-tab .tab-content>.tab-pane .image-list.right-big-space li:last-child,
.dir-profile-tab .tab-content>.tab-pane .image-list li:last-child,
.dir-profile-tab .tab-content>.tab-pane .image-list.right-big-space li:last-child {
	margin-right: 0;
}

.doc-profile-tab .tab-content>.tab-pane h4,
.dir-profile-tab .tab-content>.tab-pane h4 {
	font-size: 18px;
	line-height: 20px;
	margin-top: 0;
	color: #464646;
	font-weight: 600;
	margin-bottom: 30px;
}

.doc-profile-tab .tab-content>.tab-pane .fellowship-list,
.dir-profile-tab .tab-content>.tab-pane .fellowship-list {
	padding-left: 0;
	margin-bottom: 0;
	margin-bottom: 35px;
}

.doc-profile-tab .tab-content>.tab-pane .fellowship-list li,
.dir-profile-tab .tab-content>.tab-pane .fellowship-list li {
	list-style: none;
	font-size: 17px;
	line-height: 20px;
	color: #7c7c7c;
}

.doc-profile-tab .tab-content>.tab-pane .fellowship-list li:first-child,
.dir-profile-tab .tab-content>.tab-pane .fellowship-list li:first-child {
	font-size: 18px;
	font-weight: bold;
}

.share-content {
	text-align: center;
}

.share-content .social-share {
    width: 240px;
    position: absolute;
    left: 50%;
    margin-left: -120px;
}

.share-content input[type="checkbox"] {
	display:none;
}

.share-checkbox:checked + .share-label{
  background:#7B7484;
  color:#231733;
}

.share-checkbox:checked ~ .social-share {
  opacity:1;
  -webkit-transform: scale(1) translateY(-90px);
  -moz-transform:scale(1) translateY(-90px);
  -o-transform:scale(1) translateY(-90px);
  transform:scale(1) translateY(-90px);
    -webkit-transition:.5s;
  -moz-transition:.5s;
  -o-transition:.5s;
  transition:.5s;
}

.share-label {
	font-size:16px;
	cursor:pointer;
	margin:0;
	padding:5px 10px;
	color:#7B7484;
}

.social-share {
  transform-origin:50% 0%;

  -webkit-transform: scale(0) translateY(-190px);
  -moz-transform:scale(0) translateY(-190px);
  -o-transform:scale(0) translateY(-190px);
  transform:scale(0) translateY(-190px);

  opacity:0;
}

.social-share ul {
  position:relative;
  left:0;
  right:0;
  margin:auto;
  color:#fff;
  height:46px;
  width:240px;
  background:#e0e0e0;
  padding:0;
  list-style:none;
}

.social-share ul li a{
  font-size:20px;
  color: #fff;
  width:60px;
  margin:0;
  padding:12px 0;
  text-align:center;
  float:left;
  display:block;
  height:46px;
}

/*.social-share ul li a {
	display: block;
    width: 60px;
    height: 14px;
}*/

.socila-share ul li a:hover {color:rgba(0,0,0,.5);}

.social-share ul:after {
	content:'';
	display:block;
	position:absolute;
	left:0;
	right:0;
	margin:35px auto;
	height:0;
	width:0;
	border-left: 20px solid transparent;
	border-right: 20px solid transparent;
	border-top: 20px solid #e0e0e0;
	z-index: -1;
}

.social-share li a[class*="twitter"] {background:#55acee;padding:12px 0;}
.social-share li a[class*="gplus"] {background:#E34429;padding:12px 0;}
.social-share li a[class*="share-facebook"] {background: #3a5795; padding: 12px 0;}
.social-share li a[class*="share-linkedin"] {background: #0177B5; padding: 12px 0;}

@media (min-width: 992px) {
	.dir-profile-header,
	.doc-profile-header {
		display: -webkit-box;
	    display: -webkit-flex;
	    display: -ms-flexbox;
	    display: flex;
	    -webkit-box-pack: justify;
	    -webkit-justify-content: space-between;
	        -ms-flex-pack: justify;
	            justify-content: space-between;
	}

	.dir-profile-header-wrap,
	.doc-profile-header-wrap {
		width: 85%;
	}

	.share-content {
		width: 15%;
		position: relative;
	}
}

@media (max-width: 991px) {
	.doc-profile-wrap,
	.dir-profile-wrap {
		margin-bottom: 30px;
	}

	.doc-profile-wrap .col-md-5 img,
	.dir-profile-wrap .col-md-5 img{
		display: table;
		margin: auto;
		margin-bottom: 20px;
	}

	.doc-profile-header h3,
	.dir-profile-header h3,
	.doc-profile-header p.blue,
	.dir-profile-header p.blue {
		text-align: center;
	}

	.doc-profile-header p.blue,
	.dir-profile-header p.blue {
		margin-bottom: 35px;
	}

	.doc-profile-footer,
	.dir-profile-footer,
	.doc-profile-footer-left,
	.dir-profile-footer-left {
		display: block;
	}

	.doc-single-profile .row > .col-md-9, .dir-single-profile .row > .col-md-9 {
		padding-left: 15px;
	}
}

@media (max-width: 767px) {
	.doc-profile-share-content {
		display: block;
	}

	.doc-profile-tab .tab-pane p,
	.dir-profile-tab .tab-pane p {
		margin-bottom: 10px;
	}

	.doc-profile-tab .tab-content>.tab-pane .tab-doc-loation span:first-child,
	.dir-profile-tab .tab-content>.tab-pane .tab-doc-loation span:first-child {
		width: 20%;
	}

	.doc-profile-tab .tab-content>.tab-pane .tab-doc-loation span:last-child,
	.dir-profile-tab .tab-content>.tab-pane .tab-doc-loation span:last-child {
		width: 80%;
	}

	.dir-single-features h4 {
		display: block;
	}

	.dir-single-features .checker-list-inline {
		display: block;
	}

	.nav-tabs {
		display: -webkit-box;
	    display: -webkit-flex;
	    display: -ms-flexbox;
	    display: flex;
	}
}


@media (max-width: 420px) {
	.doc-profile-tab .nav-tabs>li,
	.dir-profile-tab .nav-tabs>li {
		padding: 0 10px;
	}

	.doc-profile-tab .nav-tabs>li>a,
	.dir-profile-tab .nav-tabs>li>a {
		font-size: 18px;
		line-height: 22px;
		padding: 10px 0;
	}
}

/* Hospital Directory Page Rules
===================================
*/

.dir-single-search-wrap {
	padding: 60px;
}

.dir-single-wrap {
	margin-bottom: 25px;
}

.dir-single-wrap:last-child {
	margin-bottom: 0;
}


.dir-single-wrap-inner {
	border: 1px solid #e4e4e4;
}

.dir-single-wrap-inner-left{
	padding-left: 0;
}


.dir-single-wrap-inner-left {
	padding: 25px;
}

.dir-single-wrap-inner-right {
	padding: 0 30px;
	background-color: #f5f5f5;
	border-left: 1px solid #e4e4e4;
}

.dir-single-wrap-inner-right p {
	margin-bottom: 10px;
}

.dir-contact-info {
	position: relative;
	top: 50%;
}

.dir-contact-info .btn {
	background-color: transparent;
}

.dir-contact-info .btn:hover {
	background-color: #00baff;
}

.dir-doc-info i {
	display: table-cell;
	vertical-align: middle;
	width: 35px;
	padding-right: 10px;
}

.bn-version .dir-doc-info i {
	font-size: 25px;
	line-height: 30px;
}

.dir-doc-info span.dir-cell {
	display: table-cell;
	vertical-align: middle;
}

.dir-doc-info span.dir-cell .time{
	display: block;
}


.dir-contact-info .btn-grey {
	margin-bottom: 0;
}

.dir-single-wrap-inner-left .col-md-3,
.dir-single-wrap-inner-left .col-md-9 {
	padding-left: 0;
}
.dir-single-wrap-inner-left .col-md-9 {
	padding-right: 0;
}

.dir-single-wrap-inner-left h2 {
	color: #505050;
	font-size: 24px;
	line-height: 30px;
	margin-top: 0;
	font-weight: 600;
	margin-bottom: 0;
	-moz-transition: all ease 0.3s;
	-webkit-transition: all ease 0.3s;
	-ms-transition: all ease 0.3s;
	transition: all ease 0.3s;
}

.dir-single-wrap-inner-left h2:hover {
	color: #00baff;
}

.dir-single-wrap-inner-left p {
	margin-bottom: 10px;
}

.dir-single-wrap-inner-left p.location-addr i {
	padding-right: 15px;
}

.dir-single-wrap-inner-left p.location-addr i,
.dir-single-wrap-inner-left p.location-addr span {
	display: table-cell;
	vertical-align: top;
}

.bn-version .dir-single-wrap-inner-left p.location-addr span {
	font-size: 18px;
	line-height: 22px;
	vertical-align: middle;
}

.dir-single-wrap-inner-left .rating {
	margin-bottom: 30px;
}

.dir-address-wrap {
	display: table;
	width: 100%;
}

.dir-address-left,
.dir-address-right {
	display: table-cell;
	vertical-align: top;
}

.dir-address-left {
	width: 30%;
	padding-right: 10px;
}

.dir-address-right {
	width: 70%;
}

.category-form-filter {
	position: relative;
}

.category-form-filter .btn-default {
	margin-bottom: 5px;
	text-align: left;
	position: relative;
	font-size: 20px;
	line-height: 26px;
	font-weight: 600;
	height: 60px;
	padding: 10px;
}

.bn-version .category-form-filter .btn-default {
	font-weight: bold;
	padding-left: 25px;
}

.category-form-filter .btn-default::after {
	content: "\f107";
	position: absolute;
	top: 0;
	right: 30px;
	display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: 25px;
    font-weight: 600;
    line-height: 55px;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.category-form-filter.open .btn-default::after {
	content: "\f106";
}


.category-form-filter .appointment-filter-inner {
	width: 100%;
	padding: 5px 30px;
}

.category-form-filter .appointment-filter-inner.accordion-filter {
	padding: 0;
}

.category-form-filter .btn-default.active.focus,
.category-form-filter .btn-default.active:focus,
.category-form-filter .btn-default.active:hover,
.category-form-filter .btn-default:active.focus,
.category-form-filter .btn-default:active:focus,
.category-form-filter .btn-default:active:hover,
.category-form-filter.open>.dropdown-toggle.btn-default.focus,
.category-form-filter.open>.dropdown-toggle.btn-default:focus,
.category-form-filter.open>.dropdown-toggle.btn-default:hover {
	color: #fff;
	background-color: #00baed;
	border-color: #fff;
}

.appointment-filter-list {
	border: 0;
}

.appointment-filter-inner .area-filter-list li {
	list-style: none;
}

/*.category-form-filter .filter- {
	width: 100%;
}*/


@media ( max-width: 991px ) {
	.dir-contact-info {
		top: auto;
		padding: 20px 0;
	}

	.dir-address-wrap {
		display: block;
	}

	.dir-address-left,
	.dir-address-right {
		display: block;
		width: 100%;
	}

	.dir-single-search-wrap {
		padding: 60px 0;
	}
}

@media ( min-width: 768px ) {
	.category-form-filter:hover .btn-default::after {
		content: "\f106";
	}
}

@media ( max-width: 767px ) {
	.dir-single-wrap-inner-left h2 {
		text-align: center;
	}

	.dir-single-wrap-inner-left p {
		text-align: center;
	}

	.dir-single-wrap-inner-left .rating {
		text-align: center;
	}

	.dir-single-wrap-inner-left p.location-addr {
		display: table;
		margin: auto;
	}

	.fileinput.input-group {
		width: 100%;
	}

	.fileinput-new.input-group .btn-file,
	.fileinput-new .input-group .btn-file,
	.fileinput.input-group>.btn-file {
		margin: 0;
		width: 100%;
		border-radius: 0;
	}
}

/* Doc Appointment Page Rules
==============================
*/
.appointment-form-head {
	padding: 70px 0;
	background-color: #f0f3f4;
	position: relative;
}

.appointment-progressbar {
	padding-left: 0;
	display: table;
	margin: auto;
}

.appointment-progressbar li {
	list-style: none;
	display: table-cell;
	position: relative;
	width: 33.333%;
	z-index: 10;
}


.appointment-progressbar li::after {
	content: " ";
	position: absolute;
	top: 35px;
	left: 50%;
	width: 100%;
	border-bottom: 2px dashed #e6e6e6;
	z-index: -1;
}

.appointment-progressbar li:last-child::after {
	border-bottom: 0;
}

.appointment-progressbar li img {
	margin-bottom: 10px;
}

.appointment-progressbar p {
	margin-bottom: 0;
	text-align: center;
	color: #6e6e6e;
	font-weight: 600;
	font-size: 14px;
	line-height: 20px;
}

.appointment-progressbar p span {
	font-weight: 400;
}

.appointment-form-content {
	padding: 100px 0;
	background-color: #fff;
}

.form-select-wrap {
	display: table;
	width: 70%;
	margin: auto;
}

.form-select-wrap .form-group {
	display: table-cell;
	width: 33.33%;
	padding: 0 5px;
}

.form-select-wrap .form-group .form-control {
	background-color: #cccccc;
	color: #6c6c6c;
	font-weight: 600;
}

.form-select-wrap .form-group .form-down-angle {
	color: #6c6c6c;
}

/*.select2-container {
	cursor: pointer;
	display: block;
}*/

.select2-container {
	/*border: 1px solid transparent;*/
	border-radius: 0;
	position: relative;
}

.bn-version .select2-container  {
	font-size: 18px;
	line-height: 22px;
}

.category .select2-container::after {
	content: "\f107";
	position: absolute;
	top: 0;
	right: 0;
	color: #fff;
	display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 25px;
    font-weight: bold;
    right: 30px;
    line-height: 60px;
}

.category .cat-location-search + .select2-container::before {
	content: "\f041";
	position: absolute;
	top: 0;
	left: 15px;
	width: 15px;
	color: #fff;
	display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 25px;
    font-weight: bold;
    right: 30px;
    line-height: 60px;
}

.category .cat-location-search + .select2-container .select2-selection__rendered {
	padding-left: 35px;
}

.category .select2-container--open.select2-container::after {
	content: "\f106";
}

.category span.select2-selection.select2-selection--single {
	transition: all linear 0.3s;
}

.category .select2-container:hover span.select2-selection.select2-selection--single {
	background-color: #00baed;
	border-color: #fff;
}

span.select2-selection.select2-selection--single {
	border: 1px solid #e9e9e9;
	outline: 0;
}

.select2-container--open span.select2-selection.select2-selection--single {
	border-color: #00baff;
}


.select2-container--open .select2-dropdown--below,
.select2-container--open .select2-dropdown--above {
	border: 1px solid #00baff;
    -webkit-box-shadow: 0 6px 12px rgba(0,0,0,.175);
    box-shadow: 0 6px 12px rgba(0,0,0,.175);
}

.select2-container--open .select2-dropdown--below {
	border-top: 0;
}

.select2-container--open .select2-dropdown--above {
	border-bottom: 0;
}

.select2-results__option {
	padding: 15px 6px;
}

.select2-container--default .select2-search--dropdown .select2-search__field {
	border-color: #00baff;
	padding: 10px;
	outline: 0;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
	background-color: #00baff;
}

.select2-container--default .select2-selection--multiple {
	border-radius: 0;
	border-color: transparent;
	background-color: #eeeeee;
	-webkit-transition: all ease 0.3s;
	-moz-transition: all ease 0.3s;
	-ms-transition: all ease 0.3s;
	transition: all ease 0.3s;
}


.single-main-content .select2-container--default .select2-selection--multiple {
	padding: 30px 15px;
}

.select2-container--default.select2-container--focus .select2-selection--multiple {
	border-color: #00baff;
}

.doc-search .select2-container .select2-selection--single,
.dir-search .select2-container .select2-selection--single {
	height: 67px;
}

.select2-container .select2-selection--single {
	height: 48px;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
	line-height: 47px;
	padding-left: 25px;
	color: #6c6c6c;
	background-color: #e9e9e9;
}

.category .select2-container--default .select2-selection--single .select2-selection__rendered {
	line-height: 60px;
	font-size: 20px;
	background-color: transparent;
	color: #fff;
}

.meet-doc .select2-container--default .select2-selection--single .select2-selection__rendered {
	line-height: 65px;
}

.category .select2-container--default .select2-selection--single {
	height: 60px;
	background-color: transparent;
	color: #fff;
	border: 2px solid #fff;
}

.category .select2-container--default .select2-selection--single .select2-selection__placeholder {
	color: #fff;
}

/*.meet-doc .select2-container--default .select2-selection--single .select2-selection__placeholder {
	line-height: 66px;
}*/

/*.select2-container--default .select2-selection--single {
	height: 67px;
}*/

.select2-hidden-accessible {
	display: none;
}


.select2-container--open .select2-container--default .select2-selection--single {
	background-color: #00baff;
}

.select2-container--default .select2-selection--single {
	border: 0;
	border-radius: 0;
    color: #6c6c6c;
    font-weight: 600;

}


.select2-container--default .select2-selection--single .select2-selection__arrow {
	display: none;
}

.select2-container--default .select2-selection--multiple .select2-selection__rendered {
	cursor: pointer;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice {
	border: 0;
	border-radius: 0;
	background-color: #00baff;
	color: #fff;
	font-weight: 600;
}

.single-main-content .select2-container--default .select2-selection--multiple .select2-selection__choice {
	padding: 10px 20px;
	margin-right: 20px;
}

span.select2-selection__choice__remove {
	float: right;
    color: #fff !important;
    margin-left: 10px;
}

.select2-container--open {
	border-radius: 0;
	background-color: #00baff;
}

.select2-dropdown {
	border-radius: 0;
	border: transparent;
}


.bn-version .select2-container .select2-search--inline .select2-search__field {
	font-size: 18px;
}

.select2-container .select2-selection--multiple .select2-selection__rendered {
	text-overflow: clip;
    white-space: normal;
}

::-webkit-input-placeholder {
   color: #6e6e6e;
}

:-moz-placeholder { /* Firefox 18- */
   color: #6e6e6e;
}

::-moz-placeholder {  /* Firefox 19+ */
   color: #6e6e6e;
}

:-ms-input-placeholder {
   color: #6e6e6e;
}

/*.select2-container--open .select2-dropdown--below {
	z-index: 9999;
}*/


/* Food & Nutrition Page Rules
=================================
*/

.random-food-title,
.food-side-title {
	font-size: 16px;
	line-height: 20px;
	color: #777777;
	font-weight: 600;
	margin-top: 0;
	margin-bottom: 0;
}

.top-blue-bar {
	border-top: 6px solid #00baed;
	display: table;
	padding: 10px 20px;
}

/*.food-side-title {
	padding-left: 20px;
}*/

.food-hr-bar {
	margin-top: 25px;
	margin-bottom: 25px;
	border-color: #e9edf1;
}

.food-thumbnail-wrap {
	padding: 35px 0;
}

.food-thumbnail-wrap .heading-border-wrap {
	margin: 20px 0;
}

.food-thumbnail .thumbnail {
	padding-top: 0;
	padding-bottom: 0;
	padding-left: 0;
	padding-right: 0;
	margin-bottom: 35px;
	background-color: #fff;
	box-shadow: 1px 1px 3px #ccc;
	border-bottom-right-radius: 5px;
	border-bottom-left-radius: 5px;
}

.food-thumbnail .thumbnail>img {
	width: 100%;
	border: 0;
	border-radius: 0;
	padding: 0;
	margin-bottom: 0;
}

.food-thumbnail .caption,
.video-caption {
	display: table;
	width: 100%;
	padding: 20px;
}

.food-thumbnail .caption-left,
.video-caption-left {
	display: table-cell;
	vertical-align: middle;
}

.food-thumbnail .caption-left h4 {
	font-size: 15px;
	line-height: 20px;
	margin-top: 0;
	margin-bottom: 0;
	font-weight: 700;
	color: #787878;
}

.bn-version .food-thumbnail .caption-left h4 {
	font-size: 18px;
	line-height: 22px;
}

.video-caption-left h4 {
	font-size: 15px;
	color: #9298a4;
	line-height: 20px;
	font-weight: 400;
	margin-bottom: 0;
	margin-top: 0;
}

.video-caption-left p {
	font-size: 13px;
	line-height: 15px;
	color: #abb0b9;
	margin-bottom: 0;
}


.video-caption-right span.video-views {
	background-color: #eaedf1;
	padding: 5px 10px;
	border-radius: 15px;
	margin-right: 10px;
}

.food-thumbnail .caption-right,
.video-caption-right {
	display: table-cell;
	vertical-align: middle;
	text-align: right;
}

.caption-right .btn-right {
	margin: 0;
	padding: 0 15px;
	display: table;
	margin-left: auto;
}

.caption-right .btn-right {
	background-color: #777777;
	color: #fff;
}

.video-caption-right p {
	margin-bottom: 0;
}

.video-caption-right i {
	color: #f77462;
}

p.food-cat {
	margin-bottom: 0;
	font-size: 13px;
	line-height: 20px;
	color: #9e9e9e;
}

p.food-cal {
	margin-bottom: 0;
	text-align: right;
	color: #00baff;
	font-weight: 700;
}

p.food-cal i {
	margin-right: 5px;
}

aside .food-form {
	margin-bottom: 25px;
}

aside .food-form .btn-blue {
	margin-bottom: 0;
}

aside .food-form>.form-horizontal {
	background-color: #fbfbfb;
}


.form-table {
	display: block;
	width: 100%;
	margin: auto;
}

.form-table p {
	margin-bottom: 0;
}

.bn-version .form-table p {
	font-size: 18px;
    font-weight: normal;
    height: 35px;
    padding: 10px 15px;
}

.form-submit-text {
	display: none;
}

.has-success .form-submit-text {
	display: block;
	font-weight: 700;
	color: #3c763d;
}

.has-warning .form-submit-text {
	display: block;
	font-weight: 700;
	color: #8a6d3b;
}

.has-error .form-submit-text {
	display: block;
	font-weight: 700;
	color: #a94442;
}

.form-row,
.form-row-inline {
	display: block;
	width: 100%;
}

.form-row-inline .form-cell-left,
.form-row-inline .form-cell-right {
	position: relative;
	display: inline-block;
	width: 49%;
}

.required-asterisk::after {
	content: "*";
	position: relative;
	color: #e46250;
	font-size: 16px;
	line-height: 20px;
	padding-left: 5px;
}

.ft-text {
	position: absolute;
	top: 50%;
	margin-top: -10px;
	padding: 0 15px;
	border-right: 1px solid #ccc;
	color: #6e6e6e;
	font-weight: 600;
}

.bn-version .ft-text {
	margin-top: -18px;
	padding-top: 2px;
	padding-bottom: 2px;
}

.form-row-inline .form-cell-left {
	padding-right: 5px;
}

.form-row-inline .form-cell-right {
	padding-left: 5px;
}

.form-cell-left,
.form-cell-right {
	display: inline-block;
	vertical-align: middle;
	padding: 5px 0;
}

.form-cell-left {
	width: 39%;
}

.form-cell-right {
	width: 59%;
}

.form-cell-right select,
.form-cell-left select,
.form-row select {
	cursor: pointer;
	text-indent: 0.01px;
    text-overflow: '';
	-webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.form-cell-right .form-control,
.form-cell-left .form-control,
.form-row .form-control {
	height: 35px;
    padding: 6px 12px;
    padding-right: 40px;
    background-color: #eeeeee;
    color: #6e6e6e;
    font-size: 13px;
}

.bn-version .form-cell-right .form-control,
.bn-version .form-cell-left .form-control,
.bn-version .form-row .form-control {
	font-size: 18px;
}

.form-ft .form-cell-right .form-control,
.form-ft .form-cell-left .form-control {
	padding-right: 6px;
	padding-left: 60px;
}

.form-cell-right .form-down-angle,
.form-cell-left .form-down-angle,
.form-row .form-down-angle {
	padding-right: 0;
	margin-right: 0;
	text-align: center;
	width: 40px;
	height: 35px;
	margin-top: -35px;
	padding-top: 4px;
	border-left: 1px solid #ccc;
	cursor: pointer;
}

/*.select2-container + .form-down-angle {
	height: 33px;
}

.bn-version .select2-container + .form-down-angle {
	height: 38px;
}*/


.form-cell-right-inner-wrap {
	display: table;
	width: 100%;
}

.form-cell-right-left,
.form-cell-right-right {
	display: table-cell;
}

.form-cell-right-left {
	padding-right: 5px;
}

.form-cell-left h4,
.form-cell-right h4,
.form-row-inline h4,
.form-row h4 {
	margin-top: 0;
	margin-bottom: 0;
	font-size: 13px;
	line-height: 20px;
	color: #6e6e6e;
	font-weight: 700;
}

.bn-version .form-cell-left h4,
.bn-version .form-cell-right h4,
.bn-version .form-row-inline h4,
.bn-version .form-row h4 {
	font-size: 18px;
	line-height: 22px;
	font-weight: normal;
}

.form-row > h4 {
	margin-top: 5px;
	margin-bottom: 10px;
}

.form-row:last-child > h4 {
	margin-top: 10px;
}


.form-row-inline .form-cell-left h4,
.form-row-inline .form-cell-right h4 {
	margin-bottom: 10px;
	margin-top: 0;
}

.form-horizontal .checkbox,
.form-horizontal .checkbox-inline,
.form-horizontal .radio,
.form-horizontal .radio-inline {
	padding-top: 0;
}

.form-table input[type=radio],
.food-radio-wrap input[type=radio] {
	width: 15px;
	height: 15px;
	top: 0;
	left: 0;
}

.form-table input[type=radio]::after,
.food-radio-wrap input[type=radio]::after {
	content: " ";
	position: absolute;
	top: 0;
	left: 0;
	width: 15px;
	height: 15px;
	border-radius: 50%;
	border: 1px solid #c1c1c1;
	background: #fff;
	-moz-transition: all linear 0.3s;
	-webkit-transition: all linear 0.3s;
	-ms-transition: all linear 0.3s;
	transition: all linear 0.3s;
}

.form-table input[type=radio]:checked::after,
.food-radio-wrap input[type=radio]:checked::after {
	content: " ";
	position: absolute;
	top: -2px;
	left: -3px;
	width: 20px;
	height: 20px;
	background-color: #f77462;
	border: 5px solid #fdd5d0;
}

.radio-inline-wrap {
	display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
            justify-content: space-between;
}


.radio-inline-wrap .radio-inline > input[type="radio"] {
	margin-right: 10px;
}

.radio-inline-wrap .radio-inline span.label-text {
	margin-left: 10px;
}

.bn-version .radio-inline-wrap .radio-inline {
	font-size: 18px;
	line-height: 22px;
}

/*.radio-inline-wrap .radio-inline:last-child {
	text-align: right;
}*/

.video-img {
	margin-top: 15px;
}

.nutrition-wrap {
	border-bottom: 1px solid #e4e4e4;
	background-color: #f4f4f4;
}

.nutrition-wrap .tab-content {
	padding: 20px;
}

.food-radio-wrap {
	display: table;
	width: 100%;
	margin-bottom: 25px;
}

.food-radio-wrap .radio-inline {
	display: table-cell;
}

.disease-benefit-list li {
	list-style: none;
}

.disease-benefit-list li h4 {
	font-size: 15px;
	line-height: 20px;
	font-weight: 400;
	color: #000;
	margin-bottom: 5px;
}

.bn-version .disease-benefit-list li h4 {
	font-size: 18px;
	line-height: 22px;
}

.disease-benefit-list li p {
	margin-bottom: 25px;
}

.disease-benefit-list li:last-child p {
	margin-bottom: 0;
}

@media ( max-width: 767px ) {
	.food-thumbnail .caption,
	.video-caption {
		display: block;
	}

	.food-thumbnail .caption-left,
	.video-caption-left,
	.food-thumbnail .caption-right,
	.video-caption-right {
		display: block;
		text-align: center;
	}

	.video-caption-left {
		margin-bottom: 20px;
	}


	.food-thumbnail .caption-right p.food-cal,
	.video-caption-right p.food-cal {
		text-align: center;
	}

	.food-thumbnail .caption-left h4,
	.bn-version .food-thumbnail .caption-left h4 {
		margin-bottom: 10px;
	}

	p.food-cal i {
		display: block;
		margin-bottom: 5px;
	}


}

/* Diet Plan Page Rules
============================
*/

.diet-plan {
	padding: 35px 0;
}

.diet-plan .single-main-content {
	margin-top: 20px;
}

.diet-chart-wrap {
	background-color: #e7e7e7;
	padding: 30px 20px;
	border-bottom: 1px solid #e4e4e4;
}

.diet-chart-wrap h3 {
	line-height: 33px;
}

#diet-chart {
	display: table;
	margin-left: auto;
	margin-right: auto;
	margin-top:	50px;
}

#diet-legend-wrap {
	margin-top: 50px;
}

.diet-legend {
	padding-left: 0;
	margin-bottom: 0;
	display: table;
	width: 100%;
	margin: auto;
}

.diet-legend li {
	list-style: none;
	display: block;
	width: 100%;
	padding: 5px 0;
}

.diet-legend p {
	margin-bottom: 0;
	display: inline-block;
}

.legend-block-outer {
	display: block;
}

.legend-color-block {
	width: 10px;
	height: 10px;
	border-radius: 50%;
	margin-right: 10px;
}

.left-legend-block,
.right-legend-block {
	display: inline-block;
	vertical-align: middle;
}

.left-legend-block {
	width: 60%;
}

.left-legend-block p {
	font-size: 13px;
}

.bn-version .left-legend-block p {
	font-size: 16px;
}

.right-legend-block {
	text-align: right;
	width: 40%;
}

.diet-table {
	font-size: 11px;
	margin-bottom: 25px;
}

.diet-table thead {
	background-color: #e0e9f0;
	border: 1px solid #e0e9f0;
}

.diet-table tbody {
	border-left: 1px solid #dfdcdc;
	border-bottom: 1px solid #dfdcdc;
	border-right: 1px solid #dfdcdc;
}

.diet-table thead>tr>th {
	text-align: center;
}

.diet-table td i {
	font-size: 17px;
	line-height: 20px;
	margin-right: 5px;
}

.diet-table td a:last-child i {
	margin-right: 0;
}

.diet-plan-desc-wrap {
	margin-bottom: 30px;
}


.diet-desc,
.diet-plan-desc {
	padding: 20px;
	border: 1px solid #e9e9e9;
}

.diet-plan-desc p {
	margin-bottom: 0;
	font-size: 22px;
	line-height: 30px;
	color: #777777;
	font-weight: 700;
}

.diet-desc h3 {
	color: #777777;
	font-size: 20px;
	line-height: 30px;
}

.diet-desc .diet-hr {
	margin-top: 20px;
	margin-bottom: 20px;
}

.diet-desc p {
	margin-bottom: 0;
	text-align: justify;
}


.pdf .meal-cal-visual-left {
	width: 100%;
	-webkit-box-pack: end;
    	-webkit-justify-content: flex-end;
    	    -ms-flex-pack: end;
    	        justify-content: flex-end;
}

.pdf .meal-cal-visual-left p {
	border-left: 2px solid #ccc;
	padding-left: 20px;
	padding-top: 10px;
	padding-bottom: 10px;
	font-size: 10px;
}


.meal-cal-visual-right {
	position: relative;
	height: 7px;
	top: 7px;
}

.calorie {
	position:relative;
	display:block;
	width:100%;
	background:#eee;
	height:7px;
	border-radius:5px;
	-moz-border-radius:5px;
	-webkit-border-radius:5px;
	-webkit-transition:0.4s linear;
	-moz-transition:0.4s linear;
	-ms-transition:0.4s linear;
	-o-transition:0.4s linear;
	transition:0.4s linear;
	-webkit-transition-property:width, background-color;
	-moz-transition-property:width, background-color;
	-ms-transition-property:width, background-color;
	-o-transition-property:width, background-color;
	transition-property:width, background-color;
}

.calorie-bar {
	height:7px;
	width:0px;
	background:#6adcfa;
	border-radius:5px;
	-moz-border-radius:5px;
	-webkit-border-radius:5px;
}

.skill-bar-percent {
	position:absolute;
	right:10px;
	top:0;
	font-size:11px;
	height:35px;
	line-height:35px;
	color:#ffffff;
	color:rgba(0, 0, 0, 0.4);
}

/* Diet Plan Table Old table rules */

/*.diet-plan-table {
	border-collapse: separate;
	border-spacing: 0 22px;
}

.diet-plan-table .btn {
	margin-bottom: 0;
	font-size: 14px;
	font-weight: 700;
	padding-top: 3px ;
	padding-bottom: 3px;
	border-radius: 5px;
}

.diet-plan-table>tbody>tr>td {
	vertical-align: middle;
	padding: 0 20px;
}

.diet-plan-table>tbody>tr>td span {
	border: 0;
	padding: 5px 0;
}


.diet-plan-table>tbody>tr>td span.food-name,
.diet-plan-table>tbody>tr>td span.food-amnt,
.diet-plan-table>tbody>tr>td span.food-cal {
	font-size: 11px;
	line-height: 15px;
	color: #6e6e6e;
	font-weight: 700;
	display: block;
	width: 100%;
}

.diet-plan-table>tbody>tr>td.diet-bar-wrap {
	padding: 0 10px;
}

.diet-plan-table>tbody>tr>td span.food-cal-left {
	font-size: 11px;
	line-height: 15px;
	color: #9eb0c3;
	float: left;
	padding: 0 10px;
}*/

/*Diet Plan Table updated Rules */

.diet-plan-table {
	padding: 25px 0;
}


.diet-plan-table p {
	margin-bottom: 0;
}

.diet-plan-table ul li {
	list-style: none;
	font-size: 13px;
	line-height: 20px;
	color: #6e6e6e;
	font-weight: 600;
}

.bn-version .diet-plan-table ul li {
	font-size: 15px;
	line-height: 20px;
}

.pdf .diet-plan-table ul li {
	font-size: 10px;
}

.pdf .diet-plan-table .meal-food-list {
	width: 100%;
}

ul.meal-food-list li {
	display: inline-block;
	padding: 10px;
}

ul.meal-food-list:first-child li {
	padding-top: 0;
}

ul.meal-food-list:last-child li {
	padding-bottom: 0;
}

ul.meal-food-list li:first-child {
	padding-left: 0;
}

.diet-plan-table ul li a {
	font-size: 15px;
	line-height: 20px;
	margin-left: 5px;
}

.meal-food-cal-list-wrap {
	border-right: 2px solid #dce3e9;
}

.meal-title h4 {
	margin: 0;
	font-weight: 700;
	color: #616161;
}

.pdf .meal-title h4 {
	font-size: 15px;
}

.meal-title h4 i {
	font-size: 25px;
	line-height: 30px;
	position: relative;
	top: 2px;
	text-align: center;
}

.bar {
	position: relative;
	top: 5px;
	height:7px;
	width: 160px;
	border-radius:10px;
	background-color: #dae1e8;
	float: left;
	margin: 0 10px;
}

.bar div {
	height: 100%;
	width: 20%;
	background-color: #ff4c79;
	border-radius: 10px;
}

/*.coffee {
	width:100%;
	-moz-animation:graphic-design 2s ease-out;
	-webkit-animation:graphic-design 2s ease-out;
	background-color: #f674a4;

}*/

.food-cal-wrap {
	border-right: 1px solid #ccc;
	padding-right: 20px;
}

span.food-cal i {
	font-size: 13px;
    line-height: 15px;
    top: 2px;
    position: relative;
    margin-left: 5px;
}

.food-title i,
.food-title h4 {
	display: table-cell;
}

/*.food-title {
	width: 10%;
}*/

.food-title i {
	font-size: 25px;
	line-height: 30px;
	padding-right: 30px;
	position: relative;
	top: 3px;
	color: #4c5e70;
}

.food-title h4 {
	font-size: 15px;
	line-height: 20px;
	color: #4c5e70;
	font-weight: 700;
}

@page {
  size: A4;
  margin: 0;
}

/*.diet-plan-details.pdf {
	page-break-before: always;
	page-break-after: always;
}*/


.pdf-table>tbody>tr>td {
	vertical-align: middle;
	text-align: left;
	padding-left: 0;
}

.pdf-table>tbody>tr {
	border-bottom: 1px solid #ccc;
}

.pdf-table>tbody>tr:last-child {
	border-bottom: 0;
}

.pdf-table>tbody>tr>td:last-child {
	text-align: right;
}

.pdf-table>tbody>tr>td:last-child span {
	border-top: 0;
	border-right: 0;
	border-bottom: 0;
	border-radius: 0;
	border-left: 2px solid #ccc;
	padding-right: 0;
	padding-left: 20px;
}

.diet-plan-table #_1 h4 {
	background-image: url(../img/food/breakfast.png);
	background-repeat: no-repeat;
	padding-left: 30px;
}

.diet-plan-table #_2 h4 {
	background-image: url(../img/food/mid-breakfast.png);
	background-repeat: no-repeat;
	padding-left: 30px;
}

.diet-plan-table #_3 h4 {
	background-image: url(../img/food/lunch.png);
	background-repeat: no-repeat;
	padding-left: 30px;
}

.diet-plan-table #_4 h4 {
	background-image: url(../img/food/afternoon.png);
	background-repeat: no-repeat;
	padding-left: 30px;
}

.diet-plan-table #_5 h4 {
	background-image: url(../img/food/dinner.png);
	background-repeat: no-repeat;
	padding-left: 30px;
}

.diet-plan-table #_6 h4 {
	background-image: url(../img/food/dinner-night.png);
	background-repeat: no-repeat;
	padding-left: 30px;
}


@media(min-width: 992px) {
	.diet-plan-table .diet-plan-row {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 100%;
		padding: 25px 0;
		border-bottom: 1px solid #e2e7eb;
	}

	.diet-plan-table .diet-plan-row:first-child {
		padding-top: 0;
	}
	/*
	.diet-plan-table .diet-plan-row:last-child {
		padding-bottom: 0;
		border-bottom: 0;
	}*/

	.diet-plan-table .meal-title,
	.diet-plan-table .meal-food-list-wrap,
	.diet-plan-table .meal-food-amnt-list-wrap,
	.diet-plan-table .meal-food-cal-list-wrap,
	.diet-plan-table .meal-cal-visual-wrap,
	.diet-plan-table .diet-plan-btn {
		padding: 0;
		display: -webkit-inline-box;
		display: -webkit-inline-flex;
		display: -ms-inline-flexbox;
		display: inline-flex;
		-webkit-align-self: center;
		    -ms-flex-item-align: center;
		        align-self: center;
		-webkit-flex-flow: row wrap;
	        -ms-flex-flow: row wrap;
	            flex-flow: row wrap;
	}

	.diet-plan-table .diet-plan-btn {
		width: 15%;
		padding-left: 20px;
	}

	.diet-plan-table .meal-title {
		width: 15%;
	}

	/*.pdf .diet-plan-table .meal-title {
		width: 25%;
	}
	*/
	.diet-plan-table .meal-food-list-wrap {
		width: 50%;
	    border-right: 2px solid #ccc;
		-webkit-box-pack: end;
	    -webkit-justify-content: flex-end;
	        -ms-flex-pack: end;
	            justify-content: flex-end;
	}

	.pdf .diet-plan-table .meal-food-list-wrap {
		display: -webkit-box;
	    display: -webkit-flex;
	    display: -ms-flexbox;
	    display: flex;
		border-right: 0;
		-webkit-box-pack: start;
	    -webkit-justify-content: flex-start;
	        -ms-flex-pack: start;
	            justify-content: flex-start;
	}

	.diet-plan-table .meal-cal-visual-wrap {
		width: 20%;
		padding-left: 10px;
	}

	.meal-cal-visual {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-orient: vertical;
	    -webkit-box-direction: normal;
	    -webkit-flex-direction: column;
	        -ms-flex-direction: column;
	            flex-direction: column;
		width: 100%;
	}

	.meal-cal-visual-left,
	.meal-cal-visual-right {
		text-align: center;
		width: 100%;
		vertical-align: middle;
	}
}


@media ( max-width: 991px ) {
	.diet-plan-row {
		margin-bottom: 50px;
	}

	.diet-plan-row:last-child {
		margin-bottom: 0;
	}

	.meal-title h4 {
		display: table;
		margin: 10px auto;
	}

	ul.meal-food-list {
		display: table;
		margin: auto;
	}

	.meal-cal-visual-wrap {
		display: block;
		margin: 25px;
		text-align: center;
	}

}


/* Diet List Page Rules
=================================
*/

.diet-list-table {
	border: 1px solid #cfd7de;
	background-color: #fbfbfb;
}

.diet-list-table thead>tr {
	background-color: #e4e4e4;
}

.diet-list-table tbody>tr {
	transition: all linear 0.3s;
}

.diet-list-table tbody>tr:hover {
	background-color: #00baff;
}

.diet-list-table tbody>tr:hover td,
.diet-list-table tbody>tr:hover .action-list li>a {
	color: #fff;
}

.action-list li>a {
	font-size: 18px;
	line-height: 22px;
}

.single-main-content {
	margin-bottom: 40px;
}

.single-main-content:last-child {
	margin-bottom: 0;
}

/* Food Diet Plan Details Page Rules
====================================
*/

.single-food-details {
	padding-top: 40px;
}

.single-food-details-left hr {
	margin-top: 15px;
	margin-bottom: 15px;
}

.single-food-details .single-food-details-inner {
	padding-bottom: 20px;
	/*border-bottom: 1px solid #cacaca;*/
}

.single-food-details #diet-chart {
	margin-top: 0;
}

.single-food-details #diet-legend-wrap {
	margin-top: 20px;
}

.single-food-details-right p {
	margin-bottom: 20px;
}

.fruit-share {
	margin-bottom: 8px;
	display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
        -ms-flex-pack: end;
            justify-content: flex-end;
    -webkit-flex-flow: row wrap;
        -ms-flex-flow: row wrap;
            flex-flow: row wrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
}

.fruit-share p {
	font-weight: bold;
	font-size: 25px;
	margin-right: 20px;
	margin-bottom: 0;
}

.fruit-share-list {
	display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
        -ms-flex-pack: end;
            justify-content: flex-end;
    -webkit-flex-flow: row wrap;
        -ms-flex-flow: row wrap;
            flex-flow: row wrap;
}

.fruit-share-list li {
	list-style: none;
	display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

.fruit-share-list li:last-child {
	margin-right: -3px;
}

.fruit-share-list li a,
.fruit-share-list li button {
	border: 0;
	background-color: #d4d4d4;
	color: #fff;
	font-size: 18px;
	margin-right: 3px;
	min-width: 35px;
	padding: 5px 10px;
	-webkit-transition: all ease 0.3s;
	-moz-transition: all ease 0.3s;
	transition: all ease 0.3s;
	-webkit-box-flex: 1;
    -webkit-flex: 1;
        -ms-flex: 1;
            flex: 1;
}

.fruit-share-list li.facebook a {
	background-color: #3b5999;
}

.fruit-share-list li.twitter a {
	background-color: #55acee;
}

.fruit-share-list li.google-plus a {
	background-color: #DC4E41;
}

.fruit-share-list li.linkedin a {
	background-color: #0077B5;
}

.fruit-share-list li.print a,
.fruit-share-list li.print button {
	background-color: #019dd7;
}

.fruit-share-list li.pdf a,
.fruit-share-list li.pdf button {
	background-color: #ec407a;
}

.fruit-img {
	position: relative;
}

.fruit-name {
	font-size: 35px;
	line-height: 38px;
	color: #fff;
	padding: 10px 50px;
	position: absolute;
	top: 0;
	left: 0;
	background-color: rgba(0, 0, 0, .60);
}

.food-details-title {
	color: #777777;
	font-size: 24px;
	line-height: 30px;
	font-weight: 600;

}

.food-advantage {
	padding-top: 30px;
}

table.table-food-pros > thead > tr > th {
	background-color: #0186b7;
	color: #fff;
	text-align: center;
	border: 1px solid #fff;
	width: 50%;
	padding: 15px;
	font-weight: normal;
}

table.table-food-pros > tbody > tr > td {
	color: #7d7d7d;
	border: 1px solid #fff;
	background-color: #f5f5f5;
	padding: 15px;
	font-weight: normal;
}

.food-advantage .heading-border-wrap {
	padding-top: 25px;
	padding-bottom: 30px;
}

.vitamin-row:last-child ul {
	border-bottom: 1px solid #cfd7de;
}

.vitamin-main-title {
	display: block;
	font-size: 16px;
	line-height: 22px;
	color: #6e6e6e;
	font-weight: 700;
	padding-top: 15px;
	padding-bottom: 15px;
	padding-left: 15px;
	margin: 0;
	border-left: 4px solid #00baff;
	border-top: 1px solid #e5eaed;
	border-bottom: 1px solid #e5eaed;
}

.vitamin-list {
	margin-left: 0;
	padding-left: 20px;
}

.vitamin-list li {
	padding-top: 15px;
	padding-bottom: 15px;
	padding-right: 15px;
	padding-left: 30px;
	color: #6e6e6e;
	position: relative;
}

.bn-version .vitamin-list li {
	font-size: 16px;
	line-height: 20px;
}

.vitamin-list li:before {
	content: " ";
    position: absolute;
    top: 50%;
    margin-top: -7.5px;
    left: 0;
    width: 13px;
    height: 15px;
    background: url(../img/food/tick-mark.png);
    background-repeat: no-repeat;
    background-position: top left;
}

h3 .vitamin-amount {
	font-size: 13px;
	line-height: 22px;
	font-weight: 400;
	margin-right: 50px;
}

.bn-version h3 .vitamin-amount {
	font-size: 18px;
	line-height: 22px;
}

.vitamin-title {
	font-weight: 600;
}

.panel-title {
	position: relative;
}

.panel-title a::after {
	content: "\f056";
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
	position: absolute;
	top: 0;
	right: 0;
	padding: 9px 20px;
	float: right;
    font-size: 30px;
    line-height: 30px;
    font-weight: 100;
    color: #6d6d6d;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
	transition: all linear 0.3s;
}

.panel-title a.collapsed::after {
    content: "\f055";
}

.single-main-content .panel-group .panel {
	border-radius: 0;
	border: 0;
	margin: 0;
}

.single-main-content .panel-heading {
	padding: 0;
	background-color: #fff;
}

.single-main-content .panel:first-child .panel-heading a {
	border-top: 1px solid #dadada;
}

.single-main-content .panel-heading a {
	display: block;
	padding: 15px;
	font-weight: 600;
	color: #6d6d6d;
	border-bottom: 1px solid #dadada;
	transition: all linear 0.3s;
}

.single-main-content .panel-heading a[aria-expanded="true"] {
	background-color: #00baff;
	color: #fff;
}

.bn-version .single-main-content .panel-heading a {
	font-size: 18px;
	line-height: 22px;
}

.single-main-content .panel-heading:hover a {
	color: #fff;
	background-color: #00baff;
	border-color: #00baff;
}

.single-main-content .panel-heading:hover .panel-title a::after,
.single-main-content .panel-heading:hover .panel-title a[aria-expanded="true"]::after  {
	color: #fff;
}

.single-main-content .panel-default>.panel-heading+.panel-collapse>.panel-body {
	border: 0;
	border-bottom: 1px solid #dadada;
}



.btn-action-fixed.fixed {
    position: fixed;
    bottom: 0;
    width: 100%;
    left: 0;
    z-index: 1;
    background-color: rgba(0,0,0,.65);
}

@media (min-width: 992px) {
	.btn-action-wrap {
		display: -webkit-box;
	    display: -webkit-flex;
	    display: -ms-flexbox;
	    display: flex;
		-webkit-box-pack: start;
	    -webkit-justify-content: flex-start;
	        -ms-flex-pack: start;
	            justify-content: flex-start;
	}

	.btn-action-wrap .btn {
		margin: 0 5px;
	}

	.btn-action-wrap .btn:first-child {
		margin-left: 0;
	}

	.btn-action-fixed .btn-action-wrap {
		-webkit-justify-content: center;
		    -ms-flex-pack: distribute;
		        justify-content: center;
	}

	.btn-action-fixed .btn-action-wrap .btn{
		-webkit-box-flex: 0;
	    -webkit-flex: 0 1 calc(150px - 5px);
	        -ms-flex: 0 1 calc(150px - 5px);
	            flex: 0 1 calc(150px - 5px);
	}
}


@media ( max-width: 991px ) {

	.btn-action-wrap {
		display: block;
	}

	.btn-action-wrap .btn {
		display: block;
		margin-bottom: 10px
	}

	.btn-action-wrap .btn:last-child {
		margin-bottom: 20px;
	}

}

@media (max-width: 400px ) {
	.fruit-share {
		-webkit-flex-flow: column;
        -ms-flex-flow: column;
            flex-flow: column;
	}
	.fruit-share p {
		margin-right: 0;
		margin-bottom: 10px;
	}
}


/* Food Browse Page Rules
=============================
*/

.food-cat {
	padding: 40px;
}

.food-cat:last-child {
	padding-bottom: 40px;
}

.food-cat .heading-border-wrap {
	margin: 25px 0;
}

/*.food-browse-list {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}*/

.food-browse-list li,
.food-highlight-list li {
	list-style: none;
	display: inline-block;
	margin-right: 5px;
}

/*.food-highlight-list li {
	list-style: none;
}*/

.food-highlight-list {
	padding: 20px;
	border: 1px solid #e9e9e9;
	/*display: flex;
	flex-wrap: wrap;
	justify-content: space-around;*/
}

.food-highlight-list li.btn-blue {
	font-weight: 400;
	margin-bottom: 5px;
	padding: 5px;
	font-size: 14px;
	line-height: 16px;
}

.bn-version .food-highlight-list li.btn-blue {
	font-size: 18px;
	line-height: 22px;
}


@media ( max-width: 767px ) {
	.food-cat {
		padding:40px 0;
	}
}

/*.food-highlight-list li {}*/

/* Food 404 Page Rules
=========================
*/

.sec-404 {
	padding: 45px 0;
}

.desc-404 {
	border: 1px solid #e6e6e6;
}

.desc-404 p {
	margin-bottom: 0;
	font-size: 50px;
	line-height: 60px;
	font-weight: 700;
	padding: 15px 20px;
}

/* Confirmation Page Rules
==============================
*/

.confirmation-header {
	position: relative;
	padding: 50px 0;
	z-index: 0;
}

.confirmation-header h4 {
	font-weight: 700;
}

.confirmation-header p,
.confirmation-bottom-header p {
	margin-bottom: 0;
}

.confirmation-bottom-header {
	padding: 35px 0;
	background-color: #e6e6e6;
}

.confirmation-bottom-header h4 {
	color: #565656;
	font-weight: 700;
}

.confirmation-bottom-header p {
	color: #565656;
}

.suggested-docs .thumbnail>img {
	border-radius: 0;
	border: 0;
	margin-bottom: 0;
}

.suggested-docs .thumbnail>.caption {
	padding: 15px 0;
}

.suggested-docs .thumbnail>.caption h3  {
	font-weight: 700;
	color: #505050;
	margin-bottom: 15px;
}

.suggested-docs .thumbnail>.caption p.blue {
	color: #00baff;
	font-size: 15px;
	line-height: 23px;
	margin-bottom: 15px;
}

.suggested-docs .thumbnail>.caption p.address {
	color: #505050;
	font-size: 15px;
	line-height: 23px;
	margin-bottom: 5px;
}

.suggested-docs .thumbnail>.caption .rating {
	padding-left: 0;
	margin: auto;
	display: table;
	margin-bottom: 20px;
}

.contact-list {
	display: table;
	margin: auto;
	padding-left: 0;
	margin-bottom: 0;
	width: 100%;
}

.dir-single-wrap-inner-left .contact-list {
	margin-top: 15px;
}

.contact-list li {
	display: table-cell;
	background-color: #fff;
	padding: 5px 15px;
	margin-right: 10px;
	text-align: center;
}

.contact-list li:hover {
	background-color: #dddddd;
}

.contact-list li i {
	color: #9e9e9e;
	font-size: 22px;
	line-height: 25px;
}

.suggested-docs .btn-grey {
	font-size: 20px;
	line-height: 25px;
	margin-bottom: 75px;
}


.user-logged-in .search-form {
	padding: 25px;
	border: 1px solid #e4e4e4;
	margin-bottom: 25px;
	margin-top: 25px;
}

.user-logged-in .search-form form {
	margin-top: 0;
	margin-bottom: 0;
}

.doc-content h3 {
	margin-top: 25px;
	font-size: 28px;
	line-height: 30px;
	color: #666666;
	margin-bottom: 0;
}

.doc-content > .container-fluid > .row >.col-md-9 {
	padding: 0 25px;
}


.doc-content aside.col-md-3 {
	background-color: #eaeaea;
	padding: 80px 25px;
}


@media ( max-width: 991px) {
	.contact-list {
		display: -webkit-box;
	    display: -webkit-flex;
	    display: -ms-flexbox;
	    display: flex;
	    -webkit-box-pack: center;
	    -webkit-justify-content: center;
	        -ms-flex-pack: center;
	            justify-content: center;
	}
}


/* Diet Plan nutrient section Rules
=================================
*/
.diet-plan-nutrient {
	padding: 60px 0;
	background-color: #f3f3f3;
}

.diet-plan-nutrient .heading-border-wrap {
	padding-top: 25px;
	padding-bottom: 30px;
}

.diet-plan-nutrient-single {
	margin-bottom: 30px;
	background-color: #fff;
	padding: 25px 20px;
}

.diet-plan-nutrient-single.middle {
	background-color: #00baff;
	color: #fff;
}

.diet-plan-nutrient-single.middle h3,
.diet-plan-nutrient-single.middle p {
	color: #fff;
}


.diet-plan-nutrient-single:last-child {
	margin-bottom: 0;
}

.heading-border-wrap.white .heading-border-bottom {
	border-color: #fff;
}

@media ( min-width: 768px) {

	.diet-plan-nutrient-wrap {
		display:-webkit-box;
	    display:-webkit-flex;
	    display:-ms-flexbox;
	    display:flex;
	    /*-webkit-flex-flow: row wrap;
        -ms-flex-flow: row wrap;
            flex-flow: row wrap;*/
	}

	.diet-plan-nutrient-single {
		-webkit-box-flex: 1;
	    -webkit-flex: 1 1 auto;
	        -ms-flex: 1 1 auto;
	            flex: 1 1 auto;
	}

	.diet-plan-nutrient-single.left,
	.diet-plan-nutrient-single.right {
		width: 30%;
		margin-bottom: 0;
	}

	.diet-plan-nutrient-single.middle {
		width: 40%;
		margin-left: 20px;
		margin-right: 20px;
		margin-bottom: 0;
	}

	.diet-plan-nutrient .food-highlight-list {
		padding: 0;
		border: 0;
		display: -webkit-box;
	    display: -webkit-flex;
	    display: -ms-flexbox;
	    display: flex;
	    -webkit-flex-flow: row wrap;
	        -ms-flex-flow: row wrap;
	            flex-flow: row wrap;
	}

	.diet-plan-nutrient .food-highlight-list .btn{
		-webkit-box-flex: 1;
	        -webkit-flex: 1 1 auto;
	            -ms-flex: 1 1 auto;
	                flex: 1 1 auto;
    }

}

/* Profile Page Rules
========================
*/

.profile {
	padding: 30px 0;
}

/* Nav Aside Rules */
.nav-aside {
	border: 1px solid #d6d6d6;
}


.nav-aside .dropdown > .dropdown-menu {
	position: static;
	float: none;
}

.nav-aside>li>a {
	position: relative;
	font-size: 15px;
	font-weight: bold;
	color: #7f7f7f;
	padding: 25px;
	border-bottom: 1px solid #d6d6d6;
	cursor: pointer;
	-webkit-transition: all ease 0.3s;
	-moz-transition: all ease 0.3s;
	-ms-transition: all ease 0.3s;
	transition: all ease 0.3s;
}

.nav-aside>li:last-child>a{
	border-bottom: 0;
}

.nav-aside>li>a:hover,
.nav-aside>li>a:focus,
.nav-aside>li.active>a,
.nav-aside>li.active>a:hover,
.nav-aside>li.active>a:focus {
	background-color: #00baff;
	color: #fff;
	border-color: #00baff;
}

.nav-aside>li>a::after {
	content: "\f105";
	display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: absolute;
    top: 0;
    right: 25px;
    font-size: 20px;
    font-weight: bold;
    line-height: 72px;
}

.nav-aside>li.active>a::after {
	content: "\f107";
}


.nav-aside li ul>li {
	list-style: none;
}

.nav-aside li ul>li>a {
	display: block;
	padding: 25px 25px 25px 55px;
	color: #747474;
	border-bottom: 1px solid #d6d6d6;
	-moz-transition: all ease 0.3s;
	-webkit-transition: all ease 0.3s;
	-ms-transition: all ease 0.3s;
	transition: all ease 0.3s;
}

.nav-aside li ul>li>a:hover,
.nav-aside li ul>li>a:focus,
.nav-aside li ul>li.active>a,
.nav-aside li ul>li.active>a:hover,
.nav-aside li ul>li.active>a:focus {
	background-color: #d6d6d6;
}

.nav-aside li:last-child ul>li:last-child>a {
	border-bottom: 0;
}

.nav-aside > .vault-dropdown > .vault-dropdown-menu {
	display: none;
	-moz-transition: all ease 0.3s;
	-webkit-transition: all ease 0.3s;
	-ms-transition: all ease 0.3s;
	transition: all ease 0.3s;
}


.nav-aside > .vault-dropdown.active > .vault-dropdown-menu {
	display: block;
}

p.avatar-text {
		margin-bottom: 10px;
}

.profile-image-block {
	display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}

.profile-btn {
	margin-left: 30px;
}

.profile-btn .btn:last-child {
	margin-bottom: 0;
}

.profile-form .form-control {
	border-color: #ccc;
	color: #000;
}

.profile-form textarea.form-control {
	padding: 10px 25px;
}

.profile-form .form-control:focus {
	border-color: #00baff;
}

.profile-form input[type=checkbox],
.profile-form input[type=radio] {
	width: 1px;
	height: 1px;
	margin-right: 10px;
}

.profile-form .checkbox-inline {
	line-height: 50px;
}

.radio-button-wrap {
	display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.profile-form input[type="checkbox"] + span.checkbox-btn {
	top: 50%;
	margin-top: -13.5px;
}


@media (min-width: 768px) {
	.profile-form .control-label {
		padding-top: 0;
		line-height: 50px;
		text-align: left;
		color: #747474;
	}
}

/* Team Page Rules
=========================
*/

.category.team-category {
	padding: 75px 0;
}


h1.team-title {
	display: table;
	margin: -30px auto 30px auto;
	font-size: 35px;
	line-height: 40px;
	background-color: #00baed;
	padding: 10px 25px;
}

.team-member-list {
	padding: 25px 0;
}

.member-row {
	display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;

    -webkit-flex-flow: row wrap;
        -ms-flex-flow: row wrap;
            flex-flow: row wrap;

    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
}

.member-single {
	max-width: 200px;
	margin: 20px;
}

.member-img-wrap {
	position: relative;
	width: 100%;
	height: auto;
	margin-bottom: 20px;
}

.member-img-wrap:hover .member-social-links-wrap .member-social-links {
	opacity: 1;
}

.member-social-links-wrap {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: auto;
}

.member-social-links {
	display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;

    -webkit-transition: all ease 0.3s;
    transition: all ease 0.3s;
}

.member-social-links li {
	background-color: rgba( 0, 0, 0, 0.3 );
	list-style-type: none;


	-webkit-box-flex: 1;
    -webkit-flex: 1;
        -ms-flex: 1;
            flex: 1;
}

.member-social-links li a {
	color: #fff;
	font-size: 20px;
	line-height: 30px;
	text-align: center;
	padding: 10px;

	-webkit-transition: all ease 0.3s;
	transition: all ease 0.3s;

	display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;

    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
}

.member-social-links li.facebook a:hover,
.member-social-links li.facebook a:focus {
	color: #3b5999;
}

.member-social-links li.twitter a:hover,
.member-social-links li.twitter a:focus {
	color: #55acee;
}

.member-social-links li.linkedin a:hover,
.member-social-links li.linkedin a:focus {
	color: #0077B5;
}

.member-social-links li.website a:hover,
.member-social-links li.website a:focus {
	color: #34465d;
}

@media ( max-width: 767px ) {
	.member-single {
		display: block;
		width: 100%;
	}
}

/* Health Profile Page Rules
==============================
*/

.health-profile {
	padding: 50px 0;
}

.health-profile .checkbox-inline,
.health-profile .radio-inline {
	padding-left: 30px;
	margin-right: 15px;
}

.checkbox, .radio {
	margin-bottom: 15px;
}

.health-profile .form-control {
	border-color: #ccc;
}

.health-profile .form-control:focus {
	border-color: #00baff;
}

.input-group-addon.has-select-box {
	padding: 0;
}

.input-group-addon.has-select-box:focus {
	border-color: #00baff;
}

.input-group-select {
	height: 48px;
	border: 0;
	padding: 0 15px;
	cursor: pointer;
}

.input-group-select:focus {
	outline: 0;
}

.health-profile .select2-container--default .select2-selection--multiple {
	background-color: #fff;
	border-color: #ccc;
}

.health-profile .select2-container .select2-search--inline .select2-search__field{
	line-height: 40px;
	padding: 0 20px;
}

.health-profile .select2-container--default .select2-selection--multiple .select2-selection__choice {
	line-height: 42px;
}

/* Ask A Doctor Page Rules
==========================
*/

.ask-doc {
	padding: 50px 0;
	background-color: #f4f4f4;
}

.ask-doc .btn-prescription {
	margin: 0;
}

.ask-doc .btn-prescription i {
	margin-right: 10px;
}

.doc-faq-single {
	border: 1px solid #ddd;
	background-color: #fff;
	margin-bottom: 25px;

	-webkit-transition: all ease 0.3s;
	transition: all ease 0.3s;
}

.doc-faq-single.details {
	margin: 0;
}

.doc-faq-single:hover {
	border-color: #00baff;
}

.doc-faq-single.answer:hover {
	border-color: #70B600;
}

.doc-suggestion-title h2 {
	font-weight: normal;
	margin: 0;
	padding: 25px;
	border-left: 1px solid #ddd;
	border-right: 1px solid #ddd;
	color: #00baff;
	background-color: #fff;
}

.doc-suggestion-title h4 {
	background-color: #fff;
	margin: 0;
	padding-top: 10px;
	padding-bottom: 10px;
	padding-right: 10px;
	padding-left: 60px;
	border-left: 1px solid #ddd;
	border-right: 1px solid #ddd;
}

span.dot {
	position: relative;
	top: -7px;
	display: inline-block;
	width: 15px;
	height: 15px;
	margin-right: 20px;
	border-radius: 50%;
	background-color: #00baff;
}

.doc-faq-single header {
	padding: 25px;
}

.doc-faq-single header .heading-border-wrap {
	padding-top: 15px;
	padding-bottom: 10px;
}

.doc-faq-title {
	width: 100%;

	display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;

    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
            justify-content: space-between;

    -webkit-flex-flow: row wrap;
        -ms-flex-flow: row wrap;
            flex-flow: row wrap;
}

.doc-faq-single .body .heading-border-wrap {
	padding: 15px 0;
}

.doc-faq-single .symptom-list {
	margin: 25px 0;
}

.doc-faq-single .symptom-list li {
	border: 0;
}

.doc-faq-single .attachment {
	margin-bottom: 20px;
}

.doc-faq-single .attachment-tab {
	position: relative;
}

.nav-attachment {
	display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;

    border: 1px solid #ddd;
}

.nav-attachment li {
	list-style-type: none;
	width: 25%;
	text-align: center;
	border-right: 1px solid #ddd;
}

.nav-attachment li:last-child {
	border-right: 0;
}

.nav-attachment li a {
	padding: 10px;
	font-size: 18px;
	line-height: 22px;

	display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;

    -webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
}

.nav-attachment li.active a,
.nav-attachment li a:hover,
.nav-attachment li a:focus {
	background-color: #00baff;
	color: #fff;
	border-color: #00baff;
}

.attachment-tab .tab-pane {
	padding: 25px 0;
}

.attachment-tab .tab-content p {
	margin-left: 5px;
}

.doc-faq-single .selected-symptom-list.row {
	margin: 0;
}

.doc-faq-single .selected-symptom-list li {
	padding-left: 35px;
}

.doc-faq-single .selected-symptom-list li:before {
	left: 0;
}

.doc-faq-title .left {
	display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;

    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
            justify-content: space-between;

    -webkit-flex-flow: row wrap;
        -ms-flex-flow: row wrap;
            flex-flow: row wrap;
}

.doc-faq-title .left .left {
	margin-right: 20px;
}

.doc-faq-title .left .left .img-wrap {
	max-width: 120px;
}

.doc-faq-title .left .left .img-circle {
	border: 0;
}

.doc-faq-title .left .right {
	display: block;
}

.doc-faq-title .right .calendar i {
	color: #27ae60;
}

.doc-faq-title .right .img-wrap {
	display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;

	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
}

.doc-faq-title .right .img-wrap form {
	display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

.doc-faq-title .right .img-wrap .btn-gray {
	position: relative;
	display: none;
	margin-bottom: 0;
	padding: 10px;
	background-color: #fff;
	border-color: #ddd;
	border-width: 1px;

	background-image: url('../img/ask-a-doc/icons/tick-gray.png');
	background-repeat: no-repeat;
	background-size: initial;
	background-position: 50%;
	min-width: 50px;
}

.doc-faq-title .right .img-wrap .btn-gray:hover,
.doc-faq-title .right .img-wrap .btn-gray:focus,
.doc-faq-title .right .img-wrap .btn-gray.active {
	box-shadow: none;
	outline: 0;
	background-image: url('../img/ask-a-doc/icons/tick.png');
	background-color: #ddd;
}

.doc-faq-title .right .img-wrap .btn-gray {
	display: block;
}

.doc-faq-title .right .img-wrap form + .img-block {
	border-top: 1px solid #ddd;
	border-right: 1px solid #ddd;
	border-bottom: 1px solid #ddd;
}

.doc-faq-title .right .img-wrap .img-block {
	display: block;
	max-width: 60px;
	padding: 5px 10px;
}

.calendar .answer-given {
	display: block;
	font-size: 25px;
	line-height: 30px;
	color: #656565;
	margin-bottom: 5px;
}


.doc-faq-title .left .right h3 {
	margin-bottom: 10px;
}

.doc-faq-title .left .right p {
	margin-bottom: 5px;
}

.doc-faq-title .left h3 {
	color: #656565;
	-webkit-transition: all ease 0.3s;
	transition: all ease 0.3s;
}

.doc-faq-title .left h3:hover,
.doc-faq-title .left h3:focus {
	color: #00baff;
}

.doc-faq-title .right {
	/*width: 30%;*/

	display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;

    -webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;

	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;

	-webkit-box-align: end;
    -webkit-align-items: flex-end;
	-ms-flex-align: end;
	-ms-grid-row-align: flex-end;
	align-items: flex-end;
}

.doc-faq-title .right p {
	border: 0;
	margin-bottom: 0;
	margin-right: 10px;
}

.btn-group.title-meta {
	border-top: 1px solid #ddd;
	border-left: 1px solid #ddd;
	border-bottom: 1px solid #ddd;
}

.title-meta > .btn-group:not(:last-child) {
	margin-right: 1px;
}

.btn-group.title-meta .btn-pop {
	color: #656565;
	background-color: #f4f4f4;
	margin: 0;
	border-top: 1px solid transparent;
	border-bottom: 1px solid transparent;
	border-left: 1px solid transparent;
	border-right: 1px solid #ddd;
	font-size: 16px;
	line-height: 21px;
	padding: 10px;
	min-height: auto;
}

.btn-pop .glyphicon {
	top: 3px;
}

.open .btn-pop.dropdown-toggle {
	box-shadow: none;
	-webkit-box-shadow: none;
	border: 1px solid #00baff;
}

.open .rx-dropdown {
	border-radius: 0;
	top: 130%;
	-webkit-box-shadow: none;
	box-shadow: none;
	border: 1px solid #00baff;
}

.rx-dropdown input[type="checkbox"] + span.checkbox-btn {
	width: 20px;
	height: 20px;
	margin-top: 0;
}

.rx-dropdown input[type="checkbox"]:checked + span.checkbox-btn::after {
	line-height: 20px;
	font-size: 13px;
}

.rx-dropdown input[type=checkbox] {
	height: 20px;
	width: 20px;
}

.rx-dropdown input[type=checkbox]:checked::after {
	height: 20px;
	width: 20px;
}


.open .rx-dropdown:before {
    content: "";
	position: absolute;
    top: -21px;
    left: 7px;
    display: block;
    width: 0;
    height: 0;
    border-color: transparent transparent #00baff;
    border-style: solid;
    border-width: 10px;
}

.open .rx-dropdown:after {
    content: '';
	position: absolute;
    top: -20px;
    left: 7px;
    display: block;
    width: 0;
    height: 0;
    border-color: transparent transparent #fff;
    border-style: solid;
    border-width: 10px;
}

.open .dropdown-menu-right:after,
.open .dropdown-menu-right:before {
	right: 7px;
	left: auto;
}

.open .doc-dropdown {
	padding-left: 5px;
	padding-right: 5px;
}

.doc-dropdown a {
	display: inline-block;
	max-width: 35px;
	vertical-align: middle;
}

.doc-dropdown a img {
	border-radius: 50%;
	margin: 2.5px;
}

.rx-dropdown .label-text {
	margin-left: 10px;
}

.btn-group.title-meta .btn-pop.dropdown-toggle {
	line-height: 21px;
}

.doc-faq-single .body {
	padding: 0 25px;
}

.doc-faq-single footer {
	border-top: 1px solid #ddd;
	padding: 25px;
	background-color: #f4f4f4;

	display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;

    -webkit-flex-flow: row wrap;
        -ms-flex-flow: row wrap;
            flex-flow: row wrap;

    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
            justify-content: space-between;
}

.doc-faq-single footer .left {
	position: relative;
}

.doctor-popup {
	position: absolute;
	bottom: 100%;
	left: 0;
	background-color: rgba(0,0,0, 0.8);
	color: #fff;
	padding: 5px 10px;
}

p.doctor-popup {
	margin: 0;
	font-size: 13px;
	line-height: 15px;
}

.btn-group.footer-meta {
	border-top: 1px solid #ddd;
	border-left: 1px solid #ddd;
	border-bottom: 1px solid #ddd;
}

.btn-group.btn-form-group {
	display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;

	-webkit-flex-flow: row wrap;
	-ms-flex-flow: row wrap;
	flex-flow: row wrap;
}

.btn-form-group form {
	display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

.btn-group.footer-meta .btn-pop-footer {
	color: #656565;
	background-color: #fff;
	margin: 0;
	border-top: 0;
	border-bottom: 0;
	border-left: 0;
	border-right: 1px solid #ddd;
	font-size: 16px;
	line-height: 19px;
	padding: 15px;
	min-height: auto;
}

.btn-group.footer-meta .btn-pop-footer:hover,
.btn-group.footer-meta .btn-pop-footer:focus {
	background-color: #00baff;
	color: #fff;
}

.btn-group.footer-meta .btn-pop-footer.like:hover,
.btn-group.footer-meta .btn-pop-footer.like:focus,
.btn-group.footer-meta .btn-pop-footer.like.active {
	background-color: #fbfff3;
	color: #000;
	box-shadow: none;
}

.btn-group.footer-meta .btn-pop-footer.like svg:hover #like-bg,
.btn-group.footer-meta .btn-pop-footer.like svg:focus #like-bg {
	fill: #ccc;
}

.btn-group.footer-meta .btn-pop-footer.dislike:hover,
.btn-group.footer-meta .btn-pop-footer.dislike:focus,
.btn-group.footer-meta .btn-pop-footer.dislike.active {
	background-color: #fff3f3;
	color: #000;
	box-shadow: none;
}

.btn-group.footer-meta-no-border .btn-no-border {
	color: #656565;
	background-color: #f4f4f4;
	margin: 0;
	border: 0;
	font-size: 16px;
	line-height: 19px;
	padding: 15px;
	min-height: auto;
}

.sidebar-form .form-control {
	color: #656565;
	border-color: #ddd;
	border-right: 0;
}

.sidebar-form form {
	display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

.sidebar-form form .form-group {
	margin: 0;
	background-color: #fff;
	width: 75%;

	display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

.sidebar-form .btn-side-submit {
	width: 25%;
	margin: 0;
	padding: 10px 20px;
	background-color: #fff;
	border-color: #ddd;
	border-width: 1px;
	color: #656565;
	text-align: center;

	display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;

    -webkit-box-pack: center;
	-webkit-justify-content: center;
	    -ms-flex-pack: center;
	        justify-content: center;
}

.sidebar-form .btn-side-submit:hover,
.sidebar-form .btn-side-submit:focus {
	background-color: #00baff;
	color: #fff;
}

.ask-doc aside {
	margin-bottom: 20px;
}

.ask-doc aside .btn-full {
	margin-bottom: 0;
	border-width: 1px;
	border-color: #ddd;
	padding: 10px 5px;
}

.rx-list-group .list-group-item {
	border-radius: 0;
	font-size: 18px;
	line-height: 23px;
	padding: 15px 25px;
	color: #999;

	-webkit-transition: all ease 0.3s;
	transition: all ease 0.3s;
}

.rx-list-group .list-group-item:hover,
.rx-list-group .list-group-item:focus {
	color: #656565;
}

.rx-media-list {
	background-color: #fff;
	border: 1px solid #ddd;
	padding-top: 10px;
	padding-bottom: 25px;
}

.rx-media {
	padding: 15px;
	margin: 0;
	border-bottom: 1px solid #ddd;
	-webkit-transition: all ease 0.3s;
	transition: all ease 0.3s;
}

.rx-media:hover {
	background-color: #f4f4f4;
}

.rx-media:last-child {
	border-bottom: 0;
}

.rx-media-head {
	padding: 15px;
	padding-bottom: 0;
}

.rx-media a h4:hover,
.rx-media a h4:focus {
	color: #00baff;
}

.rx-media-head .heading-border-wrap {
	padding: 5px 0;
}
.rx-media .media-heading {
	font-size: 16px;
	line-height: 19px;
}

.rx-media .media-meta {
	font-size: 15px;
	line-height: 18px;
	color: #c1c1c1;
	margin-bottom: 0;
}

.rx-tag-list {
	background-color: #fff;
	border: 1px solid #ddd;
}

.rx-tag-list ul {
	padding: 15px;
	display: flex;
	flex-flow: row wrap;
}

.rx-tag-list ul li {
	list-style: none;
	margin: 2px;
}

.rx-tag-list ul li a {
	display: block;
	font-size: 16px;
	line-height: 19px;
	padding: 10px 15px;
	border: 1px solid #ddd;

	-webkit-transition: all ease 0.3s;
	transition: all ease 0.3s;
}

.rx-tag-list ul li a:hover,
.rx-tag-list ul li a:focus {
	background-color: #00baff;
	color: #fff;
}

.doc-faq-ans {
	border: 1px solid #ddd;
	background-color: #fff;
}

.doc-faq-ans-title {
	color: #656565;
	margin-bottom: 15px;
}

.doc-faq-ans-title + .heading-border-wrap {
	padding-bottom: 25px;
}

.doc-faq-ans header {
	padding: 25px;
	border-bottom: 1px solid #ddd;

	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;

	-webkit-box-pack: center;
	-webkit-justify-content: center;
	    -ms-flex-pack: center;
	        justify-content: center;
}

.doc-faq-ans header .right {
	min-width: 60%;

	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;

	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	    -ms-flex-direction: column;
	        flex-direction: column;

	-webkit-box-pack: justify;
    -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
            justify-content: space-between;

    -webkit-box-align:end;
	-webkit-align-items:flex-end;
	    -ms-flex-align:end;
	        align-items:flex-end;
}

.doc-faq-ans .btn-group.footer-meta-no-border .btn-no-border {
	background-color: #fff;
	padding-bottom: 0;
}

.doc-faq-ans .btn-group.footer-meta-no-border .btn-no-border:last-child {
	padding-right: 0;
}

.doc-faq-ans .body {
	padding: 25px;
}

.doc-faq-ans .body .btn-grey {
	margin: 0;
}

.follow-meta {
	border: 1px solid #ddd;
	background-color: #fff;
	padding: 20px;
}

.follow-meta li {
	color: #656565;
	list-style: none;
	font-size: 18px;
	line-height: 35px;
}

.doc-faq-ans footer {
	padding: 0 25px;
}

.doc-faq-ans footer .btn-grey {
	border-color: #ddd;
	border-width: 1px;
}

.askdoctor-modal .form-control {
	border-color: #ddd;
	color: #656565;
	min-height: 60px;
}

.askdoctor-modal .form-control:focus {
	border-color: #00baff;
}

.askdoctor-modal .btn-grey-trans {
	border: 1px solid #ddd;
	background-color: #fff;
}

.askdoctor-modal .btn-grey-trans:hover,
.askdoctor-modal .btn-grey-trans:focus {
	background-color: #00baff;
	border-color: #00baff;
}

.askdoctor-modal .form-group.right {
	position: relative;
	text-align: right;
}

.askdoctor-modal .select2-container--default {
	position: relative;
}

.askdoctor-modal .select2-container--default:before {
	content: '\f055';
	display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    font-size: 45px;
    line-height: 60px;
    color: #ccc;

    position: absolute;
    top: 0;
    left: 10px;
    pointer-events: none;
}

.askdoctor-modal .select2-container--focus:before {
	color: #00baff;
}

.askdoctor-modal .select2-container--default .select2-selection--multiple {
	background-color: #fff;
	border-color: #ddd;
}

.askdoctor-modal .select2-container--focus.select2-container--default .select2-selection--multiple {
	border-color: #00baff !important;
}

.askdoctor-modal .select2-container--default .select2-selection--multiple .select2-selection__rendered {
	line-height: 45px;
	margin-left: 50px;
}

.askdoctor-modal .select2-container--default .select2-search--inline .select2-search__field {
	width: 100% !important;
}

.askdoctor-modal .limit-text {
	margin: 0;
	text-align: right;
	padding-top: 10px;
}

.askdoctor-modal .col-md-2 {
	padding-right: 0;
}
.askdoctor-modal .col-md-10 {
	padding-left: 0;
}

.askdoctor-modal .usr-img {
	height: 60px;
	border: 1px solid #ddd;
	border-right: 0;
}

.askdoctor-modal .usr-img img {
	max-height: 40px;
	border-radius: 50%;
	position: relative;
	margin: 8px auto;
}

.askdoctor-modal .modal-header {
	background-color: #f4f4f4;
	border-bottom-width: 2px;
}

.askdoctor-modal .modal-title {
	font-weight: normal;
}

.askdoctor-modal .human-img-block-inner {
	background-color: #fff;
}

.askdoctor-modal .symptom-checker-block {
	flex-direction: column;
}
.askdoctor-modal .symptom-checker-single {
	max-width: 100%;
	margin-bottom: 10px;
}

.askdoctor-modal #mapster_wrap_0,
.askdoctor-modal #mapster_wrap_1 {
	margin: auto;
}

.inline-svg-icon {
	position: relative;
	top: 5px;
	display: inline-block;
	width: 20px;
	height: 20px;
}

.inline-svg-icon.right {
	margin-left: 5px;
}

.inline-svg-icon.left {
	margin-right: 5px;
}

.inline-svg-icon > object {
	display: block;
	width: auto;
	height: auto;
	pointer-events: none;
}

p.address i {
	top: 4px;
	color: #ccc;
}

#like-bg {
	fill: #f0ffcd;
}

.btn-grey:hover #like-bg,
.btn-grey:focus #like-bg {
	fill: #00baff;
}

#like-border {
	fill: #43ac18;
}

.btn-grey svg:hover #like-border,
.btn-grey svg:focus #like-border {
	fill: #fff;
}

.ask-doc .form-control {
	border-color: #ddd;
	color: #656565;
}

.ask-doc textarea.form-control {
	margin-bottom: 15px;
}

.ask-doc .form-control:hover,
.ask-doc .form-control:focus {
	border-color: #00baff;
}

/* Ask A Doctor Javascript Functionality */

.text-edit-wrap {
	position: relative;
	border-top: 1px dashed transparent;
	padding-top: 10px;
	padding-bottom: 15px;
}


.edit-btn {
	position: relative;
	display: block;
	cursor: pointer;
	position: absolute;
	top: 0;
	right: 0;
	font-size: 18px;
	line-height: 22px;
	color: #fff;
	z-index: 100;
	margin-top: -30px;
}

.edit-btn.add {
	margin-top: -75px;
}

.edit-btn .btn-gray {
	margin-bottom: 0;
	border-color: #ddd;
	border-width: 1px;
	background-color: #fff;
	color: #616161;
}

.edit-btn .btn-gray:hover,
.edit-btn .btn-gray:focus {
	background-color: #fff;
	color: #27ae60;
	border-color: #27ae60;
}

.edit-btn .btn-gray:before {
	content: url('../img/ask-a-doc/icons/add.png');
	position: absolute;
	top: 0;
	left: 20px;
	display: inline-block;
	font-size: 35px;
	line-height: 52px;
}

.doctor-symptom-list-wrap {
	position: relative;
	border-top: 1px dashed transparent;
}

/*.text-edit-wrap:hover .edit-btn,
.doctor-symptom-list-wrap:hover .edit-btn {
	display: block;
}*/


.doctor-symptom-list li {
	position: relative;
	padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 55px;
    padding-right: 30px;
	cursor: pointer;
	border-bottom: 1px solid #d7d7d7;
	font-size: 18px;
	line-height: 22px;
}

/*.selected-symptom-list li:last-child {
	border-bottom: 0;
}*/

.doctor-symptom-list li span.handle-click:before {
	content: url('../img/ask-a-doc/icons/tick.png');
    position: absolute;
    top: 0;
    left: 20px;
    display: inline-block;
    font-size: 35px;
    line-height: 45px;
}

.doctor-symptom-list li span.detach-symptom:hover:before {
	content: url('../img/ask-a-doc/icons/cross.png');
}

.doctor-symptom-list li a {
	display: inline-block;
	padding: 0;
	border: 0;
}

.doctor-symptom-list li a:hover {
	text-decoration: underline;
}

.doctor-symptom-list li:hover a,
.doctor-symptom-list li:focus a {
	border: 0;
	background-color: transparent;
	color: #616161;
	font-weight: normal;
}

.doctor-symptom-list li a:before {
	content: '';
}

.dropzone {
	margin-bottom: 15px;
}

.inner-wrapper {
	position: relative;
	cursor: pointer;
}

.img-row .inner-wrapper:hover:before {
	content: '\e014';
	display: inline-block;
    font-family: 'Glyphicons Halflings';
    font-style: normal;
    font-weight: 400;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

	position: absolute;
	top: 50%;
	left: 50%;
	margin-left: -25px;
	margin-top: -25px;
	font-size: 50px;
    padding: 5px 7px;
    color: #e74c3c;
    border: 4px solid #e74c3c;
    border-radius: 50%;
    box-shadow: 0 0px 4px #222;
    pointer-events: none;
}

.fig-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;

	-webkit-flex-flow: row wrap;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
}

.fig-wrap > li {
	width: calc(33.33% - 10px);

	margin: 5px;
	list-style-type: none;
	position: relative;
}

.fig-wrap figure img {
	position: relative;
	z-index: 10;
	display: table;
	margin: auto;

	-webkit-transition: -webkit-transform 0.4s;
	transition: -webkit-transform 0.4s;
	transition: transform 0.4s;
	transition: transform 0.4s, -webkit-transform 0.4s;
}

.fig-wrap figure:hover img {
	-webkit-transform: scale(0.4);
           transform: scale(0.4);
}

.fig-wrap figcaption {
	position: absolute;
	top: 0;
	left: 0;
	padding: 10px 20px;
	background-color: #00baff;
	color: #fff;

	height: 100%;
	width: 100%;
	opacity: 0;

	-webkit-transform: scale(0.7);
	transform: scale(0.7);

	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;

	-webkit-transition: opacity 0.4s, -webkit-transform 0.4s;
	transition: opacity 0.4s, -webkit-transform 0.4s;
	transition: transform 0.4s, opacity 0.4s;
	transition: transform 0.4s, opacity 0.4s, -webkit-transform 0.4s;
}

.fig-wrap:hover figcaption {
	transform: scale(1);
	opacity: 1;
}


.fig-wrap figcaption header {
	padding: 0;
	margin: 0;
}

.fig-wrap figcaption header .cls-btn {
	position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
    z-index: 11;

	-webkit-transition: color 0.4s, -webkit-transform 0.4s;
	transition: color 0.4s, -webkit-transform 0.4s;
	transition: transform 0.4s, color 0.4s;
	transition: transform 0.4s, color 0.4s, -webkit-transform 0.4s;
}

.fig-wrap figcaption header .cls-btn:hover,
.fig-wrap figcaption header .cls-btn:focus {
	color: #d9534f;
	-webkit-transform: scale(1.4);
	transform: scale(1.4);
}

.fig-wrap figcaption footer {
	border: 0;
	padding: 0;
	margin: 0;
	background-color: transparent;
	position: absolute;
    bottom: 5px;
    width: 100%;
    left: 0;
    z-index: 11;

	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	  justify-content: flex-end;
}

.fig-wrap figcaption footer p {
	margin-bottom: 0;
	margin-right: 20px;
	color: #fff;
	cursor: pointer;

	-webkit-transition: -webkit-transform 0.4s;
	transition: -webkit-transform 0.4s;
	transition: transform 0.4s;
	transition: transform 0.4s, -webkit-transform 0.4s;
}

.fig-wrap figcaption footer p a {
	color: #fff;
}

.fig-wrap figcaption footer p:last-child {
	margin-right: 5px;
}

.fig-wrap figcaption footer p:hover,
.fig-wrap figcaption footer p:focus {
	-webkit-transform: scale(1.4);
	transform: scale(1.4);
}

.fig-wrap figcaption h3 {
	color: #fff;
	margin: 0;
	padding: 0;
	font-size: 20px;
	line-height: 25px;
}

.btn-group-center {
	display: table;
	margin: auto;
}

.modal .btn-white {
	color: #656565;
	border-color: #ddd;
	border-width: 1px;
	background-color: #fff;
}

.modal .btn-right {
	margin-top: 0;
}

.btn-group-center .btn-blue {
	border-width: 1px;
}

.modal .btn-white:hover,
.modal .btn-white:focus {
	background-color: #00baff;
	border-color: #00baff;
	color: #fff;
}

.progress-bar {
	height: 2px;
	width: 100%;
	margin: 25px 0;
	position: relative;
	background-color: #f4f4f4;
	box-shadow: inset 0 -1px 1px rgba(255,255,255,0.3);
}

.progress-bar > .dot {
	position: absolute;
	top: 0;
	width: 10px;
	height: 10px;
	margin-top: -4px;
	margin-left: -9px;
	border-radius: 50%;
	background-color: #f4f4f4;
	z-index: 10;
}

.progress-bar > .dot.blue {
	background-color: #00baff;
}

.progress-bar > .dot.one {
	left: 0;
}

.progress-bar > .dot.two {
	left: 25%;
}

.progress-bar > .dot.three {
	left: 50%;
}

.progress-bar > .dot.four {
	left: 75%;
}

.progress-bar > .dot.five {
	left: 100%;
}

.progress-bar > .done {
	display: block;
	height: 100%;
	background-color: #00baff;
	position: relative;

	-webkit-transition: all ease 0.3s;
	transition: all ease 0.3s;
}

.progress-bar > .done:before {
	content: '';
	position: absolute;
	top: 0;
	right: 0;
	width: 25px;
    height: 25px;
    margin-top: -12px;
    margin-right: -12.5px;
	border-radius: 50%;
	background-color: #fff;
	border: 1px solid #ddd;
	z-index: 11;
}

.modal .form-control {
	border-color: #ddd;
}

.modal .form-control:focus {
	border-color: #00baff;
}

/*.form-horizontal .checkbox,
.form-horizontal .checkbox-inline,
.form-horizontal .radio,
.form-horizontal .radio-inline {
	margin-bottom: 20px;
}*/

.modal .checkbox .label-text,
.modal .radio .label-text,
.checkbox .label-text,
.radio .label-text,
.checkbox-inline .label-text {
	margin-left: 30px;
	line-height: 21px;
	font-weight: normal;
}

.modal .radio-inline .label-text {
	padding-left: 5px;
}

.modal .label-text {
	color: #656565;
}

.modal .select2-container--default .select2-selection--single .select2-selection__rendered {
	line-height: 46px;
	background-color: #fff;
}

.modal .select2-container::after {
	content: "\f107";
    position: absolute;
    top: 0;
    right: 0;
    color: #ddd;
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 25px;
    font-weight: bold;
    right: 30px;
    line-height: 48px;
}

.modal .select2-container--open.select2-container::after {
	content: "\f106";
	color: #00baff;
}

.select2-container--open .select2-dropdown--below,
.select2-container--open .select2-dropdown--above {
	-webkit-box-shadow: none;
	box-shadow: none;
}

.modal .symptom-checker-block {
	display: block;
}

.modal .symptom-checker-single {
	width: 100%;
	max-width: 100%;
	margin-bottom: 10px;
}

.modal-sym-tab {
	display: table;
	margin: auto;
	border-bottom: 0;
}

.modal-sym-tab > li {
	margin-bottom: 0;
}

.modal-sym-tab > li > a {
	border: 1px solid #ddd;
	border-radius: 0;
	min-width: 125px;
	text-align: center;
	margin: 0;
}
.modal-sym-tab > li.active > a,
.modal-sym-tab > li.active:focus > a,
.modal-sym-tab > li.active:hover > a,
.modal-sym-tab > li > a:hover,
.modal-sym-tab > li > a:focus {
	background-color: #ddd !important;
	border-color: #ddd !important;
}

.upload-wrap {
	display: block;
}

.upload-single {
	display: block;
	padding-top: 15px;
	border-bottom: 1px dashed #c1c1c1;
}

.upload-single:last-child {
	border-bottom: 0;
}

.upload-single .row {
	margin-bottom: 15px;
}

p.upload-title {
	position: relative;
	margin: 0;
	padding-left: 35px;
}

p.upload-title:before {
	content: '';
	position: absolute;
	top: 50%;
	left: 0;
	width: 15px;
	height: 15px;
	margin-top: -7.5px;
	border-radius: 50%;
}

.upload-single.img p.upload-title:before {
	background-color: #27ae60;
}
.upload-single.video p.upload-title:before {
	background-color: #f1c40f;
}
.upload-single.prescription p.upload-title:before {
	background-color: #2980b9;
}
.upload-single.lab p.upload-title:before {
	background-color: #663399;
}

.modal .collapse {
	width: 100%;
}

.modal .human-image > div,
.modal .human-image .img-right,
.modal #mapster_wrap_0,
.modal #mapster_wrap_1,
.modal #mapster_wrap_2,
.modal #mapster_wrap_3,
.modal #mapster_wrap_4,
.modal #mapster_wrap_5 {
	margin: auto;
}

.bn-version .btn-add-files {
	font-size: 16px;
	line-height: 20px;

}

.btn.btn-add-files {
	display: none;
	margin-left: 5px;
	margin-top: 10px;
	margin-bottom: 10px;
	padding: 5px 10px;
}

.btn.btn-add-files {
	display: block;
}

textarea.html-editor {
	background-color: #fff;
}

.note-editor .btn.btn-default {
	border-color: #ddd;
	border-width: 1px;
	padding: 10px;
	margin-bottom: 5px;
	color: #222;
	font-size: 16px;
	line-height: 20px;
}

.note-editor .btn.btn-default:hover,
.note-editor .btn.btn-default:focus {
	background-color: #00baff;
	border-color: #00baff;
	color: #fff;
}

.note-editor .note-editable {
	background-color: #fff;
}

.ques-pop {
	position: relative;
	min-width: 385px;
	max-width: 385px;
	margin: 30px auto;
	padding: 40px 20px;
	background-color: #fff;
	border: 1px solid #ddd;
	box-shadow: 3px 5px 10px #ddd;
}

.ques-pop .close {
	opacity: .8;
	position: absolute;
	top: 0;
	right: 0;
	margin-top: -19.5px;
	margin-right: -19.5px;
}

.ques-pop .close:hover {
	opacity: 1;
}

.ques-pop-head {
	display: block;
	padding: 15px;
}

.ques-pop-head button.close {
	position: absolute;
	top: 0;
	right: 0;
	z-index: 100;
}

.ques-pop-body {
	display: block;
}

.ques-pop-body h3 {
	color: #656565;
	margin-bottom: 30px;
}

.ques-pop-body .question .btn-primary {
	border-width: 1px;
	border-color: #43ac18;
	background-color: #fbfff3;
	color: #43ac18;
	padding: 10px 30px;
}

.ques-pop-body .question .btn-danger {
	border-width: 1px;
	border-color: #DD2020;
	background-color: #fff3f3;
	color: #DD2020;
	padding: 10px 30px;
}

.ques-pop-footer .btn-warning {
	display: table;
	margin: auto;
}

.btn-danger {
	background-color: transparent;
	color: #d43f3a;
}

.btn.btn-danger:hover,
.btn.btn-danger:focus {
	background-color: #C9502C;
	border-color: #C9502C;
}

.suggested-docs .media-left a {
	display: block;
	min-width: 70px;
	max-width: 70px;
}

.suggested-docs .media-body {
	vertical-align: middle;
}

.suggested-docs .media-body h4 {
	margin-bottom: 0;
}

.share-btn-wrap {
	position: relative;
}

.share-btn-wrap .social-share {
	position: absolute;
	bottom: 100%;
	left: -50%;
	margin-left: 20px;
	-webkit-transform: unset;
	transform: unset;
	opacity: 1;

	display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    opacity: 0;
    -webkit-transition: all ease 0.3s;
    transition: all ease 0.3s;
}

.share-btn-wrap:hover .social-share {
	opacity: 1;
}

.share-btn-wrap .social-share > li {
	list-style: none;
}

.share-btn-wrap .social-share > li > a {
	color: #fff;
	min-width: 35px;
}

.no-data-found {
	background-color: #fff;
	border: 1px solid #ddd;
	min-height: 400px;

	display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;

	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;

	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	-ms-grid-row-align: center;
	align-items: center;

	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
}

@media ( max-width: 767px ) {
	.doc-faq-ans header {
		-webkit-flex-flow: row wrap;
        -ms-flex-flow: row wrap;
            flex-flow: row wrap;
	}
	.doc-faq-ans header .right {
		min-width: auto;
		margin-top: 20px;

		-webkit-box-pack: center;
	-webkit-justify-content: center;
	    -ms-flex-pack: center;
	        justify-content: center;
	}
}

/* Appointment Page Rules
==========================
*/

.appointment {
	padding: 50px 0;

	display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;

    -webkit-flex-flow: row wrap;
	-ms-flex-flow: row wrap;
	flex-flow: row wrap;

	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
}

.appointment .doc-panel {
	position: relative;
	margin: 0 5px;
	background-color: #fff;
	border: 1px solid #ddd;
	max-width: 300px;
	min-width: 300px;

	display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;

	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;

}


.doc-panel.author {
	background-color: #fff;

	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.doc-panel.author .author-body {
	padding: 40px 10px;
}

.doc-panel.author .img-wrap {
	margin: 0 auto 30px auto;
	max-width: 125px;
	border: 5px solid #fff;
	box-shadow: 0 2px 6px #ddd;
}

.doc-panel.author h4 {
	font-size: 25px;
	line-height: 30px;
	color: #656565;
	font-weight: bold;
	text-align: center;
	margin-top: 0;
}

.doc-panel.author p {
	margin-bottom: 5px;
}

.doc-panel.author .doc-speciality {
	color: #00baff;
	text-align: center;
}

.doc-panel.author .doc-degree {
	text-align: center;
	color: #c1c1c1;
	margin-bottom: 20px;
}

.doc-panel.author .doc-address {
	text-align: center;
	color: #c1c1c1;
}

.doc-panel.author .doc-address i {
	top: 3px;
	margin-right: 10px;
}

.doc-panel.author .rating-wrap {
	background-color: #f5f5f5;
	padding: 15px 10px;
	border-top: 1px solid #ddd;
}

.doc-panel.author .rating {
	display: table;
	margin: auto;
}

.appointment-wrapper {
	background-color: #f4f4f4;
}

.doc-panel.author .visiting-date {
	color: #c1c1c1;
	margin: 10px 20px;
}

.doc-panel.author .visiting-hour {
	color: #c1c1c1;
	margin: 10px 20px;
}

@media ( min-width: 992px ) {
	.appointment .doctor-appointment-date {
		min-width: 400px;
	}
}

.doc-appointment-header {
	background-color: #f5f5f5;
	padding: 15px 10px;
	border-bottom: 1px solid #ddd;
}

.doc-appointment-header h4 {
	font-weight: bold;
	text-align: center;
	margin: 0;
}

.datetimepicker-inline {
	padding: 4px 20px;
	width: 100%;
}

.doc-appointment-body thead tr:first-child th {
	font-size: 20px;
	line-height: 25px;
	color: #656565;
	font-weight: normal;
}

.doc-appointment-body thead tr:last-child th {
	color: #00baed;
}

.datetimepicker table {
    border-collapse: separate;
    border-spacing: 5px;
    margin-top: 20px;
}

.datetimepicker td {
	border-radius: 0;
	border: 1px solid #00baed;
	color: #656565;
	font-weight: normal;
	line-height: 25px;
}

.datetimepicker td.disabled {
	border: 0;
	color: #c1c1c1;
	cursor: not-allowed;
}

.datetimepicker td.day.disabled:hover,
.datetimepicker td.day.disabled:focus {
	border: 0;
	color: #c1c1c1;
	cursor: not-allowed;
	background-color: #fff;
}

.datetimepicker table tr td.day:hover {
	background-color: #00baed;
	color: #fff;
	border-color: #00baed;
}

.datetimepicker table tr td.active:active,
.datetimepicker table tr td.active:hover:active,
.datetimepicker table tr td.active.disabled:active,
.datetimepicker table tr td.active.disabled:hover:active,
.datetimepicker table tr td.active.active,
.datetimepicker table tr td.active:hover.active,
.datetimepicker table tr td.active.disabled.active,
.datetimepicker table tr td.active.disabled:hover.active,
.datetimepicker table tr td span.active:active,
.datetimepicker table tr td span.active:hover:active,
.datetimepicker table tr td span.active.disabled:active,
.datetimepicker table tr td span.active.disabled:hover:active,
.datetimepicker table tr td span.active.active,
.datetimepicker table tr td span.active:hover.active,
.datetimepicker table tr td span.active.disabled.active,
.datetimepicker table tr td span.active.disabled:hover.active {
	color: #fff;
	background-color: #00baed;
	background-image: none;
	border-radius: 0;
	border-color: #00baed;
	-webkit-box-shadow: 0 6px 15px #ddd;
	box-shadow: 0 6px 15px #ddd;
}

.datetimepicker table tr td.active,
.datetimepicker table tr td.active:hover,
.datetimepicker table tr td.active.disabled,
.datetimepicker table tr td.active.disabled:hover {
	background-color: #00baed;
	background-image: none;
	border-radius: 0;
	border-color: #00baed;
	-webkit-box-shadow: 0 6px 15px #ddd;
	box-shadow: 0 6px 15px #ddd;
}

.doctor-appointment-time {
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.doctor-appointment-body-wrapper {
	display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;

	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;

	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;

	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.doctor-appointment-time-body {
	max-height: 350px;
	overflow-y: auto;
}

.doctor-appointment-time-header {
	padding: 15px 10px;
	background-color: #f5f5f5;
	border-bottom: 1px solid #ddd;
}

.doctor-appointment-time-header h4 {
	font-weight: bold;
	margin: 0;
	text-align: center;
}

p.select-appointment-time-single {
	position: relative;
	margin: 0;
	padding: 20px 10px 20px 45px;
	border-bottom: 1px solid #ddd;
}

p.select-appointment-time-single:last-child {
	border-bottom: 0;
}

p.select-appointment-time-single:before {
    content: url('../img/doc-appointment/tick-gray.png');
    position: absolute;
    background-size: 25px 25px;
    background-repeat: no-repeat;
    left: 10px;
    top: 50%;
    margin-top: -12.5px;
	width: 25px;
    height:25px;
}

p.select-appointment-time-single:hover,
p.select-appointment-time-single:focus {
	cursor: pointer;
	background-color: #f5f5f5;
}

p.select-appointment-time-single:hover:before,
p.select-appointment-time-single:focus:before,
p.select-appointment-time-single.active:before {
	content: url('../img/doc-appointment/tick-green.png');
}

.doctor-appointment-time-footer {
	padding: 10px;
	border-top: 1px solid #ddd;
}

.doctor-appointment-time-footer .btn-full {
	margin-bottom: 0;
}

.doctor-appointment-time-footer .btn-full.disabled:hover,
.doctor-appointment-time-footer .btn-full.disabled:focus,
.doctor-appointment-time-footer .btn-full.active.disabled,
.doctor-appointment-time-footer .btn-full.active.disabled:hover,
.doctor-appointment-time-footer .btn-full.active.disabled:focus {
	cursor: not-allowed;
	background-color: #ddd;
	color: #000;
	border-color: #ddd;
	outline: 0;
}

.icon-arrow-left:before {
	content: '\e257';
}

.icon-arrow-right:before {
	content: '\e258';
}

.doctor-appointment-form {
	margin-left: 20px;
	background-color: #fff;
	border: 1px solid #ddd;
}

.doctor-appointment-form-header {
	background-color: #f4f4f4;
	border-bottom: 1px solid #ddd;
}

.doctor-appointment-form h3 {
	text-align: center;
	padding: 15px;
	color: #656565;
}

.doctor-appointment-form-body {
	margin-top: 10px;
	padding: 0 20px;
}

.doctor-appointment-form .form-control {
	border-color: #ddd;
	color: #656565;
}

.doctor-appointment-form .form-control:focus {
	border-color: #00baff;
}

.doctor-appointment-form.form-horizontal .control-label {
	padding-top: 0;
}

.doctor-appointment-form .radio-inline span.label-text {
	padding-left: 5px;
}

.doctor-appointment-form.form-horizontal .control-label.text-left {
	text-align: left;
	font-weight: normal;
	font-size: 20px;
	line-height: 25px;
}

.myself,
.relatives,
.others {
  margin-bottom: 20px;
}

@media ( min-width: 992px) {
	.doctor-appointment-form {
		min-width: 70%;
	}
}

/* Health Profiler Page Rules
==============================
*/
h2.no-margin {
	margin: 0;
}

.health-profiler {
	position: relative;
	padding: 50px 0;
	background-color: #f6f6f6;
}

.sans-serif div,
.sans-serif h1,
.sans-serif h2,
.sans-serif h3,
.sans-serif h4,
.sans-serif h5,
.sans-serif h6,
.sans-serif p,
.sans-serif input,
.sans-serif textarea,
.sans-serif button,
.sans-serif span,
.sans-serif aside {
	font-family: 'Open Sans', sans-serif;
}

.solaiman div,
.solaiman h1,
.solaiman h2,
.solaiman h3,
.solaiman h4,
.solaiman h5,
.solaiman h6,
.solaiman p,
.solaiman input,
.solaiman textarea,
.solaiman button,
.solaiman span,
.solaiman aside {
	font-family: 'SolaimanLipi';
}

.profile-sidebar {
	background-color: #fff;
	border: 1px solid #ddd;
}

.profile-sidebar .nav-vault {
	border: 0;
}

.profile-sidebar .nav-vault > li > .vault-dropdown-toggle {
	position: relative;
}

.profile-sidebar .nav-vault>li>a {
	font-size: 18px;
	line-height: 23px;
	font-weight: 500;
	color: #6d6d6d;
	padding: 15px 25px 15px 55px;
	border-color: #f4f4f4;
	cursor: pointer;
}

.profile-sidebar .nav-vault li ul>li>a {
	font-size: 16px;
	line-height: 20px;
	font-weight: 500;
	color: #6d6d6d;
	padding: 15px 25px 15px 85px;
	border-color: #f4f4f4;
	cursor: pointer;
}

.sans-serif .profile-sidebar .nav-vault>li>a {
	font-size: 16px;
	line-height: 20px;
	font-weight: 100;
}

.sans-serif .profile-sidebar .nav-vault li ul>li>a {
	font-size: 14px;
	line-height: 18px;
}
.profile-sidebar .nav-vault li ul>li>a {
    position: relative;
}

.profile-sidebar .nav-vault>li:first-child>a {
	border-top: 1px solid #f4f4f4;
}

.profile-sidebar .nav-vault>li>a:hover,
.profile-sidebar .nav-vault>li>a:focus,
.profile-sidebar .nav-vault>li.active>a,
.profile-sidebar .nav-vault>li.active>a:hover,
.profile-sidebar .nav-vault>li.active>a:focus {
	background-color: #f8faf9;
	border-color: #f8faf9;
	color: #222;
}

.profile-sidebar .nav-vault li ul>li>a:hover,
.profile-sidebar .nav-vault li ul>li>a:focus,
.profile-sidebar .nav-vault li ul>li.active>a,
.profile-sidebar .nav-vault li ul>li.active>a:hover,
.profile-sidebar .nav-vault li ul>li.active>a:focus {
	background-color: #ebebeb;
	border-color: #ebebeb;
}

.profile-sidebar .nav-vault>.vault-dropdown>a::after {
	line-height: 52px;
	font-weight: normal;
	color: #cfcfcf;
}

.img-icon:before {
	display: inline-block;
	position: absolute;
	top: 50%;
	left: 20px;
	margin-top: 3px;

	-webkit-transform: translateY(-50%) scale(.8);
	transform: translateY(-50%) scale(.8);
}

.nav-vault > li > ul > li > a .img-icon:before {
    left: 50px;
}

.img-add:before {
	content: url(../img/health_profile/add.png);
}
.img-profile:before {
	content: url(../img/health_profile/profile.png);
}
.img-appointment:before {
	content: url(../img/health_profile/appointment.png);
}
.img-scale:before {
	content: url(../img/health_profile/scale.png);
}
.img-health:before {
	content: url(../img/health_profile/health.png);
}
.img-aid:before {
	content: url(../img/health_profile/aid.png);
}
.img-blood:before {
	content: url(../img/health_profile/blood.png);
}
.img-birth-control:before {
	content: url(../img/health_profile/birth_control.png);
}
.img-doc:before {
	content: url(../img/health_profile/doc.png);
}
.img-file:before {
	content: url(../img/health_profile/file.png);
}
.img-health-problem:before {
	content: url(../img/health_profile/health-problem.png);
}
.img-menstruation:before {
	content: url(../img/health_profile/menstruation.png);
}
.img-medications:before {
	content: url(../img/health_profile/medications.png);
}
.img-height:before {
	content: url(../img/health_profile/height.png);
}
.img-weight:before {
	content: url(../img/health_profile/weight.png);
}
.img-peak-flow:before {
	content: url(../img/health_profile/peak-flow.png);
}
.img-blood-pressure:before {
	content: url(../img/health_profile/blood-pressure.png);
}
.img-blood-glucose:before {
	content: url(../img/health_profile/blood-glucose.png);
}
.img-blood-cholesterol:before {
	content: url(../img/health_profile/blood-cholesterol.png);
}
.img-addiction:before {
	content: url(../img/health_profile/addiction.png);
}
.img-exercise:before {
	content: url(../img/health_profile/exercise.png);
}
.img-health-condition:before {
	content: url(../img/health_profile/health-condition.png);
}
.img-allergy:before {
	content: url(../img/health_profile/allergy.png);
}
.img-disability:before {
	content: url(../img/health_profile/disability.png);
}
.img-operation:before {
	content: url(../img/health_profile/operation.png);
}
.img-immunization:before {
	content: url(../img/health_profile/immunization.png);
}
.img-prosthetic-device:before {
	content: url(../img/health_profile/prosthetic-device.png);
}
.img-blood-transfusion:before {
	content: url(../img/health_profile/blood-transfusion.png);
}
.img-blood-donation:before {
	content: url(../img/health_profile/blood-donation.png);
}
.img-birth-control-method:before {
	content: url(../img/health_profile/birth-control-method.png);
}
.img-menstruation-history:before {
	content: url(../img/health_profile/menstruation-history.png);
}
.img-medication:before {
	content: url(../img/health_profile/medication.png);
}
.img-prescription:before {
	content: url(../img/health_profile/prescription.png);
}
.img-report-upload:before {
	content: url(../img/health_profile/upload.png);
}
.img-lab-result:before {
	content: url(../img/health_profile/lab-result.png);
}
.img-lab-report:before {
	content: url(../img/health_profile/lab-report.png);
}

.profile-sidebar .img-upload {
	max-width: 100px;
    margin: 40px auto 20px auto;
}

.profile-sidebar .img-circle {
	border: 5px solid #fff;
	box-shadow: 0 6px 15px #ddd;
}

.profile-sidebar .profile-title {
	margin-bottom: 20px;
}

.sans-serif .profile-sidebar .profile-title {
	font-weight: 300;
}

.img-upload {
	position: relative;
	margin-bottom: 5px;
}

.img-upload p {
	position: absolute;
	top: 50%;
	left: 50%;
	margin: 0;
	height: 100%;
	width: 100%;
	border-radius: 50%;
	color: #fff;
	cursor: pointer;
	text-align: center;

	-webkit-transform: translateX(-50%) translateY(-50%);
	transform: translateX(-50%) translateY(-50%);
	-webkit-transition: all ease 0.3s;
	transition: all ease 0.3s;
}

.img-upload p i {
	color: #ddd;
	font-size: 30px;
	line-height: 30px;
	min-height: 100px;

	-webkit-transition: all ease 0.3s;
	transition: all ease 0.3s;

	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;

	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;

	-webkit-box-align: end;
	-ms-flex-align: end;
	align-items: flex-end;
}

.img-upload p span {
	font-size: 20px;
	line-height: 20px;
	opacity: 0;
}

.img-upload:hover p {
	background-color: rgba(0, 0, 0, 0.7);
}

.img-upload:hover i {
	color: #fff;
	font-size: 20px;
	line-height: 20px;
	min-height: auto;
	height: 100%;

	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;

	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
}

.img-upload:hover span {
	opacity: 1;
}

.btn.btn-small {
	padding-left: 5px;
	padding-right: 5px;
	line-height: 26px;
}

.btn-small .caret {
	height: 11px;
}

.prescription-form .col-sm-4,
.prescription-form .col-sm-2,
.prescription-form .col-sm-1,
.prescription-form .col-sm-3 {
	padding-left: 5px;
	padding-right: 5px;
}

.prescription-form-item {
	display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;

	-webkit-flex-flow: row wrap;
	-ms-flex-flow: row wrap;
	flex-flow: row wrap;

    margin-bottom: 10px;
}

/*.prescription-form-item-sub {
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;

	margin: 0 2px;
}*/

.prescription-form-item-sub .form-control {
	font-size: 15px;
	line-height: 15px;
}

.prescription-form-item-sub.button-action {
	width: calc(5% - 4px);
	margin: 5px 2px;
	padding: 0;
}

.btn-action {
	width: 100%;
}

.btn-action.add {
	color: #fff;
	background-color: #2ecc71;
}

.btn-action.remove {
	color: #fff;
	background-color: #e74c3c;
}

.btn-action.add i,
.btn-action.remove i {
	top: 3px;
}

.btn-action.add:hover,
.btn-action.add:focus {
	background-color: #27ae60;
	border-color: #27ae60;
}

.btn-action.remove:hover,
.btn-action.remove:focus {
	background-color: #c0392b;
	border-color: #c0392b;
}

.prescription-form-item-sub.drug {
	width: calc(35.5% - 4px);
	margin: 5px 2px;
}

.prescription-form-item-sub.strength,
.prescription-form-item-sub.dose,
.prescription-form-item-sub.formation {
	width: calc(21.5% - 4px);
	margin: 5px 2px;
}

.prescription-form-item-sub.duration {
	width: calc(30% - 4px);
	margin: 5px 2px;
}

.prescription-form-item-sub.instruction {
	width: calc(65% - 4px);
	margin: 5px 2px;
}


.prescription-form-item-sub .form-control {
	padding: 5px;
	height: 34px;
}

.tokenfield.form-control {
	height: auto;
	padding: 10px 10px 4px 10px;
}

.tokenfield .token .close {
	line-height: 20px;
}

.paddless.btn {
	padding-left: 10px;
	padding-right: 10px;
}

.tokenfield-btn-wrap .paddless.btn {
	margin-top: 23px;
	width: 100%;
}

.height-50 {
	min-height: 50px;
}

.height-50 .caret {
	position: relative;
	top: -3px;
}

.inline-input-group,
.input-unit {
	display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

.margin-right-5 {
	margin-right: 5px;
}

.width-80,
.input-group .width-80 {
	width: 80%;
}

.width-75,
.input-group .width-75 {
	width: 75%;
}

.width-60,
.input-group .width-60 {
	width: 60%;
}

.width-50,
.input-group .width-50 {
	width: 50%;
}

.width-40,
.input-group .width-40 {
	width: 40%;
}

.width-33,
.input-group .width-33 {
	width: 33.33%;
}

.width-25,
.input-group .width-25 {
	width: 25%;
}

.width-20,
.input-group .width-20 {
	width: 20%;
}

.inline-input-group .input-unit .btn {
	padding: 5px;
	width: 100%;
}

.inline-input-group .input-unit .caret {
	position: relative;
	top: -3px;
}
.profile-details > h1 {
	color: #666666;
	margin-top: 0;
	margin-bottom: 20px;
}

.profile-details {
	position: relative;
	background-color: #fff;
	border: 1px solid #ddd;
	margin-bottom: 25px;
}

.profile-details-basic {
	padding: 25px;
}

.profile-details .author-address {
	background-color: #fbfbfb;
	padding: 15px 25px;
	border-top: 1px solid #ddd;
}

.profile-details .author-address > p {
	margin-bottom: 0;
}

.profile-details p {
	margin-bottom: 15px;
}

p.edit-profile {
	position: absolute;
	top: 10px;
	right: 10px;
	text-align: center;
	line-height: 35px;
	width: 35px;
	height: 35px;
	margin: 0;
	color: #00baff;
	border-radius: 50%;
	cursor: pointer;
	-webkit-transition: all ease 0.3s;
	transition: all ease 0.3s;
	z-index: 1;
}

.edit-action-menu,
ul.edit-action-menu {
	box-shadow: none;
	border: 1px solid #ddd;
	margin-right: -1px;
	min-width: auto;
	box-shadow: 0 0 15px #ddd;
}

.profile-desc-input {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;

	-webkit-box-pack: start;
	-ms-flex-pack: start;
	justify-content: flex-start;

	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}

/*ul.edit-action-menu:after {
	content: " ";
    position: absolute;
    top: -6px;
    left: 50%;
    margin-left: -6px;
    width: 0;
    height: 0;
    border-top: transparent;
    border-left: 6px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 6px solid #e5e5e5;
    z-index: 1;
}

ul.edit-action-menu:before {
	content: " ";
    position: absolute;
    top: -4px;
    left: 50%;
    margin-left: -5px;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid #fff;
    z-index: 2;
}*/

ul.edit-action-menu > .inner > li {
	padding: 10px;
}

.edit-action-menu .inner {
	display: flex;
	justify-content: center;
}

.edit-action-menu .inner > li > i {
	font-size: 20px;
	line-height: 30px;
}

.green {
	color: #2ecc71;
}

.profile-details .edit-profile > i {
	line-height: 30px;
}

p.edit-profile:hover {
	background-color: #00baff;
	border-color: #00baff;
	color: #fff;
}

.profile-details-basic p > i,
.author-address p > i {
	display: inline-block;
	font-size: 25px;
	line-height: 30px;
	margin-right: 15px;
}

.profile-details-basic p > span,
.author-address p > span {
	position: relative;
	top: -3px;
}

.dark-blue {
	color: #404d55;
}

.light-blue {
	color: #09a8df;
}

.light-gray {
	color: #a0b1b8;
}

.dark-gray {
	color: #6f7e83;
}

.light-green {
	color: #02d4b1;
}

.light-orange {
	color: #ff991b;
}

.maroon {
	color: #a73096;
}

.no-list-added {
	background-color: #fff;
	border: 1px solid #ddd;
	min-height: 200px;
	margin-bottom: 25px;
	padding: 25px;

	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;

	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;

	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;

	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}

.no-list-added > .img-wrap {
	max-width: 100px;
	margin-bottom: 25px;
}

.add-new-item {
	position: relative;
	background-color: #fff;
	border: 1px solid #ddd;
	padding: 60px 25px 25px 25px;
	margin-bottom: 25px;
	box-shadow: 0 8px 17px 0 #ddd,0 -4px 20px 0 #ddd;
}

.add-new-item .control-label {
	color: #6f6f6f;
}

.add-new-item > .close {
	position: absolute;
	top: 15px;
	right: 25px;
}

.add-new-item > .close > span {
	display: block;
	width: 20px;
	height: 20px;
	border: 1px solid #6f6f6f;
	border-radius: 50%;
}

.add-new-item .form-control {
	/*color: #6f6f6f;*/
	border-color: #eee;
}

select.form-control {
	color: #222;
}

.add-new-item .action-btn-wrap {
	justify-content: flex-end;
}

.add-new-item .action-btn-wrap > button:first-child {
	margin-right: 10px;
}

.no-list-added p {
	margin: 0;
	font-size: 25px;
	line-height: 30px;
	color: #c1c1c1;
}

.list-details {
	background-color: #fff;
	border: 1px solid #ddd;
}

.list-details .list-details-header {
	padding: 25px;
	border-bottom: 1px solid #ddd;
}

.list-details-header h4 {
	font-size: 25px;
	line-height: 30px;
	margin-top: 0;
	margin-bottom: 0;
}

.list-details-header p {
	color: #999;
	margin-bottom: 0;
	font-size: 16px;
	line-height: 20px;
}

.list-details-body {
	padding: 25px;
}

.note {
	background-color: #fff9f3;
	border: 1px solid #ff991b;
	padding: 20px;
	border-radius: 4px;
	min-height: 240px;
}

.pagination-wrap {
	position: relative;
	margin: 30px 0;
}

.profile-list-pagination {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;

	-webkit-box-pack: end;
	-ms-flex-pack: end;
	justify-content: flex-end;

	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}

.profile-list-pagination > li {
	list-style: none;
	font-size: 25px;
	line-height: 30px;

	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}

.profile-list-pagination > li > a {
	color: #929292;
	padding: 5px 15px;
	border-radius: 50%;
	width: 40px;
	height: 40px;

	-webkit-transition: all ease 0.3s;
	transition: all ease 0.3s;

	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;

	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;

	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}

.profile-list-pagination > li.prev > a,
.profile-list-pagination > li.next > a {
	border: 1px solid #ddd;
}

.profile-list-pagination > li.active > a,
.profile-list-pagination > li > a:hover,
.profile-list-pagination > li > a:focus,
.profile-list-pagination > li.prev > a:hover,
.profile-list-pagination > li.prev > a:focus,
.profile-list-pagination > li.next > a:hover,
.profile-list-pagination > li.next > a:focus {
	border-color: #00baff;
	background-color: #00baff;
	color: #fff;
}

.list-added-successful {
	background-color: #fff;
	min-height: 200px;
	max-width: 500px;
	margin: auto;
	box-shadow: 0 1px 20px #ddd;
	padding: 25px;
	margin-bottom: 25px;

	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;

	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;

	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;

	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
}

.list-added-successful .img-wrap {
	max-width: 100px;
	margin-bottom: 25px;
}

.list-added-successful p {
	color: #27ae60;
	font-size: 25px;
	line-height: 25px;
	margin: 0;
}

.profile-edit-wrap {
	margin-bottom: 25px;
}

.profile-edit {
	background-color: #fff;
	border: 1px solid #ddd;
}

.profile-edit .profile-head {
	position: relative;
	padding: 25px;
	border-bottom: 1px solid #eee;
}

.profile-edit .profile-head h3 {
	margin-bottom: 10px;
	color: #777777;
}

.profile-edit .profile-head p {
	margin-bottom: 0;
}

.profile-edit .control-label {
	color: #6f6f6f;
}

.profile-edit .form-group {
	margin-bottom: 0;
}

.profile-edit .form-control {
	border-color: #eee;
	color: #222;
	padding-left: 10px;
	padding-right: 10px;
	height: 30px;
}

.profile-edit .profile-body {
	padding: 25px;
}

.profile-edit .img-upload:hover p {
	border-radius: 0;
}

.row.margin-bottom-10 {
	margin-bottom: 10px;
}

.question-wizard {
	position: relative;
	background-color: #fff;
	border: 1px solid #ddd;
	margin-bottom: 25px;
}

.question-wizard .basic-question{
	padding: 25px 25px 70px 25px;
}

.action-list {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;

	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;

	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}

.action-list > li {
	color: #ccc;
	list-style: none;
	font-size: 16px;
	line-height: 20px;
	margin: 0 10px;
	cursor: pointer;

	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.action-list > li:hover,
.action-list > li:focus {
	color: #6f6f6f;
}

.action-list > li:last-child {
	margin-right: 0;
	margin-left: 30px;
}

.action-list > li > .close > span {
	display: block;
	width: 20px;
	height: 20px;
	border: 1px solid #6f6f6f;
	border-radius: 50%;
}

.form-flex {
	margin-left: 15px;

	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

	-ms-flex-flow: row wrap;
	flex-flow: row wrap;
}

.basic-question .slick-slide > h3 {
	margin-bottom: 25px;

	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}

.basic-question .slick-slide h2 {
	font-size: 35px;
	line-height: 40px;
}

.basic-question .form-control {
	border-color: #ddd;
}

.basic-question .form-control:focus {
	border-color: #00baff;
}

.form-control.others-input {
	display: none;
	margin-left: -20px;
    width: calc(100% + 20px);
    height: 35px;
    margin-top: 10px;
	padding: 8px;
	color: #777;
}

input.others-radio:checked + .radio-btn + .label-text + .others-input,
input.others-checkbox:checked + .checkbox-btn + .label-text + .others-input {
	display: block;
}

.full-block .form-control.others-input {
	margin-left: 0;
	max-width: 200px;
}

.full-block {
	display: block;
	width: 100%;
	margin: 25px 0;
	padding-left: 25px;
}

.full-block.if-smoker,
.full-block.if-retired-smoker,
.full-block.if-alcoholic,
.full-block.if-drug-addict,
.full-block.if-disabled,
.full-block.if-food-allergy,
.full-block.if-drugs-allergy,
.full-block.if-animals-allergy,
.full-block.if-operation-done,
.full-block.if-artificial-body-part,
.full-block.if-medicine-taken,
.full-block.if-no-menopog,
.full-block.if-not-pregnant,
.if-pain-after-period {
	display: none;
}

.basic-question .full-block > h3 {
	margin-bottom: 10px;
}

.basic-question .radio-inline .label-text,
.basic-question .checkbox-inline .label-text {
	display: inline-block;
	position: relative;
	top: 2px;
	padding-left: 15px;
}

.form-flex .radio-inline,
.form-flex .checkbox-inline {
	margin-left: 0;
	margin-right: 10px;
	margin-bottom: 20px;
	width: calc(25% - 10px);
}

.basic-question input[type=radio] + span.radio-btn {
	top: 2px;
	margin-top: 0;
}

.basic-question .radio-inline+.radio-inline {
	margin-left: 0;
}

.action-wrap {
	margin-bottom: 20px;
}

.question-nav {
	position: absolute;
	right: 0;
	bottom: 0;
	width: 100%;
}

.question-nav .inner {
	padding: 10px 25px;
	border-top: 1px solid #ddd;
}

.question-nav .inner,
.question-nav .inner > .prev-next {
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

	-webkit-box-align: center;
	-ms-flex-align: center;
	-ms-grid-row-align: center;
	align-items: center;

	-webkit-box-pack: end;
	-ms-flex-pack: end;
	justify-content: flex-end;
}

.question-nav .inner > .ignore {
	color: #00baff;
	margin-right: 50px;
	font-size: 18px;
	line-height: 22px;
}

.serial-num {
	color: #fff;
	background-color: #00baff;
	border-radius: 50%;
	width: 50px;
	height: 50px;
	margin-right: 20px;

	display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;

	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;

	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}

.question-nav .inner > .prev-next > .slick-arrow {
	color: #999;
	position: static;
	margin-bottom: 0;
	padding: 0;
	background-color: #fff;
	border: 1px solid #ddd;
	cursor: pointer;
	border-radius: 50%;
}

.question-nav .inner > .prev-next > .slick-arrow > i {
	width: 30px;
	height: 30px;

	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;

	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;

	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}

.question-nav .inner > .prev-next > .slick-arrow:last-child {
	margin-left: 3px;
}

.question-nav .inner > .prev-next > .slick-arrow:hover,
.question-nav .inner > .prev-next > .slick-arrow:focus {
	background-color: #00baff;
	border-color: #00baff;
	color: #fff;
}

.question-nav .inner > .prev-next > .slick-arrow.slick-disabled {
	opacity: 0.5;
	background-color: #fff;
	color: #999;
	border: 1px solid #ddd;
	cursor: not-allowed;
}

.btn-add,
.bn-version .btn-add {
	font-size: 16px;
	line-height: 20px;
	padding: 10px;
	width: 100%;
	color: #fff;
	background-color: #00baff;
	margin-bottom: 0;
	min-height: 50px;
}


.btn-calendar,
.bn-version .btn-calendar {
	font-size: 16px;
	line-height: 20px;
	padding: 10px;
	width: 100%;
	color: #929292;
	background-color: #fff;
	margin-bottom: 0;
	min-height: 50px;
}

.profile-table-select {
	position: relative;
}

.profile-table-select:after {
	content: '\f107';
	display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

	font-size: 20px;
	position: absolute;
	top: 50%;
	right: 35px;
	color: #fff;
	transform: translateY(-50%);
	pointer-events: none;
	z-index: 1;
}

.profile-table-select select {
	cursor: pointer;
	background-color: #00baff;
	color: #fff;
	-webkit-appearance: none;
	-webkit-border-radius: 0px;
}

.table-profile-details {
	background-color: #fff;
	border: 1px solid #ddd;
}

.table-profile-details > tbody > tr > td.button-toggle-wrap {
	position: relative;
	padding: 0;
}

.table-profile-details > tbody > tr > td.button-toggle-wrap.open:before {
	content: " ";
    position: absolute;
    bottom: -2px;
    left: 50%;
    margin-left: -5px;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid #fff;
    z-index: 9999;
}

.table-profile-details > tbody > tr > td.button-toggle-wrap.open:after {
	content: " ";
    position: absolute;
    bottom: -2px;
    left: 50%;
    margin-left: -6px;
    width: 0;
    height: 0;
    border-top: transparent;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 6px solid #e5e5e5;
    z-index: 1;
}

.table-profile-details > tbody > tr > td.button-toggle-wrap > span {
	position: absolute;
    top: 0;
    left: 0;
	width: 100%;
    height: 100%;
}

.table-profile-details > tbody > tr > td.button-toggle-wrap > span > i {
	position: absolute;
    top: 50%;
	left: 50%;
    transform: translateX(-50%) translateY(-50%);;
}

.table-profile-details > thead > tr > td:not(:last-child) {
	color: #00baff;
}

.table-profile-details > thead > tr > td:last-child {
	color: #929292;
}

.table-profile-details > tbody > tr {
	position: relative;
	cursor: pointer;
}

/*.table-profile-details > tbody > tr:hover,
.table-profile-details > tbody > tr:focus {
	background-color: #f4f4f4;
}*/

.table-profile-details > tbody > tr.row-hover-bg {
	background-color: #f4f4f4;
}

.table.profile-in-depth {
	margin-bottom: 0;
}

.table.profile-in-depth > tbody > tr:first-child > td {
	border-top: 0;
}

.table.profile-in-depth > tbody > tr > td {
	border: 0;
	padding-left: 0;
	padding-right: 0;
}

.table.profile-in-depth > tbody > tr > td:nth-child(2) {
	padding: 10px;
}

.table.profile-in-depth > tbody > tr >td:nth-child(-n+2) {
	color: #00baff;
}

.table-profile-details > tbody > tr > td {
	color: #6f6f6f;
}

.table-profile-details > thead > tr > td.center {
	text-align: center;
}

.table-profile-details > tbody > tr > td.center {
	text-align: center;
}


.edit-profile {
	margin-right: 10px;
	cursor: pointer;
}

.delete-item {
	position: relative;
	z-index: 10;
	cursor: pointer;
}

.date-picker-modal .modal-header {
	background-color: #f4f4f4;
}

.date-picker-modal .modal-footer {
	padding: 0;

	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.date-picker-modal .modal-footer .btn {
	text-align: center;

	-webkit-box-flex: 1;
	-ms-flex: 1;
	flex: 1;

	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;

}

.date-picker-modal .modal-footer .btn:first-child {
	border-top-color: #fff;
}

.date-picker-modal .modal-footer .btn:first-child:hover,
.date-picker-modal .modal-footer .btn:first-child:focus {
	border-color: #c0392b;
	background-color: #c0392b;
}

.date-picker-modal .modal-footer .btn+.btn {
	margin: 0;
}

.date-picker-modal .modal-body p {
	margin-bottom: 10px;
}

.date-input-wrap {
	position: relative;
	margin-bottom: 30px;
}

.date-input-wrap:before {
	content: '\e109';
	color: #ddd;
    font-family: 'Glyphicons Halflings';
    font-style: normal;
    font-weight: 400;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

	position: absolute;
	top: 50%;
	left: 15px;
	transform: translateY(-50%);
	z-index: 1;
	pointer-events: none;
}

.date-input-circle {
	color: #ddd;
	font-size: 5px;
	line-height: 10px;
	position: absolute;
	bottom: 0;
	transform: translateY(50%);
	z-index: 1;
}

.date-input-circle.left {
	left: 0;
}

.date-input-circle.right {
	right: 0;
}

.date-input-wrap > .form-control,
.date-input-wrap > .form-control[readonly] {
	text-align: center;
	height: 30px;
	border-top: 0;
	border-left: 0;
	border-right: 0;
	background-color: #fff;
	margin-bottom: 0;
}

.date-input-wrap > .form-control:focus + i,
.date-input-wrap > .form-control:focus + i + i,
.date-input-wrap > .form-control[readonly]:focus + i,
.date-input-wrap > .form-control[readonly]:focus + i + i {
	color: #00baff;
}

.action-btn-wrap {
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
}

.btn-delete {
	background-color: #fff;
	border-color: #ddd;
	border-width: 1px;
}

.btn-delete:hover,
.btn-delete:focus {
	background-color: #c0392b;
	border-color: #c0392b;
}

.modal-header.gray-modal-header {
	background-color: #f4f4f4;
}

.profile-select-wrap {
	position: relative;
}

.profile-select-wrap > .form-control {
	-webkit-appearance: none;
	-webkit-border-radius: 0px;
	height: 40px;
	padding: 10px 25px 10px 10px;
}

.profile-select-wrap > .form-control + i,
.add-date > .form-control + i {
	color: #c1c1c1;
	font-size: 18px;
	line-height: 22px;
	position: absolute;
	top: 50%;
	right: 10px;
	transform: translateY(-50%);
	pointer-events: none;
}

.add-date {
	position: relative;
}

.add-date > .form-control {
	height: 40px;
	background-color: #fff;
}

.profile-input-group {
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    margin-right: -1px;
}

.profile-select-wrap > .form-control {
    margin-left: -1px;
}

.profile-input-group > .form-control:focus + .profile-select-wrap > .form-control {
    border-left-color: #00baff;
}

.profile-input-group > .form-control {
	height: 40px;
	padding: 10px 25px 10px 10px;
}

.form-group .profile-input-group .form-control,
.datepicker.form-control {
    font-size: 16px;
    line-height: 20px;
    color: #222;
}

.datepicker.form-control {
    padding: 10px 25px 10px 10px;
}

.form-add-profile-item .control-label {
	color: #777;
	padding-top: 0;
	line-height: 40px;
}

.modal-edit-profile .modal-body {
	padding: 25px;
}

.modal-edit-profile .action-btn-wrap {
	-webkit-box-pack: start;
	-ms-flex-pack: start;
	justify-content: flex-start;
}

.modal-edit-profile .action-btn-wrap > .btn+.btn {
	margin-left: 10px;
}


@media (min-width: 768px) {
	.date-picker-modal .modal-dialog {
		width: 320px;
	}

	.modal-edit-profile .modal-dialog {
		width: 500px;
	}

	.form-add-profile-item .control-label {
		text-align: left;
	}
}


/* SMS Verification Page Rules
================================
*/

.sms-verify-wrap {
	background-color: #f4f4f4;
	padding: 30px 15px;
}

.sms-verify {
	max-width: 500px;
	background-color: #fff;
	margin: auto;
	border: 1px solid #ddd;
}

.sms-verify header h3 {
	margin: 0;
	color: #656565;
	padding: 15px 25px;
	border-bottom: 1px solid #ddd;
}

.sms-verify .body {
	padding: 30px 25px;
	border-bottom: 1px solid #ddd;
}

.sms-verify .body form {
	margin-bottom: 0;
}

.sms-verify .body .form-control {
	border-color: #ddd;
	margin-bottom: 20px;
}

.sms-verify .body .form-control:focus {
	border-color: #00baff;
}

.sms-verify .body a {
	font-size: 18px;
	line-height: 23px;
	color: #00baff;
}

.sms-verify footer {
	padding: 20px;
}

.sms-verify footer .btn-blue {
	margin-top: 0;
	margin-bottom: 0;
	padding: 10px 50px;
	font-size: 18px;
	line-height: 20px;
}
/*
.tokenfield .token {

}*/
/* Nav Vault Rules */
.nav-vault {
	border: 1px solid #d6d6d6;
}


.nav-vault .dropdown > .dropdown-menu {
	position: static;
	float: none;
}

.nav-vault>li>a {
	position: relative;
	font-size: 15px;
	font-weight: bold;
	color: #7f7f7f;
	padding: 25px;
	border-bottom: 1px solid #d6d6d6;
	cursor: pointer;
	-webkit-transition: all ease 0.3s;
	-moz-transition: all ease 0.3s;
	-ms-transition: all ease 0.3s;
	transition: all ease 0.3s;
}

.nav-vault>li:last-child>a{
	border-bottom: 0;
}

.nav-vault>li>a:hover,
.nav-vault>li>a:focus,
.nav-vault>li.active>a,
.nav-vault>li.active>a:hover,
.nav-vault>li.active>a:focus {
	background-color: #00baff;
	color: #fff;
	border-color: #00baff;
}

.nav-vault>.vault-dropdown>a::after {
	content: "\f105";
	display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: absolute;
    top: 0;
    right: 25px;
    font-size: 20px;
    font-weight: bold;
    line-height: 72px;
}

.nav-vault>.vault-dropdown.active>a::after {
	content: "\f107";
}


.nav-vault li ul>li {
	list-style: none;
}

.nav-vault li ul>li>a {
	display: block;
	padding: 25px 25px 25px 55px;
	color: #747474;
	border-bottom: 1px solid #d6d6d6;
	-moz-transition: all ease 0.3s;
	-webkit-transition: all ease 0.3s;
	-ms-transition: all ease 0.3s;
	transition: all ease 0.3s;
}

.nav-vault li ul>li>a:hover,
.nav-vault li ul>li>a:focus,
.nav-vault li ul>li.active>a,
.nav-vault li ul>li.active>a:hover,
.nav-vault li ul>li.active>a:focus {
	background-color: #d6d6d6;
}

.nav-vault li:last-child ul>li:last-child>a {
	border-bottom: 0;
}

.nav-vault > .vault-dropdown > .vault-dropdown-menu {
	display: none;
	-moz-transition: all ease 0.3s;
	-webkit-transition: all ease 0.3s;
	-ms-transition: all ease 0.3s;
	transition: all ease 0.3s;
}


.nav-vault > .vault-dropdown.active > .vault-dropdown-menu {
	display: block;
}

.vault-search {
	margin-bottom: 20px;
}

.vault-search .btn {
	padding: 10px;
	font-size: 16px;
	line-height: 20px;
	min-height: 50px;
	width: 100%;
	margin-bottom: 0;
}

.vault-search .caret {
	height: 11px;
}

.vault-search .form-control {
	border-color: #ddd;
}

.vault-search .form-control:focus {
	border-color: #00baff;
}

/* Owl Carousel
=========================
*/



.owl-theme .owl-controls{
	margin-top: 10px;
	text-align: center;
}

/* Styling Next and Prev buttons */

.owl-theme .owl-controls .owl-buttons div{
	color: #bdbdbd;
	display: block;
	float: left;
	zoom: 1;
	*display: inline;/*IE7 life-saver */
	margin: 5px;
	padding: 3px 10px;
	font-size: 12px;
	-webkit-border-radius: 30px;
	-moz-border-radius: 30px;
	border-radius: 30px;
	filter: Alpha(Opacity=50);/*IE7 fix*/
	opacity: 0.5;
}
/* Clickable class fix problem with hover on touch devices */
/* Use it for non-touch hover action */
.owl-theme .owl-controls.clickable .owl-buttons div:hover{
	filter: Alpha(Opacity=100);/*IE7 fix*/
	opacity: 1;
	text-decoration: none;
}

/* Styling Pagination*/

.owl-theme .owl-controls .owl-page{
	display: inline-block;
	zoom: 1;
	*display: inline;/*IE7 life-saver */
}
.owl-theme .owl-controls .owl-page span{
	display: block;
	width: 15px;
	height: 15px;
	margin: 5px 7px;
	filter: Alpha(Opacity=50);/*IE7 fix*/
	opacity: 0.5;
	-webkit-border-radius: 20px;
	-moz-border-radius: 20px;
	border-radius: 20px;
	background: transparent;
	border: 2px solid #00baff;
	-webkit-transition: all linear 0.3s;
	-moz-transition: all linear 0.3s;
	-ms-transition: all linear 0.3s;
	-o-transition: all linear 0.3s;
	transition: all linear 0.3s;
}

.owl-theme .owl-controls .owl-page.active span,
.owl-theme .owl-controls.clickable .owl-page:hover span{
	background-color: #00baff;
	filter: Alpha(Opacity=100);/*IE7 fix*/
	opacity: 1;
}

/* If PaginationNumbers is true */

.owl-theme .owl-controls .owl-page span.owl-numbers{
	height: auto;
	width: auto;
	color: #FFF;
	padding: 2px 10px;
	font-size: 12px;
	-webkit-border-radius: 30px;
	-moz-border-radius: 30px;
	border-radius: 30px;
}

/* preloading images */
.owl-item.loading{
	min-height: 150px;
	background: url(AjaxLoader.gif) no-repeat center center
}

#owl-demo .owl-item > div img {
    display: block;
    width: 100%;
    height: auto;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    margin-bottom:4px;
}

#owl-demo .owl-item > div{
  text-align: center;
  padding:50px 0px;
  margin:3px;
  color: #cacaca;
  font-size:32px;
  border:1px white;
}

.wrapper-with-margin{
  margin:0px 50px;
}


.owl-service.owl-theme .owl-controls .owl-buttons div {
  position: absolute;
}

.owl-service.owl-theme .owl-controls .owl-buttons .owl-prev{
  left: -45px;
  top: 25%;
}

.owl-service.owl-theme .owl-controls .owl-buttons .owl-next{
  right: -45px;
  top: 25%;
}

.owl-testimonial.owl-theme .owl-controls .owl-buttons {
  position: absolute;
  bottom: -10px;
  left: 34%;
}

.bn-version .owl-testimonial.owl-theme .owl-controls .owl-buttons {
	bottom: -30px;
}

.owl-testimonial .owl-theme .owl-controls .owl-buttons .owl-prev {
	top: 0;
}

.owl-testimonial .owl-theme .owl-controls .owl-buttons .owl-next {
	top: 0;
}

@media (max-width: 1200px) {
	.owl-testimonial.owl-theme .owl-controls .owl-buttons {
		position: absolute;
	    bottom: -50px;
	}

	.bn-version .owl-testimonial.owl-theme .owl-controls .owl-buttons {
		bottom: -50px;
	}

	.owl-service.owl-theme .owl-controls .owl-buttons .owl-next {
		right: -30px;
	}

	.owl-service.owl-theme .owl-controls .owl-buttons .owl-prev {
		left: -30px;
	}
}

/*@media (max-width: 991px) {
	.owl-service.owl-theme .owl-controls .owl-buttons .owl-prev{
		left: -15px;
	}

	.owl-service.owl-theme .owl-controls .owl-buttons .owl-next{
		right: -15px;
	}

	.owl-testimonial.owl-theme .owl-controls .owl-buttons {
		left: -45px;
	    top: -40px;
	}
}*/

@media (max-width: 767px ) {
	.owl-testimonial.owl-theme .owl-controls .owl-buttons {
		position: absolute;
		bottom: 35px;
		left: 0;
	    text-align: left;
	    padding: 0 15px;
	}

	.bn-version .owl-testimonial.owl-theme .owl-controls .owl-buttons {
		bottom: -50px;
	}

	.owl-service.owl-theme .owl-controls .owl-buttons .owl-next {
		right: 0;
	}

	.owl-service.owl-theme .owl-controls .owl-buttons .owl-prev {
		left: 0;
	}

}

@media (max-width: 400px) {
	.owl-service.owl-theme .owl-controls .owl-buttons .owl-prev{
		left: 0;
	}

	.owl-service.owl-theme .owl-controls .owl-buttons .owl-next{
		right: 0;
	}
}


/* 404 Not Found Page Rules
================================
*/

.not-found {
	padding-top: 100px;
}

.not-found.broken-505 {
	padding: 50px 0;
}

.not-found-content h3 {
	color: #717171;
	font-size: 50px;
	line-height: 53px;
}

.not-found-content p {
	color: #BFBFBF;
	font-size: 20px;
	line-height: 23px;
	margin-top: 10px;
}

.not-found-content .btn {
	border-color: #BFBFBF;
	color: #BFBFBF;
	margin-top: 0;
	margin-bottom: 25px;
}

.not-found.broken-505 .not-found-content .btn {
	margin-top: 50px;
	margin-bottom: 0;
}

.not-found-content .btn:hover,
.not-found-content .btn:focus {
	border-color: #00baff;
	color: #fff;
}

.not-found-right-img {
	margin-top: 30px;
}

.not-found.broken-505 .not-found-right-img {
	margin-top: 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
	.not-found-right-img {
		margin-top: 70px;
	}
}

@media (max-width: 500px) {
	.not-found-content h3 {
		font-size: 35px;
		line-height: 37px;
	}
}
@media (max-width: 400px) {
	.not-found-content h3 {
		font-size: 25px;
		line-height: 27px;
	}
}

/* Terms And Condition Page Rules */
.terms-conditions {
	padding: 50px 0;
}

.terms-single,
.privacy-single {
	padding: 0 15px;
	margin-bottom: 50px;
}

.terms-single:last-child,
.privacy-single:last-child {
	margin-bottom: 0;
}

.privacy-policy ol > li,
.terms-conditions ol > li {
	font-size: 18px;
	line-height: 30px;
	color: #6f6f6f;
}

.terms-single h2,
.privacy-single h2 {
	font-size: 40px;
	line-height: 43px;
	font-weight: bold;
	margin-bottom: 0;
}

.terms-single h3,
.privacy-single h3 {
	color: #454545;
	margin-top: 0;
	margin-bottom: 15px;
}

.terms-single p,
.privacy-single p {
	text-align: justify;
	line-height: 25px;
}

.terms-single .heading-border-wrap,
.privacy-single .heading-border-wrap {
	padding-top: 10px;
	padding-bottom: 25px;
}

/* Privacy Page Rules */
.privacy-policy {
	padding: 50px 0;
}

/* Contact Us Page Rules */
.contact-us {
	padding: 50px 0;
}

.contact-us .form-control {
	border-color: #777;
	color: #000;
}

.contact-us .form-control:focus {
	border-color: #00baff;
}

.contact-us textarea.form-control {
	padding: 15px 25px;
}

.contact-single {
	position: relative;
	padding-left: 50px;
	margin-left: 50px;
	margin-top: -5px;
}

.contact-single:before {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	display: block;
	width: 25px;
	height: 25px;
	background-size: 25px 25px;
	background-repeat: no-repeat;
}

.contact-single.address:before {
	background: url('../img/hospital-directory/svg/location.svg');
}

.contact-single.phone:before {
	background: url('../img/hospital-directory/svg/phone.svg');
}

.contact-single.email:before {
	background: url('../img/hospital-directory/svg/email.svg');
}

.contact-single.web:before {
	background: url('../img/hospital-directory/svg/web.svg');
}

#map {
	width: 100%;
	height: 320px;
}
@media ( max-width: 767px ) {
	form {
		margin-bottom: 50px;
	}
	.contact-single {
		margin-left: 0;
	}
}
