/*begin typeahead custom design for global kdb search */
	#main-search-form .tt-menu {
		top: auto !important;		
		border: 1px solid #00baff;
		border-top: none;
		max-height: 300px;
	  	overflow-y: auto;
	}
	#main-search-form .empty-message {
		padding: 10px;
	}
/* end typeahead custom design */

/*begin typeahead symptom search suggestion design*/
	.symptom .tt-menu {
		top: auto !important;
		bottom: 100%;
		border: 1px solid #00baff;
		border-bottom: none;
		max-height: 300px;
	  	overflow-y: auto;
	}
/*end typeahead symptom search suggestion design*/

/*begin typeahead empty message design*/
	.empty-message {
		padding: 10px;
	}
/*end typeahead empty message design*/

/*begin typeahead search suggestion design*/
	.symptom-search-div .tt-menu {
		top: auto !important;
		bottom: 100%;
		border: 1px solid #00baff;
		border-bottom: none;
		max-height: 300px;
	  	overflow-y: auto;
	}
/*end typeahead search suggestion design*/